import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type PaymentsRefundationFormValues = {
	invoiceNumbers: string
}

export default (values: PaymentsRefundationFormValues) => {
	const errors: FormErrors<PaymentsRefundationFormValues> = {}

	if (
		values.invoiceNumbers &&
		!values.invoiceNumbers
			?.split('\n')
			.join()
			.split(',')
			.map((id) => id.trim())
			.filter(Boolean).length
	) {
		errors.invoiceNumbers = i18next.t('Treba zadať aspoň 1 faktúru')
	}

	if (!values.invoiceNumbers) {
		errors.invoiceNumbers = i18next.t('Treba zadať aspoň 1 faktúru')
	}

	return errors
}
