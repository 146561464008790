import { notification } from 'antd'
import axios from 'axios'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { clearAccessToken, clearProfile } from '../utils/auth'
import { ENDPOINTS } from '../utils/enums'

const Interceptors: FC = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const responseSuccessHandler = (response: any) => {
		if (response.status === 200) {
			response.data?.messages?.forEach((message: { message?: string }) => {
				notification.success({
					message: message.message || ''
				})
			})
		}
		return Promise.resolve(response)
	}

	const responseErrorHandler = (error: any) => {
		const url = error.config?.url
		const status = error.response?.status
		const messages = error.response?.data?.messages

		switch (true) {
			case status === 400 && url === 'animatedThumbnails':
				notification.error({
					message: t('Thumbnail sa nepodarilo vygenerovať')
				})
				break
			case status >= 400 && error.response?.data?.messages:
				if (error.response?.data?.messages) {
					messages.forEach((message: { message?: string }) => {
						notification.error({
							key: 'updatable',
							message: message.message || ''
						})
					})
				} else {
					messages.forEach((message: { message?: string }) => {
						notification.error({
							key: 'updatable',
							message: message.message || ''
						})
					})
				}
				break
			case status === 401:
				if (error?.config?.url === ENDPOINTS.LOGIN) {
					notification.error({
						key: 'updatable',
						message: t('Nesprávne prihlasovacie meno alebo heslo')
					})
				} else {
					notification.warning({
						key: 'updatable',
						message: t('Je potrebné sa znova prihlásiť')
					})
				}
				clearAccessToken()
				clearProfile()
				history.push(t('paths:login|path'))
				break
			case status === 403:
				notification.error({
					key: 'updatable',
					message: messages[0]?.message || t('Prístup k požiadanke bol odoprený')
				})
				break
			case status === 409:
				notification.error({
					key: 'updatable',
					message: messages[0]?.message || t('Operácia nemôže byť vykonaná kvôli konfliktu')
				})
				break
			case status === 500:
				notification.error({
					key: 'updatable',
					message: t('Nastala neočakávaná chyba na serveri, prosím kontaktujte administrátora')
				})
				break
			case status === 502:
				notification.error({
					key: 'updatable',
					message: t('Momentálne prebieha odstávka systému, skúste to prosím neskôr')
				})
				break
			case error?.code === 'ERR_CANCELED':
				// Do not trigger any notification if cancel request happend
				break
			default:
				notification.error({
					key: 'updatable',
					message: t('Nastala neočakávaná chyba, prosím kontaktujte administrátora')
				})
				break
		}

		return Promise.reject(error)
	}

	useEffect(() => {
		axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler)
	}, [])

	return null
}

export default Interceptors
