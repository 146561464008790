import { EyeOutlined } from '@ant-design/icons'
import { Badge, Button, Empty, Modal, Table } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSubscriptionPayments } from '../../../redux/payments/actions'
import { EMPTY_VALUE } from '../../../utils/enums'
import { formatPaymentMethod, formatPaymentState, formatPaymentType, getPaymentStateColor } from '../../../utils/helpers'

const SubscriptionPayments = ({ setShowPaymentsModal, id }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const payments = useSelector((state) => get(state, 'payments.subscriptionPayments.payments'))
	const context = useSelector((state) => get(state, 'payments.subscriptionPayments.context'))
	const isLoadingPayments = useSelector((state) => get(state, 'payments.subscriptionPayments.isLoading'))

	const columns = [
		{
			title: i18next.t('ID platby'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			width: 120
		},
		{
			title: i18next.t('Typ platby'),
			dataIndex: 'paymentType',
			key: 'paymentType',
			sorter: true,
			render: (type, record) => (type ? formatPaymentType(type, get(record, 'subscriptionType')) : EMPTY_VALUE)
		},
		{
			title: i18next.t('Platobná metóda'),
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			sorter: true,
			render: (method) => (method ? formatPaymentMethod(method) : EMPTY_VALUE)
		},
		{
			title: i18next.t('Dátum platby'),
			dataIndex: 'processedAt',
			key: 'processedAt',
			ellipsis: true,
			render: (text) => (text !== 0 ? dayjs(text).format('D.M.YYYY HH:mm') : EMPTY_VALUE)
		},
		{
			title: i18next.t('Stav'),
			dataIndex: 'paymentState',
			key: 'paymentState',
			ellipsis: true,
			render: (state, record) => (
				<Badge
					count={`${formatPaymentState(state, record?.isRefunded, record?.paymentType)}`}
					style={{ backgroundColor: getPaymentStateColor(state) }}
				/>
			)
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (text, record) => (
				<Button
					icon={<EyeOutlined />}
					type={'primary'}
					onClick={(e) => {
						e.stopPropagation()
						history.push(`${t('paths:payment|path')}/${get(record, 'id')}`)
					}}
				/>
			)
		}
	]

	const handleTableChange = (pagination) => {
		dispatch(
			getSubscriptionPayments(id, {
				page: pagination.current
			})
		)
	}

	return (
		<Modal
			visible
			title={t('Priradené platby')}
			okText={t('Pridať')}
			cancelText={t('Zavrieť')}
			width={800}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={() => setShowPaymentsModal(false)}
		>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={payments}
				showSorterTooltip={false}
				rowKey={'id'}
				onChange={handleTableChange}
				pagination={{
					pageSize: 20,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingPayments}
				locale={{
					emptyText: <Empty description={t('Žiadne priradené platby')} />
				}}
				size={'small'}
			/>
		</Modal>
	)
}

SubscriptionPayments.propTypes = {
	setShowPaymentsModal: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired
}

export default SubscriptionPayments
