import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES } from '../../../utils/enums'

export type MotivationAdviceFormValues = {
	id: number
	name: string
	image?: string
	text_sk: string
	text_cz?: string
	text_en?: string
	link_sk?: string
	link_cz?: string
	link_en?: string
}

export default (values: MotivationAdviceFormValues) => {
	const errors: FormErrors<MotivationAdviceFormValues> = {}

	if (!values?.name) {
		errors.name = i18next.t('Toto pole je povinné')
	}

	if (!values.text_sk) {
		errors.text_sk = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		const text = get(values, `text_${item}`)
		const link = get(values, `link_${item}`)
		if (text || link) {
			if (!values[`text_${item}`]) {
				errors[`text_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	return errors
}
