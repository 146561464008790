import dayjs from 'dayjs'
import find from 'lodash/find'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS, LANGUAGE } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { Paths } from '../../types/api'
import {
	GET_MOTIVATION_ADVICES,
	GET_MOTIVATION_ADVICE,
	DELETE_MOTIVATION_ADVICE,
	CREATE_MOTIVATION_ADVICE,
	UPDATE_MOTIVATION_ADVICE,
	MotivationAdvicePayload
} from './types'

export const getMotivationAdvices = (params: Paths.GetAdminMotivationAdvices.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_MOTIVATION_ADVICES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_MOTIVATION_ADVICES, normalizeQueryParams)

		const tableList = map(data.motivationAdvices, (item) => {
			const lang = find(item?.translations, { language: LANGUAGE.SK })

			return {
				key: item?.id,
				name: item?.name,
				text: lang?.text || '',
				link: lang?.link,
				updatedAt: dayjs(item?.updatedAt)
			}
		})

		dispatch({ type: GET_MOTIVATION_ADVICES.DONE, payload: { tableList, context: data.context } })
	} catch (error) {
		dispatch({ type: GET_MOTIVATION_ADVICES.FAILED })
		Promise.reject(error)
	}
}

export const getMotivationAdvice =
	(id: number, onSuccess?: (args: MotivationAdvicePayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: GET_MOTIVATION_ADVICE.START })
		try {
			const { data } = await getReq(ENDPOINTS.GET_MOTIVATION_ADVICE(id))
			dispatch({ type: GET_MOTIVATION_ADVICE.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_MOTIVATION_ADVICE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createMotivationAdvice =
	(body: Paths.PostAdminMotivationAdvices.RequestBody, onSuccess?: (args: number) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_MOTIVATION_ADVICE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			const { data } = await postReq(ENDPOINTS.CREATE_MOTIVATION_ADVICE, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_MOTIVATION_ADVICE.DONE })
			return onSuccess && onSuccess(data?.data?.id)
		} catch (error) {
			dispatch({ type: CREATE_MOTIVATION_ADVICE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updateMotivationAdvice =
	(id: number, body: Paths.PutAdminMotivationAdvicesId.RequestBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_MOTIVATION_ADVICE.START })
		try {
			await putReq(ENDPOINTS.UPDATE_MOTIVATION_ADVICE(id), undefined, body)
			dispatch({ type: UPDATE_MOTIVATION_ADVICE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_MOTIVATION_ADVICE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const deleteMotivationAdvice = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_MOTIVATION_ADVICE.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_MOTIVATION_ADVICE(id))
		dispatch({ type: DELETE_MOTIVATION_ADVICE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_MOTIVATION_ADVICE.FAILED })
		return onFailure && onFailure(error)
	}
}
