import PropTypes from 'prop-types'
import { Button, Empty, Input, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { each, trim } from 'lodash'
import i18next from 'i18next'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const { TextArea } = Input

const RecipeSteps = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const setTitle = (value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			title: value
		})
	}

	const addStep = () => {
		itemContentSetter(item?.id, {
			...item?.content,
			items: [...(item?.content?.items || []), { step: item?.content?.items?.length, key: item?.content?.items?.length, value: '' }]
		})
	}

	const updateStep = (value, row) => {
		const updatedContent = []

		each(item?.content?.items, (contentItem) => {
			if (contentItem?.key === row.key) {
				// eslint-disable-next-line no-param-reassign
				contentItem.value = value
			}

			updatedContent.push(contentItem)
		})

		itemContentSetter(item?.id, {
			...item?.content,
			items: updatedContent
		})
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex direction-col'}>
				<Input
					placeholder={t('Sem zadajte nadpis')}
					className={'input-field'}
					size={'large'}
					value={item?.content?.title}
					maxLength={INPUT_MAX_LENGTH}
					onChange={(e) => setTitle(e.target.value)}
					onBlur={(e) => setTitle(trim(e.target.value))}
				/>
				<Table
					className={'general-table'}
					style={{ width: '100%' }}
					columns={[
						{
							title: t('Krok'),
							dataIndex: 'step',
							key: 'step',
							render: (step) => step + 1
						},
						{
							title: t('Popis kroku'),
							dataIndex: 'value',
							key: 'value',
							render: (value, row) => (
								<TextArea
									placeholder={t('Sem popíšte aktuálny krok')}
									className={'text-area'}
									value={value}
									onChange={(e) => updateStep(e.target.value, row)}
									allowClear
								/>
							)
						}
					]}
					dataSource={item?.content?.items}
					locale={{
						emptyText: <Empty description={i18next.t('Žiadne dáta')} />
					}}
					size={'small'}
					pagination={false}
				/>
				<Button type={'primary'} onClick={addStep}>
					{t('Pridať krok')}
				</Button>
			</div>
		</div>
	)
}

RecipeSteps.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			title: PropTypes.string,
			items: PropTypes.arrayOf(
				PropTypes.shape({
					key: PropTypes.number,
					step: PropTypes.number,
					value: PropTypes.string
				})
			)
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

RecipeSteps.defaultProps = {
	item: {}
}

export default RecipeSteps
