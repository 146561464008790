import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Modal, notification, Row, Spin, Tabs, Tooltip } from 'antd'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import slugify from 'slugify'
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import DateField from '../../../atoms/form/DateField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'
import DetailHeader from '../../../components/DetailHeader'
import { deleteExercise, duplicateExercise } from '../../../redux/exercises/actions'
import { getBlogPostList, getEshopProductList, getExerciseList, getMotivationAdviceList, getProgramList, getVideosList } from '../../../redux/lists/actions'
import { ENDPOINTS, FORMS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { uploadFile } from '../../../utils/fileUploader'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { getReq } from '../../../utils/request'
import validateForm, { ExerciseFormValues } from './validateExerciseForm'
import { RootState } from '../../../redux'
import { IFieldLabels } from '../../../types/interfaces'

const { TabPane } = Tabs

type ExerciseFormProps = {
	isCreate?: boolean
}

const ExerciseForm = ({ handleSubmit, invalid, pristine, isCreate = false }: ExerciseFormProps & InjectedFormProps<ExerciseFormValues, ExerciseFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.EXERCISE_FORM)(state)) as ExerciseFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.EXERCISE_FORM))
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)
	const blogPosts = useSelector((state: RootState) => state.lists?.list?.blogPosts)
	const programs = useSelector((state: RootState) => state.lists?.list?.programs)
	const videos = useSelector((state: RootState) => state.lists?.list?.videos)
	const exercises = useSelector((state: RootState) => state.lists?.list?.exercises)
	const products = useSelector((state: RootState) => state.lists?.list?.upsellProducts)
	const motivationAdvices = useSelector((state: RootState) => state.lists?.list?.motivationAdvices)

	// states
	const [isLoading, setIsLoading] = useState(false)
	const [fetchPreferredLivestream, setFetchPreferredLivestream] = useState({ isLoading: false })
	const [selectedTab, setSelectedTab] = useState('sk')
	const [image, setImage] = useState({
		isLoading: false,
		imageUrl: ''
	})

	// constants
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		briefDescription_sk: t('Krátky popis'),
		detailedDescription_sk: t('Detailný popis'),
		image: t('Fotka (upload)'),
		video: t('Video (link na Vimeo)'),
		bonusExercises: t('Bonusové cvičenia'),
		order: t('Poradie'),
		programs: t('Program'),
		liveVideoID: t('Livestream video'),
		liveStreamPublishDate: t('Dátum a čas vysielania livestreamu')
	}

	const changeValue = (name: string, value: any) => dispatch(change(FORMS.EXERCISE_FORM, name, value))

	useEffect(() => {
		if ((fieldValues?.isPublished === true && fieldValues?.publishDate === null) || fieldValues?.publishDate === undefined) {
			dispatch(change(FORMS.EXERCISE_FORM, 'publishDate', dayjs()))
		}
	}, [fieldValues?.isPublished])

	useEffect(() => {
		const initDefaultLiveStream = async () => {
			setFetchPreferredLivestream({ isLoading: true })
			try {
				const { data } = await getReq(ENDPOINTS.GET_PREFFERED_LIVESTREAM)
				if (data?.video?.id !== null) {
					changeValue('liveVideoID', [
						{
							id: data?.video?.id,
							title: data?.video?.name || 'Preferované livestream video',
							urlSlug: data?.video?.urlSlug
						}
					])
				}
			} catch (e) {
				notification.warning({
					message: t('Nemožno doplniť preferovanť živý prenos')
				})
			}
			setFetchPreferredLivestream({ isLoading: false })
		}
		if (fieldValues?.isLiveStream && (!fieldValues?.liveVideoID || fieldValues?.liveVideoID?.length === 0)) {
			initDefaultLiveStream()
		}
	}, [fieldValues?.isLiveStream])

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať cvičenie?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteExercise(get(fieldValues, 'id'), () => {
						history.push(t('paths:exercises|path'))
					})
				)
			}
		})
	}

	const handleDuplicate = () => {
		Modal.confirm({
			title: t('Skutočne si prajete duplikovať cvičenie?'),
			icon: <CopyOutlined />,
			okText: t('Duplikovať'),
			cancelText: t('Zrušiť'),
			okType: 'primary',
			onOk: () => {
				dispatch(
					duplicateExercise(get(fieldValues, 'id'), (exerciseID: number) => {
						history.push(`${t('paths:exercise|path')}/${exerciseID}`)
					})
				)
			}
		})
	}

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.EXERCISE_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, lang: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${lang}`)
		}
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.EXERCISE_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.EXERCISE_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	const videoDetailDataButton = () => {
		const initVideoDetailData = async () => {
			try {
				setIsLoading(true)
				const videoID = fieldValues?.video[0]?.id || 0
				const { data } = await getReq(ENDPOINTS.GET_VIDEO(videoID))
				const videoTransalteData = data?.video?.translations

				map(LANGUAGES, (item) => {
					changeValue(`name_${item}`, find(videoTransalteData, { language: item })?.name)
					changeValue(`urlSlug_${item}`, find(videoTransalteData, { language: item })?.urlSlug)
					changeValue(`detailedDescription_${item}`, find(videoTransalteData, { language: item })?.detailedDescription)
				})
				await Promise.all([
					changeValue('image', data?.video?.image),
					changeValue('order', data?.video?.order),
					changeValue('publishDate', data?.video?.publishDate),
					changeValue('isPublished', data?.video?.isPublished),
					changeValue('recommendedBlogPosts', data?.video?.recommendedBlogPosts || []),
					changeValue('motivationAdvices', [
						{
							id: data?.video?.motivationAdvice?.id,
							title: data?.video?.motivationAdvice?.name
						}
					])
				])
			} catch (e) {
				notification.warning({
					message: t('Nemožno doplniť dáta')
				})
			}

			setIsLoading(false)
		}

		const handleInitVideoData = () => {
			Modal.confirm({
				title: t('Skutočne si prajete doplinť detail cvičenia? Vaše aktuálne data budú prepisané.'),
				icon: <EditOutlined />,
				okText: t('Doplniť'),
				cancelText: t('Zrušiť'),
				okType: 'primary',
				onOk: initVideoDetailData
			})
		}

		return <Button onClick={handleInitVideoData}>{t('Doplniť údaje do cvičenia')}</Button>
	}

	return (
		<Spin spinning={isLoading}>
			<form onSubmit={handleSubmit}>
				<Row gutter={16}>
					<Col span={18} className={'grid'}>
						<div className={'flex direction-col justify-start main-content'}>
							<DetailHeader
								title={isCreate ? t('Pridať cvičenie') : t('Detail cvičenia')}
								detailButtons={
									!isCreate && [
										{
											title: t('Pridať nové cvičenie'),
											path: t('paths:exerciseCreate|path')
										}
									]
								}
							/>
							<Field
								name={'video'}
								component={AsyncTransferField}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
									}
								}}
								label={fieldValues?.isLiveStream === true ? t('Video archivneho prenosu') : t('Video')}
								required={fieldValues?.isLiveStream !== true}
								button={fieldValues?.video?.length > 0 && videoDetailDataButton()}
								reduxFetch={(config: any) => dispatch(getVideosList(config))}
								modalDataSource={videos}
								showPagination={false}
								context={listContext}
								maxItems={1}
								onChange={(video: any) => {
									changeValue('name_sk', video[0]?.title || 'Bez názvu')
									changeValue('urlSlug_sk', video[0]?.urlSlug || '---')
								}}
							/>

							<div className={'highlighted'} style={{ border: fieldValues?.isLiveStream ? '2px solid #FEA385' : '1px solid #d9d9d9' }}>
								<Field name={'isLiveStream'} component={SwitchField} label={t('Cvičenie je livestream')} />
								{fieldValues?.isLiveStream === true && (
									<>
										<Field
											name={'liveVideoID'}
											component={AsyncTransferField}
											label={t('Livestream video')}
											disabledUse={fetchPreferredLivestream?.isLoading}
											required
											customButton={{
												icon: <EyeOutlined />,
												handler: (id: number) => {
													window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
												}
											}}
											reduxFetch={(config: any) => dispatch(getVideosList(config))}
											modalDataSource={videos}
											showPagination={false}
											context={listContext}
											maxItems={1}
											onChange={(liveVideoID: any) => {
												changeValue('name_sk', liveVideoID[0]?.title || 'Preferované livestream video')
												changeValue('urlSlug_sk', liveVideoID[0]?.urlSlug || '---')
											}}
										/>
										<div style={{ height: '8px' }} />
										<Field
											required
											name={'liveStreamPublishDate'}
											component={DateField}
											label={t('Dátum a čas vysielania livestreamu')}
											dttmFormat={'D.M.YYYY HH:mm'}
											showTime
										/>
									</>
								)}
							</div>

							<Field
								name={'programs'}
								component={AsyncTransferField}
								label={t('Program')}
								showPagination={false}
								reduxFetch={(config: any) => dispatch(getProgramList(config))}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:programs|path')}/${id}`, '_blank')
									}
								}}
								modalDataSource={programs}
								context={listContext}
								maxItems={1}
								required
							/>
							<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'} onTabClick={(key) => setSelectedTab(key)}>
								{map(LANGUAGES, (item) => (
									<TabPane tab={getLanguageName(item)} key={item}>
										{fieldValues?.video?.length > 0 && (
											<>
												<Field
													name={`name_${item}`}
													component={TextField}
													label={t('Názov')}
													onInput={(e: any) => changeUrlSlug(e, item)}
													size={'large'}
													disabled
												/>

												<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} disabled />
											</>
										)}

										<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} required />
									</TabPane>
								))}
							</Tabs>

							<Field
								name={'bonusExercises'}
								component={AsyncTransferField}
								label={t('Bonusové cvičenia')}
								reduxFetch={(config: any) => dispatch(getExerciseList(config))}
								modalDataSource={exercises}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
									}
								}}
								context={listContext}
								showPagination={false}
								hasAlternateName
							/>

							<Field
								name={'upsellProducts'}
								component={AsyncTransferField}
								label={t('Upsell produkty')}
								reduxFetch={(config: any) => dispatch(getEshopProductList(config))}
								modalDataSource={products}
								context={listContext}
							/>

							<Field
								name={'easyExerciseVariant'}
								component={AsyncTransferField}
								label={t('Ľahšia verzia cvičenia')}
								reduxFetch={(config: any) => dispatch(getExerciseList(config))}
								modalDataSource={exercises}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
									}
								}}
								context={listContext}
								showPagination={false}
								maxItems={1}
							/>

							<Field
								name={'hardExerciseVariant'}
								component={AsyncTransferField}
								label={t('Náročnejšia verzia cvičenia')}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
									}
								}}
								reduxFetch={(config: any) => dispatch(getExerciseList(config))}
								modalDataSource={exercises}
								context={listContext}
								showPagination={false}
								maxItems={1}
							/>

							<Field
								name={'recommendedExercises'}
								component={AsyncTransferField}
								label={t('Odporúčané cvičenia')}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
									}
								}}
								reduxFetch={(config: any) => dispatch(getExerciseList(config))}
								modalDataSource={exercises}
								context={listContext}
							/>

							<Field
								name={'motivationAdvices'}
								component={AsyncTransferField}
								label={t('Motivačný tip')}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:motivationAdvice|path')}/${id}`, '_blank')
									}
								}}
								reduxFetch={(config: any) => dispatch(getMotivationAdviceList(config))}
								modalDataSource={motivationAdvices}
								context={listContext}
								maxItems={1}
							/>

							<Field
								name={'recommendedBlogPosts'}
								component={AsyncTransferField}
								label={t('Odporúčané blogové články')}
								customButton={{
									icon: <EyeOutlined />,
									handler: (id: number) => {
										window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
									}
								}}
								reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
								modalDataSource={blogPosts}
								context={listContext}
							/>
						</div>
					</Col>
					<Col span={6} className={'grid'}>
						<div className={'flex direction-col justify-start sidebar-content'}>
							<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

							{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

							<Field
								name={'image'}
								imageUrl={get(fieldValues, 'image')}
								component={UploadInputField}
								customHelper={t('recommendedSize|videoThumbnail')}
								label={t('Fotka (upload)')}
								customRequest={uploadImage}
								isLoading={image.isLoading}
								required
							/>

							<Field name={'publishDate'} component={DateField} label={t('Dátum a čas publikovania')} dttmFormat={'D.M.YYYY HH:mm'} showTime />
							<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

							<div className={'flex direction-col justify-center'}>
								{!isCreate && (
									<Button icon={<CopyOutlined />} onClick={handleDuplicate} type={'default'} style={{ margin: '20px' }}>
										{t('Duplikovať')}
									</Button>
								)}
								{!isCreate && (
									<Button
										icon={<EyeOutlined />}
										onClick={() => {
											let buildUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:exerciseWeb|path')}/`
											if (fieldValues?.isPublished) {
												buildUrl += get(fieldValues, `urlSlug_${selectedTab}`)
											} else {
												buildUrl += get(fieldValues, 'id')
											}
											if (fieldValues?.programs) {
												buildUrl += `?program=${fieldValues?.programs[0]?.urlSlug || 'none'}`
											}
											window.open(buildUrl, '_blank')
										}}
										type={'default'}
										style={{ margin: '20px' }}
									>
										{t('Zobraziť na webe')}
									</Button>
								)}
								<Tooltip
									title={
										invalid
											? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
													getFieldLabel(field, fieldLabels)
												)}`
											: null
									}
									placement={'bottom'}
								>
									<Button
										icon={<SaveOutlined />}
										onClick={handleSubmit}
										disabled={pristine || invalid}
										type={'primary'}
										style={{ margin: '20px' }}
									>
										{t('Uložiť')}
									</Button>
								</Tooltip>

								{!isCreate && (
									<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
										{t('Vymazať cvičenie')}
									</Button>
								)}
							</div>
						</div>
					</Col>
				</Row>
			</form>
		</Spin>
	)
}

export default reduxForm<ExerciseFormValues, ExerciseFormProps>({
	form: FORMS.EXERCISE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateForm
})(ExerciseForm)
