import { Button, Empty, Form, Input, Popconfirm, Select, Table } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, LinkOutlined, PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Link, useHistory } from 'react-router-dom'
import { SelectValue } from 'antd/lib/select'
import { capitalize, debounce, get, lowerCase, map } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { deleteDownload, loadDownloadCategories, loadDownloads } from '../../redux/downloads/actions'
import { setFilters } from '../../redux/filters/actions'

// helpers
import { getLanguageName, setFiltersForPage } from '../../utils/helpers'

// enums
import { EMPTY_VALUE, LANGUAGE, LANGUAGES } from '../../utils/enums'

// images
import flagSlovakia from '../../assets/images/icons/flag-slovakia.svg'
import flagCzechia from '../../assets/images/icons/flag-czechia.svg'
import OrderRepresent from '../../atoms/OrderRepresent'

const PAGE_SIZE = 20
const { Option } = Select

interface IFilter {
	language: SelectValue | undefined
	page?: number
	order?: number
	search?: string
	categories?: string[]
}

interface IRecord {
	id: number
	language: string
	title: string
	description: string
	categories: string[]
}

const Downloads = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const isLoading = useSelector((state) => get(state, 'downloads.items.isLoading'))
	const context = useSelector((state) => get(state, 'downloads.items.context'))
	const downloads = useSelector((state) => get(state, 'downloads.items.list')) || []
	const downloadCategories = useSelector((state) => get(state, 'downloads.categories.list')) || []
	const persistFilter = useSelector((state) => get(state, 'filters'))
	const [filter, setFilter] = useState<IFilter>({
		...setFiltersForPage(t('paths:downloads|key'), persistFilter),
		language: ''
	})

	const body = {
		limit: PAGE_SIZE,
		page: 1,
		...filter
	}

	const columns: ColumnsType<IRecord> = [
		{
			title: '',
			key: 'language',
			dataIndex: 'language',
			width: '45px',
			render: (value: string) => {
				switch (value) {
					case LANGUAGE.SK:
						return <img src={flagSlovakia} alt={value} className={'lng-flag'} />
					case LANGUAGE.CZ:
						return <img src={flagCzechia} alt={value} className={'lng-flag'} />
					default:
						return value
				}
			}
		},
		{
			title: t('Názov'),
			key: 'title',
			dataIndex: 'title',
			sorter: true,
			width: 180,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Počet stiahnutí'),
			key: 'downloadsCount',
			dataIndex: 'downloadsCount',
			width: 70,
			align: 'center',
			ellipsis: true,
			render: (value: string) => <OrderRepresent views value={value || 0} tooltip={t('Stiahnutia')} />
		},
		{
			title: t('Popis'),
			key: 'description',
			dataIndex: 'description',
			width: '280px',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Kategória'),
			key: 'category',
			dataIndex: 'category',
			width: '180px',
			ellipsis: true,
			render: (value: any) => (value ? value.name : EMPTY_VALUE)
		},
		{
			title: '',
			key: 'operation',
			width: '90px',
			fixed: 'right',
			render: (record: IRecord) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:downloadDetail|path')}/${get(record, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<PlusCircleOutlined style={{ color: 'red' }} />}
						okText={t('Vymazať')}
						okButtonProps={{
							size: 'small'
						}}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteDownload(get(record, 'id'), () => {
									dispatch(loadDownloads(body) as any)
								}) as any
							)
						}}
						cancelText={t('Zrušiť')}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
						onCancel={(e: any) => e.stopPropagation()}
					>
						<Button icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field === 'date' ? 'date' : sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debouncedSearch = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleSearch = (e: any) => {
		if (get(e, 'target.value.length') > 2 || get(e, 'target.value.length') === 0) {
			debouncedSearch(e.target.value)
		}
	}

	const resetFilter = () => {
		setFilter({
			language: ''
		})
	}

	useEffect(() => {
		dispatch(loadDownloadCategories() as any)
	}, [dispatch])

	useEffect(() => {
		dispatch(loadDownloads(body) as any)
		dispatch(setFilters(t('paths:downloads|key'), body) as any)
	}, [dispatch, t, filter])

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} style={{ width: 300 }} onChange={handleSearch} allowClear />
				</Form.Item>
				<Button
					icon={<UserAddOutlined />}
					type={'primary'}
					onClick={() => {
						history.push({
							pathname: t('paths:downloadCreate|path')
						})
					}}
				>
					{t('Pridať súbor')}
				</Button>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap', marginBottom: 20 }}>
				<Form.Item>
					<Select
						placeholder={t('Kategórie')}
						value={filter.categories}
						mode={'multiple'}
						style={{ width: 300, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, categories: value, page: 1 })}
						filterOption={(input, option) => lowerCase(option?.children as any).indexOf(lowerCase(input)) >= 0}
					>
						{map(downloadCategories, (value: any, index) => (
							<Option key={`category-${index}`} value={value.id}>
								{value.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Select style={{ width: 200, marginRight: 20 }} onChange={(value) => setFilter({ ...filter, language: value })} value={filter?.language}>
						<Option value={''}>{t('Všetky jazyky')}</Option>
						{map(LANGUAGES, (language, index) => (
							<Option key={index} value={language}>
								{capitalize(getLanguageName(language))}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={downloads}
				onChange={handleTableChange}
				showSorterTooltip={false}
				rowKey={'id'}
				style={{ marginTop: -20 }}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoading}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:downloadDetail|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Downloads
