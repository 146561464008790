import { Paths } from '../../types/api'

export type DownloadCategoriesPayload = Paths.GetAdminDownloadCategories.Responses.$200
export type DownloadCategoryPayload = Paths.GetAdminDownloadCategoriesCategoryId.Responses.$200

export enum GET_DOWNLOAD_CATEGORIES {
	START = 'GET_DOWNLOAD_CATEGORIES_START',
	DONE = 'GET_DOWNLOAD_CATEGORIES_DONE',
	FAILED = 'GET_DOWNLOAD_CATEGORIES_FAILED'
}

export enum GET_DOWNLOAD_CATEGORY {
	START = 'GET_DOWNLOAD_CATEGORY_START',
	DONE = 'GET_DOWNLOAD_CATEGORY_DONE',
	FAILED = 'GET_DOWNLOAD_CATEGORY_FAILED'
}
export enum UPDATE_DOWNLOAD_CATEGORY {
	START = 'UPDATE_DOWNLOAD_CATEGORY_START',
	DONE = 'UPDATE_DOWNLOAD_CATEGORY_DONE',
	FAILED = 'UPDATE_DOWNLOAD_CATEGORY_FAILED'
}

export enum CREATE_DOWNLOAD_CATEGORY {
	START = 'CREATE_DOWNLOAD_CATEGORY_START',
	DONE = 'CREATE_DOWNLOAD_CATEGORY_DONE',
	FAILED = 'CREATE_DOWNLOAD_CATEGORY_FAILED'
}

export enum DELETE_DOWNLOAD_CATEGORY {
	START = 'DELETE_DOWNLOAD_CATEGORIES_START',
	DONE = 'DELETE_DOWNLOAD_CATEGORIES_DONE',
	FAILED = 'DELETE_DOWNLOAD_CATEGORIES_FAILED'
}
