import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type AccessoryFormValues = {
	id: number
	name_sk: string
	image: string
}

export default (values: AccessoryFormValues) => {
	const errors: FormErrors<AccessoryFormValues> = {}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	return errors
}
