import { RESET_FILTERS, SET_FILTERS } from './types'

export const resetFilters = () => (dispatch) => {
	dispatch({
		type: RESET_FILTERS
	})
}

export const setFilters = (page, filters) => (dispatch) => {
	dispatch({
		type: SET_FILTERS,
		payload: {
			page,
			filters
		}
	})
}
