import {
	LOAD_SELECT_INSTRUCTORS,
	LOAD_SELECT_PROGRAMS,
	LOAD_SELECT_EXERCISES,
	LOAD_SELECT_TARGETS,
	LOAD_SELECT_MOTIVATION_ADVICES,
	LOAD_SELECT_ACCESSORIES,
	LOAD_SELECT_BLOG_POSTS,
	LOAD_SELECT_BLOG_CATEGORIES,
	LOAD_SELECT_PREFERENCES
} from './types'

export const initState = {
	instructors: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	programs: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	exercises: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	targets: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	motivationAdvices: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	accessories: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	blogPosts: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	blogCategories: {
		list: {},
		isLoading: false,
		isFailure: false
	},
	preferences: {
		origin: {},
		list: [],
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOAD_SELECT_INSTRUCTORS.START:
			return {
				...initState,
				...state,
				instructors: {
					...state.programs,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_INSTRUCTORS.DONE:
			return {
				...initState,
				...state,
				instructors: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_INSTRUCTORS.FAILED:
			return {
				...state,
				instructors: {
					...state.programs,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_PROGRAMS.START:
			return {
				...initState,
				...state,
				programs: {
					...state.programs,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_PROGRAMS.DONE:
			return {
				...initState,
				...state,
				programs: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_PROGRAMS.FAILED:
			return {
				...state,
				programs: {
					...state.programs,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_EXERCISES.START:
			return {
				...initState,
				...state,
				exercises: {
					...state.exercises,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_EXERCISES.DONE:
			return {
				...initState,
				...state,
				exercises: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_EXERCISES.FAILED:
			return {
				...state,
				exercises: {
					...state.exercises,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_TARGETS.START:
			return {
				...initState,
				...state,
				targets: {
					...state.targets,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_TARGETS.DONE:
			return {
				...initState,
				...state,
				targets: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_TARGETS.FAILED:
			return {
				...state,
				targets: {
					...state.targets,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_MOTIVATION_ADVICES.START:
			return {
				...initState,
				...state,
				motivationAdvices: {
					...state.motivationAdvices,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_MOTIVATION_ADVICES.DONE:
			return {
				...initState,
				...state,
				motivationAdvices: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_MOTIVATION_ADVICES.FAILED:
			return {
				...state,
				motivationAdvices: {
					...state.motivationAdvices,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_ACCESSORIES.START:
			return {
				...initState,
				...state,
				accessories: {
					...state.accessories,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_ACCESSORIES.DONE:
			return {
				...initState,
				...state,
				accessories: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_ACCESSORIES.FAILED:
			return {
				...state,
				accessories: {
					...state.accessories,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_BLOG_POSTS.START:
			return {
				...initState,
				...state,
				blogPosts: {
					...state.blogPosts,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_BLOG_POSTS.DONE:
			return {
				...initState,
				...state,
				blogPosts: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_BLOG_POSTS.FAILED:
			return {
				...state,
				blogPosts: {
					...state.blogPosts,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_BLOG_CATEGORIES.START:
			return {
				...initState,
				...state,
				blogCategories: {
					...state.blogCategories,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_BLOG_CATEGORIES.DONE:
			return {
				...initState,
				...state,
				blogCategories: {
					list: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_BLOG_CATEGORIES.FAILED:
			return {
				...state,
				blogCategories: {
					...state.blogCategories,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_SELECT_PREFERENCES.START:
			return {
				...initState,
				...state,
				preferences: {
					...state.preferences,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_SELECT_PREFERENCES.DONE:
			return {
				...initState,
				...state,
				preferences: {
					origin: action.payload.origin,
					list: action.payload.list,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_SELECT_PREFERENCES.FAILED:
			return {
				...state,
				preferences: {
					...state.preferences,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
