import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { debounce, find, get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteProgram } from '../../../redux/programs/actions'
import {
	getBlogPostList,
	getEshopProductList,
	getExerciseList,
	getMotivationAdviceList,
	getPreferenceList,
	getTrainerList,
	getVideosList
} from '../../../redux/lists/actions'

// types
import { IFieldLabels } from '../../../types/interfaces'

// utils
import { FORMS, LANGUAGE, LANGUAGES, PLATFORM_OPTIONS, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validateProgramForm, { ProgramFormValues } from './validateProgramForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CircleColorField from '../../../atoms/form/CircleColorField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'

const { TabPane } = Tabs
const { Option } = Select

type ProgramFormProps = {
	isCreate: boolean
}

const ProgramForm = ({ handleSubmit, invalid, pristine, isCreate = false }: ProgramFormProps & InjectedFormProps<{}, ProgramFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PROGRAM_FORM)(state)) as ProgramFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PROGRAM_FORM))
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)

	const preferences = useSelector((state: RootState) => state.lists?.list?.preferences)
	const products = useSelector((state: RootState) => state.lists?.list.upsellProducts)
	const videos = useSelector((state: RootState) => state.lists?.list.videos)
	const motivationAdvices = useSelector((state: RootState) => state.lists?.list.motivationAdvices)
	const blogPosts = useSelector((state: RootState) => state.lists?.list.blogPosts)
	const exercises = useSelector((state: RootState) => state.lists?.list.exercises)
	const trainers = useSelector((state: RootState) => state.lists?.list.trainers)

	// STATES
	const [image, setImage] = useState({ isLoading: false })
	const [logo, setLogo] = useState({ isLoading: false })
	const [inverseLogo, setInverseLogo] = useState({ isLoading: false })
	const [whiteLogo, setWhiteLogo] = useState({ isLoading: false })
	const [selectedTab, setSelectedTab] = useState('sk')

	// constants
	const preferencesList = map(preferences, (item) => ({ id: item.id, title: item.title }))
	const motivationAdvicesList = map(motivationAdvices, (item) => ({ id: item.id, title: item.title }))
	const blogPostsList = map(blogPosts, (item) => ({ id: item.id, title: item.title }))
	const exercisesList = map(exercises, (item) => ({ id: item.id, title: item.title, isVisibleInCalendar: item.isVisibleInCalendar, program: item.program }))
	const trainersList = map(trainers, (item) => ({ id: item.id, title: item.title }))
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		briefDescription_sk: t('Krátky popis'),
		detailedDescription_sk: t('Detailný popis'),
		logo_sk: t('Logo (upload)'),
		image_sk: t('Titulný obrázok (upload)'),
		videoPreview_sk: t('Náhľadové video (link na Vimeo)'),
		order: t('Poradie')
	}

	useEffect(() => {
		if ((fieldValues?.isPublished === true && fieldValues?.publishDate === null) || fieldValues?.publishDate === undefined) {
			dispatch(change(FORMS.PROGRAM_FORM, 'publishDate', dayjs()))
		}
	}, [fieldValues?.isPublished])

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.PROGRAM_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, language: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${language}`)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať program?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteProgram(get(fieldValues, 'id'), () => {
						history.push(t('paths:programs|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any, language: LANGUAGE) => {
		setImage({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `image_${language}`, fileUrl))
			setImage({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadLogo = async ({ file }: any, language: LANGUAGE) => {
		setLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `logo_${language}`, fileUrl))
			setLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadInverseLogo = async ({ file }: any, language: LANGUAGE) => {
		setInverseLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `inverseLogo_${language}`, fileUrl))
			setInverseLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadWhiteLogo = async ({ file }: any, language: LANGUAGE) => {
		setWhiteLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `whiteLogo_${language}`, fileUrl))
			setWhiteLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const removeWhiteLogo = (language: LANGUAGE) => {
		dispatch(change(FORMS.PROGRAM_FORM, `whiteLogo_${language}`, null))
	}

	const handleSwitchExercisesChange = (value: any, record: any, attribute: any) => {
		let focusedRow = find(fieldValues?.exercises, ['id', record?.id])
		const exerciseArray: any[] = []
		map(fieldValues?.exercises, (item) => {
			if (item === focusedRow) {
				focusedRow = {
					...focusedRow,
					[attribute]: value
				}
				exerciseArray.push(focusedRow)
			} else {
				exerciseArray.push(item)
			}
		})
		dispatch(change(FORMS.PROGRAM_FORM, 'exercises', exerciseArray))
	}

	const handleSwitchHardExercisesChange = (value: any, record: any, attribute: any) => {
		let focusedRow = find(fieldValues?.hardExercises, ['id', record?.id])
		const hardExerciseArray: any[] = []
		map(fieldValues?.hardExercises, (item) => {
			if (item === focusedRow) {
				focusedRow = {
					...focusedRow,
					[attribute]: value
				}
				hardExerciseArray.push(focusedRow)
			} else {
				hardExerciseArray.push(item)
			}
		})
		dispatch(change(FORMS.PROGRAM_FORM, 'hardExercises', hardExerciseArray))
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať program') : t('Detail programu')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový program'),
										path: t('paths:programCreate|path')
									},
									{
										title: t('Pridať novú výzvu'),
										path: t('paths:challengeCreate|path')
									}
								]
							}
						/>
						<Field
							name={'allowedPlatforms'}
							component={CustomOptionsSelectField}
							label={t('Platformy (na ktorých sa zobrazuje)')}
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							options={map(PLATFORM_OPTIONS, (part, index) => (
								<Option key={index} value={get(part, 'value')}>
									{get(part, 'label') || ''}
								</Option>
							))}
							multiple
							required
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'} onTabClick={(key) => setSelectedTab(key)}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`name_${item}`}
										component={TextField}
										label={t('Názov')}
										onInput={(e: any) => changeUrlSlug(e, item)}
										size={'large'}
										required
									/>
									<Row>
										<Col span={6}>
											<Field
												name={`image_${item}`}
												imageUrl={get(fieldValues, `image_${item}`)}
												component={UploadInputField}
												label={t('Titulný obrázok (upload)')}
												customHelper={t('recommendedSize|videoThumbnail')}
												customRequest={(file: any) => uploadImage(file, item)}
												isLoading={image.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`logo_${item}`}
												imageUrl={get(fieldValues, `logo_${item}`)}
												component={UploadInputField}
												label={t('Logo (upload)')}
												customRequest={(file: any) => uploadLogo(file, item)}
												customHelper={t('recommendedSize|programLogo')}
												isLoading={logo.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`inverseLogo_${item}`}
												imageUrl={get(fieldValues, `inverseLogo_${item}`)}
												component={UploadInputField}
												label={t('Inverzne logo (upload)')}
												customClass={'logo-uploader-dark'}
												customHelper={t('recommendedSize|programLogo')}
												customRequest={(file: any) => uploadInverseLogo(file, item)}
												isLoading={inverseLogo.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`whiteLogo_${item}`}
												imageUrl={get(fieldValues, `whiteLogo_${item}`)}
												component={UploadInputField}
												label={t('Biele logo (upload)')}
												customClass={'logo-uploader-dark'}
												customHelper={t('recommendedSize|programLogo')}
												customRequest={(file: any) => uploadWhiteLogo(file, item)}
												isLoading={whiteLogo.isLoading}
												onRemove={() => removeWhiteLogo(item)}
											/>
										</Col>
									</Row>

									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required />

									<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} required />

									<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} required />

									<Field
										name={`videoPreviewID_${item}`}
										component={AsyncTransferField}
										label={t('Náhľadové video (link na Vimeo)')}
										customButton={{
											icon: <EyeOutlined />,
											handler: (id: number) => {
												window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
											}
										}}
										required
										reduxFetch={(config: any) => dispatch(getVideosList(config))}
										modalDataSource={videos}
										showPagination={false}
										context={listContext}
										maxItems={1}
									/>

									<Field name={`stickerText_${item}`} component={TextField} label={t('Text nálepky')} />

									<Field name={`stickerColor_${item}`} component={CircleColorField} label={t('Farba nálepky')} />
								</TabPane>
							))}
						</Tabs>
						<Field
							name={'preferences'}
							component={AsyncTransferField}
							label={t('Preferencie')}
							reduxFetch={(config: any) => dispatch(getPreferenceList(config))}
							modalDataSource={preferencesList}
							context={listContext}
						/>
						<Field
							name={'recommendedBlogPosts'}
							component={AsyncTransferField}
							label={t('Odporúčané blogové články')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
							modalDataSource={blogPostsList}
							context={listContext}
						/>
						<Field
							name={'motivationAdvices'}
							component={AsyncTransferField}
							label={t('Motivačné tipy')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:motivationAdvice|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							reduxFetch={(config: any) => dispatch(getMotivationAdviceList(config))}
							modalDataSource={motivationAdvicesList}
							context={listContext}
						/>
						<Field
							name={'exercises'}
							component={AsyncTransferField}
							label={t('Cvičenia')}
							reduxFetch={(config: any) => dispatch(getExerciseList(config))}
							modalDataSource={exercisesList}
							context={listContext}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							includeSwitcher
							includeDifficultySwitcher
							onSwitchChange={(value: any, record: any) => handleSwitchExercisesChange(value, record, 'isVisibleInCalendar')}
							onDifficultySwitchChange={(value: any, record: any) => handleSwitchExercisesChange(value, record, 'isDifficultyRatingVisible')}
							required
						/>
						<Field
							name={'hardExercises'}
							component={AsyncTransferField}
							label={t('Náročnejšie cvičenia')}
							reduxFetch={(config: any) => dispatch(getExerciseList(config))}
							modalDataSource={exercisesList}
							context={listContext}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							includeSwitcher
							includeDifficultySwitcher
							onSwitchChange={(value: any, record: any) => handleSwitchHardExercisesChange(value, record, 'isVisibleInCalendar')}
							onDifficultySwitchChange={(value: any, record: any) => handleSwitchHardExercisesChange(value, record, 'isDifficultyRatingVisible')}
						/>
						<Field
							name={'trainers'}
							component={AsyncTransferField}
							label={t('Inštruktori')}
							reduxFetch={(config: any) => dispatch(getTrainerList(config))}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:instructor|path')}/${id}`, '_blank')
								}
							}}
							modalDataSource={trainersList}
							context={listContext}
						/>
						<Field
							name={'upsellProducts'}
							component={AsyncTransferField}
							label={t('Upsell produkty')}
							reduxFetch={(config: any) => dispatch(getEshopProductList(config))}
							modalDataSource={products}
							context={listContext}
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field name={'publishDate'} component={DateField} label={t('Dátum a čas publikovania')} dttmFormat={'D.M.YYYY HH:mm'} showTime />
						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

						<Field name={'isPregnantSuitable'} component={SwitchField} label={t('Vhodné pre tehotné')} />

						<Field name={'isVisibleOnLandingPage'} component={SwitchField} label={t('Viditeľné na landing page')} />

						<Field name={'isLiveStreamCategory'} component={SwitchField} label={t('Kategoria živého prenosu')} />

						<Field name={'showExercisesInLists'} component={SwitchField} label={t('Zobraziť v zozname cvičení')} />
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button
									icon={<EyeOutlined />}
									onClick={() => {
										if (fieldValues?.isPublished) {
											window.open(
												`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programs|path')}/${get(
													fieldValues,
													`urlSlug_${selectedTab}`
												)}`,
												'_blank'
											)
										} else {
											window.open(
												`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programPreview|path')}/${get(
													fieldValues,
													'id'
												)}`,
												'_blank'
											)
										}
									}}
									type={'default'}
									style={{ margin: '20px' }}
								>
									{t('Zobraziť na webe')}
								</Button>
							)}

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať program')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<{}, ProgramFormProps>({
	form: FORMS.PROGRAM_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateProgramForm
})(ProgramForm)
