import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Input } from 'antd'
import { CirclePicker } from 'react-color'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { map, each, trim } from 'lodash'
import { useTranslation } from 'react-i18next'

// utils
import { INPUT_MAX_LENGTH } from '../../../utils/enums'
import { circlePickerColors } from '../../../utils/helpers'

const Batch = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const maxKeyRef = useRef(0)

	const setField = (fieldName, value, key) => {
		const updated = map(item?.content?.list, (batch) => {
			if (batch.key === key) {
				const tmp = { ...batch }

				tmp[fieldName] = value

				return tmp
			}

			return batch
		})

		itemContentSetter(item?.id, {
			...item?.content,
			list: updated
		})
	}

	const addBatch = () => {
		maxKeyRef.current += 1
		itemContentSetter(item?.id, {
			...item?.content,
			list: [...(item?.content?.list || []), { text: '', color: '#FEA385', key: maxKeyRef.current }]
		})
	}

	const removeBatch = (key) => {
		const updated = []

		each(item?.content?.list, (batch) => {
			if (key !== batch.key) {
				updated.push(batch)
			}
		})

		itemContentSetter(item?.id, {
			...item?.content,
			list: updated
		})
	}

	const setCenter = (value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			center: value
		})
	}

	const updateMaxKey = (key) => {
		if (key && key > maxKeyRef.current) maxKeyRef.current = key
	}

	return (
		<div className={'custom-item batch'}>
			{map(item?.content?.list, (batch) => {
				updateMaxKey(batch?.key)
				return (
					<div key={batch.key} className={'batch-item'}>
						<div className={'flex'}>
							<Input
								placeholder={t('Sem zadajte nadpis')}
								className={'input-field'}
								style={{ marginBottom: '15px' }}
								value={batch.text}
								maxLength={INPUT_MAX_LENGTH}
								onChange={(e) => setField('text', e.target.value, batch.key)}
								onBlur={(e) => setField('text', trim(e.target.value), batch.key)}
							/>
							<Button icon={<DeleteOutlined />} style={{ marginLeft: '15px' }} type={'danger'} onClick={() => removeBatch(batch.key)}>
								{t('Odstrániť nálepku')}
							</Button>
						</div>
						<CirclePicker
							width={'100%'}
							color={batch.color}
							onChange={({ hex }) => setField('color', hex, batch.key)}
							colors={circlePickerColors}
						/>
					</div>
				)
			})}

			<div className={'flex justify-center align-center flex-wrap mt-3'} style={{ gap: '10px 20px' }}>
				<Button icon={<PlusOutlined />} type={'primary'} onClick={addBatch}>
					{t('Pridať nálepku')}
				</Button>
				<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
					{t('Centrovať')}
				</Checkbox>
			</div>
		</div>
	)
}

Batch.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			list: PropTypes.arrayOf(
				PropTypes.shape({
					key: PropTypes.number,
					text: PropTypes.string,
					color: PropTypes.string
				})
			),
			center: PropTypes.bool
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Batch.defaultProps = {
	item: {}
}

export default Batch
