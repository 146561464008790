export const ACCESS_TOKEN_KEY = 'access_token'
export const USER_KEY = 'persist:USER'
export const LOGIN_URL = '/login'

/**
 * Removes saved token from localstorage
 */
export function clearAccessToken() {
	localStorage.removeItem(ACCESS_TOKEN_KEY)
}

/**
 * Removes saved profile from session storage
 */
export function clearProfile() {
	localStorage.removeItem(USER_KEY)
}

/**
 * Redirect user into login page
 */
export function login() {
	window.location.replace(LOGIN_URL)
}

/**
 * @return string token
 *
 * Returns acces token saved into storage or null
 */
export function getAccessToken() {
	return localStorage.getItem(ACCESS_TOKEN_KEY)
}

/**
 * @param token
 *
 * Save user token into localstorage
 */
export function setAccessToken(token: string) {
	localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

/**
 * @return boolean
 *
 * check if token is exist and try to decoded
 */
export function isLoggedIn() {
	try {
		const token = getAccessToken()
		if (!token) {
			return false
		}

		return true
	} catch (error) {
		return false
	}
}
