import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { InputNumber, Row, Col, Slider } from 'antd'
import { INPUT_MAX_LENGTH } from '../../utils/enums'

const TextField = ({
	input,
	name,
	label,
	placeholder,
	showLabel,
	customClass,
	customLabelClass,
	customInputClass,
	meta: { touched, error },
	disabled,
	suffix,
	required,
	min,
	max,
	onBlur,
	size,
	maxLength = INPUT_MAX_LENGTH
}) => (
	<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
		{showLabel && (
			<label className={cx(customLabelClass, { required })} htmlFor={name}>
				{label}
			</label>
		)}
		<div className={'input-field'}>
			<Row gutter={[8, 8]}>
				<Col flex={'auto'}>
					<Slider
						min={min}
						max={max}
						onChange={(value) => {
							input.onChange(parseInt(value, 10))
						}}
						value={input.value}
					/>
				</Col>
				<Col flex={'100px'}>
					<InputNumber
						{...input}
						placeholder={placeholder}
						className={cx('input-field', customInputClass, { disabled })}
						min={min}
						max={max}
						onBlur={onBlur}
						suffix={suffix}
						size={size}
						maxLength={maxLength}
						onChange={(value) => {
							input.onChange(parseInt(value, 10))
						}}
					/>
				</Col>
			</Row>
		</div>
		<div className={'tooltip-error'}>{touched ? error : ''}</div>
	</div>
)
TextField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.string,
		onChange: PropTypes.func
	}).isRequired,
	required: PropTypes.bool,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
	size: PropTypes.string
}

TextField.defaultProps = {
	name: null,
	showLabel: true,
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	max: 100,
	size: 'middle'
}

export default TextField
