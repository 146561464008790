import { Button, Form } from 'antd'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import validateSetNewPasswordForm, { SetNewPasswordFormValues } from './validateSetNewPasswordForm'

// atoms
import TextField from '../../../atoms/form/TextField'

const SetNewPasswordForm = ({ handleSubmit, invalid, submitting, pristine }: InjectedFormProps<SetNewPasswordFormValues>) => {
	const { t } = useTranslation()

	return (
		<form className={'new-password-form'} onSubmit={handleSubmit}>
			<Field name={'password'} component={TextField} type={'password'} label={t('Nové heslo')} required />
			<Field name={'confirmPassword'} component={TextField} type={'password'} label={t('Zopakujte heslo')} required />
			<Form.Item>
				<Button type={'primary'} htmlType={'submit'} loading={submitting} disabled={invalid || pristine}>
					{t('Uložiť heslo')}
				</Button>
			</Form.Item>
		</form>
	)
}

export default reduxForm<SetNewPasswordFormValues>({
	form: FORMS.SET_NEW_PASSWORD_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateSetNewPasswordForm
})(SetNewPasswordForm)
