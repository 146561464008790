import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Form, Button } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import validateLoginForm, { LoginFormValues } from './validateLoginForm'

// components
import RecaptchaTerms from '../../../components/RecaptchaTerms'

// atoms
import TextField from '../../../atoms/form/TextField'

const LoginForm = ({ invalid, pristine, handleSubmit }: InjectedFormProps<LoginFormValues>) => {
	const { t } = useTranslation()

	// selectors
	const user = useSelector((state: RootState) => state.user)

	return (
		<form onSubmit={handleSubmit}>
			<Field
				name={'email'}
				label={t('Email')}
				type={'text'}
				placeholder={t('Email')}
				component={TextField}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
			/>
			<Field
				name={'password'}
				label={t('Heslo')}
				type={'password'}
				placeholder={t('Heslo')}
				component={TextField}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
			/>
			<RecaptchaTerms />
			<Form.Item>
				<Button type={'primary'} htmlType={'submit'} loading={user?.isLoading} disabled={invalid || pristine}>
					{t('Prihlásiť sa')}
				</Button>
			</Form.Item>
		</form>
	)
}

export default reduxForm<LoginFormValues>({
	form: FORMS.LOGIN_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateLoginForm
})(LoginForm)
