import { get, mapValues, each } from 'lodash'
import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import {
	LOAD_SELECT_INSTRUCTORS,
	LOAD_SELECT_PROGRAMS,
	LOAD_SELECT_EXERCISES,
	LOAD_SELECT_TARGETS,
	LOAD_SELECT_MOTIVATION_ADVICES,
	LOAD_SELECT_ACCESSORIES,
	LOAD_SELECT_BLOG_POSTS,
	LOAD_SELECT_BLOG_CATEGORIES,
	LOAD_SELECT_PREFERENCES
} from './types'

export const getInstructors = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_INSTRUCTORS.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_INSTRUCTORS, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_INSTRUCTORS.DONE,
			payload: get(data, 'trainers', [])
		})

		return onSuccess && onSuccess(get(data, 'trainers', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_INSTRUCTORS.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getPrograms = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_PROGRAMS.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_PROGRAMS, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_PROGRAMS.DONE,
			payload: get(data, 'programs', [])
		})

		return onSuccess && onSuccess(get(data, 'programs', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_PROGRAMS.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const gerExercises = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_EXERCISES.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_EXERCISES, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_EXERCISES.DONE,
			payload: get(data, 'exercises', [])
		})

		return onSuccess && onSuccess(get(data, 'exercises', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_EXERCISES.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getTargets = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_TARGETS.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_EXERCISE_TARGETS, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_TARGETS.DONE,
			payload: get(data, 'targets', [])
		})

		return onSuccess && onSuccess(get(data, 'targets', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_TARGETS.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getMotivationAdvices = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_MOTIVATION_ADVICES.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_MOTIVATION_ADVICES, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_MOTIVATION_ADVICES.DONE,
			payload: get(data, 'motivationAdvices', [])
		})

		return onSuccess && onSuccess(get(data, 'motivationAdvices', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_MOTIVATION_ADVICES.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getAccessories = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_ACCESSORIES.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ACCESSORIES, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_ACCESSORIES.DONE,
			payload: get(data, 'accessories', [])
		})

		return onSuccess && onSuccess(get(data, 'accessories', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_ACCESSORIES.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getBlogPosts = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_BLOG_POSTS.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_POSTS, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_BLOG_POSTS.DONE,
			payload: get(data, 'blogPosts', [])
		})

		return onSuccess && onSuccess(get(data, 'blogPosts', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_BLOG_POSTS.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getBlogCategories = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_BLOG_CATEGORIES.START
	})
	try {
		const queries = { limit: 100000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_CATEGORIES, normalizeQueryParams)

		dispatch({
			type: LOAD_SELECT_BLOG_CATEGORIES.DONE,
			payload: get(data, 'blogCategories', [])
		})

		return onSuccess && onSuccess(get(data, 'blogCategories', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_BLOG_CATEGORIES.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const getPreferences = (params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_SELECT_PREFERENCES.START
	})
	try {
		const queries = { limit: 10000, page: 1, ...params }

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PREFERENCES, normalizeQueryParams)

		const payload = []

		each(data, (category) => {
			each(category, (item) => {
				payload.push(item)
			})
		})

		dispatch({
			type: LOAD_SELECT_PREFERENCES.DONE,
			payload: {
				origin: data,
				list: payload
			}
		})

		return onSuccess && onSuccess(get(data, 'accessories', []))
	} catch (error) {
		dispatch({
			type: LOAD_SELECT_PREFERENCES.FAILED
		})
		return onFailure && onFailure(error)
	}
}
