import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type DownloadCategoryFormValues = {
	id: number
	name_sk: string
}

export default (values: DownloadCategoryFormValues) => {
	const errors: FormErrors<DownloadCategoryFormValues> = {}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	return errors
}
