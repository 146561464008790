import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// types
import { IPaymentsFormValues } from '../../../types/interfaces'

export default (values: IPaymentsFormValues) => {
	const errors: FormErrors<IPaymentsFormValues> = {}

	if (!values.firstName) {
		errors.firstName = i18next.t('Toto pole je povinné')
	}

	if (!values.lastName) {
		errors.lastName = i18next.t('Toto pole je povinné')
	}

	if (!values.contactEmail) {
		errors.contactEmail = i18next.t('Toto pole je povinné')
	}

	return errors
}
