import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type PartnerFormValues = {
	id: number
	name_sk: string
	logo: string
	order: number
}

export default (values: PartnerFormValues) => {
	const errors: FormErrors<PartnerFormValues> = {}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.logo) {
		errors.logo = i18next.t('Toto pole je povinné')
	}

	if (!values.order) {
		errors.order = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 0) {
		errors.order = i18next.t('Poradie nemôže byť záporné číslo')
	}

	return errors
}
