import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import PropTypes from 'prop-types'

const DetailHeader = ({ title, detailButtons }) => (
	<div className={'flex direction-row justify-between align-center wrap'} style={{ margin: '0.45em 0' }}>
		<h1 style={{ margin: '0.22em 12px 0.22em 0' }}>{title}</h1>
		{detailButtons ? (
			<div className={'detail-buttons'}>
				{detailButtons?.map((button, index) => (
					<Button
						key={index}
						icon={button.icon || <PlusCircleOutlined />}
						href={!button?.onClick ? button.path : undefined}
						onClick={button?.onClick}
						type={'primary'}
					>
						{button.title}
					</Button>
				))}
			</div>
		) : null}
	</div>
)

DetailHeader.propTypes = {
	title: PropTypes.string.isRequired,
	detailButtons: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				icon: PropTypes.shape(),
				title: PropTypes.string,
				path: PropTypes.string,
				onClick: PropTypes.func
			})
		),
		PropTypes.bool
	])
}

DetailHeader.defaultProps = {
	detailButtons: []
}

export default DetailHeader
