import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// utils
import { ADMIN_ROLE } from '../../utils/enums'

const Homepage = () => {
	const { t } = useTranslation()
	const history = useHistory()

	// selectors
	const userRole = useSelector((state: any) => state.user?.profile?.role)

	useEffect(() => {
		switch (userRole) {
			case ADMIN_ROLE.BLOGGER:
				return history.push(t('paths:blog|path'))
			case ADMIN_ROLE.ADMIN:
				return history.push(t('paths:instructors|path'))
			case ADMIN_ROLE.SUPERADMIN:
				return history.push(t('paths:dashboard|path'))
			default:
				return history.push(t('paths:base|path'))
		}
	}, [userRole])

	return (
		<div>
			<h1>{t('Fitshaker Admin')}</h1>
		</div>
	)
}

export default Homepage
