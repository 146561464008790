import i18next from 'i18next'
import { get, each, isEmpty } from 'lodash'
import { FormErrors } from 'redux-form'
import { LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { twoWords } from '../../../utils/regex'

export default (values: any) => {
	const errors: FormErrors<any> = {}
	const { language } = values

	if (!language) {
		errors.language = i18next.t('Toto pole je povinné')

		if (!values[`name_${LANGUAGE.SK}`]) {
			errors[`name_${LANGUAGE.SK}`] = i18next.t('Toto pole je povinné')
		}

		if (!values[`urlSlug_${LANGUAGE.SK}`]) {
			errors[`urlSlug_${LANGUAGE.SK}`] = i18next.t('Toto pole je povinné')
		}
	} else {
		if (!values[`name_${language}`]) {
			errors[`name_${language}`] = i18next.t('Toto pole je povinné')
		}

		if (!values[`urlSlug_${language}`]) {
			errors[`urlSlug_${language}`] = i18next.t('Toto pole je povinné')
		}
	}

	if (isEmpty(values.roles)) {
		errors.roles = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	each(LANGUAGES, (item) => {
		const motivationBannerName = get(values, `motivationBannerName_${item}`)
		const motivationBannerLabel = get(values, `motivationBannerLabel_${item}`)
		const motivationBannerDescription = get(values, `motivationBannerDescription_${item}`)

		if (motivationBannerName || motivationBannerLabel || motivationBannerDescription) {
			if (!twoWords.test(motivationBannerName)) {
				errors[`motivationBannerName_${item}`] = i18next.t('Meno je v zlom formate')
			}
			if (motivationBannerName?.length > 12) {
				errors[`motivationBannerName_${item}`] = i18next.t('Maximalny počet znakov je 12')
			}

			if (!values[`motivationBannerName_${item}`]) {
				errors[`motivationBannerName_${item}`] = i18next.t('Toto pole je povinné')
			}
			if (!values[`motivationBannerLabel_${item}`]) {
				errors[`motivationBannerLabel_${item}`] = i18next.t('Toto pole je povinné')
			}
			if (!values[`motivationBannerDescription_${item}`]) {
				errors[`motivationBannerDescription_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	return errors
}
