import { FC } from 'react'
import { Tag } from 'antd'
import { PUSH_NOTIFICATION_STATE } from '../utils/enums'

interface IPushNotificationRepresent {
	value: string
	disabled?: boolean
}

const PushNotificationRepresent: FC<IPushNotificationRepresent> = ({ value, disabled = false }) => {
	const pickColor = (state: string) => {
		switch (state) {
			case PUSH_NOTIFICATION_STATE.DRAFT:
				return 'purple'
			case PUSH_NOTIFICATION_STATE.SCHEDULED:
				return 'blue'
			case PUSH_NOTIFICATION_STATE.PROCESSING:
				return 'gold'
			case PUSH_NOTIFICATION_STATE.SENT:
				return 'green'
			case PUSH_NOTIFICATION_STATE.FAILED:
				return 'red'
			default:
				return 'purple'
		}
	}
	return (
		<Tag color={pickColor(value)} style={{ opacity: disabled ? '0.5' : '1' }}>
			{value}
		</Tag>
	)
}

export default PushNotificationRepresent
