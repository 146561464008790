import { mapValues } from 'lodash'

// utils
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'

// enums
import { ENDPOINTS } from '../../utils/enums'

// types & interfaces
import { Paths } from '../../types/api'
import {
	CREATE_PUSH_NOTIFICATION,
	DELETE_PUSH_NOTIFICATION,
	LOAD_PUSH_NOTIFICATION,
	LOAD_PUSH_NOTIFICATIONS,
	LOAD_PUSH_NOTIFICATIONS_STATS,
	UPDATE_PUSH_NOTIFICATION
} from './types'

export const getPushNotifications = (params: Paths.GetAdminPushNotifications.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: LOAD_PUSH_NOTIFICATIONS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PUSH_NOTIFICATIONS, normalizeQueryParams)

		dispatch({
			type: LOAD_PUSH_NOTIFICATIONS.DONE,
			payload: {
				list: data.notifications as Paths.GetAdminPushNotifications.Responses.$200['notifications'],
				context: data.context as Paths.GetAdminPushNotifications.Responses.$200['context']
			}
		})
	} catch (error) {
		dispatch({ type: LOAD_PUSH_NOTIFICATIONS.FAILED })
		Promise.reject(error)
	}
}

export const getPushNotification = (id: number, onSuccess?: (data: any) => void, onFailure?: (error: any) => void) => async (dispatch: any) => {
	dispatch({ type: LOAD_PUSH_NOTIFICATION.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_PUSH_NOTIFICATION(id))

		dispatch({
			type: LOAD_PUSH_NOTIFICATION.DONE,
			payload: data
		})

		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_PUSH_NOTIFICATION.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createPushNotification =
	(values: Paths.PostAdminPushNotifications.RequestBody, onSuccess?: () => void, onFailure?: (errors: any) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_PUSH_NOTIFICATION.START })
		try {
			const normalizeQueryParams = mapValues(values, (query) => query || undefined)

			await postReq(ENDPOINTS.CREATE_PUSH_NOTIFICATION, undefined, normalizeQueryParams)

			dispatch({ type: CREATE_PUSH_NOTIFICATION.DONE })

			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CREATE_PUSH_NOTIFICATION.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updatePushNotification =
	(id: number, values: Paths.PatchAdminPushNotificationsId.RequestBody, onSuccess?: () => void, onFailure?: (error: any) => void) =>
	async (dispatch: any) => {
		dispatch({ type: UPDATE_PUSH_NOTIFICATION.START })
		try {
			const normalizeQueryParams = mapValues(values, (query) => query || undefined)

			await patchReq(ENDPOINTS.UPDATE_PUSH_NOTIFICATION(id), undefined, normalizeQueryParams)

			dispatch({ type: UPDATE_PUSH_NOTIFICATION.DONE })

			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_PUSH_NOTIFICATION.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const deletePushNotification = (id: number, onSuccess?: () => void, onFailure?: (error: any) => void) => async (dispatch: any) => {
	dispatch({
		type: DELETE_PUSH_NOTIFICATION.START
	})
	try {
		await deleteReq(ENDPOINTS.DELETE_PUSH_NOTIFICATION(id))

		dispatch({ type: DELETE_PUSH_NOTIFICATION.DONE })
		return onSuccess && onSuccess()
	} catch (error: any) {
		dispatch({ type: DELETE_PUSH_NOTIFICATION.FAILED })

		return onFailure && onFailure(error)
	}
}

export const getPushNotificationsStats = (params: Paths.GetAdminStatisticsNotifications.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: LOAD_PUSH_NOTIFICATIONS_STATS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PUSH_NOTIFICATIONS_STATS, normalizeQueryParams)

		dispatch({
			type: LOAD_PUSH_NOTIFICATIONS_STATS.DONE,
			payload: {
				stats: data.data as Paths.GetAdminStatisticsNotifications.Responses.$200['data'],
				context: data.context as Paths.GetAdminStatisticsNotifications.Responses.$200['context']
			}
		})
	} catch (error) {
		dispatch({ type: LOAD_PUSH_NOTIFICATIONS_STATS.FAILED })
		Promise.reject(error)
	}
}
