// types
import { Paths } from '../../types/api'

export type LiveStreamCategoriesSearchPayload = Paths.GetAdminLiveStreamCategoriesSearch.Responses.$200

export enum GET_LIVESTREAM_CATEGORIES {
	START = 'GET_LIVESTREAM_CATEGORIES_START',
	DONE = 'GET_LIVESTREAM_CATEGORIES_DONE',
	FAILED = 'GET_LIVESTREAM_CATEGORIES_FAILED'
}

export enum UPDATE_LIVESTREAM_CATEGORIES {
	START = 'UPDATE_LIVESTREAM_CATEGORIES_START',
	DONE = 'UPDATE_LIVESTREAM_CATEGORIES_DONE',
	FAILED = 'UPDATE_LIVESTREAM_CATEGORIES_FAILED'
}

export const SET_LIVESTREAM_CATEGORY = 'SET_LIVESTREAM_CATEGORY'
export const ADD_LIVESTREAM_CATEGORY = 'ADD_LIVESTREAM_CATEGORY'
export const REMOVE_LIVESTREAM_CATEGORY = 'REMOVE_LIVESTREAM_CATEGORY'
export const CHANGE_LIVESTREAM_CATEGORIES_ORDER = 'CHANGE_LIVESTREAM_CATEGORIES_ORDER'
