import { CREATE_PROMO_CODES, GET_PROMO_CODE, GET_SELECT_PROMO_CODES, UPDATE_PROMO_CODES, GET_PROMO_CODES_APPLICATIONS, RESET_PROMO_CODES_LIST } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_SELECT_PROMO_CODES.START:
		case GET_PROMO_CODES_APPLICATIONS.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_SELECT_PROMO_CODES.DONE:
		case GET_PROMO_CODES_APPLICATIONS.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_SELECT_PROMO_CODES.FAILED:
		case GET_PROMO_CODES_APPLICATIONS.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_PROMO_CODES_LIST:
			return {
				...state,
				list: {
					tableList: [],
					context: {},
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_PROMO_CODES.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_PROMO_CODES.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_PROMO_CODES.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_PROMO_CODES.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_PROMO_CODES.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_PROMO_CODES.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROMO_CODE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROMO_CODE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROMO_CODE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
