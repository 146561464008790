import get from 'lodash/get'
import map from 'lodash/map'
import { Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form'
import { Row, Col, Tabs } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { getLanguageName } from '../../../utils/helpers'
import { FORMS, LANGUAGE, LANGUAGES } from '../../../utils/enums'

// atoms
import TextField from '../../../atoms/form/TextField'

const { TabPane } = Tabs

const MetaTagsForm = ({ handleSubmit }: InjectedFormProps) => {
	const { t } = useTranslation()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.LANDING_METATAGS_FORM)(state))

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={0} style={{ background: 'white' }}>
				<Col span={24} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`title_${item}`}
										component={TextField}
										label={t('Metadáta titulok')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `title_${item}.length`, 0),
											totalCount: 60
										}}
									/>

									<Field
										name={`description_${item}`}
										component={TextField}
										label={t('Metadáta popis')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `description_${item}.length`, 0),
											totalCount: 150
										}}
									/>

									<Field
										name={`ogTitle_${item}`}
										component={TextField}
										label={t('OG titulok')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `ogTitle_${item}.length`, 0),
											totalCount: 60
										}}
									/>

									<Field
										name={`ogDescription_${item}`}
										component={TextField}
										label={t('OG popis')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `ogDescription_${item}.length`, 0),
											totalCount: 150
										}}
									/>
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm({
	form: FORMS.LANDING_METATAGS_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(MetaTagsForm)
