import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import i18next from 'i18next'
import styled from 'styled-components'

const getColor = (active, colors) => (active ? colors[0] : colors[1])

const TextLines = styled.div`
	color: #404040;
	font: 12px monospace;
	white-space: pre-wrap;
	padding: 10px 20px;

	div {
		margin: 0 0 0.5em;
	}
`

const StyledMenu = styled.div`
	& > * {
		display: inline-block;
	}

	& > * + * {
		margin-left: 15px;
	}
`

export const Button = React.forwardRef(
	(
		{
			// eslint-disable-next-line react/prop-types
			className,
			active,
			reversed,
			...props
		},
		ref
	) => (
		// eslint-disable-next-line react/jsx-filename-extension
		<span
			{...props}
			ref={ref}
			className={className}
			style={{ cursor: 'pointer', color: reversed ? getColor(active, ['white', '#aaa']) : getColor(active, ['black', '#ccc']) }}
		/>
	)
)

export const EditorValue = React.forwardRef(
	// eslint-disable-next-line react/prop-types
	({ className, value, ...props }, ref) => {
		// eslint-disable-next-line react/prop-types
		const textLines = value.document.nodes
			.map((node) => node.text)
			.toArray()
			.join('\n')
		return (
			<div ref={ref} {...props} className={className} style={{ margin: '30px -20px 0' }}>
				<div
					style={{
						fontSize: '14px',
						padding: '5px 20px',
						color: '#404040',
						borderTop: '2px solid #eeeeee',
						background: '#f8f8f8'
					}}
				>
					{"Slate's value as text"}
				</div>
				<TextLines>{textLines}</TextLines>
			</div>
		)
	}
)

// eslint-disable-next-line react/prop-types
export const Icon = React.forwardRef(({ className, ...props }, ref) => (
	<span {...props} ref={ref} className={cx('material-icons', className)} style={{ fontSize: '18px', verticalAlign: 'text-bottom' }} />
))

// eslint-disable-next-line react/prop-types
export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
	<div
		{...props}
		ref={ref}
		className={className}
		style={{ whiteSpace: 'pre-wrap', margin: '0 -20px 10px', padding: '10px 20px', fontSize: '14px', background: '#f8f8e8' }}
	/>
))

// eslint-disable-next-line react/prop-types
export const Menu = React.forwardRef(({ className, ...props }, ref) => <StyledMenu {...props} ref={ref} className={className} />)

export const Portal = ({ children }) => ReactDOM.createPortal(children, document.body)

// eslint-disable-next-line react/prop-types
export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
	<Menu
		{...props}
		ref={ref}
		className={className}
		style={{
			position: 'relative',
			padding: '0 18px 15px',
			margin: '1px 0 15px',
			borderBottom: '2px solid #eee',
			marginBottom: '15px'
		}}
	/>
))

export const getRichTextEditorContent = () => ({
	editor: [
		{
			type: 'paragraph',
			children: [
				{ text: i18next.t('Toto je textový editor, v ktorom možete upravovať text Napríklad použiť ') },
				{ text: `${i18next.t('tučné písmo')} `, bold: true },
				{ text: i18next.t('alebo') },
				{ text: ` ${i18next.t('kurzívu')} `, italic: true },
				{ text: i18next.t('a mnoho iného') }
			]
		}
	],
	html: `<p><span>${i18next.t('Toto je textový editor, v ktorom možete upravovať text Napríklad použiť ')}</span><strong>${i18next.t(
		'tučné písmo'
	)}</strong><span> ${i18next.t('alebo')} , </span><em>${i18next.t('kurzívu')}</em><span> ${i18next.t('a mnoho iného')}...</span></p>`
})
