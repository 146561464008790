import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../utils/enums'
import SubscriptionForm from './forms/SubscriptionForm'

const SubscriptionModal = ({ setShowModal, submitSubscription }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	return (
		<Modal
			visible
			title={t('Pridať bezplatné členstvo')}
			okText={t('Pridať')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(null)}
			onOk={() => {
				dispatch(submit(FORMS.SUBSCRIPTION_FORM))
			}}
		>
			<SubscriptionForm onSubmit={submitSubscription} />
		</Modal>
	)
}

SubscriptionModal.propTypes = {
	setShowModal: PropTypes.func.isRequired,
	submitSubscription: PropTypes.func.isRequired
}

export default SubscriptionModal
