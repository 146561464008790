import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { CloseOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Table, Input, InputNumber, Popconfirm, Form, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { get, find, map, some, forEach } from 'lodash'
import { LANGUAGE } from '../../utils/enums'
import { getTranslations, updateTranslation } from '../../redux/translations/actions'

const EditableCell = ({ editing, dataIndex, inputType, children, ...restProps }) => {
	const { t } = useTranslation()
	const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0
					}}
					rules={[
						{
							required: true,
							message: t('Musíte vyplniť preklad!')
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

EditableCell.propTypes = {
	editing: PropTypes.bool,
	dataIndex: PropTypes.string,
	title: PropTypes.string,
	inputType: PropTypes.string,
	record: PropTypes.shape({}),
	index: PropTypes.number,
	children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
}

EditableCell.defaultProps = {
	editing: false,
	dataIndex: '',
	title: '',
	inputType: '',
	record: null,
	index: null,
	children: null
}

const EditableTable = ({ data, parentSearchTerm, parentCurrentPage }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [form] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record) => record.key === editingKey

	const edit = (record) => {
		form.setFieldsValue({
			...record
		})
		setEditingKey(record.key)
	}

	const cancel = () => {
		setEditingKey('')
	}

	const save = async (key) => {
		const PAGE_SIZE = 20
		try {
			const row = await form.validateFields()
			const allLanguages = []
			forEach(LANGUAGE, (item) => {
				if (item === key) {
					allLanguages.push({
						language: key,
						value: get(row, 'translation')
					})
				}
				if (item !== key && some(get(data, 'languages'), { language: item })) {
					allLanguages.push({
						language: item,
						value: find(get(data, 'languages'), { language: item })?.value
					})
				}
			})
			dispatch(
				updateTranslation(
					{
						key: get(data, 'key'),
						languages: allLanguages
					},
					() => {
						dispatch(
							getTranslations({
								search: parentSearchTerm,
								limit: PAGE_SIZE,
								page: parentCurrentPage
							})
						)
						setEditingKey('')
					},
					() => setEditingKey('')
				)
			)
		} catch (errInfo) {
			// eslint-disable-next-line no-console
			console.log('Validate Failed:', errInfo)
		}
	}

	const columns = [
		{
			title: t('Jazyk'),
			width: 50,
			dataIndex: 'language',
			key: 'language',
			align: 'center',
			render: (value) => <strong>{value}</strong>
		},
		{
			title: t('Preklad'),
			dataIndex: 'translation',
			editable: true,
			key: 'translation'
		},
		{
			dataIndex: 'operation',
			width: '100px',
			render: (_, record) => {
				const editable = isEditing(record)
				return editable ? (
					<span>
						<Popconfirm
							title={i18next.t('Skutočne chcete uložiť zmeny?')}
							icon={<PlusCircleOutlined style={{ color: 'red' }} />}
							cancelText={i18next.t('Zrušiť')}
							okText={i18next.t('Uložiť')}
							onConfirm={() => save(record.key)}
							onCancel={(e) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'danger'
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Button
								icon={<SaveOutlined />}
								type={'primary'}
								style={{
									marginRight: 15
								}}
								onClick={(e) => e.stopPropagation()}
							/>
						</Popconfirm>
						<Button icon={<CloseOutlined />} type={'danger'} onClick={cancel} />
					</span>
				) : (
					<Button disabled={editingKey !== ''} icon={<EditOutlined />} onClick={() => edit(record)} />
				)
			}
		}
	]

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record)
			})
		}
	})

	const rowData = map(LANGUAGE, (item) => ({
		key: item,
		language: item.toUpperCase(),
		translation: get(find(get(data, 'languages'), { language: item }), 'value')
	}))

	return (
		<Form form={form} component={false}>
			<Table
				columns={mergedColumns}
				dataSource={rowData}
				pagination={false}
				components={{
					body: {
						cell: EditableCell
					}
				}}
			/>
		</Form>
	)
}

EditableTable.propTypes = {
	parentSearchTerm: PropTypes.string,
	data: PropTypes.shape(),
	parentCurrentPage: PropTypes.number
}

EditableTable.defaultProps = {
	parentSearchTerm: '',
	data: {},
	parentCurrentPage: 1
}

export default EditableTable
