export enum LOAD_VIDEOS {
	START = 'LOAD_VIDEOS_START',
	DONE = 'LOAD_VIDEOS_DONE',
	FAILED = 'LOAD_VIDEOS_FAILED'
}

export enum LOAD_VIDEO {
	START = 'LOAD_VIDEO_START',
	DONE = 'LOAD_VIDEO_DONE',
	FAILED = 'LOAD_VIDEO_FAILED'
}

export enum UPDATE_VIDEO {
	START = 'UPDATE_VIDEO_START',
	DONE = 'UPDATE_VIDEO_DONE',
	FAILED = 'UPDATE_VIDEO_FAILED'
}

export enum CREATE_VIDEO {
	START = 'CREATE_VIDEO_START',
	DONE = 'CREATE_VIDEO_DONE',
	FAILED = 'CREATE_VIDEO_FAILED'
}

export enum DELETE_VIDEO {
	START = 'DELETE_VIDEO_START',
	DONE = 'DELETE_VIDEO_DONE',
	FAILED = 'DELETE_VIDEO_FAILED'
}

export enum ANIMATION_SAVE {
	START = 'ANIMATION_SAVE_START',
	DONE = 'ANIMATION_SAVE_DONE',
	FAILED = 'ANIMATION_SAVE_FAILED'
}

export enum CHECK_GENERATION {
	START = 'CHECK_GENERATION_START',
	DONE = 'CHECK_GENERATION_DONE',
	CHECKING = 'CHECK_GENERATION_CHECKING',
	FAILED = 'CHECK_GENERATION_FAILED'
}

export enum GENERATE_ANIMATION {
	START = 'DUPLICATE_EXERCISE_START',
	DONE = 'DUPLICATE_EXERCISE_DONE',
	STOP = 'DUPLICATE_EXERCISE_FAILED'
}
