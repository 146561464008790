import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Empty, Table, Button } from 'antd'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'

const TableField = ({
	input,
	label,
	showLabel,
	customClass,
	customLabelClass,
	rowClassName,
	meta: { touched, error },
	columns,
	actionButtons,
	required,
	pagination,
	rowKey,
	emptyText
}) => {
	const { t } = useTranslation()

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && <label className={cx(customLabelClass, { required })}>{label}</label>}
			<div className={'input-field'}>
				<Table
					{...input}
					className={'general-table'}
					columns={columns}
					rowClassName={rowClassName}
					dataSource={input.value}
					locale={{
						emptyText: <Empty description={t(emptyText || 'Žiadne dáta')} />
					}}
					size={'small'}
					pagination={pagination}
					bordered={true}
					rowKey={rowKey || 'key'}
					footer={() => {
						return actionButtons.length ? (
							<div className={'action-buttons'}>
								{map(actionButtons, (item, index) => (
									<Button disabled={item?.disabled} key={index} type={item?.btnType} onClick={item?.action}>
										{item?.icon} {item?.title}
									</Button>
								))}
							</div>
						) : undefined
					}}
				/>
			</div>
			{error && <div className={'tooltip-error'}>{error}</div>}
		</div>
	)
}

TableField.propTypes = {
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		onChange: PropTypes.func.isRequired,
		value: PropTypes.string
	}).isRequired,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	rowClassName: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.shape({})),
	actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
	required: PropTypes.bool,
	pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	emptyText: PropTypes.string
}

TableField.defaultProps = {
	showLabel: true,
	label: '',
	customClass: null,
	customLabelClass: null,
	columns: [],
	actionButtons: [],
	required: false,
	pagination: false,
	rowKey: 0
}

export default TableField
