// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, putReq } from '../../utils/request'

// types
import { LOAD_SETTINGS, UPDATE_SETTINGS } from './settingsTypes'
import { Paths } from '../../types/api'

export const loadSettings = (onSuccess?: (args: any) => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: LOAD_SETTINGS.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_SETTINGS)
		dispatch({ type: LOAD_SETTINGS.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_SETTINGS.FAILED })
		return onFailure && onFailure()
	}
}

export const updatePriceList =
	(body: Paths.PutAdminSettingsPricelist.RequestBody, onSuccess?: (args: Paths.PutAdminSettingsPricelist.Responses.$200) => void, onFailure?: () => void) =>
	async (dispatch: any) => {
		dispatch({ type: UPDATE_SETTINGS.START })

		try {
			const { data } = await putReq(ENDPOINTS.UPDATE_PRICELIST, undefined, body)
			dispatch({ type: UPDATE_SETTINGS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: UPDATE_SETTINGS.FAILED })
			return onFailure && onFailure()
		}
	}
