import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES, slugErrorText } from '../../../utils/enums'
import { slugRegex } from '../../../utils/regex'

interface IExercise {
	id: number
	order: number | null
	isVisibleOnLandingPage: boolean
	isPublished: boolean
	name: string
}

export type ProgramFormValues = {
	id: number
	name_sk: string
	name_cz?: string
	name_en?: string
	urlSlug_sk: string
	urlSlug_cz?: string
	urlSlug_en?: string
	briefDescription_sk: string
	briefDescription_cz?: string
	briefDescription_en?: string
	detailedDescription_sk: string
	detailedDescription_cz?: string
	detailedDescription_en?: string
	videoPreviewID_sk: string
	videoPreviewID_cz?: string
	videoPreviewID_en?: string
	logo_sk: string
	logo_cz?: string
	logo_en?: string
	image_sk: string
	image_cz?: string
	image_en?: string
	allowedPlatforms: string[]
	order: number
	isPublished: boolean
	publishDate: string
	exercises?: IExercise[]
	hardExercises?: IExercise[]
}

export default (values: ProgramFormValues) => {
	const errors: FormErrors<ProgramFormValues> = {}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug_sk) {
		errors.urlSlug_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.briefDescription_sk) {
		errors.briefDescription_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.detailedDescription_sk) {
		errors.detailedDescription_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.allowedPlatforms || isEmpty(values.allowedPlatforms)) {
		errors.allowedPlatforms = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		const name = get(values, `name_${item}`)
		const urlSlug = get(values, `name_${item}`)
		const briefDescription = get(values, `name_${item}`)
		const detailedDescription = get(values, `name_${item}`)
		const videoPreview = get(values, `videoPreviewID_${item}`)
		const logo = get(values, `logo_${item}`)
		const image = get(values, `image_${item}`)

		if (name || urlSlug || briefDescription || detailedDescription || videoPreview || logo || image) {
			if (!values[`name_${item}`]) {
				errors[`name_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`urlSlug_${item}`]) {
				errors[`urlSlug_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (values[`urlSlug_${item}`] && !slugRegex.test(values[`urlSlug_${item}`] as string)) {
				errors[`urlSlug_${item}`] = i18next.t(slugErrorText)
			}

			if (!values[`briefDescription_${item}`]) {
				errors[`briefDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`detailedDescription_${item}`]) {
				errors[`detailedDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`videoPreviewID_${item}`] || values[`videoPreviewID_${item}`]?.length === 0) {
				errors[`videoPreviewID_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`logo_${item}`]) {
				errors[`logo_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`image_${item}`]) {
				errors[`image_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	return errors
}
