import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { Card } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { resetPassword, setNewPassword } from '../../redux/user/userActions'

// forms
import SetNewPasswordForm from './forms/SetNewPasswordForm'
import { ForgottenPasswordFormValues } from '../forgottenPassword/forms/validateForgottenPasswordForm'
import { SetNewPasswordFormValues } from './forms/validateSetNewPasswordForm'

type ForgottenPasswordProps = {
	location: {
		search: string
	}
	isRegistrationConfirm?: boolean
}

const ForgottenPassword = ({ location, isRegistrationConfirm = false }: ForgottenPasswordProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// states
	const [token, setToken] = useState<any>()

	useEffect(() => {
		const params = queryString.parse(location.search)
		if (!params.token) {
			history.push(`${t('paths:base|path')}`)
		}
		setToken(params.token)
	}, [location.search, t])

	const handleSubmit = (values: SetNewPasswordFormValues | ForgottenPasswordFormValues) => {
		if (isRegistrationConfirm) {
			dispatch(setNewPassword(values, token, () => history.push(t('paths:login|path'))))
		} else {
			dispatch(resetPassword(values, token, () => history.push(t('paths:login|path'))))
		}
	}
	return (
		<div className={'flex-container'}>
			<Card style={{ width: '400px' }}>
				<div className={'login-logo'} />
				<SetNewPasswordForm onSubmit={handleSubmit} />
			</Card>
		</div>
	)
}

export default ForgottenPassword
