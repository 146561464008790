import { FC, useState } from 'react'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined, UserAddOutlined, EyeOutlined } from '@ant-design/icons'
import { Field, reduxForm, getFormValues, change, getFormSyncErrors, InjectedFormProps } from 'redux-form'
import { debounce, get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { deleteInstructor } from '../../../redux/instructors/actions'
import { getBlogPostList, getProgramList, getVideosList } from '../../../redux/lists/actions'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'

// utils
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// enums
import { FORMS, INSTRUCTOR_ROLES_OPTIONS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'

// validators
import validateInstructorForm from './validateInstructorForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'
import { RootState } from '../../../redux'

const { Option } = Select

interface IInstructorForm {
	isCreate?: boolean
}

const InstructorForm: FC<IInstructorForm & InjectedFormProps<{}, IInstructorForm>> = ({ handleSubmit, invalid, pristine, isCreate }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.INSTRUCTOR_FORM)(state)) as any
	const formErrors = useSelector(getFormSyncErrors(FORMS.INSTRUCTOR_FORM))
	const listContext = useSelector((state) => get(state, 'lists.list.context'))
	const programs = useSelector((state) => get(state, 'lists.list.programs'))
	const videos = useSelector((state) => get(state, 'lists.list.videos'))
	const programsList = map(programs, (item: any) => ({ id: item.id, title: item.title }))
	const blogPosts = useSelector((state) => get(state, 'lists.list.blogPosts'))
	const blogPostsList = map(blogPosts, (item: any) => ({ id: item.id, title: item.title }))

	// constants
	const fieldLabels: IFieldLabels = {
		language: t('Jazyk inštruktora'),
		name_sk: t('Meno'),
		roles: t('Typ inštruktora'),
		urlSlug_sk: t('URL'),
		videoID: t('Video'),
		order: t('Poradie')
	}

	const instructorLanguage = map(LANGUAGES, (language) => ({
		value: language,
		label: getLanguageName(language)
	}))

	const [profilePhoto, setProfilePhoto] = useState({
		isLoading: false,
		imageUrl: get(fieldValues, 'image', null)
	})
	const [coverPhoto, setCoverPhoto] = useState({
		isLoading: false,
		imageUrl: get(fieldValues, 'image', null)
	})

	const refreshLogoAfterUpload = (fileUrl: string) => {
		setProfilePhoto({
			isLoading: false,
			imageUrl: fileUrl
		})
	}
	const refreshCoverAfterUpload = (fileUrl: string) => {
		setCoverPhoto({
			isLoading: false,
			imageUrl: fileUrl
		})
	}

	const removeInstructor = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať inštruktora?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteInstructor(fieldValues?.id, () => history.push(t('paths:instructors|path'))))
			}
		})
	}

	const debounceChangeField = debounce((value) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.INSTRUCTOR_FORM, 'urlSlug', slug))
	}, 300)

	const changeUrlSlug = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value)
		}
	}

	const uploadImage = async ({ file }: any) => {
		setProfilePhoto({
			...profilePhoto,
			isLoading: true
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.TRAINER)

		if (fileUrl) {
			dispatch(change(FORMS.INSTRUCTOR_FORM, 'image', fileUrl))
			refreshLogoAfterUpload(fileUrl)
		}
	}

	const uploadCoverImage = async ({ file }: any) => {
		setCoverPhoto({
			...coverPhoto,
			isLoading: true
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.TRAINER_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.INSTRUCTOR_FORM, 'coverImage', fileUrl))
			refreshCoverAfterUpload(fileUrl)
		}
	}

	const tabItems = map(LANGUAGES, (item: string) => ({
		label: getLanguageName(item),
		key: item,
		forceRender: true,
		children: (
			<>
				<Field
					name={`name_${item}`}
					component={TextField}
					label={t('Meno')}
					onInput={changeUrlSlug}
					size={'large'}
					required={get(fieldValues, 'language', LANGUAGE.SK) === item}
				/>

				<Field
					name={'roles'}
					label={t('Typ inštruktora')}
					component={CustomOptionsSelectField}
					options={map(INSTRUCTOR_ROLES_OPTIONS, (option, index) => (
						<Option key={index} value={get(option, 'value')}>
							{get(option, 'label') || ''}
						</Option>
					))}
					required
					multiple
				/>

				<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required={get(fieldValues, 'language', LANGUAGE.SK) === item} />

				<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} />

				<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} />

				<Field name={`education_${item}`} component={WYSIWYGEditor} label={t('Vzdelanie')} />

				<Field name={`rewards_${item}`} component={WYSIWYGEditor} label={t('Ocenenia')} />

				<Field name={`about_${item}`} component={TextField} label={t('O Inštruktorovi (V zozname inštruktorov)')} />

				<div className={'dark_bg'}>
					<h2>{t('Motivačný banner')}</h2>
					<Field name={`motivationBannerLabel_${item}`} component={TextField} label={t('Nadpis')} />
					<Field name={`motivationBannerDescription_${item}`} component={TextField} label={t('Popis')} />
					<Field
						name={`motivationBannerName_${item}`}
						component={TextField}
						label={t('Meno trénera v inštrumentáli s predložkou')}
						placeholder={t('s trénerom')}
					/>
				</div>

				<Field
					name={'video'}
					component={AsyncTransferField}
					customButton={{
						icon: <EyeOutlined />,
						handler: (id: number) => {
							window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
						}
					}}
					label={t('Video')}
					reduxFetch={(config: any) => dispatch(getVideosList(config))}
					modalDataSource={videos}
					context={listContext}
					showPagination={false}
					maxItems={1}
				/>

				<Field
					name={'programs'}
					component={AsyncTransferField}
					label={t('Programy')}
					customButton={{
						icon: <EyeOutlined />,
						handler: (id: number) => {
							window.open(`${t('paths:programs|path')}/${id}`, '_blank')
						}
					}}
					reduxFetch={(config: any) => dispatch(getProgramList(config))}
					modalDataSource={programsList}
					context={listContext}
				/>
				<Field
					name={'recommendedBlogPosts'}
					component={AsyncTransferField}
					customButton={{
						icon: <EyeOutlined />,
						handler: (id: number) => {
							window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
						}
					}}
					label={t('Odporúčané blogové články')}
					reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
					modalDataSource={blogPostsList}
					context={listContext}
				/>
			</>
		)
	}))

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať inštruktora') : t('Detail inštruktora')}
							detailButtons={
								!isCreate && [
									{
										icon: <UserAddOutlined />,
										title: t('Pridať nového inštruktora'),
										path: t('paths:instructorCreate|path')
									}
								]
							}
						/>
						<Tabs items={tabItems} defaultActiveKey={LANGUAGE.SK} className={'translations'} />
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'language'} component={SelectField} options={instructorLanguage} label={t('Jazyk inštruktora')} required />
						<Field name={'website'} component={TextField} label={t('Web')} />
						<Field name={'instagram'} component={TextField} label={t('Instagram')} />
						<Field name={'facebook'} component={TextField} label={t('Facebook')} />
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Fotka (upload)')}
							customHelper={t('recommendedSize|profilePhoto')}
							customRequest={uploadImage}
						/>

						<Field
							name={'coverImage'}
							imageUrl={get(fieldValues, 'coverImage')}
							component={UploadInputField}
							label={t('Cover Fotka pre video thumbnail (upload)')}
							customHelper={t('recommendedSize|coverImage')}
							customRequest={uploadCoverImage}
						/>

						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

						<Field name={'isVisibleOnLandingPage'} component={SwitchField} label={t('Viditeľné na landing page')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => removeInstructor()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať inštruktora')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<{}, IInstructorForm>({
	form: FORMS.INSTRUCTOR_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateInstructorForm
})(InstructorForm)
