// types
import { GET_INSTRUCTORS, GET_INSTRUCTOR, DELETE_INSTRUCTOR, CREATE_INSTRUCTOR, UPDATE_INSTRUCTOR } from './types'

export const initState = {
	instructors: {
		rawList: [],
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	instructorDetail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_INSTRUCTORS.START:
			return {
				...initState,
				...state,
				instructors: {
					...state.instructors,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_INSTRUCTORS.DONE:
			return {
				...initState,
				...state,
				instructors: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_INSTRUCTORS.FAILED:
			return {
				...state,
				instructors: {
					...state.instructors,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_INSTRUCTOR.START:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_INSTRUCTOR.DONE:
			return {
				...initState,
				...state,
				instructorDetail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_INSTRUCTOR.FAILED:
			return {
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_INSTRUCTOR.START:
			return {
				...initState,
				...state,
				instructors: {
					...state.instructors,
					isLoading: true,
					isFailure: false
				},
				instructorDetail: {
					...state.instructorDetail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_INSTRUCTOR.DONE:
			return {
				...initState,
				...state,
				instructors: {
					...state.instructors,
					isLoading: false,
					isFailure: false
				},
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_INSTRUCTOR.FAILED:
			return {
				...initState,
				...state,
				instructors: {
					...state.instructors,
					isLoading: false,
					isFailure: true
				},
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_INSTRUCTOR.START:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_INSTRUCTOR.DONE:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_INSTRUCTOR.FAILED:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_INSTRUCTOR.START:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_INSTRUCTOR.DONE:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_INSTRUCTOR.FAILED:
			return {
				...initState,
				...state,
				instructorDetail: {
					...state.instructorDetail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
