import { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Input, Upload } from 'antd'
import { get, trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { uploadFile, IMAGE_SIZE } from '../../../utils/fileUploader'
import { INPUT_MAX_LENGTH, UPLOAD_CATEGORY } from '../../../utils/enums'

const Image = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const [isLoading, setLoading] = useState(false)

	const setField = (fieldName, value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			[fieldName]: value
		})
	}

	const uploadImage = async ({ file }) => {
		setLoading(true)
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.BLOG, IMAGE_SIZE.LARGE)
		if (fileUrl) {
			setField('url', fileUrl)
		}
		setLoading(false)
	}

	const setCenter = (value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			center: value
		})
	}

	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div>{t('Vyber súbor')}</div>
		</div>
	)

	const renderImage =
		item?.content?.url !== '' ? (
			<img
				className={'_gr_article_builder-upload-image'}
				src={get(item, 'content.url')}
				alt={t('V tomto momente nieje možné obrázok zobraziť. Uložte vaše zmeny a stránku načítajte znova.')}
			/>
		) : (
			uploadButton
		)

	return (
		<div className={'custom-item image'}>
			<div className={'row'}>
				<div className={'col-lg-6 col-md-12 custom-item-image-wrapper'}>
					<Upload listType={'picture-card'} className={'_gr_article_builder-upload-field'} showUploadList={false} customRequest={uploadImage}>
						{isLoading ? <LoadingOutlined /> : renderImage}
					</Upload>
				</div>
				<div className={'col-lg-6 col-md-12'}>
					<label className={'input-label'}>{t('Titulný text')}</label>
					<Input
						placeholder={t('titulok')}
						className={'input-field'}
						value={get(item, 'content.title')}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('title', e.target.value)}
						onBlur={(e) => setField('title', trim(e.target.value))}
						style={{ marginBottom: '15px' }}
					/>
					<label className={'input-label'}>{t('Alternatívny text')}</label>
					<Input
						placeholder={t('alternatívny text')}
						className={'input-field'}
						value={get(item, 'content.alt')}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('alt', e.target.value)}
						onBlur={(e) => setField('alt', trim(e.target.value))}
						style={{ marginBottom: '15px' }}
					/>
					<label className={'input-label'}>{t('Popis pod obrázkom')}</label>
					<Input
						placeholder={t('popis pod obrázkom')}
						className={'input-field'}
						value={get(item, 'content.caption')}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('caption', e.target.value)}
						onBlur={(e) => setField('caption', trim(e.target.value))}
						style={{ marginBottom: '15px' }}
					/>
					<label className={'input-label'}>{t('Presmerovanie')}</label>
					<Input
						placeholder={t('google')}
						className={'input-field'}
						maxLength={INPUT_MAX_LENGTH}
						value={get(item, 'content.link')}
						onChange={(e) => setField('link', e.target.value)}
						onBlur={(e) => setField('link', trim(e.target.value))}
					/>
					<div className={'flex justify-end mt-3'}>
						<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
							{t('Centrovať')}
						</Checkbox>
					</div>
				</div>
			</div>
		</div>
	)
}

Image.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			url: PropTypes.string,
			title: PropTypes.string,
			alt: PropTypes.string,
			caption: PropTypes.string,
			link: PropTypes.string,
			center: PropTypes.bool
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Image.defaultProps = {
	item: {}
}

export default Image
