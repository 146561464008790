// types
import { Paths } from '../../types/api'

export type InstructorsPayload = Paths.GetAdminTrainers.Responses.$200['trainers']
export type InstructorPayload = Paths.GetAdminTrainersId.Responses.$200

export enum GET_INSTRUCTORS {
	START = 'GET_INSTRUCTORS_START',
	DONE = 'GET_INSTRUCTORS_DONE',
	FAILED = 'GET_INSTRUCTORS_FAILED'
}

export enum GET_INSTRUCTOR {
	START = 'GET_INSTRUCTOR_START',
	DONE = 'GET_INSTRUCTOR_DONE',
	FAILED = 'GET_INSTRUCTOR_FAILED'
}

export enum DELETE_INSTRUCTOR {
	START = 'DELETE_INSTRUCTOR_START',
	DONE = 'DELETE_INSTRUCTOR_DONE',
	FAILED = 'DELETE_INSTRUCTOR_FAILED'
}

export enum CREATE_INSTRUCTOR {
	START = 'CREATE_INSTRUCTOR_START',
	DONE = 'CREATE_INSTRUCTOR_DONE',
	FAILED = 'CREATE_INSTRUCTOR_FAILED'
}

export enum UPDATE_INSTRUCTOR {
	START = 'UPDATE_INSTRUCTOR_START',
	DONE = 'UPDATE_INSTRUCTOR_DONE',
	FAILED = 'UPDATE_INSTRUCTOR_FAILED'
}
