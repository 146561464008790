import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { GET_EXERCISES, GET_EXERCISE, DELETE_EXERCISE, CREATE_EXERCISE, UPDATE_EXERCISE, DUPLICATE_EXERCISE, ExercisePayload } from './types'
import { Paths } from '../../types/api'

export const getExercises = (params: Paths.GetAdminExercises.QueryParameters, cancelToken?: any) => async (dispatch: any) => {
	dispatch({ type: GET_EXERCISES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			cancelToken: cancelToken.token,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_EXERCISES, normalizeQueryParams)

		const tableList = map(data.exercises, (item) => ({
			...item,
			key: item?.id
		}))

		dispatch({
			type: GET_EXERCISES.DONE,
			payload: {
				tableList,
				filterOptions: data.filterOptions,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_EXERCISES.FAILED })
		Promise.reject(error)
	}
}

export const getExercise = (id: number, onSuccess?: (args: ExercisePayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_EXERCISE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_EXERCISE(id))
		dispatch({ type: GET_EXERCISE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_EXERCISE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteExercise = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_EXERCISE.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_EXERCISE(id))
		dispatch({ type: DELETE_EXERCISE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_EXERCISE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createExercise =
	(body: Paths.PostAdminExercises.RequestBody, onSuccess?: (args: number) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_EXERCISE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			const forceNullParams = {
				...normalizeQueryParams,
				videoID: normalizeQueryParams.videoID || null
			}
			const { data } = await postReq(ENDPOINTS.CREATE_EXERCISE, undefined, forceNullParams)
			dispatch({ type: CREATE_EXERCISE.DONE })
			return onSuccess && onSuccess(data?.data?.id)
		} catch (error) {
			dispatch({ type: CREATE_EXERCISE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const duplicateExercise = (id: number, onSuccess?: (args: number) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DUPLICATE_EXERCISE.START })
	try {
		const { data } = await postReq(ENDPOINTS.DUPLICATE_EXERCISE(id), undefined, undefined)
		dispatch({ type: DUPLICATE_EXERCISE.DONE })
		return onSuccess && onSuccess(data?.id)
	} catch (error) {
		dispatch({ type: DUPLICATE_EXERCISE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateExercise =
	(id: number, body: Paths.PutAdminExercisesId.RequestBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_EXERCISE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			const forceNullParams = {
				...normalizeQueryParams,
				videoID: normalizeQueryParams.videoID || null
			}

			await putReq(ENDPOINTS.UPDATE_EXERCISE(id), undefined, forceNullParams)
			dispatch({ type: UPDATE_EXERCISE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_EXERCISE.FAILED })
			return onFailure && onFailure(error)
		}
	}
