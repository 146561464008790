import debounce from 'lodash/debounce'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Tabs } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteTag } from '../../../redux/tags/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { getLanguageName } from '../../../utils/helpers'

// forms
import validateTagForm, { TagFormValues } from './validateTagForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'
import TextAreaField from '../../../atoms/form/TextAreaField'

const { TabPane } = Tabs

type TagFormProps = {
	isCreate?: boolean
}

const TagForm = ({ handleSubmit, invalid, pristine, isCreate = false }: TagFormProps & InjectedFormProps<TagFormValues, TagFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.TAG_FORM)(state)) as TagFormValues

	const removeTag = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať tag?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteTag(fieldValues?.id, () => history.push(t('paths:tags|path'))))
			}
		})
	}

	const debounceChangeField = debounce((value) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.TAG_FORM, 'urlSlug', slug))
	}, 300)

	const changeUrlSlug = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať tag') : t('Detail tagu')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový tag'),
										path: t('paths:tagsCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field name={`name_${item}`} component={TextField} label={t('Názov')} onInput={changeUrlSlug} size={'large'} required />

									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required />

									<Field name={`briefDescription_${item}`} component={TextAreaField} label={t('Popis')} />
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'createdAt'} component={TextField} dttmFormat={'D.M.YYYY HH:mm'} label={t('Dátum vytvorenia')} disabled />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<div className={'flex direction-col justify-center'}>
							<Button icon={<SaveOutlined />} onClick={handleSubmit} disabled={pristine || invalid} type={'primary'} style={{ margin: '20px' }}>
								{t('Uložiť')}
							</Button>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => removeTag()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať tag')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<TagFormValues, TagFormProps>({
	form: FORMS.TAG_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateTagForm
})(TagForm)
