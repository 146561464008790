import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Col, DatePicker, Empty, PaginationProps, Row, Select, Table } from 'antd'
import { get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { getPushNotificationsStats } from '../../redux/pushNotifications/actions'

// utils
import { PUSH_NOTIFICATION_TYPE, PUSH_NOTIFICATION_TYPE_OPTIONS } from '../../utils/enums'
import { setFiltersForPage } from '../../utils/helpers'

const { Option } = Select
const { RangePicker } = DatePicker

const LIMIT_PER_PAGE = 20
const ONE_WEEK_AGO = dayjs().subtract(1, 'week')
const TODAY = dayjs()

interface IFilter {
	limit?: number
	page?: number
	type: PUSH_NOTIFICATION_TYPE
	dateFrom?: any
	dateTo?: any
}

const PushNotificationsStats = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const persistFilter = useSelector((state) => get(state, 'filters'))
	const { stats, context, isLoading } = useSelector((state: RootState) => state.pushNotifications.stats)

	// states
	const [filter, setFilter] = useState<IFilter>({
		...setFiltersForPage(t('paths:pushNotificationsStats|key'), persistFilter),
		limit: LIMIT_PER_PAGE,
		page: 1,
		type: PUSH_NOTIFICATION_TYPE.BIRTHDAY,
		dateFrom: dayjs(ONE_WEEK_AGO),
		dateTo: dayjs(TODAY)
	})

	const disabledRange = (current: any) => {
		const { dateFrom } = filter
		return dateFrom && current && dayjs(current).isAfter(dayjs().endOf('day'))
	}

	const columns = [
		{
			title: t('E-mail'),
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			render: (item: string) => item
		},
		{
			title: t('Meno a priezvisko'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (item: string) => item
		},
		{
			title: t('Dátum odoslania'),
			dataIndex: 'sentAt',
			key: 'sentAt',
			ellipsis: true,
			render: (item: string) => dayjs(item).format('D. M. YYYY')
		}
	]

	useEffect(() => {
		const body = {
			...filter,
			limit: filter?.limit,
			page: filter?.page,
			type: filter?.type,
			dateFrom: dayjs(filter?.dateFrom).format('YYYY-MM-DD'),
			dateTo: dayjs(filter?.dateTo).format('YYYY-MM-DD')
		}

		dispatch(getPushNotificationsStats(body))
	}, [dispatch, filter])

	const handleTableChange = (pagination: PaginationProps) => {
		setFilter({
			...filter,
			limit: pagination?.pageSize,
			page: pagination?.current
		})
	}

	return (
		<div>
			<Row gutter={48} style={{ marginBottom: '12px' }}>
				<Col>
					<Select
						defaultValue={filter?.type}
						onChange={(type: PUSH_NOTIFICATION_TYPE) => setFilter({ ...filter, type, page: 1 })}
						placeholder={t('Typ')}
						style={{ width: 200 }}
					>
						{map(PUSH_NOTIFICATION_TYPE_OPTIONS, (option, index) => {
							return (
								<Option key={`type-${index}`} value={option.value}>
									{option.label}
								</Option>
							)
						})}
					</Select>
				</Col>
				<Col>
					<RangePicker
						defaultValue={[dayjs(filter?.dateFrom) as any, dayjs(filter?.dateTo) as any]}
						format={'D.M.YYYY'}
						disabledDate={disabledRange}
						onChange={(rangeValues) => {
							if (rangeValues) setFilter({ ...filter, dateFrom: rangeValues[0], dateTo: rangeValues[1] })
						}}
						onCalendarChange={(rangeValues) => {
							if (rangeValues) setFilter({ ...filter, dateFrom: rangeValues[0], dateTo: rangeValues[1] })
						}}
						placeholder={[t('Od'), t('Do')]}
						style={{ width: 250 }}
						allowClear={false}
					/>
				</Col>
			</Row>
			<Table
				bordered
				className={'general-table'}
				columns={columns}
				rowKey={'id'}
				dataSource={stats}
				showSorterTooltip={false}
				loading={isLoading}
				onChange={(pagination) => handleTableChange(pagination)}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
				pagination={{
					total: context?.totalCount,
					current: context?.page,
					showSizeChanger: true,
					pageSizeOptions: [3, 5, 10, 15, 20, 30],
					locale: { items_per_page: t('na stranu') }
				}}
			/>
		</div>
	)
}

export default PushNotificationsStats
