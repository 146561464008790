import { mapValues, get } from 'lodash'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { ENDPOINTS, ANIMATED_THUMBNAIL_STATUS } from '../../utils/enums'
import { LOAD_VIDEO, LOAD_VIDEOS, DELETE_VIDEO, CREATE_VIDEO, UPDATE_VIDEO, GENERATE_ANIMATION, ANIMATION_SAVE, CHECK_GENERATION } from './types'

export const getVideos = (params) => async (dispatch) => {
	try {
		dispatch({ type: LOAD_VIDEOS.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_VIDEOS, normalizeQueryParams)

		dispatch({
			type: LOAD_VIDEOS.DONE,
			payload: {
				videos: get(data, 'videos'),
				context: get(data, 'context')
			}
		})
	} catch (error) {
		dispatch({ type: LOAD_VIDEOS.FAILED })
		Promise.reject(error)
	}
}

export const getVideo = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_VIDEO.START
	})
	try {
		const { data } = await getReq(ENDPOINTS.GET_VIDEO(id))

		dispatch({
			type: LOAD_VIDEO.DONE,
			payload: data
		})

		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({
			type: LOAD_VIDEO.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const deleteVideo = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: DELETE_VIDEO.START
	})
	try {
		await deleteReq(ENDPOINTS.DELETE_VIDEO(id))

		dispatch({ type: DELETE_VIDEO.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateVideo = (id, values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: UPDATE_VIDEO.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		await putReq(ENDPOINTS.UPDATE_VIDEO(id), null, normalizeQueryParams)

		dispatch({ type: UPDATE_VIDEO.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createVideo = (values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: CREATE_VIDEO.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq(ENDPOINTS.CREATE_VIDEO, null, normalizeQueryParams)

		dispatch({ type: CREATE_VIDEO.DONE })
		return onSuccess && onSuccess(data?.data?.id)
	} catch (error) {
		dispatch({ type: CREATE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const generateThumbnail = async (id, onSuccess, onFailure) => {
	try {
		await postReq(ENDPOINTS.GENERATE_THUMBNAIL(id))
		return onSuccess && onSuccess()
	} catch (error) {
		return onFailure && onFailure(error)
	}
}

export const generateAnimation = (id, values, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: GENERATE_ANIMATION.START })
		const { data } = await postReq(ENDPOINTS.GENERATE_THUMBNAIL_ANIMATION(id), null, values)
		dispatch({
			type: GENERATE_ANIMATION.DONE,
			payload: data
		})
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: GENERATE_ANIMATION.STOP })
		return onFailure && onFailure(error)
	}
}

export const saveAnimation = (id, values, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: ANIMATION_SAVE.START })
		await putReq(ENDPOINTS.SAVE_THUMBNAIL_ANIMATION(id), null, values)
		dispatch({ type: ANIMATION_SAVE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: ANIMATION_SAVE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const checkGeneration = (exerciseID, values, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: CHECK_GENERATION.START })
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.CHECK_THUMBNAIL_GENERATION(exerciseID), normalizeQueryParams)
		if (data?.status === ANIMATED_THUMBNAIL_STATUS.COMPLETED) {
			dispatch({ type: CHECK_GENERATION.DONE })
		}
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CHECK_GENERATION.FAILED })
		return onFailure && onFailure(error)
	}
}

export const stopGenerating = () => (dispatch) => dispatch({ type: ANIMATION_SAVE.FAILED })
