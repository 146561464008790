// types
import { Paths } from '../../types/api'

export type DonationsPayload = Paths.GetAdminSubscriptionsDonations.Responses.$200['donations']
export type DonationPayload = Paths.GetAdminSubscriptionsDonationsId.Responses.$200

export enum GET_DONATIONS {
	START = 'GET_DONATIONS_START',
	DONE = 'GET_DONATIONS_DONE',
	FAILED = 'GET_DONATIONS_FAILED'
}

export enum GET_DONATION {
	START = 'GET_DONATION_START',
	DONE = 'GET_DONATION_DONE',
	FAILED = 'GET_DONATION_FAILED'
}

export enum SEND_DONATION {
	START = 'SEND_DONATION_START',
	DONE = 'SEND_DONATION_DONE',
	FAILED = 'SEND_DONATION_FAILED'
}
