import { GET_ACCESSORIES, GET_ACCESSORY, DELETE_ACCESSORY, CREATE_ACCESSORY, UPDATE_ACCESSORY } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLastPage: false,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_ACCESSORIES.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_ACCESSORIES.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_ACCESSORIES.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_ACCESSORY.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_ACCESSORY.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_ACCESSORY.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_ACCESSORY.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_ACCESSORY.DONE:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_ACCESSORY.FAILED:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_ACCESSORY.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_ACCESSORY.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_ACCESSORY.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_ACCESSORY.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_ACCESSORY.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_ACCESSORY.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
