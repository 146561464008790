import PropTypes from 'prop-types'
import { Empty, Input, Table, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { each, trim } from 'lodash'
import i18next from 'i18next'
import { formatNutritionals } from '../../../utils/helpers'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const Nutrition = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const updateNutrition = (value, row) => {
		const updatedContent = []

		each(item?.content, (contentItem) => {
			if (contentItem?.key === row.key) {
				// eslint-disable-next-line no-param-reassign
				contentItem.value = value
			}

			updatedContent.push(contentItem)
		})

		itemContentSetter(item?.id, updatedContent)
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex direction-col'}>
				<Table
					className={'general-table'}
					style={{ width: '100%' }}
					columns={[
						{
							title: t('Typ'),
							dataIndex: 'type',
							key: 'type',
							render: (type) => formatNutritionals(type)
						},
						{
							title: t('Hodnota'),
							dataIndex: 'value',
							key: 'value',
							render: (value, row) => {
								if (row?.input === 'dropdown') {
									return (
										<Select
											defaultValue={row.value}
											onChange={(optionValue) => updateNutrition(optionValue, row)}
											style={{ width: '100%' }}
										>
											<Select.Option value={'onePortion'}>{t('na 1 porciu')}</Select.Option>
											<Select.Option value={1}>{t('na {{quantity}} ks', { quantity: 1 })}</Select.Option>
											<Select.Option value={10}>{t('na {{quantity}} ks', { quantity: 10 })}</Select.Option>
										</Select>
									)
								}
								return (
									<Input
										value={value}
										placeholder={row?.placeholder || t('Sem zadajte výživovú hodnotu')}
										maxLength={INPUT_MAX_LENGTH}
										onChange={(e) => updateNutrition(e.target.value, row)}
										onBlur={(e) => updateNutrition(trim(e.target.value), row)}
										type={row?.inputType || 'number'}
										min={0}
									/>
								)
							}
						}
					]}
					dataSource={item?.content}
					locale={{
						emptyText: <Empty description={i18next.t('Žiadne dáta')} />
					}}
					size={'small'}
					pagination={false}
				/>
			</div>
		</div>
	)
}

Nutrition.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number,
				type: PropTypes.string,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			})
		)
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Nutrition.defaultProps = {
	item: {}
}

export default Nutrition
