// types
import { Paths } from '../../types/api'

export type AccessoriesPayload = Paths.GetAdminAccessories.Responses.$200['accessories']
export type AccessoryPayload = Paths.GetAdminAccessoriesId.Responses.$200

export enum GET_ACCESSORIES {
	START = 'GET_ACCESSORIES_START',
	DONE = 'GET_ACCESSORIES_DONE',
	FAILED = 'GET_ACCESSORIES_FAILED'
}

export enum GET_ACCESSORY {
	START = 'GET_ACCESSORY_START',
	DONE = 'GET_ACCESSORY_DONE',
	FAILED = 'GET_ACCESSORY_FAILED'
}

export enum DELETE_ACCESSORY {
	START = 'DELETE_ACCESSORY_START',
	DONE = 'DELETE_ACCESSORY_DONE',
	FAILED = 'DELETE_ACCESSORY_FAILED'
}

export enum CREATE_ACCESSORY {
	START = 'CREATE_ACCESSORY_START',
	DONE = 'CREATE_ACCESSORY_DONE',
	FAILED = 'CREATE_ACCESSORY_FAILED'
}

export enum UPDATE_ACCESSORY {
	START = 'UPDATE_ACCESSORY_START',
	DONE = 'UPDATE_ACCESSORY_DONE',
	FAILED = 'UPDATE_ACCESSORY_FAILED'
}
