import { Card } from 'antd'
import { useTranslation } from 'react-i18next'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { postReq } from '../../utils/request'

// forms
import ForgottenPasswordForm from './forms/ForgottenPasswordForm'
import { ForgottenPasswordFormValues } from './forms/validateForgottenPasswordForm'

const ForgottenPasswordPage = () => {
	const { t } = useTranslation()

	const handleSubmit = async (values: ForgottenPasswordFormValues) => {
		try {
			await postReq(ENDPOINTS.ADMIN_FORGOTTEN_PASSWORD, undefined, values)
		} catch (error) {
			Promise.reject(error)
		}
	}

	return (
		<div className={'flex-container'}>
			<Card style={{ width: '400px' }}>
				<h1>{t('Obnova hesla')}</h1>
				<h5>{t('Pre obnovu hesla zadajte váš e-mail Na e-mail vám zašleme inštrukcie na obnovu hesla')}</h5>
				<ForgottenPasswordForm onSubmit={handleSubmit} />
			</Card>
		</div>
	)
}

export default ForgottenPasswordPage
