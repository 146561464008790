import { Paths } from '../../types/api'

export type BannersPayload = Paths.GetAdminHeadlines.Responses.$200['banners']
export type BannerPayload = Paths.GetAdminHeadlinesId.Responses.$200

export enum GET_BANNERS {
	START = 'GET_BANNERS_START',
	DONE = 'GET_BANNERS_DONE',
	FAILED = 'GET_BANNERS_FAILED'
}

export enum LOAD_BANNER {
	START = 'LOAD_BANNER_START',
	DONE = 'LOAD_BANNER_DONE',
	FAILED = 'LOAD_BANNER_FAILED'
}

export enum UPDATE_BANNER {
	START = 'UPDATE_BANNER_START',
	DONE = 'UPDATE_BANNER_DONE',
	FAILED = 'UPDATE_BANNER_FAILED'
}

export enum CREATE_BANNER {
	START = 'CREATE_BANNER_START',
	DONE = 'CREATE_BANNER_DONE',
	FAILED = 'CREATE_BANNER_FAILED'
}

export enum DELETE_BANNER {
	START = 'DELETE_BANNER_START',
	DONE = 'DELETE_BANNER_DONE',
	FAILED = 'DELETE_BANNER_FAILED'
}
