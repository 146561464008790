import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS, LANGUAGE } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { GET_TAG, GET_TAGS, DELETE_TAG, CREATE_TAG, UPDATE_TAG, TagPayload } from './types'
import { Paths } from '../../types/api'

export const getTags = (params: Paths.GetAdminTags.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_TAGS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_TAGS, normalizeQueryParams)

		const tableList = map(get(data, 'tags'), (item) => {
			const translation = find(item.translations, ['language', LANGUAGE.SK])
			return {
				...item,
				name: get(translation, 'name'),
				urlSlug: get(translation, 'urlSlug')
			}
		})

		dispatch({
			type: GET_TAGS.DONE,
			payload: {
				tags: tableList,
				context: get(data, 'context')
			}
		})
	} catch (error) {
		dispatch({ type: GET_TAGS.FAILED })
		Promise.reject(error)
	}
}

export const getTag = (id: number, onSuccess?: (args: TagPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_TAG.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_TAG(id))
		dispatch({ type: GET_TAG.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({
			type: GET_TAG.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const deleteTag = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_TAG.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_TAG(id))
		dispatch({ type: DELETE_TAG.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_TAG.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateTag =
	(id: number, body: Paths.PutAdminTagsTagId.RequestBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_TAG.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await putReq(ENDPOINTS.UPDATE_TAG(id), undefined, normalizeQueryParams)
			dispatch({ type: UPDATE_TAG.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_TAG.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createTag =
	(body: Paths.PostAdminTags.RequestBody, onSuccess?: (args: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_TAG.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			const { data } = await postReq(ENDPOINTS.CREATE_TAG, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_TAG.DONE })
			return onSuccess && onSuccess(get(data, 'data.id'))
		} catch (error) {
			dispatch({ type: CREATE_TAG.FAILED })
			return onFailure && onFailure(error)
		}
	}
