import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { FormSubmitHandler, initialize, SubmitHandler } from 'redux-form'
import { Spin } from 'antd'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { createDownload, loadDownload, updateDownload } from '../../redux/downloads/actions'

// enums
import { FORMS } from '../../utils/enums'

// interfaces
import { IComputedMatch } from '../../types/interfaces'

// forms
import DownloadForm, { IDownloadForm } from './forms/DownloadForm'

interface IDetailDownloadCategory {
	id: number
	name: string
}

interface IDetailDownload {
	title: string
	description: string
	category: IDetailDownloadCategory
	language: string
	file: File | null
	url: string | null
	image: File
	imageTitle: string
	imageAlt: string
	createdAt?: string
	updatedAt?: string
	handleSubmit?: FormSubmitHandler<{}, IDownloadForm, string> | SubmitHandler<{}, IDownloadForm, string>
}

interface IDetailDownloadProps {
	computedMatch: IComputedMatch
}

const DetailDownload: React.FC<IDetailDownloadProps> = ({ computedMatch }) => {
	const id = get(computedMatch, 'params.id')
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const download = useSelector((state) => get(state, 'downloads.detail'))
	const isLoading = get(download, 'isLoading')

	const initDetailForm = (initData: IDetailDownload) => {
		const data = initData || get(download, 'data')
		const initValues = {
			...data,
			category: data?.category && data?.category.id,
			createdAt: dayjs(data.createdAt).format('H:mm:ss D. MMM YYYY'),
			updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY')
		}
		dispatch(
			initialize(FORMS.DOWNLOAD_FORM, {
				...initValues
			})
		)
	}

	const initCreateForm = () => {
		const initValues = {}
		dispatch(
			initialize(FORMS.DOWNLOAD_FORM, {
				...initValues
			})
		)
	}

	useEffect(() => {
		if (id) {
			dispatch(
				loadDownload(id, (data: IDetailDownload) => {
					initDetailForm(data)
				}) as any
			)
		} else {
			initCreateForm()
		}
	}, [dispatch, id])

	const handleCreate = (body: any) => {
		dispatch(
			createDownload(body, (newId: number) => {
				history.push(`${t('paths:downloadDetail|path')}/${newId}`)
			}) as any
		)
	}

	const handleUpdate = (body: any) => {
		dispatch(
			updateDownload(id, body, () => {
				dispatch(
					loadDownload(id, (data: IDetailDownload) => {
						initDetailForm(data)
					}) as any
				)
			}) as any
		)
	}

	const handleSubmit = (values: any) => {
		const body = {
			title: get(values, 'title'),
			description: get(values, 'description'),
			categoryID: get(values, 'category') !== '' ? get(values, 'category') : null,
			language: get(values, 'language'),
			file: !get(values, 'url') || get(values, 'url') === '' ? get(values, 'file', null) : null,
			url: get(values, 'url', null),
			image: get(values, 'image'),
			imageTitle: get(values, 'imageTitle'),
			imageAlt: get(values, 'imageAlt')
		}

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<DownloadForm isCreate={!id} onSubmit={handleSubmit} />
			</Spin>
		</div>
	)
}

export default DetailDownload
