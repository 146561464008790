import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import cx from 'classnames'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

const RadioGroupField = ({ input, options, customClass, meta: { error, touched }, labelMinValue, labelMaxValue, nullable }) => {
	const { t } = useTranslation()

	return (
		<div className={cx('radio-group-wrapper', customClass)}>
			{labelMinValue && <span className={'radio-group-label radio-group-label-min'}>{labelMinValue}</span>}
			{map(options, (option) => (
				<label
					key={`option ${option.id}`}
					onClick={option?.onClick}
					className={cx('option', { 'option-selected': option.id === parseInt(input.value, 10) })}
				>
					<div className={'text'}>
						<div className={'title'}>{option.title}</div>
						<div className={'description'}>{option.description}</div>
					</div>
					<input type={'radio'} name={input.name} checked={option.id === parseInt(input.value, 10)} onChange={() => input.onChange(option.id)} />
					<span className={'checkmark'} />
				</label>
			))}
			{labelMaxValue && <span className={'radio-group-label radio-group-label-max'}>{labelMaxValue}</span>}
			{nullable && (
				<Button
					onClick={() => input.onChange(null)}
					type={'default'}
					style={{
						alignSelf: 'flex-end',
						marginBottom: 0,
						fontSize: 12,
						marginTop: 4,
						flex: '0 0 1'
					}}
				>
					{t('Vynulovať')}
				</Button>
			)}
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

RadioGroupField.propTypes = {
	input: PropTypes.shape().isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			// eslint-disable-next-line
		title: PropTypes.any,
			description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
		})
	).isRequired,
	customClass: PropTypes.string,
	meta: PropTypes.shape({
		error: PropTypes.string,
		touched: PropTypes.bool
	}).isRequired,
	labelMinValue: PropTypes.string,
	labelMaxValue: PropTypes.string,
	nullable: PropTypes.bool
}

RadioGroupField.defaultProps = {
	customClass: null,
	labelMinValue: '',
	labelMaxValue: '',
	nullable: false
}

export default RadioGroupField
