import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const DateField = ({
	input,
	dttmFormat = 'DD.MM.YYYY',
	customClass,
	showLabel,
	label,
	customLabelClass,
	name,
	allowClear,
	meta: { error, touched },
	disabledDate,
	disabledTime,
	showTime,
	defaultValue,
	defaultPickerValue,
	disabled,
	required,
	placeholder,
	picker
}) => {
	const { t } = useTranslation()

	let value
	if (input.value && dayjs(input.value).isValid()) {
		value = dayjs(input.value)
	}
	if (!input.value && defaultValue) {
		value = defaultValue
	}

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={name}>
					{label}
				</label>
			)}
			<div className={'input-field'}>
				<DatePicker
					allowClear={allowClear}
					onChange={input.onChange}
					picker={picker || 'date'}
					format={dttmFormat}
					value={value}
					disabledDate={disabledDate}
					disabledTime={disabledTime}
					showToday
					showTime={showTime}
					defaultValue={defaultValue}
					defaultPickerValue={defaultPickerValue}
					placeholder={placeholder || t('Zvoľte dátum')}
					disabled={disabled}
				/>
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

DateField.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	input: PropTypes.shape().isRequired,
	meta: PropTypes.shape({
		error: PropTypes.string,
		touched: PropTypes.bool
	}).isRequired,
	dttmFormat: PropTypes.string,
	disabledDate: PropTypes.func,
	disabledTime: PropTypes.func,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
	defaultValue: PropTypes.objectOf(dayjs),
	defaultPickerValue: PropTypes.objectOf(dayjs),
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	showLabel: PropTypes.bool,
	required: PropTypes.bool,
	allowClear: PropTypes.bool,
	picker: PropTypes.string
}

DateField.defaultProps = {
	name: null,
	label: null,
	dttmFormat: null,
	disabledDate: null,
	disabledTime: null,
	customClass: null,
	customLabelClass: null,
	showTime: false,
	defaultValue: null,
	defaultPickerValue: dayjs(),
	disabled: false,
	showLabel: true,
	required: false,
	allowClear: true,
	picker: null
}

export default DateField
