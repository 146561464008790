import { useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import toNumber from 'lodash/toNumber'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

// redux
import { RootState } from '../../redux'
import { loadSettings, updatePriceList } from '../../redux/settings/settingsActions'

// utils
import { CURRENCY, FORMS } from '../../utils/enums'

// types
import { Paths } from '../../types/api'

// forms
import SettingsForm from './forms/SettingsForm'
import { SettingsFormValues } from './forms/validateSettingsForm'

const Settings = () => {
	const dispatch = useDispatch()

	// selectors
	const settings = useSelector((state: RootState) => state.settings)
	const isLoading = settings?.isLoading

	const initDetailForm = useCallback(
		(data: any) => {
			const initValues = {
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY ')
			}

			data?.pricelist.forEach((item: any) => {
				const { currency } = item

				Object.keys(item).forEach((key) => {
					if (typeof item[key] === 'object') {
						Object.keys(item[key]).forEach((subKey) => {
							const propertyName = `${subKey}_${key}_${currency}`
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							initValues[propertyName] = item[key][subKey]
						})
					}
				})
			})

			dispatch(
				initialize(FORMS.SETTINGS_FORM, {
					...initValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		dispatch(
			loadSettings((data) => {
				initDetailForm(data)
			})
		)
	}, [dispatch, initDetailForm])

	const handleSubmit = (values: SettingsFormValues) => {
		const priceList: Paths.PutAdminSettingsPricelist.RequestBody = {
			pricelist: [
				{
					currency: CURRENCY.CZK,
					premium: {
						month: toNumber(values?.month_premium_CZK),
						quarter: toNumber(values?.quarter_premium_CZK),
						year: toNumber(values?.year_premium_CZK)
					},
					vip: {
						month: toNumber(values?.month_vip_CZK),
						quarter: toNumber(values?.quarter_vip_CZK),
						year: toNumber(values?.year_vip_CZK)
					}
				},
				{
					currency: CURRENCY.EUR,
					premium: {
						month: toNumber(values?.month_premium_EUR),
						quarter: toNumber(values?.quarter_premium_EUR),
						year: toNumber(values?.year_premium_EUR)
					},
					vip: {
						month: toNumber(values?.month_vip_EUR),
						quarter: toNumber(values?.quarter_vip_EUR),
						year: toNumber(values?.year_vip_EUR)
					}
				}
			]
		}
		dispatch(
			updatePriceList(priceList, () => {
				dispatch(loadSettings())
			})
		)
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<SettingsForm onSubmit={handleSubmit} />
			</Spin>
		</div>
	)
}

export default Settings
