import PropTypes from 'prop-types'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { trim } from 'lodash'

const EmbedElement = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const setContent = (value) => {
		itemContentSetter(item?.id, value)
	}

	return (
		<div className={'custom-item embed-element'}>
			<Input.TextArea
				placeholder={t('Sem zadajte Embed reťazec')}
				className={'input-field'}
				size={'large'}
				value={item?.content}
				onChange={(e) => setContent(e.target.value)}
				onBlur={(e) => setContent(trim(e.target.value))}
				allowClear
				autoSize={{ minRows: 4, maxRows: 6 }}
			/>
		</div>
	)
}

EmbedElement.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.string
	}),
	itemContentSetter: PropTypes.func.isRequired
}

EmbedElement.defaultProps = {
	item: {}
}

export default EmbedElement
