import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq, putReq } from '../../utils/request'

// types
import { Paths } from '../../types/api'
import {
	CREATE_PROMO_CODES,
	GET_PROMO_CODE,
	GET_SELECT_PROMO_CODES,
	UPDATE_PROMO_CODES,
	GET_PROMO_CODES_APPLICATIONS,
	GENERATE_PROMO_CODES,
	RESET_PROMO_CODES_LIST,
	PromoCodePayload
} from './types'

export const getPromoCodes = (params: Paths.GetAdminPromoCodes.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_SELECT_PROMO_CODES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PROMO_CODES, normalizeQueryParams)

		const tableList = map(data.codes, (item) => ({
			key: get(item, 'id'),
			code: get(item, 'code', ''),
			state: get(item, 'state', ''),
			type: get(item, 'type', ''),
			lifespan: get(item, 'lifespan', ''),
			forNewcomers: get(item, 'forNewcomers', false),
			argument: {
				arg: get(item, 'argument', 0),
				type: get(item, 'type', '')
			},
			validFrom: get(item, 'validFrom', ''),
			validTo: get(item, 'validTo', '')
		}))

		dispatch({
			type: GET_SELECT_PROMO_CODES.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_SELECT_PROMO_CODES.FAILED })
		Promise.reject(error)
	}
}

export const createPromoCodes =
	(body: Paths.PostAdminPromoCodes.RequestBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_PROMO_CODES.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || (query === false ? false : undefined))
			const { data } = await postReq(ENDPOINTS.CREATE_PROMO_CODES, undefined, normalizeQueryParams)

			dispatch({ type: CREATE_PROMO_CODES.DONE })
			return onSuccess && onSuccess(get(data, 'data.id'))
		} catch (error) {
			dispatch({ type: CREATE_PROMO_CODES.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updatePromoCode =
	(id: number, body: Paths.PutAdminPromoCodesCodeId.RequestBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_PROMO_CODES.START })
		try {
			await putReq(ENDPOINTS.UPDATE_PROMO_CODES(id), undefined, body)
			dispatch({ type: UPDATE_PROMO_CODES.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_PROMO_CODES.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getPromoCode = (id: number, onSuccess?: (args: PromoCodePayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_PROMO_CODE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_PROMO_CODE(id))
		dispatch({ type: GET_PROMO_CODE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_PROMO_CODE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getPromoCodesApplications = (params: Paths.GetAdminPromoCodesApplications.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_PROMO_CODES_APPLICATIONS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PROMO_CODES_USAGE, normalizeQueryParams)

		dispatch({
			type: GET_PROMO_CODES_APPLICATIONS.DONE,
			payload: {
				tableList: data.promoCodeApplications,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_PROMO_CODES_APPLICATIONS.FAILED })
		Promise.reject(error)
	}
}

export const generatePromoCodes =
	(body: Paths.PostAdminPromoCodesBatch.RequestBody, onSuccess?: (args: any) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: GENERATE_PROMO_CODES.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || (query === false ? false : undefined))
			const { data } = await postReq(ENDPOINTS.GENERATE_PROMO_CODES, undefined, normalizeQueryParams)
			dispatch({ type: GENERATE_PROMO_CODES.DONE })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GENERATE_PROMO_CODES.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const resetList = () => (dispatch: any) => {
	try {
		dispatch({ type: RESET_PROMO_CODES_LIST })
	} catch (error) {
		Promise.reject(error)
	}
}
