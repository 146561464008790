import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { Button, Empty, Popconfirm, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteTag, getTags } from '../../redux/tags/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { EMPTY_VALUE } from '../../utils/enums'
import { setFiltersForPage } from '../../utils/helpers'

// types
import { TagsPayload } from '../../redux/tags/types'

// atoms
import DateRepresent from '../../atoms/DateRepresent'

const PAGE_SIZE = 20

const Tags = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.tags?.list?.tags)
	const isLoadingList = useSelector((state: RootState) => state.tags?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.tags?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.tags?.list?.context)

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:tags|key'), persistFilter))

	const columns = [
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('URL slug'),
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Vytvorený'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 155,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: i18next.t('Aktualizovaný'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 155,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: TagsPayload[0]) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:tags|path')}/${record?.id}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať tag?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteTag(record?.id, () => {
									const body = {
										limit: PAGE_SIZE,
										page: 1,
										...filter
									}

									dispatch(getTags(body))

									dispatch(setFilters(t('paths:tags|key'), body))
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getTags(body))

		dispatch(setFilters(t('paths:tags|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, _: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-end'}>
				<Button icon={<PlusCircleOutlined />} href={t('paths:tagsCreate|path')} type={'primary'}>
					{t('Pridať tag')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as unknown as ColumnsType<any>}
				dataSource={dataSource}
				style={{ marginTop: 20 }}
				onChange={handleTableChange}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: context?.totalCount,
					current: context?.page,
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:tags|path')}/${record?.id}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

Tags.propTypes = {}

export default Tags
