import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import toLower from 'lodash/toLower'
import { Col, Row, Spin } from 'antd'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { getRecipeBooks } from '../../../redux/recipeBooks/actions'

// utils
import { FORMS, GENDER, PLANEAT_GENDER, RECIPE_BOOK_TYPE } from '../../../utils/enums'
import {
	disabledBirthdate,
	getPlaneatExerciseActivityOptions,
	getPlaneatGenderOptions,
	getPlaneatGoalOptions,
	getPlaneatStressLevelOptions,
	getPlaneatWorkDifficultyOptions,
	getRecipeBookTypeOptions
} from '../../../utils/helpers'

// types
import { RecipeBooksPayload } from '../../../redux/recipeBooks/types'
import { UserPayload } from '../../../redux/users/types'

// forms
import validateRecipeBooksForm, { RecipeBooksFormValues } from './valiateRecipeBooksForm'

// atoms
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import TextAreaField from '../../../atoms/form/TextAreaField'
import TextField from '../../../atoms/form/TextField'

const RecipeBooksForm = ({ handleSubmit }: InjectedFormProps<RecipeBooksFormValues>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state) => getFormValues(FORMS.RECIPE_BOOKS_FORM)(state)) as RecipeBooksFormValues
	const userDetail = useSelector((state: RootState) => state.users?.detail?.data)
	const { standardNutritionPlan, isLoading } = useSelector((state: RootState) => state.recipeBooks)

	// constants
	const disabledDate = (current: any) => {
		const minDate = dayjs('2024-01-01') // NOTE: Disable dates before January 1st, 2024
		const maxDate = dayjs().endOf('month') // NOTE: Disable dates after the current month
		return current && (current < minDate || current > maxDate) // NOTE: Disable if the date is before minDate or after maxDate
	}

	const initForm = useCallback(
		(userData: UserPayload, standardNutritionPlanData: RecipeBooksPayload['standardNutritionPlan']) => {
			const gender = userData?.gender === GENDER.NOT_DISCLOSED ? PLANEAT_GENDER.FEMALE : userData?.gender

			const initValues = {
				recipeBookType: RECIPE_BOOK_TYPE.STANDARD,
				goal: standardNutritionPlanData?.goal,
				height: userData?.height || standardNutritionPlanData?.height,
				weight: standardNutritionPlanData?.weight,
				birthDate: userData?.birthdate,
				gender: toLower(gender) || standardNutritionPlanData?.gender,
				workDifficulty: standardNutritionPlanData?.workDifficulty,
				exerciseActivity: standardNutritionPlanData?.exerciseActivity,
				stress: standardNutritionPlanData?.stress,
				exerciseActivityDetails: standardNutritionPlanData?.exerciseActivityDetails
			}

			dispatch(initialize(FORMS.RECIPE_BOOKS_FORM, initValues))
		},

		[dispatch]
	)

	useEffect(() => {
		dispatch(() => initForm(userDetail, standardNutritionPlan))
	}, [dispatch, userDetail, standardNutritionPlan, initForm])

	useEffect(() => {
		dispatch(getRecipeBooks(userDetail?.id))
	}, [dispatch])

	return (
		<Spin spinning={isLoading}>
			<form onSubmit={handleSubmit}>
				<Row gutter={16}>
					<Col span={12}>
						<Field
							name={'recipeBookType'}
							component={SelectField}
							label={t('Typ jedálničku')}
							options={getRecipeBookTypeOptions(t, !standardNutritionPlan)}
							required
						/>
					</Col>
					{fieldValues?.recipeBookType === RECIPE_BOOK_TYPE.BONUS && (
						<Col span={12}>
							<Field
								name={'date'}
								component={DateField}
								picker={'month'}
								disabledDate={disabledDate}
								label={t('Mesiac')}
								dttmFormat={'MMM YYYY'}
								placeholder={t('Zvoľte mesiac')}
								required
							/>
						</Col>
					)}
				</Row>
				{fieldValues?.recipeBookType === RECIPE_BOOK_TYPE.STANDARD && (
					<>
						<Field name={'goal'} label={t('Cieľ')} component={SelectField} options={getPlaneatGoalOptions(t)} required />
						<Row gutter={16}>
							<Col span={12}>
								<Field
									name={'height'}
									type='number'
									label={t('Výška')}
									component={TextField}
									placeholder={t('Zadaj výšku')}
									maxLength={3}
									suffix='cm'
									required
								/>
							</Col>
							<Col span={12}>
								<Field
									name={'weight'}
									type={'number'}
									label={t('Váha')}
									component={TextField}
									placeholder={t('Zadaj váhu')}
									step={0.1}
									suffix={'kg'}
									required
								/>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Field
									name={'birthDate'}
									component={DateField}
									disabledDate={disabledBirthdate}
									label={t('Dátum narodenia')}
									placeholder={t('Zadaj dátum narodenia')}
									defaultPickerValue={dayjs().subtract(15, 'years')}
									dttmFormat={'D.M.YYYY'}
									required
								/>
							</Col>
							<Col span={12}>
								<Field name={'gender'} component={SelectField} label={t('Pohlavie')} options={getPlaneatGenderOptions(t)} required />
							</Col>
						</Row>
						<Field
							name={'workDifficulty'}
							component={SelectField}
							label={t('Náročnosť vykonávanej práce')}
							options={getPlaneatWorkDifficultyOptions(t)}
							required
						/>
						<Field
							name={'exerciseActivity'}
							component={SelectField}
							label={t('Pravidelnosť cvičenia v týždni')}
							options={getPlaneatExerciseActivityOptions(t)}
							required
						/>
						<Field name={'stress'} component={SelectField} label={t('Hladina stresu')} options={getPlaneatStressLevelOptions(t)} required />
						<Field
							name={'exerciseActivityDetails'}
							label={t('Typ a trvanie športových aktivít v rámci bežného týždňa')}
							component={TextAreaField}
							placeholder={t('Napríklad Beh - 60 minút, Joga - 35 minút')}
						/>
					</>
				)}
			</form>
		</Spin>
	)
}

export default reduxForm<RecipeBooksFormValues>({
	form: FORMS.RECIPE_BOOKS_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateRecipeBooksForm
})(RecipeBooksForm)
