// types
import { GET_MOTIVATION_ADVICES, GET_MOTIVATION_ADVICE, DELETE_MOTIVATION_ADVICE, CREATE_MOTIVATION_ADVICE, UPDATE_MOTIVATION_ADVICE } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_MOTIVATION_ADVICES.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_MOTIVATION_ADVICES.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_MOTIVATION_ADVICES.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_MOTIVATION_ADVICE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_MOTIVATION_ADVICE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_MOTIVATION_ADVICE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_MOTIVATION_ADVICE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_MOTIVATION_ADVICE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: initState.detail.data,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_MOTIVATION_ADVICE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_MOTIVATION_ADVICE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_MOTIVATION_ADVICE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_MOTIVATION_ADVICE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_MOTIVATION_ADVICE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_MOTIVATION_ADVICE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_MOTIVATION_ADVICE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
