import { Paths } from '../../types/api'

export type RecipeBooksPayload = Paths.GetAdminUsersUserIdNutritionPlans.Responses.$200

export enum GET_RECIPE_BOOKS {
	START = 'GET_RECIPE_BOOKS_START',
	DONE = 'GET_RECIPE_BOOKS_DONE',
	FAILED = 'GET_RECIPE_BOOKS_FAILED'
}

export enum SEND_ORDER_STANDARD_RECIPE_BOOK {
	START = 'SEND_ORDER_STANDARD_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_STANDARD_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_STANDARD_RECIPE_BOOK_FAILED'
}

export enum SEND_ORDER_BONUS_RECIPE_BOOK {
	START = 'SEND_ORDER_BONUS_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_BONUS_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_BONUS_RECIPE_BOOK_FAILED'
}
