import { useState } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { RangePickerProps } from 'antd/es/date-picker'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { deletePushNotification } from '../../../redux/pushNotifications/actions'

// utils
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'
import {
	AUDIENCE_OPTIONS,
	FORMS,
	LANGUAGE,
	LANGUAGES,
	LIMITS,
	PUSH_NOTIFICATION_STATE,
	PUSH_NOTIFICATION_STATE_OPTIONS,
	UPLOAD_CATEGORY
} from '../../../utils/enums'

// types
import { IFieldLabels } from '../../../types/interfaces'

// validators
import validatePushNotificationForm, { PushNotificationFormValues } from './validatePushNotificationForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import PushNotificationRepresent from '../../../atoms/PushNotificationRepresent'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { Option } = Select

type PushNotificationFormProps = {
	isCreate?: boolean
	originalState?: string
}

const PushNotificationForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false,
	originalState
}: PushNotificationFormProps & InjectedFormProps<PushNotificationFormValues, PushNotificationFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state) => getFormValues(FORMS.PUSH_NOTIFICATION_FORM)(state)) as PushNotificationFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PUSH_NOTIFICATION_FORM))

	// states
	const [notificationImage, setNotificationImage] = useState({
		isLoading: false,
		imageUrl: get(fieldValues, 'image', null)
	})

	// constants
	const isDraft = get(fieldValues, 'state') === PUSH_NOTIFICATION_STATE.DRAFT
	const isScheduled = get(fieldValues, 'state') === PUSH_NOTIFICATION_STATE.SCHEDULED
	const isBeforeNow = dayjs(get(fieldValues, 'scheduledAt')).isBefore(dayjs())
	const isBeforeAllowedEditLimit =
		get(fieldValues, 'scheduledAt') && dayjs(get(fieldValues, 'scheduledAt')).diff(dayjs(), 'minute') < LIMITS.NOTIFICATION_EDIT
	const isDisabled =
		get(fieldValues, 'state') === PUSH_NOTIFICATION_STATE.PROCESSING ||
		get(fieldValues, 'state') === PUSH_NOTIFICATION_STATE.SENT ||
		get(fieldValues, 'state') === PUSH_NOTIFICATION_STATE.FAILED

	const range = (start: number, end: number) => {
		const result = []
		// eslint-disable-next-line no-plusplus
		for (let i = start; i < end; i++) {
			result.push(i)
		}
		return result
	}

	const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
		// Can not select days before today
		return current && current < dayjs().startOf('day')
	}

	const disabledTime = () => {
		return {
			disabledHours: () => range(0, 24).splice(0, dayjs().hour())
		}
	}

	const fieldLabels: IFieldLabels = {
		title_sk: t('Názov'),
		message_sk: t('Text notifikácie'),
		scheduledAt: t('Dátum a čas odoslania'),
		audiences: t('Cieľová skupina')
	}

	const getTooltipTitle = () => {
		if (invalid) return `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) => getFieldLabel(field, fieldLabels))}`
		if (pristine) return null
		if (!isDraft && isBeforeNow) return t('Notifikáciu je možné naplánovať iba pre dátum a čas v budúcnosti.')

		return null
	}

	const refreshImageAfterUpload = (fileUrl: any) => {
		setNotificationImage({
			isLoading: false,
			imageUrl: fileUrl
		})
	}

	const uploadImage = async ({ file }: any) => {
		setNotificationImage({
			...notificationImage,
			isLoading: true
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PUSH_NOTIFICATION)

		if (fileUrl) {
			dispatch(change(FORMS.PUSH_NOTIFICATION_FORM, 'image', fileUrl))
			refreshImageAfterUpload(fileUrl)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať push notifikáciu?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deletePushNotification(fieldValues?.id, () => {
						history.push(t('paths:pushNotifications|path'))
					})
				)
			}
		})
	}

	const tabItems = map(LANGUAGES, (item: string) => ({
		label: getLanguageName(item),
		key: item,
		children: (
			<>
				<Field
					name={`title_${item}`}
					component={TextField}
					label={t('Názov')}
					disabled={(!isCreate && !isDraft && pristine) || isDisabled}
					required={get(fieldValues, 'language', LANGUAGE.SK) === item}
				/>

				<Field
					name={`message_${item}`}
					component={TextField}
					label={t('Text notifikácie')}
					disabled={(!isCreate && !isDraft && pristine) || isDisabled}
					required={get(fieldValues, 'language', LANGUAGE.SK) === item}
				/>

				<Field
					name={'state'}
					component={CustomOptionsSelectField}
					label={t('Stav')}
					options={map(PUSH_NOTIFICATION_STATE_OPTIONS, (option, index) => {
						const isOptionDisabled =
							(!isDraft && isBeforeAllowedEditLimit && get(fieldValues, 'state') !== option.value && pristine) ||
							option.value === PUSH_NOTIFICATION_STATE.PROCESSING ||
							option.value === PUSH_NOTIFICATION_STATE.SENT ||
							option.value === PUSH_NOTIFICATION_STATE.FAILED
						return (
							<Option key={index} value={option.value} disabled={isOptionDisabled}>
								<PushNotificationRepresent value={option.value} disabled={isOptionDisabled} />
							</Option>
						)
					})}
					disabled={(!isDraft && isBeforeAllowedEditLimit && pristine) || isDisabled}
					required
				/>

				<Field
					name={'audiences'}
					label={t('Cieľová skupina')}
					component={CustomOptionsSelectField}
					options={map(AUDIENCE_OPTIONS, (option, index) => (
						<Option key={index} value={option.value}>
							{option.label || ''}
						</Option>
					))}
					disabled={(!isCreate && !isDraft && pristine) || isDisabled}
					multiple
					allowClear
					required
				/>

				{!isCreate && <Field name={'recipientCount'} component={TextField} type={'number'} label={t('Počet adresátov')} disabled />}
			</>
		)
	}))

	const renderSidebarContent = () => (
		<div className={'flex direction-col justify-center'}>
			<Field
				name={'scheduledAt'}
				component={DateField}
				label={t('Dátum a čas odoslania')}
				dttmFormat={'D.M.YYYY HH:mm'}
				placeholder={t('Zvoľte dátum a čas')}
				disabledDate={disabledDate}
				disabledTime={disabledTime}
				disabled={(!isCreate && !isDraft && pristine) || isDisabled}
				showTime
				required
			/>

			<Field
				name={'image'}
				imageUrl={get(fieldValues, 'image')}
				component={UploadInputField}
				label={t('Obrázok (upload)')}
				customRequest={uploadImage}
				disabled={(!isCreate && !isDraft && pristine) || isDisabled}
				isLoading={notificationImage.isLoading}
			/>

			<Tooltip title={getTooltipTitle()} placement={'bottom'}>
				<Button
					icon={<SaveOutlined />}
					onClick={handleSubmit}
					disabled={
						pristine ||
						invalid ||
						notificationImage.isLoading ||
						(!isDraft && isBeforeNow) ||
						(!isCreate && !pristine && isScheduled && isBeforeAllowedEditLimit) ||
						(!isCreate && isScheduled && get(fieldValues, 'state') === originalState) ||
						isDisabled
					}
					type={'primary'}
					style={{ margin: '20px' }}
				>
					{t('Uložiť')}
				</Button>
			</Tooltip>

			{!isCreate && (
				<Button
					icon={<DeleteOutlined />}
					onClick={handleRemove}
					type={'primary'}
					disabled={(!isDraft && isBeforeAllowedEditLimit && pristine) || isDisabled}
					danger
					style={{ margin: '20px' }}
				>
					{t('Vymazať notifikáciu')}
				</Button>
			)}
		</div>
	)

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať push notifikáciu') : t('Detail push notifikácie')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať novú push notifikáciu'),
										path: t('paths:pushNotificationCreate|path')
									}
								]
							}
						/>
						<Tabs items={tabItems} defaultActiveKey={LANGUAGE.SK} className={'translations'} />
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>{renderSidebarContent()}</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<PushNotificationFormValues, PushNotificationFormProps>({
	form: FORMS.PUSH_NOTIFICATION_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePushNotificationForm
})(PushNotificationForm)
