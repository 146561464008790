import { Paths } from '../../types/api'

export type DashboardPagePayload = Paths.GetAdminDashboard.Responses.$200
export type StatisticsPayload = Paths.GetAdminStatistics.Responses.$200['statistics']
export type SubscriptionStatisticsPayload = Paths.GetAdminStatisticsSubscriptions.Responses.$200['subscriptionStatistics']
export type ProgramsEngagementStatisticsPayload = Paths.GetAdminStatisticsProgramsEngagements.Responses.$200['programsEngagementStatistics']
export type ProgramEngagementTrendStatisticsPayload = Paths.GetAdminStatisticsProgramsEngagementsTrends.Responses.$200['programEngagementTrendStatistics']
export type ExercisesViewsStatisticsPayload = Paths.GetAdminStatisticsExercisesViews.Responses.$200['exercisesViewStatistics']
export type ExercisesViewsTrendStatisticsPayload = Paths.GetAdminStatisticsExercisesViewsTrends.Responses.$200['exercisesViewsTrendStatistics']
export type ExercisesFavouritesStatisticsPayload = Paths.GetAdminStatisticsExercisesFavourites.Responses.$200['favouriteExercisesStatistics']
export type PaymentsStatisticsPayload = Paths.GetAdminStatisticsPayments.Responses.$200

export type RecipeBooksStatisticsPayload = {
	basic: Paths.GetAdminStatisticsNutritionPlans.Responses.$200['nutritionPlanStatistics']['basic'] | null
	bonus: Paths.GetAdminStatisticsNutritionPlans.Responses.$200['nutritionPlanStatistics']['bonus'] | null
}

export enum GET_DASHBOARD {
	START = 'GET_DASHBOARD_START',
	DONE = 'GET_DASHBOARD_DONE',
	FAILED = 'GET_DASHBOARD_FAILED'
}

export enum GET_STATISTICS {
	START = 'GET_STATISTICS_START',
	DONE = 'GET_STATISTICS_DONE',
	FAILED = 'GET_STATISTICS_FAILED'
}

export enum GET_SUBSCRIPTION_STATISTICS {
	START = 'GET_SUBSCRIPTION_STATISTICS_START',
	DONE = 'GET_SUBSCRIPTION_STATISTICS_DONE',
	FAILED = 'GET_SUBSCRIPTION_STATISTICS_FAILED'
}

export enum GET_PROGRAMS_ENGAGEMENT_STATISTICS {
	START = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_START',
	DONE = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_DONE',
	FAILED = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_FAILED'
}

export enum GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS {
	START = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_START',
	DONE = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_DONE',
	FAILED = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_FAILED'
}

export enum GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY {
	START = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_START',
	DONE = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_DONE',
	FAILED = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_FAILED'
}

export enum GET_EXERCISES_VIEWS_STATISTICS {
	START = 'GET_EXERCISES_VIEWS_STATISTICS_START',
	DONE = 'GET_EXERCISES_VIEWS_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_VIEWS_STATISTICS_FAILED'
}

export enum GET_EXERCISES_VIEWS_TREND_STATISTICS {
	START = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_START',
	DONE = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_FAILED'
}

export enum GET_EXERCISES_FAVOURTITES_STATISTICS {
	START = 'GET_EXERCISES_FAVOURTITES_STATISTICS_START',
	DONE = 'GET_EXERCISES_FAVOURTITES_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_FAVOURTITES_STATISTICS_FAILED'
}

export enum GET_PAYMENTS_STATISTICS {
	START = 'GET_PAYMENTS_STATISTICS_START',
	DONE = 'GET_PAYMENTS_STATISTICS_DONE',
	FAILED = 'GET_PAYMENTS_STATISTICS_FAILED'
}

export enum GET_RECIPE_BOOKS_STATISTICS {
	START = 'GET_RECIPE_BOOKS_STATISTICS_START',
	FAILED = 'GET_RECIPE_BOOKS_STATISTICS_FAILED',
	DONE = 'GET_RECIPE_BOOKS_STATISTICS_DONE'
}
