// types
import { GET_EXERCISES, GET_EXERCISE, DELETE_EXERCISE, CREATE_EXERCISE, UPDATE_EXERCISE } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		filterOptions: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_EXERCISES.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_EXERCISES.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_EXERCISES.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_EXERCISE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_EXERCISE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_EXERCISE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_EXERCISE.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_EXERCISE.DONE:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_EXERCISE.FAILED:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_EXERCISE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_EXERCISE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_EXERCISE.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_EXERCISE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_EXERCISE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_EXERCISE.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
