// types
import { Paths } from '../../types/api'

export type GiftProductsPayload = Paths.GetAdminGiftProducts.Responses.$200
export type GiftProductsSearchPayload = Paths.GetAdminGiftProductsSearch.Responses.$200['giftProducts']

export enum GET_GIFT_PRODUCTS {
	START = 'GET_GIFT_PRODUCTS_START',
	DONE = 'GET_GIFT_PRODUCTS_DONE',
	FAILED = 'GET_GIFT_PRODUCTS_FAILED'
}

export enum UPDATE_GIFT_PRODUCTS {
	START = 'UPDATE_GIFT_PRODUCTS_START',
	DONE = 'UPDATE_GIFT_PRODUCTS_DONE',
	FAILED = 'UPDATE_GIFT_PRODUCTS_FAILED'
}
