import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import each from 'lodash/each'
import every from 'lodash/every'
import filter from 'lodash/filter'
import get from 'lodash/get'
import i18next from 'i18next'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import slugify from 'slugify'
import toInteger from 'lodash/toInteger'
import uniq from 'lodash/uniq'
import { Button, Col, Input, Modal, Row, Select, Tabs, Tooltip, Checkbox } from 'antd'
import { CloseCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, GifOutlined, PictureOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Field, reduxForm, getFormValues, change, getFormSyncErrors, InjectedFormProps } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

import { deleteVideo } from '../../../redux/videos/actions'
import { getBlogPostList, getAccessorieList, getTrainerList, getTargetsList, getMotivationAdviceList, getExerciseList } from '../../../redux/lists/actions'

// utils
import { FORMS, LANGUAGE, UPLOAD_CATEGORY, DIFFICULTY, EXERCISE_BODY_PART, LANGUAGES, INPUT_MAX_LENGTH } from '../../../utils/enums'
import { getLanguageName, getErrorFieldsLabel, getFieldLabel } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// validators
import validateVideoForm, { VideoFormValues } from './validateVideoForm'

// components
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CircleColorField from '../../../atoms/form/CircleColorField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import DetailHeader from '../../../components/DetailHeader'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TableField from '../../../atoms/form/TableField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'
import { IFieldLabels } from '../../../types/interfaces'

const { TabPane } = Tabs
const { Option } = Select

const defaultCaloriesTable = [
	[45, 50],
	[51, 55],
	[56, 60],
	[61, 65],
	[66, 70],
	[71, 75],
	[76, 80],
	[81, 85],
	[86, 90],
	[91, 95],
	[96, 100]
]

const armsOptions = [EXERCISE_BODY_PART.TRICEPS, EXERCISE_BODY_PART.BICEPS, EXERCISE_BODY_PART.SHOULDERS, EXERCISE_BODY_PART.FOREARM]
const legsOptions = [EXERCISE_BODY_PART.CALVES, EXERCISE_BODY_PART.QUADS, EXERCISE_BODY_PART.GLUTES]

type VideoFormProps = {
	handleSubmit: () => void
	generateThumbnail: () => void
	isCreate?: boolean
	showModal?: any
}

const VideoForm = ({
	handleSubmit,
	generateThumbnail,
	isCreate = false,
	invalid,
	pristine,
	showModal
}: VideoFormProps & InjectedFormProps<VideoFormValues, VideoFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.VIDEO_FORM)(state)) as VideoFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.VIDEO_FORM))
	const detail = useSelector((state: RootState) => state.videos?.detail?.data?.video)
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)
	const blogPosts = useSelector((state: RootState) => state.lists?.list?.blogPosts)
	const trainers = useSelector((state: RootState) => state.lists?.list?.trainers)
	const targets = useSelector((state: RootState) => state.lists?.list?.targets)
	const accessories = useSelector((state: RootState) => state.lists?.list?.accessories)
	const exercises = useSelector((state: RootState) => state.lists?.list?.exercises)
	const motivationAdvices = useSelector((state: RootState) => state.lists?.list?.motivationAdvices)

	// states
	const [image, setImage] = useState<any>({
		isLoading: false,
		imageUrl: ''
	})

	// constants
	const difficultiesOptions = [
		{ value: DIFFICULTY.EDUCATIONAL, label: t('Náučná') },
		{ value: DIFFICULTY.EASY, label: t('Ľahká') },
		{ value: DIFFICULTY.MEDIUM, label: t('Stredná') },
		{ value: DIFFICULTY.HARD, label: t('Ťažká') }
	]

	const bodyPartsOptions = [
		{ value: EXERCISE_BODY_PART.SHOULDERS, label: t('Ramená') },
		{ value: EXERCISE_BODY_PART.CHEST, label: t('Prsné svaly') },
		{ value: EXERCISE_BODY_PART.BICEPS, label: t('Biceps') },
		{ value: EXERCISE_BODY_PART.TRICEPS, label: t('Triceps') },
		{ value: EXERCISE_BODY_PART.FOREARM, label: t('Predlaktie') },
		{ value: EXERCISE_BODY_PART.ABS, label: t('Brucho') },
		{ value: EXERCISE_BODY_PART.QUADS, label: t('Stehná') },
		{ value: EXERCISE_BODY_PART.CALVES, label: t('Lýtka') },
		{ value: EXERCISE_BODY_PART.GLUTES, label: t('Zadok') },
		{ value: EXERCISE_BODY_PART.LATS, label: t('Chrbát') },
		{ value: EXERCISE_BODY_PART.HIPS, label: t('Boky') },
		{ value: EXERCISE_BODY_PART.FULL_BODY, label: t('Celé telo') }
	]

	const trainerLanguage = map(LANGUAGES, (language) => ({
		value: language,
		label: getLanguageName(language)
	}))

	const fieldLabels: IFieldLabels = {
		language: t('Jazyk videa'),
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		briefDescription_sk: t('Krátky popis'),
		detailedDescription_sk: t('Detailný popis'),
		difficulty: t('Obtiažnosť'),
		image: t('Fotka (upload)'),
		video: t('Video (link na Vimeo)'),
		duration: t('Dĺžka videa'),
		calories: t('Tabuľka kalórií'),
		order: t('Poradie')
	}

	const changeCalories = (field: string, value: string, row: any) => {
		const caloriesValue: object[] = []
		each(get(fieldValues, 'calories'), (item, index) => {
			caloriesValue.push({
				key: index,
				weightFrom: row.key === index && field === 'weightFrom' ? value : item.weightFrom,
				weightTo: row.key === index && field === 'weightTo' ? value : item.weightTo,
				calories: row.key === index && field === 'calories' ? value : item.calories
			})
		})

		dispatch(change(FORMS.VIDEO_FORM, 'calories', caloriesValue))
	}

	const fillCaloriesTable = () => {
		dispatch(
			change(
				FORMS.VIDEO_FORM,
				'calories',
				map(defaultCaloriesTable, (item, index) => ({
					key: index,
					weightFrom: item[0],
					weightTo: item[1],
					calories: 0
				}))
			)
		)
	}

	const caloriesTableColumns = [
		{
			title: i18next.t('Hmotnosť "Od" [kg]'),
			dataIndex: 'weightFrom',
			key: 'weightFrom',
			render: (weightFrom: any, row: any) => (
				// eslint-disable-next-line no-use-before-define
				<Input
					value={weightFrom}
					placeholder={'0'}
					maxLength={INPUT_MAX_LENGTH}
					min={0}
					onChange={(e) => changeCalories('weightFrom', e.target.value, row)}
					type={'number'}
				/>
			)
		},
		{
			title: i18next.t('Hmotnosť "Do" [kg]'),
			dataIndex: 'weightTo',
			key: 'weightTo',
			render: (weightTo: any, row: any) => (
				// eslint-disable-next-line no-use-before-define
				<Input
					value={weightTo}
					placeholder={'0'}
					min={0}
					maxLength={INPUT_MAX_LENGTH}
					onChange={(e) => changeCalories('weightTo', e.target.value, row)}
					type={'number'}
				/>
			)
		},
		{
			title: i18next.t('Spálené kalórie [kcal]'),
			dataIndex: 'calories',
			key: 'calories',
			render: (calories: any, row: any) => (
				// eslint-disable-next-line no-use-before-define
				<Input
					value={calories}
					placeholder={'0'}
					min={0}
					maxLength={INPUT_MAX_LENGTH}
					onChange={(e) => changeCalories('calories', e.target.value, row)}
					type={'number'}
				/>
			)
		},
		{
			title: '',
			key: 'action',
			width: 50,
			render: (_text: any, record: any) => (
				<Button
					icon={<DeleteOutlined />}
					type={'primary'}
					danger
					onClick={() => {
						const caloriesValue: object[] = []
						each(get(fieldValues, 'calories'), (item, index) => {
							if (record.key !== item.key) {
								caloriesValue.push({
									key: index,
									weightFrom: item.weightFrom,
									weightTo: item.weightTo,
									calories: item.calories
								})
							}
						})

						dispatch(change(FORMS.VIDEO_FORM, 'calories', caloriesValue))
					}}
				/>
			)
		}
	]

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať video?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteVideo(get(fieldValues, 'id'), () => {
						history.push(t('paths:videos|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.EXERCISE_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.VIDEO_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.VIDEO_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, lang: string) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${lang}`)
		}
	}

	useEffect(() => {
		if (get(fieldValues, 'isPublished') && includes([undefined, null], get(fieldValues, 'publishDate'))) {
			dispatch(change(FORMS.VIDEO_FORM, 'publishDate', dayjs()))
		}
		if (get(fieldValues, 'difficulty') === DIFFICULTY.EDUCATIONAL && !isEmpty(get(fieldValues, 'calories'))) {
			Modal.confirm({
				title: t('Upozornenie'),
				content: t('Zmenili ste obtiažnosť videa na "Náučné", čím sa vymažú hodnoty v tabuľke kalórií Prajete si potvrdiť túto zmenu?'),
				cancelText: t('Zrušiť'),
				okText: t('Potvrdiť'),
				onCancel: () => {
					dispatch(change(FORMS.VIDEO_FORM, 'difficulty', get(detail, 'difficulty', DIFFICULTY.EASY)))
				},
				onOk: () => {
					dispatch(change(FORMS.VIDEO_FORM, 'calories', []))
				}
			})
		}
	}, [get(fieldValues, 'isPublished'), get(fieldValues, 'difficulty')])

	const addCaloriesRow = () => {
		const caloriesValue = []

		if (!get(fieldValues, 'calories')) {
			caloriesValue.push({
				key: 0,
				weightFrom: '',
				weightTo: '',
				calories: ''
			})
		} else {
			let lastIndex = 0
			each(get(fieldValues, 'calories'), (item, index) => {
				caloriesValue.push({
					key: index,
					weightFrom: item.weightFrom,
					weightTo: item.weightTo,
					calories: item.calories
				})
				lastIndex = toInteger(index)
			})

			caloriesValue.push({
				key: lastIndex + 1,
				weightFrom: '',
				weightTo: '',
				calories: ''
			})
		}

		dispatch(change(FORMS.VIDEO_FORM, 'calories', caloriesValue))
	}

	const handleLimbsOptions = async (limbs: EXERCISE_BODY_PART[]) => {
		if (!every(limbs, (bodyPart: EXERCISE_BODY_PART) => includes(get(fieldValues, 'bodyParts'), bodyPart))) {
			await dispatch(change(FORMS.VIDEO_FORM, 'bodyParts', uniq(fieldValues?.bodyParts.concat(limbs))))
		} else {
			await dispatch(
				change(
					FORMS.VIDEO_FORM,
					'bodyParts',
					filter(get(fieldValues, 'bodyParts'), (option: string) => !includes(limbs, option))
				)
			)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať video') : t('Detail videa')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nové video'),
										path: t('paths:videosCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`name_${item}`}
										onInput={(e: any) => changeUrlSlug(e, item)}
										component={TextField}
										label={t('Názov')}
										size={'large'}
										required
									/>

									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL slug')} required />

									<Field name={`url_${item}`} component={TextField} label={t('Video (link na Vimeo)')} required />

									<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} required />

									<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} required />

									<Field name={`duration_${item}`} component={TextField} label={t('Dĺžka videa')} suffix={'min'} type={'number'} required />

									<Field name={`stickerText_${item}`} component={TextField} label={t('Text nálepky')} />

									<Field name={`stickerColor_${item}`} component={CircleColorField} label={t('Farba nálepky')} />
								</TabPane>
							))}
						</Tabs>

						<Field name={'difficulty'} component={SelectField} label={t('Obtiažnosť')} options={difficultiesOptions} required />

						<Field
							name={'bodyParts'}
							component={CustomOptionsSelectField}
							label={t('Časti tela')}
							options={map(bodyPartsOptions, (part, index) => (
								<Option
									key={index}
									value={get(part, 'value')}
									disabled={
										(get(part, 'value') !== EXERCISE_BODY_PART.FULL_BODY &&
											includes(get(fieldValues, 'bodyParts'), EXERCISE_BODY_PART.FULL_BODY)) ||
										(get(part, 'value') === EXERCISE_BODY_PART.FULL_BODY &&
											!isEmpty(get(fieldValues, 'bodyParts')) &&
											!includes(get(fieldValues, 'bodyParts'), EXERCISE_BODY_PART.FULL_BODY))
									}
								>
									{get(part, 'label') || ''}
								</Option>
							))}
							multiple
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						/>
						<Row gutter={8} style={{ marginBottom: '10px' }}>
							<Col>
								<Checkbox
									onChange={() => handleLimbsOptions(armsOptions)}
									checked={every(armsOptions, (item) => includes(get(fieldValues, 'bodyParts'), item))}
								>
									{t('Ruky')}
								</Checkbox>
							</Col>
							<Col>
								<Checkbox
									onChange={() => handleLimbsOptions(legsOptions)}
									checked={every(legsOptions, (item) => includes(get(fieldValues, 'bodyParts'), item))}
								>
									{t('Nohy')}
								</Checkbox>
							</Col>
						</Row>

						{get(fieldValues, 'difficulty') !== DIFFICULTY.EDUCATIONAL && (
							<Field
								name={'calories'}
								component={TableField}
								rowKey={'key'}
								label={t('Tabuľka kalórií')}
								columns={caloriesTableColumns}
								actionButtons={
									get(fieldValues, 'difficulty') !== DIFFICULTY.EDUCATIONAL && [
										{
											title: t('Pridať záznam'),
											btnType: 'primary',
											icon: <PlusCircleOutlined />,
											action: addCaloriesRow
										},
										{
											title: t('Predvyplniť tabulku'),
											btnType: 'normal',
											icon: <EditOutlined />,
											disabled: get(fieldValues, 'calories', [])?.length > 0,
											action: fillCaloriesTable
										}
									]
								}
								required
							/>
						)}

						<Field
							name={'accessories'}
							component={AsyncTransferField}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:accessory|path')}/${id}`, '_blank')
								}
							}}
							label={t('Pomôcky')}
							reduxFetch={(config: any) => dispatch(getAccessorieList(config))}
							modalDataSource={accessories}
							context={listContext}
						/>

						<Field
							name={'trainers'}
							component={AsyncTransferField}
							label={t('Inštruktori')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:instructor|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getTrainerList(config))}
							modalDataSource={trainers}
							context={listContext}
						/>

						<Field
							name={'motivationAdvices'}
							component={AsyncTransferField}
							label={t('Motivačný tip')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:motivationAdvice|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getMotivationAdviceList(config))}
							modalDataSource={motivationAdvices}
							context={listContext}
							maxItems={1}
						/>

						<Field
							name={'recommendedExercises'}
							component={AsyncTransferField}
							label={t('Odporúčané cvičenia')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getExerciseList(config))}
							modalDataSource={exercises}
							context={listContext}
						/>

						<Field
							name={'recommendedBlogPosts'}
							component={AsyncTransferField}
							label={t('Odporúčané blogové články')}
							reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
							modalDataSource={blogPosts}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
								}
							}}
							context={listContext}
						/>

						<Field
							name={'targets'}
							component={AsyncTransferField}
							label={t('Zamerané na')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: any) => {
									window.open(`${t('paths:exerciseTarget|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getTargetsList(config))}
							modalDataSource={targets}
							context={listContext}
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'language'} component={SelectField} options={trainerLanguage} label={t('Jazyk videa')} />

						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							customHelper={t('recommendedSize|videoThumbnail')}
							label={t('Fotka (upload)')}
							customRequest={uploadImage}
							isLoading={image.isLoading}
							required
						/>

						<Field
							name={'publishDate'}
							component={DateField}
							label={t('Dátum a čas publikovania')}
							dttmFormat={'D.M.YYYY HH:mm'}
							showTime
							disabled={get(fieldValues, 'isPublished')}
						/>
						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />
						<Field name={'isPreferredLiveStream'} component={SwitchField} label={t('Preferovaný živý prenos')} />

						<div className={'flex direction-col justify-center'}>
							{!isCreate && (
								<>
									<Tooltip title={`${t('Ak sa zmeny neprejavia, refreshnite prosím stránku')}`} placement={'bottom'}>
										<Button
											icon={<PictureOutlined />}
											onClick={generateThumbnail}
											disabled={!pristine}
											type={'default'}
											style={{ margin: '20px' }}
										>
											{t('Generovať thumbnail obrázok')}
										</Button>
									</Tooltip>
									<div className={'thumbnail-gif'}>
										<span>{t('GIF thumbnail')}</span>
										{detail?.animatedThumbnail ? (
											<img src={detail?.animatedThumbnail} alt={'Thumbnail'} />
										) : (
											<div>
												<CloseCircleFilled />
											</div>
										)}
									</div>
									<Button
										icon={<GifOutlined />}
										onClick={() => showModal(true)}
										disabled={!pristine}
										type={'default'}
										style={{ margin: '20px' }}
									>
										{t('Generovať thumbnail animáciu')}
									</Button>
								</>
							)}
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať video')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<VideoFormValues, VideoFormProps>({
	form: FORMS.VIDEO_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateVideoForm
})(VideoForm)
