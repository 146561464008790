import debounce from 'lodash/debounce'
import get from 'lodash/get'
import i18next from 'i18next'
import { Button, Empty, Form, Input, Menu, Popconfirm, Table } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deletePreference, getPreferences } from '../../redux/preferences/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { EMPTY_VALUE, INPUT_MAX_LENGTH, PREFERENCE_TYPE } from '../../utils/enums'
import { setFiltersForPage } from '../../utils/helpers'

// types
import { PreferencesListItemPayload } from '../../redux/preferences/types'

// atoms
import OrderRepresent from '../../atoms/OrderRepresent'
import DateRepresent from '../../atoms/DateRepresent'

const PAGE_SIZE = 20

const Preferences = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.preferences?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.preferences?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.preferences?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.preferences?.list?.context)

	// states
	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:preferences|key'), persistFilter.filters))
	const [selectedTab, setSelectedTab] = useState(() => persistFilter?.filters.type || PREFERENCE_TYPE.LIFESTYLE)

	const resetFilter = () => {
		setFilter({})
	}

	const columns = [
		{
			title: i18next.t('Názov'),
			dataIndex: 'title',
			key: 'title',
			sorter: true,
			width: 250,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Popis'),
			dataIndex: 'description',
			key: 'description',
			width: 400,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center',
			width: 60,
			render: (value: string) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			sorter: true,
			key: 'updatedAt',
			width: 155,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			align: 'right',
			width: 100,
			render: (_: any, record: PreferencesListItemPayload & { key: number }) => (
				<Popconfirm
					title={t('Skutočne chcete vymazať záznam?')}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					cancelText={t('Zrušiť')}
					okText={t('Vymazať')}
					onConfirm={(e: any) => {
						e.stopPropagation()
						dispatch(
							deletePreference(record.key, () => {
								dispatch(
									getPreferences({
										limit: PAGE_SIZE,
										page: 1,
										...filter
									})
								)
							})
						)
					}}
					onCancel={(e: any) => e.stopPropagation()}
					okButtonProps={{
						size: 'small',
						type: 'primary',
						danger: true
					}}
					cancelButtonProps={{
						size: 'small',
						type: 'ghost'
					}}
				>
					<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
				</Popconfirm>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			type: PREFERENCE_TYPE.LIFESTYLE
		}

		setFilter(body)
	}, [])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getPreferences(body))

		dispatch(setFilters(t('paths:preferences|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => {
			setFilter({ ...filter, search: searchTerm, page: 1 })
		}, 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} style={{ width: 300 }} maxLength={INPUT_MAX_LENGTH} onChange={handleOnChange} allowClear />
				</Form.Item>
				<Form.Item>
					<Button icon={<PlusCircleOutlined />} href={t('paths:preferenceCreate|path')} type={'primary'}>
						{t('Pridať preferenciu')}
					</Button>
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Menu
				onClick={(menu: any) => {
					setSelectedTab(menu.key)
					if (menu.item.props.filterBy) {
						setFilter({
							...filter,
							page: 1,
							type: menu.item.props.filterBy
						})
					}
				}}
				selectedKeys={[selectedTab]}
				mode={'horizontal'}
				style={{ marginTop: -20 }}
				items={[
					{
						key: PREFERENCE_TYPE.LIFESTYLE,
						label: t('Životný štýl'),
						filterBy: PREFERENCE_TYPE.LIFESTYLE
					},
					{
						key: PREFERENCE_TYPE.LEVEL,
						label: t('Level'),
						filterBy: PREFERENCE_TYPE.LEVEL
					},
					{
						key: PREFERENCE_TYPE.CATEGORY,
						label: t('Typ cvičenia'),
						filterBy: PREFERENCE_TYPE.CATEGORY
					},
					{
						key: PREFERENCE_TYPE.GOAL,
						label: t('Cieľ'),
						filterBy: PREFERENCE_TYPE.GOAL
					}
				]}
			/>
			<Table
				className={'general-table preference-list-table'}
				columns={columns as unknown as ColumnsType<any>}
				dataSource={dataSource}
				onChange={handleTableChange}
				showSorterTooltip={false}
				style={{ marginTop: 0 }}
				scroll={{ x: 'width' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:preference|path')}/${get(record, 'key')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Preferences
