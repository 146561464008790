import 'antd/dist/antd.less'
import 'react-image-lightbox/style.css'
import './styles/grid.min.css'
import './styles/index.sass'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/es/integration/react'
import App from './App'
import configureStore from './redux/configureStore'
import packageInfo from '../package.json'

const { store, persistor } = configureStore()

Sentry.init({
	dsn: window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_DSN,
	release: `${window.__RUNTIME_CONFIG__.REACT_APP_NAME}@v${packageInfo.version}`,
	environment: window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_ENV,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.2,
	ignoreErrors: ['ResizeObserver loop limit exceeded', 'Request failed with status code 401', 'Operation canceled due to new request.']
})

const container = document.getElementById('root')
const root = createRoot(container as any)

root.render(
	<PersistGate loading={'Loading'} persistor={persistor}>
		<App store={store} />
	</PersistGate>
)
