import PropTypes from 'prop-types'
import { map } from 'lodash'
import cx from 'classnames'
import { Droppable } from 'react-beautiful-dnd'
import GridItem from './GridItem'
import { ITEM_TYPES } from '../enums/enums'
import CustomGridItem from './CustomGridItem'
import Item from './Item'

const Grid = ({ items, allItems, onDeleteItem, onAddItem, itemsSetter, itemSetter, itemContentSetter }) => (
	<div className={'grid'}>
		<Droppable droppableId={'droppable'} type={'droppableItem'}>
			{(provided, snapshot) => (
				<div ref={provided.innerRef} className={cx('row', 'item-list', { 'dragging-over': snapshot.isDraggingOver })}>
					{map(items, (item, index) => {
						switch (item.type) {
							case ITEM_TYPES.GRID:
								return (
									<GridItem
										key={item.id}
										item={item}
										items={allItems}
										index={index}
										onDeleteItem={onDeleteItem}
										onAddItem={onAddItem}
										itemSetter={itemSetter}
										itemContentSetter={itemContentSetter}
									/>
								)
							case ITEM_TYPES.FAQ:
								return (
									<CustomGridItem
										key={item.id}
										item={item}
										items={allItems}
										index={index}
										onDeleteItem={onDeleteItem}
										onAddItem={onAddItem}
										itemsSetter={itemsSetter}
										itemContentSetter={itemContentSetter}
									/>
								)
							default:
								return <Item key={item.id} item={item} index={index} onDeleteItem={onDeleteItem} itemContentSetter={itemContentSetter} />
						}
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</div>
)

Grid.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	allItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onDeleteItem: PropTypes.func.isRequired,
	onAddItem: PropTypes.func.isRequired,
	itemsSetter: PropTypes.func.isRequired,
	itemSetter: PropTypes.func.isRequired,
	itemContentSetter: PropTypes.func.isRequired
}

export default Grid
