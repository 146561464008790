export enum LOAD_LIST {
	START = 'LOAD_LIST_START',
	FAILED = 'LOAD_LIST_FAILED',
	DONE = 'LOAD_LIST_DONE'
}

export enum LOAD_LIST_ITEM {
	START = 'LOAD_LIST_ITEM_START',
	FAILED = 'LOAD_LIST_ITEM_FAILED',
	DONE = 'LOAD_LIST_ITEM_DONE'
}
