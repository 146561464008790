import dayjs, { Dayjs } from 'dayjs'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import remove from 'lodash/remove'
import styled from 'styled-components'
import toLower from 'lodash/toLower'
import { Badge, Button, Checkbox, Col, DatePicker, Empty, Form, Input, Modal, Popconfirm, Row, Select, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Link, useHistory } from 'react-router-dom'
import { filter as lodashFilter } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	CloseCircleOutlined,
	CloudDownloadOutlined,
	DollarCircleOutlined,
	DownloadOutlined,
	ExclamationCircleFilled,
	LinkOutlined,
	MobileOutlined,
	QuestionCircleOutlined,
	RollbackOutlined
} from '@ant-design/icons'

// redux
import { RootState } from '../../redux'
import { setFilters } from '../../redux/filters/actions'
import { executePayment, exportPayments, getPaymentInvoice, getPayments, refundPayment, refundPayments } from '../../redux/payments/actions'

// utils
import {
	allowNumber,
	convertCentsToEuros,
	formatPaymentMethod,
	formatPaymentState,
	formatPaymentType,
	formatSubscriptionType,
	getPaymentStateColor,
	setFiltersForPage
} from '../../utils/helpers'
import {
	EMPTY_VALUE,
	INPUT_MAX_LENGTH,
	PAYMENT_METHOD,
	PAYMENT_METHODS,
	PAYMENT_STATE,
	PAYMENT_TYPE,
	SUBSCRIPTION_TYPE,
	SUBSCRIPTION_TYPES
} from '../../utils/enums'

// types
import { PaymentsListItemPayload } from '../../redux/payments/types'

// components
import PaymentsRefundationModal from './components/PaymentsRefundationModal'

// atoms
import DateRepresent from '../../atoms/DateRepresent'
import SubscriptionRepresent from '../../atoms/SubscriptionRepresent'

const { RangePicker } = DatePicker

const ExportCheckboxesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	.ant-form-item {
		margin: 0;
	}
`

const PAGE_SIZE = 20

interface IInitialFilterModal {
	visible: boolean
	dateFrom: Dayjs | null
	dateTo: Dayjs | null
	paymentMethods: any
}

const initialFilterModal = {
	visible: false,
	dateFrom: null,
	dateTo: null,
	paymentMethods: lodashFilter(
		PAYMENT_METHODS,
		(item) =>
			item !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE &&
			item !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE && {
				value: item,
				label: formatPaymentMethod(item)
			}
	)
}

const Payments = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const payments = useSelector((state: RootState) => state.payments?.payments?.payments)
	const isLoadingPayments = useSelector((state: RootState) => state.payments?.payments?.isLoading)
	const context = useSelector((state: RootState) => state.payments?.payments?.context)

	// states
	const filterForPage: any = setFiltersForPage(t('paths:payments|key'), persistFilter)
	const [searchValue, setSearchValue] = useState('')
	const [cardNumberValue, setCardNumberValue] = useState('')
	const [filter, setFilter] = useState<any>({
		...setFiltersForPage(t('paths:payments|key'), persistFilter),
		searchCardNumber: '',
		paymentStates: filterForPage?.paymentStates || [PAYMENT_STATE.FINISHED, PAYMENT_STATE.FAILED]
	})
	const [filterModal, setFilterModal] = useState<IInitialFilterModal>(initialFilterModal)
	const [showRefundationModal, setShowRefundationModal] = useState(false)

	const loadPaymentsRequestBody = {
		limit: PAGE_SIZE,
		page: 1,
		...filter,
		dateFrom: filter.dateFrom ? filter.dateFrom.format('x') : undefined,
		dateTo: filter.dateTo ? filter.dateTo.format('x') : undefined
	}

	const handleRefund = (id: number) => {
		dispatch(
			refundPayment(id, () => {
				dispatch(getPayments(loadPaymentsRequestBody))
				dispatch(setFilters(t('paths:users|key'), loadPaymentsRequestBody))
			})
		)
	}

	const columns = [
		{
			title: t('Číslo faktúry'),
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			ellipsis: true,
			sorter: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Používateľ (email, tel.číslo)'),
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			render: (email: string, record: PaymentsListItemPayload) => (
				<>
					<span>{email || ''}</span>
					{record?.phoneNumber && (
						<>
							<br />
							<span>{`+${record.phoneNumber}`}</span>
						</>
					)}
				</>
			)
		},
		{
			title: t('Suma'),
			dataIndex: 'price',
			key: 'price',
			sorter: true,
			ellipsis: true,
			width: 110,
			render: (price: number, currency: any) =>
				price ? `${convertCentsToEuros(price)} ${get(currency, 'currency') ? get(currency, 'currency') : ''}` : EMPTY_VALUE
		},
		{
			title: t('Členstvo'),
			dataIndex: 'subscriptionType',
			key: 'subscriptionType',
			sorter: true,
			render: (type: SUBSCRIPTION_TYPE, record: PaymentsListItemPayload) => (
				<>
					<SubscriptionRepresent value={type} text={formatSubscriptionType(type, record?.paymentType)} />
					<br />
					<span style={{ fontSize: 10 }}>{`${record.note ? `${record.note}` : ''}`}</span>
				</>
			)
		},
		{
			title: t('Platobná metóda'),
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			sorter: true,
			render: (method: PAYMENT_METHOD) => (method ? formatPaymentMethod(method) : EMPTY_VALUE)
		},
		{
			title: t('Stav'),
			dataIndex: 'paymentState',
			key: 'paymentState',
			sorter: true,
			render: (state: PAYMENT_STATE, record: PaymentsListItemPayload) => (
				<Badge
					count={`${formatPaymentState(state, record?.isRefunded, record?.paymentType)}`}
					style={{ backgroundColor: getPaymentStateColor(state, record.isRefunded, record?.paymentType) }}
				/>
			)
		},
		{
			title: t('Typ platby'),
			dataIndex: 'paymentType',
			key: 'paymentType',
			sorter: true,
			render: (type: PAYMENT_TYPE, record: PaymentsListItemPayload) => `${type ? formatPaymentType(type, get(record, 'subscriptionType')) : EMPTY_VALUE} `
		},
		{
			title: '',
			dataIndex: 'isRefundable',
			key: 'isRefundable',
			width: 20,
			render: (_: any, record: PaymentsListItemPayload) => (
				<span>
					{(() => {
						if (
							get(record, 'paymentMethod') === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE ||
							get(record, 'paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE
						) {
							const text =
								get(record, 'paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE
									? `${t(
											'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Apple konzolu podľa identifikátoru platby '
										)}${get(record, 'paymentID') || EMPTY_VALUE}`
									: `${t(
											'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Google konzolu podľa identifikátoru platby '
										)}${get(record, 'paymentID') || EMPTY_VALUE}`
							return (
								<Tooltip placement={'bottom'} title={text}>
									<span style={{ color: '#dacb00' }}>
										<MobileOutlined />
									</span>
								</Tooltip>
							)
						}

						if (
							!record.isRefundable &&
							get(record, 'paymentState') !== PAYMENT_STATE.FAILED &&
							get(record, 'paymentType') !== PAYMENT_TYPE.REFUND &&
							!get(record, 'isRefunded')
						) {
							return (
								<Tooltip placement={'bottom'} title={t('Nie je možné automaticky refundovať túto platbu')}>
									<span style={{ color: '#dacb00' }}>
										<ExclamationCircleFilled />
									</span>
								</Tooltip>
							)
						}
						return null
					})()}
				</span>
			)
		},
		{
			title: t('Dátum vytvorenia'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center',
			sorter: true,
			width: 130,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: t('Dátum spracovania'),
			dataIndex: 'processedAt',
			key: 'processedAt',
			align: 'center',
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 120,
			render: (_: any, record: PaymentsListItemPayload) => (
				<div style={{ marginLeft: '-20px', marginRight: '-1px', float: 'right' }}>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:payment|path')}/${get(record, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					{record.isExecutable && (
						<Popconfirm
							title={t('Naozaj chcete znova vyvolať platbu?')}
							icon={<QuestionCircleOutlined />}
							cancelText={t('Zrušiť')}
							okText={t('Vyvolať')}
							onConfirm={(e: any) => {
								e.stopPropagation()
								dispatch(
									executePayment(record.id, () => {
										dispatch(getPayments(loadPaymentsRequestBody))
										dispatch(setFilters(t('paths:users|key'), loadPaymentsRequestBody))
									})
								)
							}}
							onCancel={(e: any) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'primary'
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Tooltip placement={'bottom'} title={t('Vyvolať')}>
								<Button
									icon={<DollarCircleOutlined />}
									type={'primary'}
									style={{ marginRight: 4 }}
									disabled={!record.isExecutable}
									onClick={(e) => e.stopPropagation()}
								/>
							</Tooltip>
						</Popconfirm>
					)}
					{get(record, 'paymentState') !== PAYMENT_STATE.FAILED &&
						get(record, 'paymentType') !== PAYMENT_TYPE.REFUND &&
						!get(record, 'isRefunded') &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE && (
							<Popconfirm
								title={t('Skutočne si prajete refundovať platbu?')}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								cancelText={t('Zrušiť')}
								okText={t('Refundovať')}
								onConfirm={(e: any) => {
									e.stopPropagation()
									handleRefund(record.id)
								}}
								onCancel={(e: any) => e.stopPropagation()}
								okButtonProps={{
									size: 'small',
									type: 'primary',
									danger: true
								}}
								cancelButtonProps={{
									size: 'small',
									type: 'ghost'
								}}
							>
								<Tooltip
									title={
										get(record, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_BANK ||
										get(record, 'paymentMethod') === PAYMENT_METHOD.BANK_TRANSACTION ||
										get(record, 'paymentMethod') === PAYMENT_METHOD.SMS
											? t('Iba vytvorte faktúry, refundácia nebude automaticky uplatnená')
											: t('Refundovať')
									}
									placement={'bottomRight'}
								>
									<Button
										icon={<RollbackOutlined />}
										type={'primary'}
										danger
										style={{ marginRight: 4 }}
										onClick={(e) => e.stopPropagation()}
									/>
								</Tooltip>
							</Popconfirm>
						)}

					{get(record, 'paymentMethod') !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE &&
						get(record, 'paymentMethod') !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE && (
							<Tooltip title={t('Stiahnuť')} placement={'bottom'}>
								<Button
									icon={<CloudDownloadOutlined />}
									type={'primary'}
									onClick={(e) => {
										e.stopPropagation()
										dispatch(
											getPaymentInvoice(record.id, (url) => {
												window.open(url, '_blank')
											})
										)
									}}
								/>
							</Tooltip>
						)}
				</div>
			)
		}
	]

	const subscriptions = map(SUBSCRIPTION_TYPES, (item) => ({
		value: item,
		label: formatSubscriptionType(item)
	}))

	const paymentMethods = map(PAYMENT_METHODS, (item) => ({
		value: item,
		label: formatPaymentMethod(item)
	}))

	useEffect(() => {
		dispatch(getPayments(loadPaymentsRequestBody))

		if (filter?.dateFrom) {
			loadPaymentsRequestBody.dateFrom = dayjs(filter.dateFrom)
		}

		if (filter?.dateTo) {
			loadPaymentsRequestBody.dateTo = dayjs(filter.dateTo)
		}

		dispatch(setFilters(t('paths:payments|key'), loadPaymentsRequestBody))
	}, [filter, dispatch])

	const handleTableChange = (pagination: any, _: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debouncedSearch = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		setSearchValue(e.target.value)
		debouncedSearch(e.target.value)
	}

	const handleOnChangeCardNumber = (e: any) => {
		const cardNumber = e.target.value
		setCardNumberValue(cardNumber)
		if (cardNumber?.length === 4) {
			setFilter({ ...filter, searchCardNumber: cardNumber, page: 1 })
		}
		if (cardNumber?.length === 0) {
			setFilter({ ...filter, searchCardNumber: '', page: 1 })
		}
	}

	const resetFilter = () => {
		setSearchValue('')
		setCardNumberValue('')
		setFilter({ paymentStates: [], searchCardNumber: '' })
	}

	const handleExport = () => {
		dispatch(
			exportPayments(
				{
					dateFrom: filterModal.dateFrom ? filterModal.dateFrom.startOf('day').format('x') : undefined,
					dateTo: filterModal.dateTo ? filterModal.dateTo.endOf('day').format('x') : undefined,
					paymentMethods: filterModal.paymentMethods
				},
				(csvData) => {
					const csvContent = `data:text/csv;charset=utf-8,${csvData}`

					const encodedUri = encodeURI(csvContent)
					const link = document.createElement('a')
					link.setAttribute('href', encodedUri)

					let fileName = 'export'

					if (filterModal.dateFrom) {
						fileName += `_${filterModal.dateFrom.format('D.M.YYYY')}`
					}

					if (filterModal.dateTo) {
						fileName += `_${filterModal.dateTo.format('D.M.YYYY')}`
					}

					fileName += '.csv'

					link.setAttribute('download', fileName)
					document.body.appendChild(link)

					link.click()

					setFilterModal(initialFilterModal)
				}
			)
		)
	}

	const handleChangePaymentState = (value: any, state: PAYMENT_STATE) => {
		const oldArray = [...get(filter, 'paymentStates')]
		const paymentStates = !!value && get(value, 'target.checked') ? [...oldArray, state] : [...remove(oldArray, (item) => item !== state)]
		setFilter({ ...filter, page: 1, paymentStates })
	}

	const onFilterChange = (dates: any[]) => {
		setFilterModal({
			...filterModal,
			dateFrom: dates[0],
			dateTo: dates[1]
		})
	}

	const handleChangeExportState = (e: any, methodValue: PAYMENT_METHOD) => {
		const oldArray = [...get(filterModal, 'paymentMethods')]
		const paymentStates = !!e && get(e, 'target.checked') ? [...oldArray, methodValue] : [...remove(oldArray, (item) => item !== methodValue)]
		setFilterModal({ ...filterModal, paymentMethods: paymentStates })
	}

	const onCloseFilterModal = () => {
		setFilterModal(initialFilterModal)
	}

	const handleBulkRefundation = ({ invoiceNumbers }: any) => {
		const uniqueInvoiceNumbers = new Set(
			invoiceNumbers
				.split('\n')
				.join()
				.split(',')
				.map((invoiceNumber: string) => invoiceNumber.trim())
				.filter(Boolean)
		)

		dispatch(refundPayments({ invoiceNumber: [...uniqueInvoiceNumbers] } as any, () => setShowRefundationModal(false)))
	}

	return (
		<div className={'page-wrapper'}>
			<Row gutter={[8, 8]} justify={'space-between'} style={{ marginBottom: 24 }}>
				<Col xs={24} md={14}>
					<Input.Search
						onChange={handleOnChange}
						defaultValue={filter?.search}
						value={searchValue}
						allowClear
						maxLength={INPUT_MAX_LENGTH}
						placeholder={t('Zadaj email, tel číslo alebo číslo faktúry')}
					/>
				</Col>
				<Col xs={24} xl={10}>
					<Row gutter={[8, 8]}>
						<Col>
							<Button icon={<DownloadOutlined />} onClick={() => setFilterModal({ ...filterModal, visible: true })} type={'primary'}>
								{t('Exportovať platby')}
							</Button>
						</Col>
						<Col>
							<Button icon={<RollbackOutlined />} onClick={() => setShowRefundationModal(true)} type={'primary'} danger>
								{t('Refundovať hromadne')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={[8, 8]} className={'paymentFilter'} style={{ flexWrap: 'wrap', marginBottom: '16px' }}>
				<Col>
					<Input.Search
						onChange={handleOnChangeCardNumber}
						defaultValue={filter?.searchCardNumber}
						value={cardNumberValue}
						style={{ width: 250 }}
						maxLength={4}
						onKeyPress={(e) => allowNumber(e)}
						allowClear
						placeholder={t('Posledne štvorčislie karty')}
					/>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0 }}>
						<Select
							style={{ width: 250 }}
							onChange={(value) => setFilter({ ...filter, subscriptionType: value, page: 1 })}
							placeholder={t('Členstvo')}
							value={filter.subscriptionType}
							options={subscriptions}
							filterOption={(input, option: any) => toLower(option.children).indexOf(toLower(input)) >= 0}
						/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0 }}>
						<Select
							style={{ width: 250 }}
							onChange={(value) => setFilter({ ...filter, paymentMethod: value, page: 1 })}
							placeholder={t('Platobná metóda')}
							value={filter.paymentMethod}
							options={paymentMethods}
							filterOption={(input, option: any) => toLower(option.children).indexOf(toLower(input)) >= 0}
						/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0 }}>
						<DatePicker
							style={{ width: 250 }}
							onChange={(value) => setFilter({ ...filter, dateFrom: value && value.startOf('day'), page: 1 })}
							value={filter.dateFrom}
							format={'D.M.YYYY'}
							placeholder={t('Dátum od')}
							showToday
						/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0 }}>
						<DatePicker
							style={{ width: 250 }}
							onChange={(value) => setFilter({ ...filter, dateTo: value && value.endOf('day'), page: 1 })}
							value={filter.dateTo}
							format={'D.M.YYYY'}
							placeholder={t('Dátum do')}
							showToday
						/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0 }}>
						<Button icon={<CloseCircleOutlined />} onClick={resetFilter}>
							{t('Zrušiť filter')}
						</Button>
					</Form.Item>
				</Col>
			</Row>
			<div className={'flex paymentFilter checkboxes'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<span style={{ padding: '6px 16px 6px 16px', fontWeight: '500' }}>{t('Stav platby')}</span>
				</Form.Item>
				<Form.Item>
					<Checkbox
						onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.FINISHED)}
						checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.FINISHED)}
					>
						{formatPaymentState(PAYMENT_STATE.FINISHED)}
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Checkbox
						onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.FAILED)}
						checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.FAILED)}
					>
						{formatPaymentState(PAYMENT_STATE.FAILED)}
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Checkbox
						onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.INITIALIZED)}
						checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.INITIALIZED)}
					>
						{formatPaymentState(PAYMENT_STATE.INITIALIZED)}
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Checkbox
						onChange={(value) => handleChangePaymentState(value, PAYMENT_STATE.PREAUTHORIZED)}
						checked={includes(get(filter, 'paymentStates'), PAYMENT_STATE.PREAUTHORIZED)}
					>
						{formatPaymentState(PAYMENT_STATE.PREAUTHORIZED)}
					</Checkbox>
				</Form.Item>
			</div>
			<Table
				className={'general-table'}
				columns={columns as unknown as ColumnsType<any>}
				dataSource={payments}
				onChange={handleTableChange}
				showSorterTooltip={false}
				rowKey={'id'}
				style={{ marginTop: -20 }}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingPayments}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:payment|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
			<Modal
				title={t('Export zoznamu platieb')}
				visible={filterModal.visible}
				onCancel={onCloseFilterModal}
				destroyOnClose
				footer={[
					<Button key={'back'} onClick={onCloseFilterModal}>
						{t('Zrušiť')}
					</Button>,
					<Button key={'submit'} type={'primary'} onClick={handleExport}>
						{t('Exportovať')}
					</Button>
				]}
			>
				<RangePicker allowEmpty={[true, true]} format={'D.M.YYYY'} onCalendarChange={onFilterChange as any} style={{ marginBottom: '8px' }} />
				<ExportCheckboxesWrapper>
					<span style={{ fontWeight: '500' }}>{t('Platobné metódy')}</span>
					{map(paymentMethods, (method, index) => (
						<Form.Item key={index}>
							<Checkbox onChange={(e) => handleChangeExportState(e, method.value)} checked={includes(filterModal.paymentMethods, method.value)}>
								{method.label}
							</Checkbox>
						</Form.Item>
					))}
				</ExportCheckboxesWrapper>
			</Modal>

			{showRefundationModal && <PaymentsRefundationModal submitPaymentsRefundation={handleBulkRefundation} setShowModal={setShowRefundationModal} />}
		</div>
	)
}

export default Payments
