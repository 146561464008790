import { useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { Tabs } from 'antd'
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS, UPLOAD_CATEGORY, LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validatePartnerForm, { PartnerFormValues } from './validatePartnerForm'

// atoms
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type PartnerFormProps = {
	onSubmit: (values: PartnerFormValues) => void
}

const PartnerForm = ({ handleSubmit, onSubmit }: PartnerFormProps & InjectedFormProps<PartnerFormValues, PartnerFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.LANDING_PARTNER_FORM)(state)) as PartnerFormValues

	// states
	const [logo, setLogo] = useState({
		isLoading: false,
		imageUrl: ''
	})

	const uploadLogo = async ({ file }: any) => {
		setLogo({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.LANDING_PARTNER_FORM, 'logo', fileUrl))
			setLogo({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				name={'logo'}
				imageUrl={get(fieldValues, 'logo')}
				component={UploadInputField}
				label={t('Logo (upload)')}
				customHelper={t('recommendedSize|homepagePartner')}
				customRequest={uploadLogo}
				isLoading={logo?.isLoading}
				required
			/>
			<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
				{map(LANGUAGES, (item) => (
					<TabPane tab={getLanguageName(item)} key={item}>
						<Field
							name={`name_${item}`}
							label={t('Názov')}
							type={'text'}
							placeholder={t('Názov')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
					</TabPane>
				))}
			</Tabs>
		</form>
	)
}

export default reduxForm<PartnerFormValues, PartnerFormProps>({
	form: FORMS.LANDING_PARTNER_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePartnerForm
})(PartnerForm)
