import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq } from '../../utils/request'

// types
import {
	GET_USER_PAYMENTS,
	GET_PAYMENT_INVOICE,
	GET_PAYMENTS,
	GET_PAYMENT,
	REFUND_PAYMENT,
	REFUND_PAYMENTS,
	EXPORT_PAYMENTS,
	EXECUTE_PAYMENT,
	GET_SUBSCRIPTION_PAYMENTS,
	DEACTIVATE_SMS_CODE,
	PaymentsPayload,
	PaymentPayload
} from './types'
import { Paths } from '../../types/api'

export const getUserPayments =
	(userID: number, params: Paths.GetAdminPayments.QueryParameters, onSuccess?: (args: PaymentsPayload) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_USER_PAYMENTS.START })
		try {
			const queries = {
				limit: 20,
				page: 1,
				userID,
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)

			const { data } = await getReq(ENDPOINTS.GET_PAYMENTS, normalizeQueryParams)
			dispatch({ type: GET_USER_PAYMENTS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_USER_PAYMENTS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getSubscriptionPayments =
	(
		subscriptionID: number,
		params: Paths.GetAdminPayments.QueryParameters,
		onSuccess?: (args: PaymentsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_SUBSCRIPTION_PAYMENTS.START })

		try {
			const queries = {
				limit: 20,
				page: 1,
				subscriptionID,
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)

			const { data } = await getReq(ENDPOINTS.GET_PAYMENTS, normalizeQueryParams)
			dispatch({ type: GET_SUBSCRIPTION_PAYMENTS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_SUBSCRIPTION_PAYMENTS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getPayments =
	(params: Paths.GetAdminPayments.QueryParameters, onSuccess?: (args: PaymentsPayload) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PAYMENTS.START })
		try {
			const queries = {
				limit: 3,
				page: 1,
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_PAYMENTS, normalizeQueryParams)
			dispatch({ type: GET_PAYMENTS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PAYMENTS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const exportPayments =
	(params: Paths.GetAdminPaymentsExport.QueryParameters, onSuccess?: (args: any) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: EXPORT_PAYMENTS.START })
		try {
			const { data } = await getReq(ENDPOINTS.EXPORT_PAYMENTS, params)
			dispatch({ type: EXPORT_PAYMENTS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: EXPORT_PAYMENTS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getPaymentInvoice = (userID: number, onSuccess?: (args: string) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_PAYMENT_INVOICE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_PAYMENT_INVOICE(userID))
		dispatch({ type: GET_PAYMENT_INVOICE.DONE, payload: data })
		return onSuccess && onSuccess(data?.url)
	} catch (error) {
		dispatch({ type: GET_PAYMENT_INVOICE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getPaymentDetail = (id: number, onSuccess?: (args: PaymentPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_PAYMENT.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_PAYMENT(id))
		dispatch({ type: GET_PAYMENT.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_PAYMENT.FAILED })
		return onFailure && onFailure(error)
	}
}

export const refundPayment = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: REFUND_PAYMENT.START })
	try {
		await postReq(ENDPOINTS.REFUND_PAYMENT(id))
		dispatch({ type: REFUND_PAYMENT.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: REFUND_PAYMENT.FAILED })
		return onFailure && onFailure(error)
	}
}

export const refundPayments =
	(body: Paths.PostAdminPaymentsRefund.RequestBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: REFUND_PAYMENTS.START })
		try {
			await postReq(ENDPOINTS.REFUND_PAYMENTS, undefined, body)
			dispatch({ type: REFUND_PAYMENTS.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: REFUND_PAYMENTS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const executePayment = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: EXECUTE_PAYMENT.START })
	try {
		await postReq(ENDPOINTS.EXECUTE_PAYMENT(id))
		dispatch({ type: EXECUTE_PAYMENT.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: EXECUTE_PAYMENT.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deactivateSMSCode =
	(code: Paths.PostAdminPaymentsCodeDeactivate.RequestBody['code'], onSuccess?: () => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: DEACTIVATE_SMS_CODE.START })
		try {
			await postReq(ENDPOINTS.DEACTIVATE_SMS_CODE, undefined, { code })
			dispatch({ type: DEACTIVATE_SMS_CODE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: DEACTIVATE_SMS_CODE.FAILED })
			return onFailure && onFailure(error)
		}
	}
