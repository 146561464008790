import PropTypes from 'prop-types'
import { Button, Empty, Input, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { each, trim } from 'lodash'
import i18next from 'i18next'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const Ingredients = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const addIngredient = () => {
		itemContentSetter(item?.id, [...(item?.content || []), { name: '', key: item?.content?.length }])
	}

	const updateIngredient = (value, row) => {
		const updatedContent = []

		each(item?.content, (contentItem) => {
			if (contentItem?.key === row.key) {
				// eslint-disable-next-line no-param-reassign
				contentItem.name = value
			}

			updatedContent.push(contentItem)
		})

		itemContentSetter(item?.id, updatedContent)
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex direction-col'}>
				<Table
					className={'general-table'}
					style={{ width: '100%' }}
					columns={[
						{
							title: t('Ingredience'),
							dataIndex: 'name',
							key: 'name',
							render: (value, row) => (
								// eslint-disable-next-line no-use-before-define
								<Input
									value={value}
									placeholder={t('Sem zadajte ingredienciu a jej množstvo')}
									maxLength={INPUT_MAX_LENGTH}
									onChange={(e) => updateIngredient(e.target.value, row)}
									onBlur={(e) => updateIngredient(trim(e.target.value), row)}
								/>
							)
						}
					]}
					dataSource={item?.content}
					locale={{
						emptyText: <Empty description={i18next.t('Žiadne dáta')} />
					}}
					size={'small'}
					pagination={false}
				/>
				<Button type={'primary'} onClick={addIngredient}>
					{t('Pridať ingredienciu')}
				</Button>
			</div>
		</div>
	)
}

Ingredients.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number,
				name: PropTypes.string
			})
		)
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Ingredients.defaultProps = {
	item: {}
}

export default Ingredients
