import React from 'react'
import PropTypes from 'prop-types'
import { Button, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import { GENERATE_STATE } from '../utils/enums'

const GeneratorOverlay = ({ discard, status }) => {
	const { t } = useTranslation()

	const loadingDescription = () => {
		switch (status) {
			case GENERATE_STATE.SENDING:
				return t('Posielam požiadavku')
			case GENERATE_STATE.CHECKING:
				return t('Generujem animáciu')
			case GENERATE_STATE.SAVING:
				return t('Ukladanie animácie')
			default:
				return t('Generujem animáciu')
		}
	}

	return (
		<div className={'generator-overview'}>
			<div className={'loading-wrapper'}>
				<div className={'generation-description'}>
					<span>{`${loadingDescription()}...`}</span>
				</div>
				<Progress
					strokeColor={{
						from: '#fea385',
						to: '#ffc5ad'
					}}
					percent={99.9}
					showInfo={false}
					status={'active'}
				/>
				<div className={'generation-description'}>{t('Generovanie GIF thumbnailu môže trvať niekolko minút')}</div>
				<Button className={'btn'} type={'button'} onClick={discard} disabled={status === GENERATE_STATE.SAVING || status === GENERATE_STATE.SENDING}>
					{t('Zrušiť generovanie')}
				</Button>
			</div>
		</div>
	)
}

GeneratorOverlay.propTypes = {
	discard: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired
}

export default GeneratorOverlay
