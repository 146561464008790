import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { slugRegex } from '../../../utils/regex'
import { LANGUAGE, slugErrorText } from '../../../utils/enums'

export type BlogFormValues = {
	id: number
	pageMetadata?: any
	title: string
	urlSlug: string
	briefDescription: string
	image: string
	language: LANGUAGE
	type: string
	categories: string
	imageAlt: string
	publishedAt: string
	imageTitle: string
}

export default (values: BlogFormValues) => {
	const errors: FormErrors<BlogFormValues> = {}

	if (!values.title) {
		errors.title = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug) {
		errors.urlSlug = i18next.t('Toto pole je povinné')
	}

	if (values.urlSlug && !slugRegex.test(values.urlSlug)) {
		errors.urlSlug = i18next.t(slugErrorText)
	}

	if (!values.briefDescription) {
		errors.briefDescription = i18next.t('Toto pole je povinné')
	}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if (!values.language) {
		errors.language = i18next.t('Toto pole je povinné')
	}

	if (!values.type) {
		errors.type = i18next.t('Toto pole je povinné')
	}

	if (!values.categories) {
		errors.categories = i18next.t('Toto pole je povinné')
	}

	if (!values.imageAlt) {
		errors.imageAlt = i18next.t('Toto pole je povinné')
	}

	if (!values.publishedAt) {
		errors.publishedAt = i18next.t('Toto pole je povinné')
	}

	if (!values.imageTitle) {
		errors.imageTitle = i18next.t('Toto pole je povinné')
	}

	return errors
}
