import styled from 'styled-components'
import { Table } from 'antd'

export const StyledTable = styled(Table)`
	&:first-child {
		border: 0;
	}

	.ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2) > th:last-of-type {
		border-right: 1px solid #f0f0f0;
	}

	.ant-table-tbody > tr > td {
		padding: 8px 8px !important;
	}

	.ant-table-measure-row {
		display: none;
	}

	.ant-table-expanded-row {
		.ant-table-content {
			tr {
				&:hover {
					td {
						background: lightgrey;
					}
				}
			}
			td {
				background: whitesmoke;
				cursor: default;
			}
		}
	}
`

export const PaymentsTableCell = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 12px;

	> div {
		height: 40px;

		&:first-child {
			margin-bottom: 4px;
		}
	}
	.column {
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
	}

	.flag-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.percentage {
		text-align: center;
	}
`
