// enums
import { ENDPOINTS } from '../../utils/enums'
import { getReq, putReq } from '../../utils/request'

// types
import { Paths } from '../../types/api'
import {
	GET_LIVESTREAM_CATEGORIES,
	UPDATE_LIVESTREAM_CATEGORIES,
	SET_LIVESTREAM_CATEGORY,
	ADD_LIVESTREAM_CATEGORY,
	REMOVE_LIVESTREAM_CATEGORY,
	CHANGE_LIVESTREAM_CATEGORIES_ORDER
} from './types'

export const getLiveStreamCategories = (onSuccess?: (args: any) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_LIVESTREAM_CATEGORIES.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_LIVESTREAM_CATEGORIES)
		dispatch({ type: GET_LIVESTREAM_CATEGORIES.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_LIVESTREAM_CATEGORIES.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateLiveStreamCategories =
	(body: Paths.PutAdminLiveStreamCategories.RequestBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_LIVESTREAM_CATEGORIES.START })
		try {
			await putReq(ENDPOINTS.UPDATE_LIVESTREAM_CATEGORIES, undefined, body)
			dispatch({ type: UPDATE_LIVESTREAM_CATEGORIES.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_LIVESTREAM_CATEGORIES.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const setLiveStreamCategory = (value: any) => async (dispatch: any) => dispatch({ type: SET_LIVESTREAM_CATEGORY, payload: value })
export const addLiveStreamCategory = () => async (dispatch: any) => dispatch({ type: ADD_LIVESTREAM_CATEGORY })
export const removeLiveStreamCategory = (id: number) => async (dispatch: any) => dispatch({ type: REMOVE_LIVESTREAM_CATEGORY, payload: id })
export const changeLiveStreamCategoriesOrder = (array: any) => async (dispatch: any) => dispatch({ type: CHANGE_LIVESTREAM_CATEGORIES_ORDER, payload: array })
