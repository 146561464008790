import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import get from 'lodash/get'
import { initialize } from 'redux-form'
import { notification, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { createAdministrator, getAdministrator, updateAdministrator } from '../../redux/administrators/actions'
import { RootState } from '../../redux'

// utils
import { EMPTY_VALUE, FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// types
import { Paths } from '../../types/api'
import { IComputedMatch } from '../../types/interfaces'

// forms
import { AdministratorFormValues } from './forms/validateAdministratorForm'
import AdministratorForm from './forms/AdministratorForm'

type AdministratorDetailProps = {
	computedMatch: IComputedMatch
}

const AdministratorDetail = ({ computedMatch }: AdministratorDetailProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.administrators?.detail)
	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {}
		dispatch(initialize(FORMS.ADMINISTRATOR_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: any, originImage?: string | null) => {
			const langValues: any = {}

			const initValues = {
				id: data?.id,
				firstName: data?.firstName,
				lastName: data?.lastName,
				email: data?.email,
				role: data?.role,
				urlSlug: data?.urlSlug,
				isConfirmed: data?.isConfirmed ? t('Áno') : t('Nie'),
				lastLoginAt: data.lastLoginAt ? dayjs(data.lastLoginAt).format('H:mm:ss D. MMM YYYY ') : EMPTY_VALUE,
				image: originImage || data?.imagePaths?.medium
			}

			each(LANGUAGES, (item) => {
				const lang = find(data?.translations, { language: item })
				langValues[`briefDescription_${item}`] = lang?.briefDescription
				langValues[`detailedDescription_${item}`] = lang?.detailedDescription
			})

			dispatch(initialize(FORMS.ADMINISTRATOR_FORM, { ...initValues, ...langValues }))
		},
		[dispatch, t]
	)

	useEffect(() => {
		if (id) {
			dispatch(
				getAdministrator(id, (data) => {
					initDetailForm(data)
				})
			)
		} else {
			initEmptyDetailForm()
		}
	}, [dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleUpdate = (body: Paths.PutAdminAdministratorsId.RequestBody) => {
		dispatch(
			updateAdministrator(id, body, () => {
				dispatch(
					getAdministrator(id, (data) => {
						initDetailForm(data, body?.image)
					})
				)
			})
		)
	}

	const handleCreate = (body: Paths.PutAdminAdministratorsId.RequestBody) => {
		dispatch(createAdministrator(body, () => history.push(t('paths:administrators|path'))))
	}

	const handleSubmit = (values: AdministratorFormValues) => {
		const body: Paths.PostAdminAdministrators.RequestBody | Paths.PutAdminAdministratorsId.RequestBody = {
			firstName: values?.firstName,
			lastName: values?.lastName,
			email: values?.email,
			role: values?.role,
			image: values?.image,
			urlSlug: values?.urlSlug,
			translations: []
		}

		let err = false

		each(LANGUAGES, (item: LANGUAGE) => {
			const briefDescription = get(values, `briefDescription_${item}`)
			const detailedDescription = get(values, `detailedDescription_${item}`)
			if (briefDescription && detailedDescription && body?.translations) {
				body.translations.push({
					briefDescription,
					detailedDescription,
					language: item
				})
			} else if ((briefDescription && !detailedDescription) || (!briefDescription && detailedDescription)) {
				err = true
				notification.error({
					message: t('Ak chcete vyplniť popis je potrebné vyplniť všetky preklady pre daný jazyk')
				})
			}
		})

		if (id) {
			if (!err) {
				handleUpdate(body)
			}
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<AdministratorForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default AdministratorDetail
