import { CloseCircleOutlined, DeleteOutlined, LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Empty, Form, Input, Popconfirm, Select, Table } from 'antd'
import i18next from 'i18next'
import { debounce, get, map } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import DateRepresent from '../../atoms/DateRepresent'
import SubscriptionRepresent from '../../atoms/SubscriptionRepresent'
import { setFilters } from '../../redux/filters/actions'
import { deleteUser, getUsers } from '../../redux/users/actions'
import { EMPTY_VALUE, INPUT_MAX_LENGTH } from '../../utils/enums'
import { formatSubscriptionType, setFiltersForPage } from '../../utils/helpers'

const PAGE_SIZE = 20

const { Option } = Select

const Users = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const persistFilter = useSelector((state) => get(state, 'filters'))
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:users|key'), persistFilter))

	const USERS_SUBSCRIPTION = {
		MONTH: { value: 'MONTH', translate: t('Pravidelné mesačné členské') },
		ONETIME_MONTH: { value: 'ONETIME_MONTH', translate: t('Jednorazové mesačné členské') },
		QUARTER: { value: 'QUARTER', translate: t('Jednorazové štvrťročné členské') },
		YEAR: { value: 'YEAR', translate: t('Jednorazové ročné členské') },
		NO_SUBSCRIPTION: { value: 'NO_SUBSCRIPTION', translate: t('Žiadne členské') },
		PROMOCODE: { value: 'PROMOCODE', translate: t('Promo kód členské') },
		ANY_SUBSCRIPTION: { value: 'ANY_SUBSCRIPTION', translate: t('Všetci používatelia s členstvom') },
		CUSTOM: { value: 'CUSTOM', translate: t('Bezplatné členstvo') }
	}

	const columns = [
		{
			title: i18next.t('Email'),
			dataIndex: 'contactEmail',
			key: 'contactEmail',
			sorter: true,
			ellipsis: false,
			width: '40%',
			render: (value, record) => (
				<span>
					<strong>{'Reg.'}</strong>
					{' - '}
					{record.email || ''}
					<br />
					<strong>{'Kont.'}</strong>
					{' - '}
					{record.contactEmail || ''}
				</span>
			)
		},
		{
			title: i18next.t('Meno a priezvisko'),
			dataIndex: 'lastName',
			key: 'fullName',
			sorter: true,
			ellipsis: true,
			width: '20%',
			render: (value) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Aktuálne členstvo'),
			dataIndex: 'subscription',
			key: 'subscription',
			width: '20%',
			render: (text, record) => <SubscriptionRepresent text={formatSubscriptionType(text, get(record, 'paymentType'))} value={text} />
		},
		{
			title: i18next.t('Posledné prihlásenie'),
			dataIndex: 'lastLoginAt',
			key: 'lastLoginAt',
			sorter: true,
			width: '15%',
			render: (value) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (text, record) => (
				<>
					<span style={{ marginRight: '8px' }}>
						<Link to={`${t('paths:user|path')}/${get(record, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={i18next.t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={i18next.t('Zrušiť')}
						okText={i18next.t('Vymazať')}
						onConfirm={(e) => {
							e.stopPropagation()
							dispatch(
								deleteUser(record.id, () => {
									const body = {
										limit: PAGE_SIZE,
										page: 1,
										...filter
									}

									dispatch(getUsers(body))

									dispatch(setFilters(t('paths:users|key'), body))
								})
							)
						}}
						onCancel={(e) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'danger'
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'danger'} onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	const users = useSelector((state) => get(state, 'users.list.tableList'))
	const isLoadingUsers = useSelector((state) => get(state, 'users.list.isLoading'))
	const isLoadingDetail = useSelector((state) => get(state, 'users.detail.isLoading'))
	const context = useSelector((state) => get(state, 'users.list.context'))

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getUsers(body))

		dispatch(setFilters(t('paths:users|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination, filters, sorter) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e) => {
		debounced(e.target.value)
	}

	const resetFilter = () => {
		setFilter({})
	}

	const pregnantOptions = [
		{ value: 'true', label: t('Áno') },
		{ value: 'false', label: t('Nie') }
	]
	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} maxLength={INPUT_MAX_LENGTH} style={{ width: 300 }} onChange={handleOnChange} allowClear />
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Select
						style={{ width: 300, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, subscription: value, page: 1 })}
						placeholder={t('Členstvo')}
						value={filter.subscription}
						allowClear
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{map(USERS_SUBSCRIPTION, (subscription, index) => (
							<Option key={index} value={subscription.value}>
								{subscription.translate}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Select
						style={{ width: 150, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, isPregnant: value, page: 1 })}
						placeholder={t('Tehotenstvo')}
						value={filter.isPregnant}
						allowClear
						options={pregnantOptions}
					/>
				</Form.Item>
				<Form.Item>
					<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={users}
				onChange={handleTableChange}
				showSorterTooltip={false}
				rowKey={'id'}
				style={{ marginTop: -20 }}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingUsers || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:user|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

Users.propTypes = {}

export default Users
