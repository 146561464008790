import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { ADMIN_ROLE, slugErrorText } from '../../../utils/enums'
import { emailRegEx, slugRegex } from '../../../utils/regex'

export type AdministratorFormValues = {
	id: number
	firstName: string
	lastName: string
	email: string
	urlSlug: string
	role: ADMIN_ROLE
	image?: string
}

export default (values: AdministratorFormValues) => {
	const errors: FormErrors<AdministratorFormValues> = {}

	if (!values.firstName || (values.firstName && !(values.firstName.length >= 2 && values.firstName.length <= 20))) {
		errors.firstName = i18next.t('Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (!values.lastName || (values.lastName && !(values.lastName.length >= 2 && values.lastName.length <= 20))) {
		errors.lastName = i18next.t('Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (!values.email || (values.email && !emailRegEx.test(values.email))) {
		errors.email = i18next.t('Email nie je platný')
	}

	if (!values.urlSlug) {
		errors.urlSlug = i18next.t('Pole je povinné')
	}

	if (values.urlSlug && !slugRegex.test(values.urlSlug)) {
		errors.urlSlug = i18next.t(slugErrorText)
	}

	if (!values.role) {
		errors.role = i18next.t('Pole je povinné')
	}

	return errors
}
