import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../../utils/enums'
import PasswordChangeForm from '../forms/PasswordChangeForm'

const PasswordChangeModal = ({ setShowModal, submitPasswordChange }) => {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	return (
		<Modal
			visible
			title={t('Zmeniť používateľovi heslo')}
			okText={t('Zmeniť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(null)}
			onOk={() => {
				dispatch(submit(FORMS.PASSWORD_CHANGE_FORM))
			}}
		>
			<PasswordChangeForm onSubmit={submitPasswordChange} />
		</Modal>
	)
}

PasswordChangeModal.propTypes = {
	setShowModal: PropTypes.func.isRequired,
	submitPasswordChange: PropTypes.func.isRequired
}

export default PasswordChangeModal
