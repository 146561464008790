import { map, mapValues } from 'lodash'
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'
import { EMPTY_VALUE, ENDPOINTS } from '../../utils/enums'
import {
	LOAD_USERS,
	LOAD_DETAIL_USER,
	DELETE_USER,
	UPDATE_USER,
	LOAD_USER_PROGRAM,
	LOAD_USER_PROGRAMS,
	UPDATE_USER_PROGRAM,
	DELETE_USER_PROGRAM,
	CREATE_USER_PROGRAM,
	LOAD_USER_USER_AGENTS
} from './types'

export const getUsers = (params) => async (dispatch) => {
	try {
		dispatch({ type: LOAD_USERS.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_USERS, normalizeQueryParams)

		const tableList = map(data.users, (user) => ({
			...user,
			fullName: !user.firstName && !user.lastName ? EMPTY_VALUE : `${user.firstName || ''} ${user.lastName || ''}`.trim()
		}))
		dispatch({
			type: LOAD_USERS.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: LOAD_USERS.FAILED })
		Promise.reject(error)
	}
}

export const getUser = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: LOAD_DETAIL_USER.START
	})
	try {
		const { data } = await getReq(ENDPOINTS.GET_USER(id))
		dispatch({
			type: LOAD_DETAIL_USER.DONE,
			payload: data
		})

		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({
			type: LOAD_DETAIL_USER.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const deleteUser = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: DELETE_USER.START
	})
	try {
		await deleteReq(ENDPOINTS.DELETE_USER(id))

		dispatch({ type: DELETE_USER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_USER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateUser = (id, values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: UPDATE_USER.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		await patchReq(ENDPOINTS.UPDATE_USER(id), null, normalizeQueryParams)

		dispatch({ type: UPDATE_USER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_USER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getUserPrograms =
	(userID, onSuccess = undefined, onFailure = undefined) =>
	async (dispatch) => {
		try {
			dispatch({ type: LOAD_USER_PROGRAMS.START })
			const { data } = await getReq(ENDPOINTS.GET_USER_PROGRAMS(userID))
			dispatch({
				type: LOAD_USER_PROGRAMS.DONE,
				payload: data
			})
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: LOAD_USER_PROGRAMS.FAILED })
			return onFailure && onFailure()
		}
	}

export const getUserProgram =
	(userID, programID, onSuccess = undefined, onFailure = undefined) =>
	async (dispatch) => {
		try {
			dispatch({ type: LOAD_USER_PROGRAM.START })
			const { data } = await getReq(ENDPOINTS.GET_USER_PROGRAM(userID, programID))
			dispatch({
				type: LOAD_USER_PROGRAM.DONE,
				payload: data
			})
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: LOAD_USER_PROGRAM.FAILED })
			return onFailure && onFailure()
		}
	}

export const deleteUserProgram =
	(userID, programID, onSuccess, onFailure = undefined) =>
	async (dispatch) => {
		try {
			dispatch({ type: DELETE_USER_PROGRAM.START })
			await deleteReq(ENDPOINTS.DELETE_USER_PROGRAM(userID, programID))
			dispatch({ type: DELETE_USER_PROGRAM.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: DELETE_USER_PROGRAM.FAILED })
			return onFailure && onFailure()
		}
	}

export const updateUserProgram = (userID, programID, values, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_USER_PROGRAM.START })
		const normalizeQueryParams = mapValues(values, (query) => query)
		await patchReq(ENDPOINTS.GET_USER_PROGRAM(userID, programID), null, normalizeQueryParams)
		dispatch({ type: UPDATE_USER_PROGRAM.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_USER_PROGRAM.FAILED })
		return onFailure && onFailure()
	}
}

export const createUserProgram =
	(userID, values, onSuccess = undefined, onFailure = undefined) =>
	async (dispatch) => {
		try {
			dispatch({ type: CREATE_USER_PROGRAM.START })
			const normalizeQueryParams = mapValues(values, (query) => query)
			await postReq(ENDPOINTS.GET_USER_PROGRAMS(userID), null, normalizeQueryParams)
			dispatch({ type: CREATE_USER_PROGRAM.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CREATE_USER_PROGRAM.FAILED })
			return onFailure && onFailure()
		}
	}

export const getUserUserAgents =
	(userID, params, onSuccess = undefined, onFailure = undefined) =>
	async (dispatch) => {
		try {
			dispatch({ type: LOAD_USER_USER_AGENTS.START })

			const queries = {
				limit: 20,
				page: 1,
				userID,
				...params
			}
			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_USER_USER_AGENTS(userID), normalizeQueryParams)

			dispatch({
				type: LOAD_USER_USER_AGENTS.DONE,
				payload: data
			})
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: LOAD_USER_USER_AGENTS.FAILED })
			return onFailure && onFailure()
		}
	}
