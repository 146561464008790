import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Col, DatePicker, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { getRecipeBooksStatistics } from '../../../redux/dashboard/actions'

// utils
import { STATIC_DATES } from '../../../utils/enums'

// components
import StatisticWithDescription from './StatisticWithDescription'

const { RangePicker } = DatePicker

type DatesTypes = {
	dateFrom: Dayjs
	dateTo: Dayjs
}

const DashboardRecipeBooks = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const { data, isLoading } = useSelector((state: RootState) => state.dashboard?.recipeBooksStatistics)

	// states
	const [dates, setDates] = useState<DatesTypes>({
		dateFrom: STATIC_DATES.START_OF_MONTH,
		dateTo: STATIC_DATES.END_OF_MONTH
	})

	// constants
	const disabledDate = (current: any) => {
		const minDate = dayjs('2024-01-01') // NOTE: Disable dates before January 1st, 2024
		const maxDate = dayjs().endOf('month') // NOTE: Disable dates after the current month
		return current && (current < minDate || current > maxDate) // NOTE: Disable if the date is before minDate or after maxDate
	}

	useEffect(() => {
		dispatch(
			getRecipeBooksStatistics({
				dateFrom: dates.dateFrom.format('YYYY-MM-DD'),
				dateTo: dates.dateTo.format('YYYY-MM-DD')
			})
		)
	}, [dispatch, dates])

	const handleRangeChange = (rangeValues: any) => {
		if (rangeValues.length === 2) {
			setDates({ dateFrom: rangeValues[0], dateTo: rangeValues[1] })
		}
	}

	return (
		<div>
			<Row gutter={[16, 16]} align={'middle'} style={{ marginBottom: '16px' }}>
				<Col>
					<span>
						<strong>{t('Štatistiky za obdobie')}</strong>
					</span>
				</Col>
				<Col xs={24} sm={8}>
					<RangePicker
						defaultValue={[dates.dateFrom as any, dates.dateTo as any]}
						format={'D.M.YYYY'}
						disabledDate={disabledDate}
						disabled={isLoading}
						onChange={handleRangeChange as any}
						placeholder={[t('Od'), t('Do')]}
						allowClear={false}
						allowEmpty={[false, false]}
						style={{ width: 250 }}
					/>
				</Col>
			</Row>
			<Spin spinning={isLoading}>
				<Row gutter={[16, 16]} align={'top'}>
					<Col xs={24} md={12}>
						<div className={'dashboard-card'}>
							<h2>{'Základné jedálničky'}</h2>
							<div className={'card-content'}>
								<StatisticWithDescription
									title={t('Všetky žiadosti')}
									value={data.basic.all}
									suffix={t('žiad')}
									description={t('Celkový počet žiadostí o vygenerovanie základného jedálnička')}
								/>
								<StatisticWithDescription
									title={t('Čakajúce žiadosti')}
									value={data.basic.requested}
									suffix={t('žiad')}
									description={t('Čakajúce žiadosti o vygenerovanie základného jedálnička')}
								/>
								<StatisticWithDescription
									title={t('Dokončené žiadosti')}
									value={data.basic.created}
									suffix={t('žiad')}
									description={t('Dokončené žiadosti o vygenerovanie základného jedálnička')}
								/>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className={'dashboard-card'}>
							<h2>{'Bonusové jedálničky'}</h2>
							<div className={'card-content'}>
								<StatisticWithDescription
									title={t('Všetky žiadosti')}
									value={data.bonus.all}
									suffix={t('žiad')}
									description={t('Celkový počet žiadostí o vygenerovanie bonusového jedálnička')}
								/>
								<StatisticWithDescription
									title={t('Dokončené žiadosti')}
									value={data.bonus.created}
									suffix={t('žiad')}
									description={t('Dokončené žiadosti o vygenerovanie bonusového jedálničku')}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Spin>
		</div>
	)
}

export default DashboardRecipeBooks
