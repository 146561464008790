import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize, getFormValues, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { DonationPayload } from '../../redux/donations/types'
import { getDonation, resendDonation } from '../../redux/donations/actions'

// utils
import { CURRENCY, FORMS, PAYMENT_CURRENCY_LABEL, PAYMENT_METHOD } from '../../utils/enums'
import { formatSubscriptionType, convertCentsToEuros, formatPaymentMethod, formatPaymentState, formatSubscriptionTier } from '../../utils/helpers'

// types
import { IComputedMatch } from '../../types/interfaces'

// forms
import DonationForm from './forms/DonationForm'
import ResendModalForm from './forms/DonationResendEmailForm'
import { DonationFormValues } from './forms/validateDonationForm'

type DetailDonationProps = {
	computedMatch: IComputedMatch
}

const DetailDonation = ({ computedMatch }: DetailDonationProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { id } = computedMatch.params

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.DONATION_EMAIL_FORM)(state)) as DonationFormValues
	const detail = useSelector((state: RootState) => state.donations?.detail)

	// states
	const [modalState, setModalState] = useState<{ visible: boolean; id: number | null }>({ visible: false, id: null })

	// constants
	const isLoading = detail?.isLoading

	const initEmailForm = (data: DonationPayload) => {
		dispatch(change(FORMS.DONATION_EMAIL_FORM, 'activationEmail', data?.recipientEmail))
	}

	const initDetailForm = (data: any) => {
		const trustPayBank = {
			dataAccountId: get(data, 'paymentData.AccountId'),
			dataAmount: get(data, 'paymentData.Amount'),
			dataCounterAccount: get(data, 'paymentData.CounterAccount'),
			dataCounterAccountName: get(data, 'paymentData.CounterAccountName'),
			dataCurrency: PAYMENT_CURRENCY_LABEL[get(data, 'paymentData.Currency') as CURRENCY],
			dataPaymentId: get(data, 'paymentData.PaymentId'),
			dataReference: get(data, 'paymentData.Reference'),
			dataResultCode: get(data, 'paymentData.ResultCode'),
			dataAcquirerResponseId: get(data, 'paymentData.AcquirerResponseId'),
			dataSignature: get(data, 'paymentData.Signature'),
			dataType: get(data, 'paymentData.Type')
		}

		const trustPayCard = {
			cardExpiration: get(data, 'paymentData.CardExpiration'),
			cardId: get(data, 'paymentData.CardID'),
			cardMask: get(data, 'paymentData.CardMask'),
			cardRecTxSec: get(data, 'paymentData.CardRecTxSec'),
			cardAuthNumber: get(data, 'paymentData.AuthNumber'),
			cardAcqResId: get(data, 'paymentData.AcqResId')
		}

		const paypal = {
			paypalCreateTime: dayjs(get(data, 'paymentData.create_time')).format('YYYY.MM.DD HH:MM'),
			paypalEventType: get(data, 'paymentData.event_type'),
			paypalSummary: get(data, 'paymentData.summary'),
			paypalId: get(data, 'paymentData.id'),
			paypalPayerID: get(data, 'paymentData.PayerID'),
			paypalPaymentId: get(data, 'paymentData.paymentId'),
			paypalToken: get(data, 'paymentData.paymentId'),
			paypalData: get(data, 'paymentData')
		}
		let initValues = {
			...data,
			isRegistered: get(data, 'isRegistered') ? t('Áno') : t('Nie'),
			subscriptionTier: formatSubscriptionTier(data?.subscriptionTier),
			subscriptionType: formatSubscriptionType(get(data, 'subscriptionType')),
			activatedAt: get(data, 'isUsed') ? get(data, 'activatedAt') : t('Neuplatnený'),
			smsCode: get(data, 'smsCode'),
			price: `${convertCentsToEuros(get(data, 'price'))} ${PAYMENT_CURRENCY_LABEL[get(data, 'currency') as CURRENCY]}`,
			paymentFormated: {
				paymentState: formatPaymentState(get(data, 'paymentState')),
				paymentMethod: formatPaymentMethod(get(data, 'paymentMethod'))
			}
		}
		switch (get(data, 'paymentMethod')) {
			case PAYMENT_METHOD.TRUSTPAY_BANK:
				initValues = { ...initValues, ...trustPayBank }
				break
			case PAYMENT_METHOD.TRUSTPAY_CARD:
				initValues = { ...initValues, ...trustPayBank, ...trustPayCard }
				break
			case PAYMENT_METHOD.SMS:
				initValues = { ...initValues, smsNotifications: get(data, 'smsNotifications') }
				break
			case PAYMENT_METHOD.PAYPAL:
				initValues = { ...initValues, ...paypal }
				break
			case PAYMENT_METHOD.BANK_TRANSACTION:
				initValues = { ...initValues, data: get(data, 'data') }
				break
			default:
				initValues = { ...initValues, data: get(data, 'data') }
		}

		dispatch(initialize(FORMS.DONATION_FORM, initValues))
	}

	useEffect(() => {
		if (id) {
			dispatch(
				getDonation(id, (data: DonationPayload) => {
					initDetailForm(data)
					initEmailForm(data)
				})
			)
		}
	}, [dispatch, id])

	const resendEmail = () => {
		if (modalState?.id) {
			dispatch(resendDonation(modalState.id, { email: fieldValues?.activationEmail }))
		}
		setModalState({ visible: false, id: null })
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<DonationForm id={id} setModalState={setModalState} />
				<ResendModalForm modalState={modalState} setModal={setModalState} onSubmit={resendEmail} />
			</Spin>
		</div>
	)
}

export default DetailDonation
