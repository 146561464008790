import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'antd'
import WYSIWYG from './WYSIWYG'

const RichTextEditor = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const setCenter = (value) => {
		itemContentSetter(item?.id, { ...item?.content, center: value })
	}

	return (
		<div className={'custom-item wysiwyg'}>
			<WYSIWYG itemId={item?.id} itemContent={item?.content} itemEditor={item?.content?.editor} itemContentSetter={itemContentSetter} />
			<div className={'width-100 mt-3 mb-3 border-top'} />
			<div className={'flex justify-end'}>
				<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
					{t('Centrovať')}
				</Checkbox>
			</div>
		</div>
	)
}

RichTextEditor.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			editor: PropTypes.arrayOf(PropTypes.shape({})),
			html: PropTypes.string,
			center: PropTypes.bool
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

RichTextEditor.defaultProps = {
	item: {}
}

export default RichTextEditor
