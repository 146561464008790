export enum LOAD_USER_SUBSCRIPTIONS {
	START = 'LOAD_USER_SUBSCRIPTIONS_START',
	DONE = 'LOAD_USER_SUBSCRIPTIONS_DONE',
	FAILED = 'LOAD_USER_SUBSCRIPTIONS_FAILED'
}

export enum LOAD_SUBSCRIPTIONS {
	START = 'LOAD_SUBSCRIPTIONS_START',
	DONE = 'LOAD_SUBSCRIPTIONS_DONE',
	FAILED = 'LOAD_SUBSCRIPTIONS_FAILED'
}

export enum LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE {
	START = 'LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE_START',
	DONE = 'LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE_DONE',
	FAILED = 'LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE_FAILED'
}

export enum LOAD_USER_SUBSCRIPTIONS_TIER {
	START = 'LOAD_USER_SUBSCRIPTIONS_TIER_START',
	DONE = 'LOAD_USER_SUBSCRIPTIONS_TIER_DONE',
	FAILED = 'LOAD_USER_SUBSCRIPTIONS_TIER_FAILED'
}

export enum CHANGE_USER_SUBSCRIPTIONS_TIER {
	START = 'CHANGE_USER_SUBSCRIPTIONS_TIER_START',
	DONE = 'CHANGE_USER_SUBSCRIPTIONS_TIER_DONE',
	FAILED = 'CHANGE_USER_SUBSCRIPTIONS_TIER_FAILED'
}
