import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { emailRegEx } from '../../../utils/regex'

export type ForgottenPasswordFormValues = {
	email: string
	password: string
}

export default (values: ForgottenPasswordFormValues) => {
	const errors: FormErrors<ForgottenPasswordFormValues> = {}

	if (!values.email) {
		errors.email = i18next.t('Toto pole je povinné')
	}

	if (values.email && !emailRegEx.test(values.email)) {
		errors.email = i18next.t('Email nie je platný')
	}

	return errors
}
