import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { CaretUpOutlined, CaretDownOutlined, RiseOutlined, FallOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Col, Empty, Row, Spin, Statistic, Table, Button, Tabs, Form, DatePicker, Select, Menu } from 'antd'
import { Line, Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import { get, isEmpty, map, reverse, slice, forEach, truncate, keys, mapValues, orderBy, reduce, values } from 'lodash'
import { initialize, getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import styled from 'styled-components'
import { getPrograms } from '../../redux/select/actions'
import {
	getDashboardPage,
	getStatistics,
	getSubscriptionStatistics,
	getProgramsEngagementStatistics,
	getExercisesViewStatistics,
	getExercisesFavouritesStatistics,
	getExercisesViewsTrendStatistics,
	getProgramEngagementTrendStatistics,
	getProgramEngagementTrendStatisticsPerDay
} from '../../redux/dashboard/actions'

// helpers
import { disabledDate, intToRGB, hashCode } from '../../utils/helpers'

// enums
import { FORMS, DASHBOARD_PANEL, EMPTY_VALUE, SUBSCRIPTION_USER_TYPE } from '../../utils/enums'

// components
import StatisticWithDescription from './components/StatisticWithDescription'
import SubscriptionMonthForm from './components/SubscriptionMonthForm'
import DashboardPayments from './components/DashboardPayments'
import DashboardRecipeBooks from './components/DashboardRecipeBooks'

const Marker = styled.div`
	margin-right: 6px;
	border-radius: 50%;
	background: ${(props) => props.background};
	width: 12px;
	height: 12px;
`

const { Option } = Select
const { RangePicker } = DatePicker

const TWO_MONTHS_AGO = dayjs().subtract(1, 'day').subtract(2, 'month')
const YESTERDAY = dayjs().subtract(1, 'day')
const TODAY = dayjs()
const TEN_DAYS_AGO = dayjs().subtract(10, 'day')
// defined by database data
const NO_PREVIOUS_DATA = dayjs('2021-05-10')

const Dashboard = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const userStatistics = useSelector((state) => get(state, 'dashboard.userStatistics.data.statistics'))
	const [userTableData, setUserTableData] = useState({ dateFrom: TEN_DAYS_AGO, dateTo: TODAY })

	const programs = useSelector((state) => get(state, 'select.programs.list', []))
	const programStatistics = useSelector((state) => get(state, 'dashboard.programsStatistics.data.programsEngagementStatistics'))
	const programContext = useSelector((state) => get(state, 'dashboard.programsStatistics.data.context'))
	const [programsTableData, setProgramsTableData] = useState({ page: 1, limit: 10, date: YESTERDAY })

	const programEngagementTrendStatisticsPerDay = useSelector((state) =>
		get(state, 'dashboard.programEngagementTrendStatisticsPerDay.data.programEngagementTrendStatistics')
	)
	const [programEngagementTrendPerDayTableData, setProgramEngagementTrendPerDayTableData] = useState({ dateFrom: YESTERDAY, dateTo: YESTERDAY, programs: [] })

	const [programEngagementFilterType, setProgramEngagementFilterType] = useState(SUBSCRIPTION_USER_TYPE.ALL)
	const programEngagementTrendStatistics = useSelector((state) =>
		get(state, 'dashboard.programEngagementTrendStatistics.data.programEngagementTrendStatistics')
	)
	const [programEngagementTrendData, setProgramEngagementTrendData] = useState({ dateFrom: TWO_MONTHS_AGO, dateTo: YESTERDAY, programs: [] })

	const exercisesViewStatistics = useSelector((state) => get(state, 'dashboard.exercisesViewStatistics.data.exercisesViewStatistics'))
	const exercisesViewContext = useSelector((state) => get(state, 'dashboard.exercisesViewStatistics.data.context'))
	const [exercisesViewTableData, setExercisesViewTableData] = useState({ page: 1, limit: 10, date: YESTERDAY })

	const exercisesViewsTrendStatistics = useSelector((state) => get(state, 'dashboard.exercisesViewsTrendStatistics.data.exercisesViewsTrendStatistics'))
	const [exercisesViewsTrendTableData, setExercisesViewsTrendTableData] = useState({ date: YESTERDAY })

	const exercisesFavouritesStatistics = useSelector((state) => get(state, 'dashboard.exercisesFavouritesStatistics.data.favouriteExercisesStatistics'))
	const exercisesFavouritesContext = useSelector((state) => get(state, 'dashboard.exercisesFavouritesStatistics.data.context'))
	const [exercisesFavouritesTableData, setExercisesFavouritesTableData] = useState({ page: 1, limit: 10, date: YESTERDAY })

	const dashboard = useSelector((state) => get(state, 'dashboard.page.data'))
	const isLoadingUsers = useSelector((state) => get(state, 'dashboard.page.isLoading'))

	const subsFieldValues = useSelector((state) => getFormValues(FORMS.SUBSCRIPTION_MONTH_FORM)(state))
	const subsStatistics = useSelector((state) => get(state, 'dashboard.subscriptionStatistics'))
	const subIsLoading = get(subsStatistics, 'isLoading')
	const subsStatisticsValues = get(subsStatistics, 'data')
	const statisticsLoading = useSelector((state) => get(state, 'dashboard.userStatistics.isLoading'))

	const disabledRange = (current) => {
		const { dateFrom, dateTo } = userTableData
		const tooEarly = dateTo && dateTo.diff(current, 'months') > 11
		const tooLate = dateFrom && current.diff(dateFrom, 'months') > 11
		const beforeMay = current && current < NO_PREVIOUS_DATA.startOf('day')

		if (!tooEarly && !tooLate && dayjs(current).isBefore(TODAY.startOf('day')) && !beforeMay) return false
		return true
	}

	const createCustomStatistics = () => {
		const customStatistics = []
		forEach(userStatistics, (item, index) => {
			customStatistics.push({
				...item,
				differenceAllPaying: userStatistics[index + 1] ? get(item, 'allPayingMembers') - get(userStatistics[index + 1], 'allPayingMembers') : 0,
				differencePremium: userStatistics[index + 1] ? get(item, 'premiumMembers') - get(userStatistics[index + 1], 'premiumMembers') : 0,
				differenceVip: userStatistics[index + 1] ? get(item, 'vipMembers') - get(userStatistics[index + 1], 'vipMembers') : 0,
				differenceAll: userStatistics[index + 1] ? get(item, 'allMembers') - get(userStatistics[index + 1], 'allMembers') : 0
			})
		})
		return customStatistics
	}

	const chartStatistics = reverse(slice(createCustomStatistics()))

	const normalizedProgramEngagementTrendStatistics = orderBy(
		map(keys(programEngagementTrendStatistics), (date) => ({
			date,
			program: mapValues(programEngagementTrendStatistics)[date]
		})),
		['date'],
		'asc'
	)

	const getColor = (name) => `#${intToRGB(hashCode(name))}`

	const getStatisticsForSubscriptionUserType = () => {
		if (programEngagementFilterType === SUBSCRIPTION_USER_TYPE.PREMIUM) return 'shiftPremium'
		if (programEngagementFilterType === SUBSCRIPTION_USER_TYPE.NON_PREMIUM) return 'shiftNonPremium'
		return 'shiftAll'
	}

	const getReducedStatistics = (type) => {
		return reduce(
			normalizedProgramEngagementTrendStatistics,
			(out, { program }) => {
				program.forEach((item) => {
					const data = item[type]
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-param-reassign
					out[item?.id] ? out[item?.id].data.push(data) : (out[item?.id] = { id: item?.id, name: item?.name, data: [data] })
				})
				return out
			},
			{}
		)
	}

	useEffect(() => {
		dispatch(getDashboardPage())
		dispatch(initialize(FORMS.SUBSCRIPTION_MONTH_FORM, { selectedMonth: dayjs().subtract(1, 'month').toISOString() }))
	}, [dispatch])

	useEffect(() => {
		dispatch(
			getSubscriptionStatistics({
				selectedMonth: subsFieldValues?.selectedMonth ? dayjs(subsFieldValues.selectedMonth).toISOString() : dayjs().subtract(1, 'month').toISOString()
			})
		)
	}, [dispatch, subsFieldValues])

	useEffect(() => {
		if (userTableData?.dateFrom && userTableData?.dateTo) {
			dispatch(
				getStatistics({
					dateFrom: dayjs(userTableData?.dateFrom).format('YYYY-MM-DD'),
					dateTo: dayjs(userTableData?.dateTo).format('YYYY-MM-DD')
				})
			)
		}
	}, [dispatch, userTableData])

	useEffect(() => {
		dispatch(getPrograms({ limit: 1000000 }))
	}, [dispatch])

	useEffect(() => {
		dispatch(
			getProgramsEngagementStatistics({
				limit: programsTableData?.limit,
				page: programsTableData?.page,
				date: dayjs(programsTableData?.date).format('YYYY-MM-DD')
			})
		)
	}, [dispatch, programsTableData])

	useEffect(() => {
		dispatch(
			getProgramEngagementTrendStatisticsPerDay({
				dateFrom: dayjs(programEngagementTrendPerDayTableData?.dateFrom).format('YYYY-MM-DD'),
				dateTo: dayjs(programEngagementTrendPerDayTableData?.dateTo).format('YYYY-MM-DD'),
				programs: programEngagementTrendPerDayTableData?.programs
			})
		)
	}, [dispatch, programEngagementTrendPerDayTableData])

	useEffect(() => {
		dispatch(
			getProgramEngagementTrendStatistics({
				dateFrom: dayjs(programEngagementTrendData?.dateFrom).format('YYYY-MM-DD'),
				dateTo: dayjs(programEngagementTrendData?.dateTo).format('YYYY-MM-DD'),
				programs: programEngagementTrendData?.programs
			})
		)
	}, [dispatch, programEngagementTrendData])

	useEffect(() => {
		dispatch(
			getExercisesViewStatistics({
				limit: exercisesViewTableData?.limit,
				page: exercisesViewTableData?.page,
				date: dayjs(exercisesViewTableData?.date).format('YYYY-MM-DD')
			})
		)
	}, [dispatch, exercisesViewTableData])

	useEffect(() => {
		dispatch(getExercisesViewsTrendStatistics({ date: dayjs(exercisesViewsTrendTableData?.date).format('YYYY-MM-DD') }))
	}, [dispatch, exercisesViewsTrendTableData])

	useEffect(() => {
		dispatch(
			getExercisesFavouritesStatistics({
				limit: exercisesFavouritesTableData?.limit,
				page: exercisesFavouritesTableData?.page,
				date: dayjs(exercisesFavouritesTableData?.date).format('YYYY-MM-DD')
			})
		)
	}, [dispatch, exercisesFavouritesTableData])

	const handleTableChange = (pagination, panel) => {
		switch (panel) {
			case DASHBOARD_PANEL.PROGRAMS:
				setProgramsTableData({
					...programsTableData,
					limit: get(pagination, 'pageSize'),
					page: get(pagination, 'current')
				})
				break
			case DASHBOARD_PANEL.PROGRAM_ENGAGEMENT_TREND_PER_DAY:
				setProgramEngagementTrendPerDayTableData({
					...programEngagementTrendPerDayTableData
				})
				break
			case DASHBOARD_PANEL.PROGRAM_ENGAGEMENT_TREND:
				setProgramEngagementTrendData({
					...programEngagementTrendData
				})
				break
			case DASHBOARD_PANEL.EXERCISES_VIEWS:
				setExercisesViewTableData({
					...exercisesViewTableData,
					limit: get(pagination, 'pageSize'),
					page: get(pagination, 'current')
				})
				break
			case DASHBOARD_PANEL.EXERCISES_VIEWS_TREND:
				setExercisesViewsTrendTableData({
					...exercisesViewsTrendTableData
				})
				break
			case DASHBOARD_PANEL.EXERCISES_FAVOURITES:
				setExercisesFavouritesTableData({
					...exercisesFavouritesTableData,
					limit: get(pagination, 'pageSize'),
					page: get(pagination, 'current')
				})
				break
			default:
				break
		}
	}

	const userColumns = [
		{
			title: i18next.t('Dátum'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 100,
			render: (date) => (date !== 0 ? dayjs(date).format('D.M.YYYY') : EMPTY_VALUE)
		},
		{
			title: i18next.t('S plateným členstvom'),
			dataIndex: 'allPayingMembers',
			key: 'allPayingMembers',
			width: 100,
			render: (allPayingMembers, row) => (
				<span>
					{`${allPayingMembers} `}
					<span>
						{get(row, 'differenceAllPaying') === 0 && ''}
						{get(row, 'differenceAllPaying') < 0 && (
							<span style={{ color: '#ff3f3f' }}>
								<CaretDownOutlined />
								{Math.abs(get(row, 'differenceAllPaying'))}
							</span>
						)}
						{get(row, 'differenceAllPaying') > 0 && (
							<span style={{ color: '#44ad44' }}>
								<CaretUpOutlined />
								{get(row, 'differenceAllPaying')}
							</span>
						)}
					</span>
				</span>
			)
		},
		{
			title: i18next.t('S Premium členstvom'),
			dataIndex: 'premiumMembers',
			key: 'premiumMembers',
			width: 100,
			render: (premiumMembers, row) => (
				<span>
					{`${premiumMembers} `}
					<span>
						{get(row, 'differencePremium') === 0 && ''}
						{get(row, 'differencePremium') < 0 && (
							<span style={{ color: '#ff3f3f' }}>
								<CaretDownOutlined />
								{Math.abs(get(row, 'differencePremium'))}
							</span>
						)}
						{get(row, 'differencePremium') > 0 && (
							<span style={{ color: '#44ad44' }}>
								<CaretUpOutlined />
								{get(row, 'differencePremium')}
							</span>
						)}
					</span>
				</span>
			)
		},
		{
			title: i18next.t('S VIP členstvom'),
			dataIndex: 'vipMembers',
			key: 'vipMembers',
			width: 100,
			render: (premiumMembers, row) => (
				<span>
					{`${premiumMembers} `}
					<span>
						{get(row, 'differenceVip') === 0 && ''}
						{get(row, 'differenceVip') < 0 && (
							<span style={{ color: '#ff3f3f' }}>
								<CaretDownOutlined />
								{Math.abs(get(row, 'differenceVip'))}
							</span>
						)}
						{get(row, 'differenceVip') > 0 && (
							<span style={{ color: '#44ad44' }}>
								<CaretUpOutlined />
								{get(row, 'differenceVip')}
							</span>
						)}
					</span>
				</span>
			)
		},
		{
			title: i18next.t('S bezplatným členstvom'),
			dataIndex: 'freeMembers',
			key: 'freeMembers',
			width: 115,
			render: (freeMembers) => freeMembers
		},
		{
			title: i18next.t('Všetci používatelia'),
			dataIndex: 'allMembers',
			key: 'allMembers',
			width: 100,
			render: (allMembers, row) => (
				<span>
					{`${allMembers} `}
					<span>
						{get(row, 'differenceAll') === 0 && ''}
						{get(row, 'differenceAll') < 0 && (
							<span style={{ color: '#aaa' }}>
								<CaretDownOutlined />
								{Math.abs(get(row, 'differenceAll'))}
							</span>
						)}
						{get(row, 'differenceAll') > 0 && (
							<span style={{ color: '#aaa' }}>
								<CaretUpOutlined />
								{get(row, 'differenceAll')}
							</span>
						)}
					</span>
				</span>
			)
		}
	]

	const programColumns = [
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true
		},
		{
			title: i18next.t('Cvičiaci'),
			dataIndex: 'activeUsersCount',
			key: 'activeUsersCount',
			align: 'center',
			width: '70px',
			render: (item) => <strong>{item}</strong>
		},
		{
			title: '',
			fixed: 'right',
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			align: 'center',
			width: '90px',
			render: (urlSlug, report) => (
				<>
					<Link className={'btn'} to={`${t('paths:program|path')}/${report?.id}`}>
						<Button icon={<EditOutlined />} onClick={(e) => e.stopPropagation()} style={{ marginRight: '8px' }} />
					</Link>
					<Button
						icon={<EyeOutlined />}
						onClick={(e) => {
							e.stopPropagation()
							window.open(`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programs|path')}/${urlSlug}`, '_blank')
						}}
						type={'primary'}
					/>
				</>
			)
		}
	]

	const programEngagementTrendColumns = [
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (item) =>
				item && (
					<Row align={'middle'} style={{ flexWrap: 'nowrap' }}>
						<Col>
							<Marker background={getColor(item)} />
						</Col>
						<Col style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item}</Col>
					</Row>
				)
		},
		{
			title: t('Vývoj cvičenosti'),
			dataIndex: getStatisticsForSubscriptionUserType(),
			key: getStatisticsForSubscriptionUserType(),
			align: 'center',
			width: '100px',
			render: (item) => <strong>{item}</strong>
		},
		{
			title: '',
			fixed: 'right',
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			align: 'center',
			width: '90px',
			render: (urlSlug, report) => (
				<>
					<Link className={'btn'} to={`${t('paths:program|path')}/${report?.id}`}>
						<Button icon={<EditOutlined />} onClick={(e) => e.stopPropagation()} style={{ marginRight: '8px' }} />
					</Link>
					<Button
						icon={<EyeOutlined />}
						onClick={(e) => {
							e.stopPropagation()
							window.open(`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programs|path')}/${urlSlug}`, '_blank')
						}}
						type={'primary'}
					/>
				</>
			)
		}
	]

	const exercisesViewColumns = (type) => [
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true
		},
		{
			align: 'center',
			title: type === DASHBOARD_PANEL.EXERCISES_VIEWS ? i18next.t('Počet pozretí') : i18next.t('Počet uživateľov'),
			dataIndex: type === DASHBOARD_PANEL.EXERCISES_VIEWS ? 'views' : 'userFavouriteExercisesCount',
			key: type === DASHBOARD_PANEL.EXERCISES_VIEWS ? 'views' : 'userFavouriteExercisesCount',
			width: '85px',
			render: (item) => <strong>{item}</strong>
		},
		{
			title: '',
			align: 'center',
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			width: '90px',
			render: (urlSlug, report) => (
				<>
					<Link className={'btn'} to={`${t('paths:exercise|path')}/${report?.id}`}>
						<Button icon={<EditOutlined />} onClick={(e) => e.stopPropagation()} style={{ marginRight: '8px' }} />
					</Link>
					<Button
						icon={<EyeOutlined />}
						onClick={(e) => {
							e.stopPropagation()
							window.open(`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:exercises|path')}/${urlSlug}`, '_blank')
						}}
						type={'primary'}
					/>
				</>
			)
		}
	]

	const exercisesViewsTrendColumns = [
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true
		},
		{
			title: t('Prírastok za 30 dní'),
			dataIndex: 'viewsShift',
			key: 'viewsShift',
			align: 'center',
			width: '90px',
			render: (item) => <strong>{item}</strong>
		},
		{
			title: '',
			align: 'center',
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			width: '90px',
			render: (urlSlug, report) => (
				<>
					<Link className={'btn'} to={`${t('paths:exercise|path')}/${report?.id}`}>
						<Button icon={<EditOutlined />} onClick={(e) => e.stopPropagation()} style={{ marginRight: '8px' }} />
					</Link>
					<Button
						icon={<EyeOutlined />}
						onClick={(e) => {
							e.stopPropagation()
							window.open(`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:exercises|path')}/${urlSlug}`, '_blank')
						}}
						type={'primary'}
					/>
				</>
			)
		}
	]

	const userChartData = {
		datasets: [
			{
				label: 'Premium',
				fill: false,
				backgroundColor: 'rgb(254, 163, 133)',
				borderColor: 'rgb(254, 196, 170)',
				data: map(chartStatistics, (timestamp) => get(timestamp, 'premiumMembers'))
			},
			{
				label: 'VIP',
				fill: false,
				backgroundColor: 'rgb(65,105,225)',
				borderColor: 'rgb(135,206,250)',
				data: map(chartStatistics, (timestamp) => get(timestamp, 'vipMembers'))
			}
		],
		labels: map(chartStatistics, (timestamp) => dayjs(timestamp.createdAt).format('D.M.YYYY'))
	}

	const programsChartData = {
		datasets: [
			{
				label: 'Cvičiaci',
				fill: false,
				backgroundColor: 'rgba(254, 163, 133, 0.3)',
				borderColor: 'rgba(254, 163, 133, 1)',
				borderWidth: 1,
				data: map(programStatistics, (program) => get(program, 'activeUsersCount'))
			}
		],
		labels: map(programStatistics, (program) => truncate(get(program, 'name'), { omission: '...', length: 28 }))
	}

	const programEngagementAllChartData = {
		datasets: map(mapValues(getReducedStatistics('shiftAll')), (program) => {
			return {
				label: program?.name || `${t('ID')} ${program?.id}`,
				fill: false,
				backgroundColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				borderColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				data: program?.data
			}
		}),
		labels: map(orderBy(keys(programEngagementTrendStatistics), [], 'asc'), (timestamp) => dayjs(timestamp).format('D.M.YYYY'))
	}

	const programEngagementPremiumChartData = {
		datasets: map(mapValues(getReducedStatistics('shiftPremium')), (program) => {
			return {
				label: program?.name || `${t('ID')} ${program?.id}`,
				fill: false,
				backgroundColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				borderColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				data: program?.data
			}
		}),
		labels: map(orderBy(keys(programEngagementTrendStatistics), [], 'asc'), (timestamp) => dayjs(timestamp).format('D.M.YYYY'))
	}

	const programEngagementNonPremiumChartData = {
		datasets: map(mapValues(getReducedStatistics('shiftNonPremium')), (program) => {
			return {
				label: program?.name || `${t('ID')} ${program?.id}`,
				fill: false,
				backgroundColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				borderColor: `#${intToRGB(hashCode(program?.name || `${t('ID')} ${program?.id}`))}`,
				data: program?.data
			}
		}),
		labels: map(orderBy(keys(programEngagementTrendStatistics), [], 'asc'), (timestamp) => dayjs(timestamp).format('D.M.YYYY'))
	}

	const programEngagementChartOptions = {
		legend: {
			display: false
		}
	}

	const exercisesViewChartData = {
		datasets: [
			{
				label: i18next.t('Počet pozretí'),
				fill: false,
				backgroundColor: 'rgba(254, 163, 133, 0.3)',
				borderColor: 'rgba(254, 163, 133, 1)',
				borderWidth: 1,
				data: map(exercisesViewStatistics, (exercise) => get(exercise, 'views'))
			}
		],
		labels: map(exercisesViewStatistics, (exercise) => truncate(get(exercise, 'name'), { omission: '...', length: 28 }))
	}

	const exercisesViewsTrendChartData = {
		datasets: [
			{
				label: t('Prírastok pozretí'),
				fill: false,
				backgroundColor: 'rgba(254, 163, 133, 0.3)',
				borderColor: 'rgba(254, 163, 133, 1)',
				borderWidth: 1,
				data: map(exercisesViewsTrendStatistics, (exercise) => get(exercise, 'viewsShift'))
			}
		],
		labels: map(exercisesViewsTrendStatistics, (exercise) => truncate(get(exercise, 'name'), { omission: '...', length: 28 }))
	}

	const exercisesFavouritesChartData = {
		datasets: [
			{
				label: i18next.t('Počet uživateľov ktorím sa páči'),
				fill: false,
				backgroundColor: 'rgba(254, 163, 133, 0.3)',
				borderColor: 'rgba(254, 163, 133, 1)',
				borderWidth: 1,
				data: map(exercisesFavouritesStatistics, (exercise) => get(exercise, 'userFavouriteExercisesCount'))
			}
		],
		labels: map(exercisesFavouritesStatistics, (exercise) => truncate(get(exercise, 'name'), { omission: '...', length: 28 }))
	}

	const getProgramEngagementChartData = () => {
		if (programEngagementFilterType === SUBSCRIPTION_USER_TYPE.PREMIUM) return programEngagementPremiumChartData
		if (programEngagementFilterType === SUBSCRIPTION_USER_TYPE.NON_PREMIUM) return programEngagementNonPremiumChartData
		return programEngagementAllChartData
	}

	return (
		<div className={'page-wrapper dashboard-page'}>
			<Tabs defaultActiveKey={DASHBOARD_PANEL.USERS}>
				<Tabs.TabPane tab={t('Použivatelia')} key={DASHBOARD_PANEL.USERS}>
					<Row gutter={16} style={{ border: '1px solid #fea385', margin: '0 0 16px 0', borderRadius: '12px', paddingTop: '8px' }}>
						<Col span={12} className={'grid'}>
							<span>
								<b style={{ marginRight: '16px' }}>{'Mesačné štatistiky'}</b> <SubscriptionMonthForm />
							</span>
						</Col>
						<Col span={24} className={'grid'}>
							<Spin spinning={subIsLoading}>
								<div className={'dashboard-card'}>
									<h2>{'Všetky členstvá'}</h2>
									<div className={'card-content'}>
										<StatisticWithDescription
											title={t('Všetky členstvá')}
											value={get(subsStatisticsValues, 'allSubscriptions')}
											suffix={'č.'}
											description={t('statisticsDescriptions|allSubscriptions')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (€)')}
											value={get(subsStatisticsValues, 'paidAllEur')}
											suffix={'€'}
											description={t('statisticsDescriptions|paidAllEur')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (Kč)')}
											value={get(subsStatisticsValues, 'paidAllCzk')}
											suffix={'Kč'}
											description={t('statisticsDescriptions|paidAllCzk')}
										/>
										<StatisticWithDescription
											title={t('S promo kódom')}
											value={get(subsStatisticsValues, 'withPromocode')}
											description={t('statisticsDescriptions|withPromocode')}
											suffix={'č.'}
										/>
										<StatisticWithDescription
											title={t('Ručne pridané')}
											value={get(subsStatisticsValues, 'withCustom')}
											description={t('statisticsDescriptions|withCustom')}
											suffix={'č.'}
										/>
										<StatisticWithDescription
											title={t('Retention rate')}
											value={get(subsStatisticsValues, 'retentionRate')}
											suffix={'%'}
											// description={t('statisticsDescriptions|allSubscriptions')}
										/>
									</div>
								</div>
							</Spin>
						</Col>
						<Col span={8} className={'grid'}>
							<Spin spinning={subIsLoading}>
								<div className={'dashboard-card'}>
									<h2>{t('Nové členstvá')}</h2>
									<div className={'card-content'}>
										<StatisticWithDescription
											title={t('Členstvá')}
											value={get(subsStatisticsValues, 'newSubscriptions')}
											prefix={<RiseOutlined />}
											valueStyle={{ color: '#3f8600' }}
											suffix={'č.'}
											description={t('statisticsDescriptions|newSubscriptions')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (€)')}
											value={get(subsStatisticsValues, 'paidNewEur')}
											suffix={'€'}
											description={t('statisticsDescriptions|paidNewEur')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (Kč)')}
											value={get(subsStatisticsValues, 'paidNewCzk')}
											suffix={'Kč'}
											description={t('statisticsDescriptions|paidNewCzk')}
										/>
									</div>
								</div>
							</Spin>
						</Col>
						<Col span={8} className={'grid'}>
							<Spin spinning={subIsLoading}>
								<div className={'dashboard-card'}>
									<h2>{t('Zrušené členstvá')}</h2>
									<div className={'card-content'}>
										<StatisticWithDescription
											title={t('Členstvá')}
											value={get(subsStatisticsValues, 'canceledSubscriptions')}
											prefix={<FallOutlined />}
											valueStyle={{ color: '#cf1322' }}
											suffix={'č.'}
											description={t('statisticsDescriptions|canceledSubscriptions')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (€)')}
											value={get(subsStatisticsValues, 'paidFromCanceledEur')}
											suffix={'€'}
											description={t('statisticsDescriptions|paidFromCanceledEur')}
										/>
										<StatisticWithDescription
											title={t('V hodnote (Kč)')}
											value={get(subsStatisticsValues, 'paidFromCanceledCzk')}
											suffix={'Kč'}
											description={t('statisticsDescriptions|paidFromCanceledCzk')}
										/>
									</div>
								</div>
							</Spin>
						</Col>
						<Col span={8} className={'grid'}>
							<Spin spinning={subIsLoading}>
								<div className={'dashboard-card'}>
									<h2>{t('Sumar')}</h2>
									<div className={'card-content'}>
										<StatisticWithDescription
											title={t('Mesačné')}
											value={get(subsStatisticsValues, 'newMonthly')}
											suffix={'č.'}
											description={t('statisticsDescriptions|newMonthly')}
										/>
										<StatisticWithDescription
											title={t('Štvrťročné')}
											value={get(subsStatisticsValues, 'newQuarterly')}
											suffix={'č.'}
											description={t('statisticsDescriptions|newQuarterly')}
										/>
										<StatisticWithDescription
											title={t('Ročné')}
											value={get(subsStatisticsValues, 'newYearly')}
											suffix={'č.'}
											description={t('statisticsDescriptions|newYearly')}
										/>
									</div>
								</div>
							</Spin>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12} className={'grid'}>
							<Spin spinning={isLoadingUsers}>
								<div className={'dashboard-card'}>
									<h2>{t('Členovia')}</h2>
									<div className={'card-content'}>
										<Statistic title={t('Všetci')} value={get(dashboard, 'users.all')} />
										<Statistic title={t('S plateným členstvom')} value={get(dashboard, 'users.paidSubscription')} />
										<Statistic title={t('S bezplatným členstvom')} value={get(dashboard, 'users.freeSubscription')} />
									</div>
								</div>
							</Spin>
						</Col>
						<Col span={12} className={'grid'}>
							<Spin spinning={isLoadingUsers}>
								<div className={'dashboard-card'}>
									<h2>
										{t('Výzva')} - {get(dashboard, 'challenge.name')}
									</h2>
									{isEmpty(get(dashboard, 'challenge')) ? (
										<p className={'no-challenge-notification'}>{t('Aktuálne neprebieha žiadna výzva')}</p>
									) : (
										<>
											<Row gutter={[16, 8]} style={{ marginBottom: '4px' }}>
												<Col xs={24} lg={12} xl={8} style={{ whiteSpace: 'nowrap' }}>
													<Statistic title={t('Počet prihlásených')} value={get(dashboard, 'challenge.users')} />
												</Col>
												<Col xs={24} lg={12} xl={8} style={{ whiteSpace: 'nowrap' }}>
													<Statistic title={t('Súťažne prihlásených')} value={get(dashboard, 'challenge.competitionUsers')} />
												</Col>
												<Col xs={24} lg={12} xl={8} style={{ whiteSpace: 'nowrap' }}>
													<Statistic title={t('Nesúťažne prihlásených')} value={get(dashboard, 'challenge.nonCompetitionUsers')} />
												</Col>
											</Row>
											<Row gutter={[16, 8]}>
												<Col xs={24} lg={12} xl={8} style={{ whiteSpace: 'nowrap' }}>
													<Statistic title={t('S premium členstvom')} value={get(dashboard, 'challenge.subscriptionUsers')} />
												</Col>
												<Col xs={24} lg={12} xl={8} style={{ whiteSpace: 'nowrap' }}>
													<Statistic title={t('Bez premium členstva')} value={get(dashboard, 'challenge.nonSubscriptionUsers')} />
												</Col>
											</Row>
										</>
									)}
								</div>
							</Spin>
						</Col>
					</Row>
					<div className={'dashboard-card'}>
						<Row gutter={16}>
							<Col span={14} className={'grid'}>
								<h2>{t('Počet aktívnych používateľov')}</h2>
								<Form.Item style={{ marginBottom: '10.5px' }}>
									<RangePicker
										defaultValue={[TODAY, TEN_DAYS_AGO]}
										format={'D.M.YYYY'}
										disabledDate={disabledRange}
										onChange={(rangeValues) => {
											if (rangeValues) setUserTableData({ dateFrom: rangeValues[0], dateTo: rangeValues[1] })
											else setUserTableData({ dateFrom: undefined, dateTo: undefined })
										}}
										onCalendarChange={(rangeValues) => {
											if (rangeValues) setUserTableData({ dateFrom: rangeValues[0], dateTo: rangeValues[1] })
											else setUserTableData({ dateFrom: undefined, dateTo: undefined })
										}}
										placeholder={[t('Od'), t('Do')]}
										allowEmpty={[true, true]}
										style={{ width: 250 }}
									/>
								</Form.Item>
								<Table
									bordered
									className={'general-table'}
									columns={userColumns}
									rowKey={'id'}
									dataSource={createCustomStatistics()}
									showSorterTooltip={false}
									loading={statisticsLoading}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
									pagination={{
										pageSize: 20,
										showSizeChanger: false
									}}
								/>
							</Col>
							<Col span={10} className={'grid'} style={{ marginTop: '48px' }}>
								<Spin spinning={statisticsLoading}>
									<div className={'dashboard-chart-wrapper'}>
										<Line height={150} data={userChartData} />
									</div>
								</Spin>
							</Col>
						</Row>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={t('Programy')} key={DASHBOARD_PANEL.PROGRAMS}>
					<div className={'dashboard-card'}>
						<Row gutter={16}>
							<Col span={9} className={'grid'}>
								<h2>{t('Počet aktívnych premium cvičiacich')}</h2>
								<Table
									bordered
									className={'general-table'}
									columns={programColumns}
									rowKey={'id'}
									dataSource={programStatistics}
									showSorterTooltip={false}
									loading={statisticsLoading}
									onChange={(pagination) => handleTableChange(pagination, DASHBOARD_PANEL.PROGRAMS)}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
									pagination={{
										pageSize: programsTableData?.limit,
										total: get(programContext, 'totalCount'),
										current: get(programContext, 'page'),
										showSizeChanger: true,
										pageSizeOptions: [3, 5, 10, 15, 20, 30],
										locale: { items_per_page: t('na stranu') }
									}}
								/>
							</Col>
							<Col span={15} className={'grid'}>
								<Form.Item style={{ marginBottom: '10.5px' }}>
									<DatePicker
										style={{ width: 250 }}
										onChange={(value) => setProgramsTableData({ ...programsTableData, date: value })}
										defaultValue={dayjs().subtract(1, 'day')}
										format={'D.M.YYYY'}
										placeholder={t('Zvoľte dátum')}
										disabledDate={disabledDate}
										allowClear={false}
									/>
								</Form.Item>
								<Spin spinning={statisticsLoading}>
									<div className={'dashboard-chart-wrapper bar'}>
										<Bar height={150} data={programsChartData} />
									</div>
								</Spin>
							</Col>
						</Row>
					</div>
					<div className={'dashboard-card'}>
						<h2>{t('Vývoj cvičenosti')}</h2>
						<Menu
							onClick={(menu) => setProgramEngagementFilterType(menu.key)}
							selectedKeys={[programEngagementFilterType]}
							mode={'horizontal'}
							items={[
								{
									key: SUBSCRIPTION_USER_TYPE.ALL,
									label: t('Všetci')
								},
								{
									key: SUBSCRIPTION_USER_TYPE.PREMIUM,
									label: t('Premium')
								},
								{
									key: SUBSCRIPTION_USER_TYPE.NON_PREMIUM,
									label: t('Bez členstva')
								}
							]}
						/>
						<Row gutter={16} style={{ marginTop: '16px' }}>
							<Col span={9} className={'grid'}>
								<Row align={'middle'} justify={'end'} style={{ marginBottom: '10.5px' }}>
									<Col style={{ paddingRight: '4px' }}>
										<span>{t('Štatistiky ku dňu')}</span>
									</Col>
									<Col>
										<DatePicker
											onChange={(value) =>
												setProgramEngagementTrendPerDayTableData({
													...programEngagementTrendPerDayTableData,
													dateFrom: value,
													dateTo: value
												})
											}
											defaultValue={dayjs().subtract(1, 'day')}
											format={'D.M.YYYY'}
											placeholder={t('Zvoľte dátum')}
											disabledDate={disabledDate}
											allowClear={false}
										/>
									</Col>
								</Row>
								<Table
									bordered
									className={'general-table'}
									columns={programEngagementTrendColumns}
									rowKey={'id'}
									dataSource={values(programEngagementTrendStatisticsPerDay)[0]}
									showSorterTooltip={false}
									loading={statisticsLoading}
									onChange={(pagination) => handleTableChange(pagination, DASHBOARD_PANEL.PROGRAM_ENGAGEMENT_TREND)}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
									pagination={{
										showSizeChanger: true,
										pageSizeOptions: [3, 5, 10, 15, 20, 30],
										locale: { items_per_page: t('na stranu') }
									}}
								/>
							</Col>
							<Col span={15} className={'grid'}>
								<Row align={'middle'} style={{ marginBottom: '8px' }}>
									<Col span={24}>
										<Form.Item style={{ margin: 0 }}>
											<Select
												style={{ width: '100%' }}
												onChange={(value) =>
													setProgramEngagementTrendData({
														...programEngagementTrendData,
														programs: value
													})
												}
												placeholder={t('Výzvy a programy')}
												value={programEngagementTrendData?.programs}
												mode={'multiple'}
												filterOption={(input, option) =>
													option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
												allowClear
											>
												{map(programs, (program, index) => (
													<Option key={`program-filter-${index}`} value={get(program, 'id')}>
														{get(program, 'name')}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col style={{ marginBottom: '10.5px' }}>
										<Row align={'middle'}>
											<Col style={{ paddingRight: '4px' }}>
												<span>{t('Od')}</span>
											</Col>
											<Col>
												<DatePicker
													onChange={(value) =>
														setProgramEngagementTrendData({
															...programEngagementTrendData,
															dateFrom: value
														})
													}
													defaultValue={dayjs().subtract(1, 'day').subtract(2, 'month')}
													format={'D.M.YYYY'}
													placeholder={t('Zvoľte dátum')}
													disabledDate={disabledDate}
													allowClear={false}
												/>
											</Col>
										</Row>
									</Col>
									<Col style={{ marginBottom: '10.5px' }}>
										<Row align={'middle'}>
											<Col style={{ paddingRight: '4px' }}>
												<span>{t('Do')}</span>
											</Col>
											<Col>
												<DatePicker
													onChange={(value) =>
														setProgramEngagementTrendData({
															...programEngagementTrendData,
															dateTo: value
														})
													}
													defaultValue={dayjs().subtract(1, 'day')}
													format={'D.M.YYYY'}
													placeholder={t('Zvoľte dátum')}
													disabledDate={disabledDate}
													allowClear={false}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Spin spinning={statisticsLoading}>
									<div className={'dashboard-chart-wrapper'}>
										<Line height={150} data={getProgramEngagementChartData()} options={programEngagementChartOptions} />
									</div>
								</Spin>
							</Col>
						</Row>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={t('Cvičenia')} key={DASHBOARD_PANEL.EXERCISES_VIEWS}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<div className={'dashboard-card'}>
								<Row gutter={16}>
									<Col span={9} className={'grid'}>
										<h2>{t('Počet prezretí cvičenia')}</h2>
										<Table
											bordered
											className={'general-table'}
											columns={exercisesViewColumns(DASHBOARD_PANEL.EXERCISES_VIEWS)}
											rowKey={'id'}
											dataSource={exercisesViewStatistics}
											showSorterTooltip={false}
											loading={statisticsLoading}
											onChange={(pagination) => handleTableChange(pagination, DASHBOARD_PANEL.EXERCISES_VIEWS)}
											locale={{
												emptyText: <Empty description={t('Žiadne dáta')} />
											}}
											size={'small'}
											pagination={{
												pageSize: exercisesViewTableData?.limit,
												total: get(exercisesViewContext, 'totalCount'),
												current: get(exercisesViewContext, 'page'),
												showSizeChanger: true,
												pageSizeOptions: [3, 5, 10, 15, 20, 30],
												locale: { items_per_page: t('na stranu') }
											}}
										/>
									</Col>
									<Col span={15} className={'grid'}>
										<Form.Item style={{ marginBottom: '10.5px' }}>
											<DatePicker
												style={{ width: 250 }}
												onChange={(value) => setExercisesViewTableData({ ...exercisesViewTableData, date: value })}
												defaultValue={dayjs().subtract(1, 'day')}
												format={'D.M.YYYY'}
												placeholder={t('Zvoľte dátum')}
												disabledDate={disabledDate}
												allowClear={false}
											/>
										</Form.Item>
										<Spin spinning={statisticsLoading}>
											<div className={'dashboard-chart-wrapper bar'}>
												<Bar height={150} data={exercisesViewChartData} />
											</div>
										</Spin>
									</Col>
								</Row>
							</div>
						</Col>
						<Col span={24}>
							<div className={'dashboard-card'}>
								<Row gutter={16}>
									<Col span={9} className={'grid'}>
										<h2>{t('Prírastok prezretí')}</h2>
										<Table
											bordered
											className={'general-table'}
											columns={exercisesViewsTrendColumns}
											rowKey={'id'}
											dataSource={exercisesViewsTrendStatistics}
											showSorterTooltip={false}
											loading={statisticsLoading}
											onChange={(pagination) => handleTableChange(pagination, DASHBOARD_PANEL.EXERCISES_VIEWS_TREND)}
											locale={{
												emptyText: <Empty description={t('Žiadne dáta')} />
											}}
											size={'small'}
											pagination={{
												showSizeChanger: true,
												pageSizeOptions: [3, 5, 10, 15, 20, 30],
												locale: { items_per_page: t('na stranu') }
											}}
										/>
									</Col>
									<Col span={15} className={'grid'}>
										<Form.Item style={{ marginBottom: '10.5px' }}>
											<DatePicker
												style={{ width: 250 }}
												onChange={(value) =>
													setExercisesViewsTrendTableData({
														...exercisesViewsTrendTableData,
														date: value
													})
												}
												defaultValue={dayjs().subtract(1, 'day')}
												format={'D.M.YYYY'}
												placeholder={t('Zvoľte dátum')}
												disabledDate={disabledDate}
												allowClear={false}
											/>
										</Form.Item>
										<Spin spinning={statisticsLoading}>
											<div className={'dashboard-chart-wrapper bar'}>
												<Bar height={150} data={exercisesViewsTrendChartData} />
											</div>
										</Spin>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>

					<div className={'dashboard-card'}>
						<Row gutter={16}>
							<Col span={9} className={'grid'}>
								<h2>{t('Obľúbené cvičenia')}</h2>
								<Table
									bordered
									className={'general-table'}
									columns={exercisesViewColumns(DASHBOARD_PANEL.EXERCISES_FAVOURITES)}
									rowKey={'id'}
									dataSource={exercisesFavouritesStatistics}
									showSorterTooltip={false}
									loading={statisticsLoading}
									onChange={(pagination) => handleTableChange(pagination, DASHBOARD_PANEL.EXERCISES_FAVOURITES)}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta')} />
									}}
									size={'small'}
									pagination={{
										pageSize: exercisesFavouritesTableData?.limit,
										total: get(exercisesFavouritesContext, 'totalCount'),
										current: get(exercisesFavouritesContext, 'page'),
										showSizeChanger: true,
										pageSizeOptions: [3, 5, 10, 15, 20, 30],
										locale: { items_per_page: t('na stranu') }
									}}
								/>
							</Col>
							<Col span={15} className={'grid'}>
								<Form.Item style={{ marginBottom: '10.5px' }}>
									<DatePicker
										style={{ width: 250 }}
										onChange={(value) => setExercisesFavouritesTableData({ ...exercisesFavouritesTableData, date: value })}
										defaultValue={dayjs().subtract(1, 'day')}
										format={'D.M.YYYY'}
										placeholder={t('Zvoľte dátum')}
										disabledDate={disabledDate}
										allowClear={false}
									/>
								</Form.Item>
								<Spin spinning={statisticsLoading}>
									<div className={'dashboard-chart-wrapper bar'}>
										<Bar height={150} data={exercisesFavouritesChartData} />
									</div>
								</Spin>
							</Col>
						</Row>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={t('Platby')} key={DASHBOARD_PANEL.PAYMENTS}>
					<DashboardPayments />
				</Tabs.TabPane>
				<Tabs.TabPane tab={t('Jedálničky')} key={DASHBOARD_PANEL.RECIPE_BOOKS}>
					<DashboardRecipeBooks />
				</Tabs.TabPane>
			</Tabs>
			<Row gutter={16} />
		</div>
	)
}

Dashboard.propTypes = {}

export default Dashboard
