/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, FC } from 'react'
import Lightbox from 'react-image-lightbox'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

interface ILightBox {
	isOpen?: boolean
	photoGallery?: { imagePath: string; caption?: string }[] | any
	firstImageIndex?: number
	onClose: () => void
}

// @ts-ignore
const LightBox: FC<ILightBox> = ({ isOpen = false, photoGallery = [], firstImageIndex = 0, onClose }) => {
	const [photoIndex, setPhotoIndex] = useState(firstImageIndex)

	useEffect(() => {
		setPhotoIndex(firstImageIndex)
	}, [firstImageIndex])

	useEffect(() => {
		const target = document.getElementsByClassName('ReactModalPortal')

		if (isOpen) {
			disableBodyScroll(target as any, { reserveScrollBarGap: true })
		} else {
			clearAllBodyScrollLocks()
		}
	}, [isOpen])

	const closeBox = () => {
		setPhotoIndex(0)
		onClose()
	}

	return (
		isOpen &&
		photoGallery.length > 0 && (
			<Lightbox
				mainSrc={photoGallery[photoIndex].imagePath}
				nextSrc={photoGallery[(photoIndex + 1) % photoGallery.length].imagePath}
				prevSrc={photoGallery[(photoIndex + photoGallery.length - 1) % photoGallery.length].imagePath}
				imageTitle={photoGallery[photoIndex].caption}
				onMovePrevRequest={() => setPhotoIndex((photoIndex + photoGallery.length - 1) % photoGallery.length)}
				onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photoGallery.length)}
				onCloseRequest={closeBox}
			/>
		)
	)
}

export default LightBox
