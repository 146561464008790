import PropTypes from 'prop-types'
import { Input, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import trim from 'lodash/trim'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const Button = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const setCenter = (value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			center: value
		})
	}

	const setLink = (link) => {
		itemContentSetter(item?.id, {
			...item?.content,
			link
		})
	}

	const setText = (text) => {
		itemContentSetter(item?.id, {
			...item?.content,
			text
		})
	}

	const setNewPage = (e) => {
		itemContentSetter(item?.id, {
			...item?.content,
			newPage: e.target.checked
		})
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex'}>
				<Input
					placeholder={t('Sem zadajte text tlačidla')}
					className={'input-field'}
					style={{ marginRight: '15px' }}
					size={'large'}
					maxLength={INPUT_MAX_LENGTH}
					value={item?.content?.text}
					onChange={(e) => setText(e.target.value)}
					onBlur={(e) => setText(trim(e.target.value))}
				/>
				<Input
					placeholder={t('google')}
					className={'input-field'}
					size={'large'}
					value={item?.content?.link}
					onChange={(e) => setLink(e.target.value)}
				/>
			</div>
			<div className={'flex flex-wrap mt-3'} style={{ gap: 8, float: 'right' }}>
				<Checkbox onChange={setNewPage} checked={item?.content?.newPage}>
					{t('Otvoriť na novej stránke')}
				</Checkbox>
				<Checkbox className={'ml-0'} checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
					{t('Centrovať')}
				</Checkbox>
			</div>
		</div>
	)
}

Button.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			text: PropTypes.string,
			link: PropTypes.string,
			newPage: PropTypes.bool,
			center: PropTypes.bool
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Button.defaultProps = {
	item: {}
}

export default Button
