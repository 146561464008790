import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Empty, Popconfirm, Table } from 'antd'
import i18next from 'i18next'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import BooleanRepresent from '../../atoms/BooleanRepresent'
import OrderRepresent from '../../atoms/OrderRepresent'
import { deleteBanner, getBanners } from '../../redux/banners/actions'
import { setFilters } from '../../redux/filters/actions'
import { BANNER_TYPE, EMPTY_VALUE } from '../../utils/enums'
import { setFiltersForPage } from '../../utils/helpers'
import { RootState } from '../../redux'

const PAGE_SIZE = 20

const Banners = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.banners?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.banners?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.banners?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.banners?.list?.context)

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:banners|key'), persistFilter))

	const columns = [
		{
			title: i18next.t('Obrázok'),
			dataIndex: 'image',
			key: 'image',
			className: 'thumbnail-column',
			render: (image: string) => (
				<div className={'flex justify-start align-center'} style={{ width: '150px' }}>
					<img src={image} height={40} alt={'img'} />
				</div>
			)
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			render: (value: string) => {
				return value || EMPTY_VALUE
			}
		},
		{
			title: i18next.t('Typ banneru'),
			dataIndex: 'bannerType',
			key: 'bannerType',
			ellipsis: true,
			render: (value: BANNER_TYPE) => {
				if (value[0] === BANNER_TYPE.ESHOP_SLIDER) return t('Eshop slider')
				return t('Banner')
			}
		},
		{
			title: i18next.t('Viditelný'),
			dataIndex: 'isVisible',
			key: 'isVisible',
			align: 'center',
			width: 120,
			render: (value: boolean) => <BooleanRepresent isTrue={!!value} />
		},
		{
			title: i18next.t('Poradie'),
			dataIndex: 'order',
			key: 'order',
			align: 'center',
			width: 60,
			render: (value: number) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: any) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:banners|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteBanner(record.key, () => {
									dispatch(getBanners())
									dispatch(setFilters(t('paths:banners|key')))
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							ghost: true
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getBanners(body))
		dispatch(setFilters(t('paths:banners|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: object[], sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-end'}>
				<Button icon={<PlusCircleOutlined />} href={t('paths:bannerCreate|path')} type={'primary'}>
					{t('Pridať banner')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				onChange={handleTableChange as any}
				dataSource={dataSource}
				style={{ marginTop: 20 }}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:banners|path')}/${get(record, 'key')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Banners
