import { Paths } from '../../types/api'

export type UserPayload = Paths.GetAdminUsersId.Responses.$200

export enum LOAD_USERS {
	START = 'LOAD_USERS_START',
	DONE = 'LOAD_USERS_DONE',
	FAILED = 'LOAD_USERS_FAILED'
}

export enum LOAD_DETAIL_USER {
	START = 'LOAD_DETAIL_USER_START',
	DONE = 'LOAD_DETAIL_USER_DONE',
	FAILED = 'LOAD_DETAIL_USER_FAILED'
}

export enum DELETE_USER {
	START = 'DELETE_USER_START',
	DONE = 'DELETE_USER_DONE',
	FAILED = 'DELETE_USER_FAILED'
}

export enum CREATE_USER {
	START = 'CREATE_USER_START',
	DONE = 'CREATE_USER_DONE',
	FAILED = 'CREATE_USER_FAILED'
}

export enum UPDATE_USER {
	START = 'UPDATE_USER_START',
	DONE = 'UPDATE_USER_DONE',
	FAILED = 'UPDATE_USER_FAILED'
}

export enum LOAD_USER_PROGRAMS {
	START = 'START_LOAD_USER_PROGRAMS',
	DONE = 'DONE_LOAD_USER_PROGRAMS',
	FAILED = 'FAILED_LOAD_USER_PROGRAMS'
}

export enum LOAD_USER_PROGRAM {
	START = 'START_LOAD_USER_PROGRAM',
	DONE = 'DONE_LOAD_USER_PROGRAM',
	FAILED = 'FAILED_LOAD_USER_PROGRAM'
}

export enum DELETE_USER_PROGRAM {
	START = 'START_DELETE_USER_PROGRAM',
	DONE = 'DONE_DELETE_USER_PROGRAM',
	FAILED = 'FAILED_DELETE_USER_PROGRAM'
}

export enum UPDATE_USER_PROGRAM {
	START = 'START_UPDATE_USER_PROGRAM',
	DONE = 'DONE_UPDATE_USER_PROGRAM',
	FAILED = 'FAILED_UPDATE_USER_PROGRAM'
}

export enum CREATE_USER_PROGRAM {
	START = 'START_CREATE_USER_PROGRAM',
	DONE = 'DONE_CREATE_USER_PROGRAM',
	FAILED = 'FAILED_CREATE_USER_PROGRAM'
}

export enum LOAD_USER_USER_AGENTS {
	START = 'START_LOAD_USER_USER_AGENTS',
	DONE = 'DONE_LOAD_USER_USER_AGENTS',
	FAILED = 'FAILED_LOAD_USER_USER_AGENTS'
}
