import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { get } from 'lodash'
import cx from 'classnames'
import i18next from 'i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'

import deleteIcon from '../assets/deleteIcon.svg'
import handleIcon from '../assets/handleIcon.svg'
// headers
import Header from '../customComponents/Header'

import deleteIconWhite from '../assets/deleteIconWhite.svg'
import handleIconWhite from '../assets/handleIconWhite.svg'
import { ITEM_TYPES } from '../enums/enums'
import EmbedElement from '../customComponents/EmbedElement'
import EmbedTable from '../customComponents/EmbedTable'
import YoutubeVideo from '../customComponents/YoutubeVideo'
import Image from '../customComponents/Image'
import RichTextEditor from '../customComponents/RichTextEditor'
import PreparationTime from '../customComponents/PreparationTime'
import Testimonial from '../customComponents/Testimonial'
import RecipeSteps from '../customComponents/RecipeSteps'
import ColoredBox from '../customComponents/ColoredBox'
import Ingredients from '../customComponents/Ingredients'
import Nutrition from '../customComponents/Nutrition'
import Button from '../customComponents/Button'
import UserProfile from '../customComponents/UserProfile'
import AsyncTransferRedirect from '../customComponents/AsyncTransferRedirect'
import HorizontalGap from '../customComponents/HorizontalGap'
import Reference from '../customComponents/Reference'
import Batch from '../customComponents/Batch'
import ProfileCard from '../customComponents/ProfileCard'
import Statistics from '../customComponents/Statistics'
import Anchor from '../customComponents/Anchor'

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	...draggableStyle
})

const Item = ({ item, index, onDeleteItem, itemContentSetter }) => {
	const [borderBoxState, setBorderBoxState] = useState(null)

	useEffect(() => {}, [borderBoxState])

	const renderItemContent = () => {
		switch (item.type) {
			case ITEM_TYPES.H1:
			case ITEM_TYPES.H2:
			case ITEM_TYPES.H3:
			case ITEM_TYPES.H4:
				return <Header item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.EMBED_ELEMENT:
				return <EmbedElement item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.TABLE:
				return <EmbedTable item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.YOUTUBE:
				return <YoutubeVideo item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.IMAGE:
				return <Image item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.TEXT:
				return <RichTextEditor item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.PREPARATION_TIME:
				return <PreparationTime item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.TESTIMONIAL:
				return <Testimonial item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.RECIPE_STEPS:
				return <RecipeSteps item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.COLORED_BOX:
				return <ColoredBox item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.NUTRITION:
				return <Nutrition item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.INGREDIENTS:
				return <Ingredients item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.BUTTON:
				return <Button item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.USER_PROFILE:
				return <UserProfile item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.PROFILE_CARD:
				return <ProfileCard item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.REDIRECT_ELEMENT:
				return <AsyncTransferRedirect redirectItem={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.HORIZONTAL_GAP:
				return <HorizontalGap item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.REFERENCE:
				return <Reference item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.BATCH:
				return <Batch item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.STATISTICS:
				return <Statistics item={item} itemContentSetter={itemContentSetter} />
			case ITEM_TYPES.ANCHOR:
				return <Anchor item={item} itemContentSetter={itemContentSetter} />
			default:
				return null
		}
	}

	return (
		<Draggable key={get(item, 'id')} draggableId={get(item, 'id')} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
					className={cx('item', `col-${get(item, 'width', 12)}`, { dragging: snapshot.isDragging })}
				>
					<div className={'row'}>
						<div className={'col-12 header'}>
							<div
								{...provided.dragHandleProps}
								className={'tab-button handle'}
								onMouseEnter={() => setBorderBoxState('handle')}
								onMouseLeave={() => setBorderBoxState(null)}
							>
								<div style={{ backgroundImage: `url(${borderBoxState === 'handle' ? handleIconWhite : handleIcon})` }} />
							</div>
							<Popconfirm
								title={i18next.t('Skutočne chcete vymazať komponent?')}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								cancelText={i18next.t('Zrušiť')}
								okText={i18next.t('Vymazať')}
								onConfirm={(e) => {
									e.stopPropagation()
									onDeleteItem(item)
								}}
								onCancel={(e) => e.stopPropagation()}
								okButtonProps={{
									size: 'small',
									type: 'danger'
								}}
								cancelButtonProps={{
									size: 'small',
									type: 'ghost'
								}}
							>
								<div
									className={'tab-button delete'}
									onMouseEnter={() => setBorderBoxState('delete')}
									onMouseLeave={() => setBorderBoxState(null)}
								>
									<div style={{ backgroundImage: `url(${borderBoxState === 'delete' ? deleteIconWhite : deleteIcon})` }} />
								</div>
							</Popconfirm>
							<div className={'tab-button name'}>{get(item, 'name')}</div>
						</div>
					</div>
					<div className={cx('border-box', { [borderBoxState]: borderBoxState })}>{renderItemContent()}</div>
				</div>
			)}
		</Draggable>
	)
}

Item.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		type: PropTypes.string,
		content: PropTypes.oneOfType(PropTypes.shape({}), PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.shape({})))),
		width: PropTypes.number
	}),
	index: PropTypes.number.isRequired,
	onDeleteItem: PropTypes.func.isRequired,
	itemContentSetter: PropTypes.func.isRequired
}

Item.defaultProps = {
	item: {
		id: null,
		name: null,
		content: null,
		width: 12
	}
}

export default Item
