/* eslint-disable import/no-import-module-exports */

import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './index'

function loggerFilter(state, action) {
	if (action.type.startsWith('persist')) {
		return false
	}
	if (action.type === '@@redux-form/REGISTER_FIELD') {
		return false
	}
	return action.type !== '@@redux-form/UNREGISTER_FIELD'
}

function configureStoreProd(initialState) {
	const middlewares = [
		// Add other middleware on this line...
		thunk
	]

	const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)
	return { store, persistor }
}

function configureStoreDev(initialState) {
	const logger = createLogger({
		collapsed: true,
		duration: true,
		predicate: loggerFilter
	})

	const middlewares = [thunk, logger, reduxImmutableStateInvariant()]
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
	const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./', () => {
			// eslint-disable-next-line
			const nextReducer = require('./').default
			store.replaceReducer(nextReducer)
		})
	}
	return { store, persistor }
}

const configureStore = window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore
