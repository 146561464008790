import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { Button, Empty, Popconfirm, Table } from 'antd'
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteDownloadCategory, getDownloadCategories } from '../../redux/downloadCategories/actions'

// helpers
import { setFiltersForPage } from '../../utils/helpers'

// utils
import { EMPTY_VALUE } from '../../utils/enums'

// atoms
import OrderRepresent from '../../atoms/OrderRepresent'

const PAGE_SIZE = 20

const DownloadCategories = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.downloadCategories?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.downloadCategories?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.downloadCategories?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.downloadCategories?.list?.context)

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:downloadCategories|key'), persistFilter))
	const columns = [
		{
			title: t('Stiahnutia'),
			key: 'downloadsCount',
			dataIndex: 'downloadsCount',
			width: 70,
			align: 'center',
			ellipsis: true,
			render: (value: string) => <OrderRepresent views value={value || 0} tooltip={t('Stiahnutia')} />
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: '',
			key: 'operation',
			width: 50,
			render: (text: string, record: any) => (
				<Popconfirm
					title={t('Skutočne chcete vymazať kategóriu?')}
					placement={'topRight'}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					cancelText={t('Zrušiť')}
					okText={t('Vymazať')}
					onConfirm={(e: any) => {
						e.stopPropagation()
						dispatch(
							deleteDownloadCategory(record.key, () => {
								const body = {
									limit: PAGE_SIZE,
									page: 1,
									...filter
								}

								dispatch(getDownloadCategories(body))
							})
						)
					}}
					okButtonProps={{
						size: 'small',
						type: 'primary',
						danger: true
					}}
					cancelButtonProps={{
						size: 'small',
						type: 'ghost'
					}}
				>
					<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e: any) => e.stopPropagation()} />
				</Popconfirm>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getDownloadCategories(body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any) => {
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-end'}>
				<Button icon={<PlusCircleOutlined />} href={t('paths:downloadCategoryCreate|path')} type={'primary'}>
					{t('Pridať kategóriu')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={dataSource}
				onChange={handleTableChange}
				style={{ marginTop: 20 }}
				scroll={{ x: 'max-content' }}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: context?.totalCount,
					current: context?.page,
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:downloadCategoryDetail|path')}/${record?.key}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
			/>
		</div>
	)
}

export default DownloadCategories
