import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { emailRegEx } from '../../../utils/regex'

export type DonationFormValues = {
	activationEmail: string
	isUsed: boolean
	paypalData: string
}

export default (values: DonationFormValues) => {
	const errors: FormErrors<DonationFormValues> = {}

	if (!values.activationEmail || (values.activationEmail && !emailRegEx.test(values.activationEmail))) {
		errors.activationEmail = i18next.t('Email nie je platný')
	}

	return errors
}
