import { useEffect, useState } from 'react'
import { map, remove, find, each, get, forEach, every } from 'lodash'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DragDropContext } from 'react-beautiful-dnd'
import Grid from './grid/Grid'
import './styles/grid.min.css'
import './styles/articleBuilder.sass'
import { ITEM_TYPES } from './enums/enums'
import { HORIZONTAL_GAP_TYPE } from './customComponents/HorizontalGap'
import { getRichTextEditorContent } from './customComponents/richTextEditorComponents'

const Area = ({ data, onChange }) => {
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		if (data) {
			onChange(data)
		} else {
			onChange({
				items: {},
				itemsOrder: []
			})
		}
	}, [data])

	const handleDragEnd = (result) => {
		const { destination, source, draggableId, type } = result

		if (!destination) {
			return
		}

		if (source.droppableId.indexOf('|') >= 0) {
			source.droppableIndex = Number(source.droppableId.substring(source.droppableId.lastIndexOf('|') + 1))
			source.droppableId = source.droppableId.substring(0, source.droppableId.indexOf('|'))
			destination.droppableIndex = Number(destination.droppableId.substring(destination.droppableId.lastIndexOf('|') + 1))
			destination.droppableId = destination.droppableId.substring(0, destination.droppableId.indexOf('|'))
		}

		const sourceItem = get(data, `items[${source.droppableId}]`)
		const destinationItem = get(data, `items[${destination.droppableId}]`)

		if (get(sourceItem, 'type') === ITEM_TYPES.FAQ || get(destinationItem, 'type') === ITEM_TYPES.FAQ) {
			if (get(sourceItem, 'type') === ITEM_TYPES.FAQ && get(destinationItem, 'type') === ITEM_TYPES.FAQ) {
				if (destination.droppableId === source.droppableId) {
					if (destination.droppableIndex === source.droppableIndex) {
						if (destination.index === source.index) {
							return
						}

						let newItemIds = []

						newItemIds = get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}].itemsOrder`)

						newItemIds.splice(source.index, 1)
						newItemIds.splice(destination.index, 0, draggableId)

						const newQuestions = [
							...get(data, `items[${destination.droppableId}].content.questions`).slice(0, destination.droppableIndex),
							{
								...get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}]`),
								itemsOrder: newItemIds
							},
							...get(data, `items[${destination.droppableId}].content.questions`).slice(destination.droppableIndex + 1)
						]

						onChange({
							...data,
							items: {
								...get(data, 'items', {}),
								[destination.droppableId]: {
									...get(data, `items[${destination.droppableId}]`),
									content: {
										...get(data, `items[${destination.droppableId}].content`),
										questions: newQuestions
									}
								}
							}
						})
					} else {
						const newSourceContent = []
						const newSourceItemsOrder = get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}].itemsOrder`)
						let newDestinationContent = get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}].content`)
						const newDestinationItemsOrder = get(
							data,
							`items[${destination.droppableId}].content.questions[${destination.droppableIndex}].itemsOrder`
						)

						forEach(get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}].content`, []), (item) => {
							if (item !== draggableId) {
								newSourceContent.push(item)
							}
						})
						newSourceItemsOrder.splice(source.index, 1)

						newDestinationContent = [...newDestinationContent, draggableId]
						newDestinationItemsOrder.splice(destination.index, 0, draggableId)

						const newQuestions = get(data, `items[${source.droppableId}].content.questions`)
						newQuestions.splice(source.droppableIndex, 1, {
							...get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}]`),
							content: newSourceContent,
							itemsOrder: newSourceItemsOrder
						})
						newQuestions.splice(destination.droppableIndex, 1, {
							...get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}]`),
							content: newDestinationContent,
							itemsOrder: newDestinationItemsOrder
						})

						onChange({
							...data,
							items: {
								...get(data, 'items', {}),
								[destination.droppableId]: {
									...get(data, `items[${destination.droppableId}]`),
									content: {
										...get(data, `items[${destination.droppableId}].content`),
										questions: newQuestions
									}
								}
							}
						})
					}

					return
				}
				if (destination.droppableId !== source.droppableId) {
					if (destination.droppableId === draggableId) {
						return
					}

					const newSource = {
						...get(data, `items[${source.droppableId}]`),
						content: {
							...get(data, `items[${source.droppableId}].content`),
							questions: [
								...get(data, `items[${source.droppableId}].content.questions`).slice(0, source.droppableIndex),
								{
									...get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}]`),
									content: remove(
										get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}].content`),
										(item) => item !== draggableId
									),
									itemsOrder: remove(
										get(data, `items[${source.droppableId}].content.questions[${source.droppableIndex}].itemsOrder`),
										(item) => item !== draggableId
									)
								},
								...get(data, `items[${source.droppableId}].content.questions`).slice(source.droppableIndex + 1)
							]
						}
					}

					const newDestination = {
						...get(data, `items[${destination.droppableId}]`),
						content: {
							...get(data, `items[${destination.droppableId}].content`),
							questions: [
								...get(data, `items[${destination.droppableId}].content.questions`).slice(0, destination.droppableIndex),
								{
									...get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}]`),
									content: [
										get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}].content`),
										draggableId
									],
									itemsOrder: [
										...get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}].itemsOrder`).slice(
											0,
											destination.index
										),
										draggableId,
										...get(data, `items[${destination.droppableId}].content.questions[${destination.droppableIndex}].itemsOrder`).slice(
											destination.index
										)
									]
								},
								...get(data, `items[${destination.droppableId}].content.questions`).slice(destination.droppableIndex + 1)
							]
						}
					}

					onChange({
						...data,
						items: {
							...get(data, 'items'),
							[source.droppableId]: newSource,
							[destination.droppableId]: newDestination
						}
					})
				}
				return
			}
			return
		}

		if (destination.droppableId === source.droppableId) {
			if (destination.index === source.index) {
				return
			}

			let newItemIds = []

			if (type === 'droppableItem') {
				newItemIds = Array.from(get(data, 'itemsOrder'))
				newItemIds.splice(source.index, 1)
				newItemIds.splice(destination.index, 0, draggableId)

				onChange({
					...data,
					itemsOrder: newItemIds
				})
			} else {
				newItemIds = Array.from(get(data, `items[${destination.droppableId}].itemsOrder`))

				newItemIds.splice(source.index, 1)
				newItemIds.splice(destination.index, 0, draggableId)

				// newItemIds = uniq(newItemIds)

				onChange({
					...data,
					items: {
						...get(data, 'items', {}),
						[destination.droppableId]: {
							...get(data, `items[${destination.droppableId}]`),
							itemsOrder: newItemIds
						}
					}
				})
			}

			return
		}

		if (destination.droppableId !== source.droppableId) {
			if (destination.droppableId === draggableId) {
				return
			}

			const newSource = {
				...get(data, `items[${source.droppableId}]`),
				content: remove(get(data, `items[${source.droppableId}].content`), (item) => item !== draggableId),
				itemsOrder: remove(get(data, `items[${source.droppableId}].itemsOrder`), (item) => item !== draggableId)
			}

			const newDestination = {
				...get(data, `items[${destination.droppableId}]`),
				content: [...get(data, `items[${destination.droppableId}].content`), draggableId],
				itemsOrder: [
					...get(data, `items[${destination.droppableId}].itemsOrder`).slice(0, destination.index),
					draggableId,
					...get(data, `items[${destination.droppableId}].itemsOrder`).slice(destination.index)
				]
			}

			onChange({
				...data,
				items: {
					...get(data, 'items'),
					[source.droppableId]: newSource,
					[destination.droppableId]: {
						...newDestination
					}
				}
			})
		}
	}

	const getGridItemName = (width) => {
		switch (width) {
			case 12:
				return '1'
			case 6:
				return '1/2'
			case 4:
				return '1/3'
			case 3:
				return '1/4'
			default:
				return width
		}
	}

	const handleDeleteItem = (listItem) => {
		const newItems = {}
		let excludeIDs = []
		switch (get(listItem, 'type')) {
			case ITEM_TYPES.GRID:
				excludeIDs = [...listItem.content]
				break
			case ITEM_TYPES.FAQ:
				forEach(listItem.content.questions, (question) => {
					excludeIDs.push(...question.content)
				})
				break
		}

		each(data.items, (item) => {
			if (listItem.id === item.id) {
				return
			}

			let newItem = item

			switch (true) {
				case get(item, 'type') === ITEM_TYPES.GRID: {
					const founded = find(item.content, (innerItem) => innerItem === listItem.id)

					if (founded) {
						const newContent = []
						const newItemsOrder = []

						each(item.content, (innerItemId) => {
							if (innerItemId !== listItem.id) {
								newContent.push(innerItemId)
							}
						})

						each(item.itemsOrder, (innerItemId) => {
							if (innerItemId !== listItem.id) {
								newItemsOrder.push(innerItemId)
							}
						})

						newItem = {
							...item,
							content: newContent,
							itemsOrder: newItemsOrder
						}
					}
					break
				}
				case get(item, 'type') === ITEM_TYPES.FAQ: {
					let founded = null
					let questionIndex = -1
					every(item.content.questions, (question, index) => {
						founded = find(question.content, (innerItem) => innerItem === listItem.id)
						questionIndex = index
						return !founded
					})

					if (founded) {
						const newContent = []
						const newItemsOrder = []

						each(item.content.questions[questionIndex].content, (innerItemId) => {
							if (innerItemId !== listItem.id) {
								newContent.push(innerItemId)
							}
						})

						each(item.content.questions[questionIndex].itemsOrder, (innerItemId) => {
							if (innerItemId !== listItem.id) {
								newItemsOrder.push(innerItemId)
							}
						})

						newItem = {
							...item,
							content: {
								...item.content,
								questions: [
									...item.content.questions.slice(0, questionIndex),
									{
										...item.content.questions[questionIndex],
										content: newContent,
										itemsOrder: newItemsOrder
									},
									...item.content.questions.slice(questionIndex + 1)
								]
							}
						}
					}
					break
				}
				default:
					break
			}

			if (!excludeIDs.includes(item.id)) {
				newItems[item.id] = newItem
			}
		})

		const itemOrder = []

		each(data.itemsOrder, (itemId) => {
			if (itemId !== listItem.id) {
				itemOrder.push(itemId)
			}
		})

		onChange({
			...data,
			items: newItems,
			itemsOrder: itemOrder
		})
	}

	const addMainGridItem = (width) => {
		const id = Date.now()

		onChange({
			...data,
			items: {
				...get(data, 'items'),
				[`item-${id}`]: {
					id: `item-${id}`,
					name: getGridItemName(width),
					type: ITEM_TYPES.GRID,
					content: [],
					itemsOrder: [],
					width,
					center: false
				}
			},
			itemsOrder: [...get(data, 'itemsOrder', []), `item-${id}`]
		})

		setShowModal(false)
	}

	const addInnerGridItem = (width, listItem) => {
		const id = Date.now()

		onChange({
			...data,
			items: {
				...data.items,
				[listItem.id]: {
					...data.items[listItem.id],
					content: [...data.items[listItem.id].content, `item-${id}`],
					itemsOrder: [...data.items[listItem.id].itemsOrder, `item-${id}`]
				},
				[`item-${id}`]: {
					id: `item-${id}`,
					name: getGridItemName(width),
					type: ITEM_TYPES.GRID,
					content: [],
					itemsOrder: [],
					width,
					center: false
				}
			}
		})

		setShowModal(false)
	}

	const processedAddGridItem = (width, listItem) => {
		if (listItem !== true) {
			addInnerGridItem(width, listItem)
		} else {
			addMainGridItem(width)
		}
	}

	const getItemByType = (id, itemType) => {
		switch (itemType) {
			case ITEM_TYPES.H1:
				return {
					id: `item-${id}`,
					name: t('Nadpis H1'),
					type: itemType,
					content: { text: '', center: false },
					width: 12
				}
			case ITEM_TYPES.H2:
				return {
					id: `item-${id}`,
					name: t('Nadpis H2'),
					type: itemType,
					content: { text: '', center: false },
					width: 12
				}
			case ITEM_TYPES.H3:
				return {
					id: `item-${id}`,
					name: t('Nadpis H3'),
					type: itemType,
					content: { text: '', center: false },
					width: 12
				}
			case ITEM_TYPES.H4:
				return {
					id: `item-${id}`,
					name: t('Nadpis H4'),
					type: itemType,
					content: { text: '', center: false },
					width: 12
				}
			case ITEM_TYPES.EMBED_ELEMENT:
				return {
					id: `item-${id}`,
					name: t('Embed príspevok'),
					type: itemType,
					content: '',
					width: 12
				}
			case ITEM_TYPES.YOUTUBE:
				return {
					id: `item-${id}`,
					name: t('Youtube video'),
					type: itemType,
					content: {
						src: '',
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.TABLE:
				return {
					id: `item-${id}`,
					name: t('Tabuľka'),
					type: itemType,
					content: '',
					width: 12
				}
			case ITEM_TYPES.IMAGE:
				return {
					id: `item-${id}`,
					name: t('Obrázok'),
					type: itemType,
					content: {
						url: '',
						title: '',
						alt: '',
						caption: '',
						link: '',
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.TEXT:
				return {
					id: `item-${id}`,
					name: t('Text'),
					type: itemType,
					content: {
						...getRichTextEditorContent(),
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.REFERENCE:
				return {
					id: `item-${id}`,
					name: t('Referencia'),
					type: itemType,
					content: {
						name: '',
						city: '',
						text: {
							...getRichTextEditorContent(),
							center: false
						},
						weightLoss: null,
						profilePhoto: '',
						photoLibrary: []
					},
					width: 12
				}
			case ITEM_TYPES.FAQ:
				return {
					id: `item-${id}`,
					name: t('FAQ'),
					type: itemType,
					content: {
						captureTitle: '',
						questions: [
							{
								title: '',
								content: [],
								itemsOrder: []
							}
						]
					},
					width: 12
				}
			case ITEM_TYPES.PREPARATION_TIME:
				return {
					id: `item-${id}`,
					name: t('Čas prípravy'),
					type: itemType,
					content: [
						{
							key: 0,
							time: '',
							label: ''
						}
					],
					width: 12
				}
			case ITEM_TYPES.TESTIMONIAL:
				return {
					id: `item-${id}`,
					name: t('Citácia'),
					type: itemType,
					content: {
						text: ''
					},
					width: 12
				}
			case ITEM_TYPES.COLORED_BOX:
				return {
					id: `item-${id}`,
					name: t('Farebný blok'),
					type: itemType,
					content: {
						value: '',
						color: '#FEA385',
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.BATCH:
				return {
					id: `item-${id}`,
					name: t('Nálepky'),
					type: itemType,
					content: {
						list: [
							{
								key: 0,
								text: '',
								color: '#FEA385'
							}
						],
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.RECIPE_STEPS:
				return {
					id: `item-${id}`,
					name: t('Postupnosť krokov'),
					type: itemType,
					content: {
						title: '',
						items: [
							{
								key: 0,
								step: 0,
								value: ''
							}
						]
					},
					width: 12
				}
			case ITEM_TYPES.INGREDIENTS:
				return {
					id: `item-${id}`,
					name: t('Ingrediencie'),
					type: itemType,
					content: [
						{
							key: 0,
							name: ''
						}
					],
					width: 12
				}
			case ITEM_TYPES.NUTRITION:
				return {
					id: `item-${id}`,
					name: t('Výživové hodnoty'),
					type: itemType,
					content: [
						{
							key: 7,
							type: 'recipeNutritionalInfoFor',
							value: 'onePortion',
							input: 'dropdown'
						},
						{
							key: 8,
							type: 'recipeNutritionalNote',
							value: '',
							inputType: 'text',
							placeholder: t('Bližšia špecifikácia porcie/ks')
						},
						{ key: 0, type: 'recipeNutritionalCarbs', value: '' },
						{ key: 1, type: 'recipeNutritionalFats', value: '' },
						{ key: 2, type: 'recipeNutritionalFiber', value: '' },
						{ key: 3, type: 'recipeNutritionalGlycemic', value: '' },
						{ key: 4, type: 'recipeNutritionalKcal', value: '' },
						{ key: 5, type: 'recipeNutritionalKj', value: '' },
						{ key: 6, type: 'recipeNutritionalProteins', value: '' }
					],
					width: 12
				}
			case ITEM_TYPES.BUTTON:
				return {
					id: `item-${id}`,
					name: t('Tlačidlo'),
					type: itemType,
					content: {
						text: '',
						link: '',
						newPage: false,
						center: false
					},
					width: 12
				}
			case ITEM_TYPES.USER_PROFILE:
				return {
					id: `item-${id}`,
					name: t('Profil'),
					type: itemType,
					content: {
						image: '',
						name: '',
						description: ''
					},
					width: 12
				}
			case ITEM_TYPES.PROFILE_CARD:
				return {
					id: `item-${id}`,
					name: t('Profilová karta'),
					type: itemType,
					content: {
						image: '',
						name: '',
						briefDescription: '',
						description: ''
					},
					width: 12
				}
			case ITEM_TYPES.REDIRECT_ELEMENT:
				return {
					id: `item-${id}`,
					name: t('Presmerovanie'),
					type: itemType,
					content: [],
					width: 12
				}
			case ITEM_TYPES.HORIZONTAL_GAP:
				return {
					id: `item-${id}`,
					name: t('Horizontálne odsadenie'),
					type: itemType,
					content: {
						type: HORIZONTAL_GAP_TYPE.LARGE
					},
					width: 12
				}
			case ITEM_TYPES.STATISTICS:
				return {
					id: `item-${id}`,
					name: t('Štatistika'),
					type: itemType,
					content: {
						image: '',
						value: '',
						text: ''
					},
					width: 12
				}
			case ITEM_TYPES.ANCHOR:
				return {
					id: `item-${id}`,
					name: t('Kotva'),
					type: itemType,
					content: {
						urlName: '',
						urlHashTag: ''
					},
					width: 12
				}
			default:
				return itemType
		}
	}

	const addMainItem = (itemType) => {
		const id = Date.now()

		onChange({
			...data,
			items: {
				...get(data, 'items'),
				[`item-${id}`]: getItemByType(id, itemType)
			},
			itemsOrder: [...get(data, 'itemsOrder', []), `item-${id}`]
		})

		setShowModal(false)
	}

	const addInnerItem = (itemType, listItem) => {
		const id = Date.now()

		onChange({
			...data,
			items: {
				...data.items,
				[listItem.id]: {
					...data.items[listItem.id],
					content: [...data.items[listItem.id].content, `item-${id}`],
					itemsOrder: [...data.items[listItem.id].itemsOrder, `item-${id}`]
				},
				[`item-${id}`]: getItemByType(id, itemType)
			}
		})

		setShowModal(false)
	}

	const processedAddItem = (itemType, innerItem) => {
		if (get(innerItem, 'item.type') === ITEM_TYPES.FAQ) {
			const id = Date.now()

			onChange({
				...data,
				items: {
					...data.items,
					[innerItem.item.id]: {
						...data.items[innerItem.item.id],
						content: {
							...data.items[innerItem.item.id].content,
							questions: Object.values({
								...data.items[innerItem.item.id].content.questions,
								[innerItem.questionIndex]: {
									title: data.items[innerItem.item.id].content.questions[innerItem.questionIndex].title,
									content: [...data.items[innerItem.item.id].content.questions[innerItem.questionIndex].content, `item-${id}`],
									itemsOrder: [...data.items[innerItem.item.id].content.questions[innerItem.questionIndex].itemsOrder, `item-${id}`]
								}
							})
						}
					},
					[`item-${id}`]: getItemByType(id, itemType)
				}
			})

			setShowModal(false)
			return
		}

		if (innerItem !== true) {
			addInnerItem(itemType, innerItem)
		} else {
			addMainItem(itemType)
		}
	}

	const itemsSetter = (items) => {
		onChange({
			...data,
			items
		})
	}

	const itemSetter = (itemID, item) => {
		onChange({
			...data,
			items: {
				...data.items,
				[itemID]: item
			}
		})
	}

	const itemContentSetter = (itemID, content) => {
		onChange({
			...data,
			items: {
				...data.items,
				[itemID]: {
					...data.items[itemID],
					content
				}
			}
		})
	}

	return (
		<div className={'_gr_article_builder'}>
			<div className={'area'}>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Grid
						items={map(get(data, 'itemsOrder', []), (itemId) => data.items[itemId])}
						allItems={map(get(data, 'items', {}), (item) => item)}
						onDeleteItem={handleDeleteItem}
						onAddItem={(listItem) => setShowModal(listItem)}
						itemsSetter={itemsSetter}
						itemSetter={itemSetter}
						itemContentSetter={itemContentSetter}
					/>
				</DragDropContext>
				<div className={'add-component-container'}>
					<div className={'add-component-button'} onClick={() => setShowModal(true)}>
						{'Pridať komponent'}
					</div>
				</div>
			</div>
			<Modal
				visible={!!showModal}
				footer={null}
				onCancel={() => setShowModal(false)}
				closable={false}
				className={'_gr_article_builder-modal'}
				width={800}
			>
				<div className={'container'}>
					<div className={'justify row'}>
						{showModal === true && (
							<>
								<div className={'component-button col-3'} onClick={() => processedAddGridItem(12, showModal)}>
									{t('Pridať 1')}
								</div>
								<div className={'component-button col-3'} onClick={() => processedAddGridItem(6, showModal)}>
									{t('Pridať 1/2')}
								</div>
								<div className={'component-button col-3'} onClick={() => processedAddGridItem(4, showModal)}>
									{t('Pridať 1/3')}
								</div>
								<div className={'component-button col-3'} onClick={() => processedAddGridItem(3, showModal)}>
									{t('Pridať 1/4')}
								</div>
							</>
						)}
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.H1, showModal)}>
							{t('Nadpis H1')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.H2, showModal)}>
							{t('Nadpis H2')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.H3, showModal)}>
							{t('Nadpis H3')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.H4, showModal)}>
							{t('Nadpis H4')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.IMAGE, showModal)}>
							{t('Obrázok')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.TEXT, showModal)}>
							{t('Text')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.TESTIMONIAL, showModal)}>
							{t('Citácia')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.COLORED_BOX, showModal)}>
							{t('Farebný blok')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.RECIPE_STEPS, showModal)}>
							{t('Postupnosť krokov')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.NUTRITION, showModal)}>
							{t('Výživové hodnoty')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.INGREDIENTS, showModal)}>
							{t('Ingrediencie')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.BUTTON, showModal)}>
							{t('Tlačidlo')}
						</div>
						{showModal === true && (
							<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.REDIRECT_ELEMENT, showModal)}>
								{t('Presmerovanie')}
							</div>
						)}
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.EMBED_ELEMENT, showModal)}>
							{t('Embed príspevok')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.TABLE, showModal)}>
							{t('Tabuľka')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.PREPARATION_TIME, showModal)}>
							{t('Čas prípravy')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.USER_PROFILE, showModal)}>
							{t('Profil')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.HORIZONTAL_GAP, showModal)}>
							{t('Horizontálne odsadenie')}
						</div>
						{showModal === true && (
							<>
								<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.REFERENCE, showModal)}>
									{t('Referencia')}
								</div>
								<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.FAQ, showModal)}>
									{t('FAQ')}
								</div>
							</>
						)}
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.BATCH, showModal)}>
							{t('Nálepky')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.YOUTUBE, showModal)}>
							{t('Youtube video')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.PROFILE_CARD, showModal)}>
							{t('Profilová karta')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.STATISTICS, showModal)}>
							{t('Štatistika')}
						</div>
						<div className={'component-button col-3'} onClick={() => processedAddItem(ITEM_TYPES.ANCHOR, showModal)}>
							{t('Kotva')}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

Area.propTypes = {
	data: PropTypes.shape({
		items: PropTypes.shape({}),
		itemsOrder: PropTypes.arrayOf(PropTypes.string)
	}),
	onChange: PropTypes.func.isRequired
}

Area.defaultProps = {
	data: {
		items: {},
		itemsOrder: []
	}
}

export default Area
