import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES } from '../../../utils/enums'

export type TestimonialFormValues = {
	id: number
	image: string
	customerName: string
	customerWeightLoss?: number
	order: number
	title_sk: string
	description_sk: string
	customerDescription_sk: string
	buttonUrl_sk: string
	buttonText_sk: string
	title_cz: string
	description_cz: string
	customerDescription_cz: string
	buttonUrl_cz: string
	buttonText_cz: string
	title_en: string
	description_en: string
	customerDescription_en: string
	buttonUrl_en: string
	buttonText_en: string
}

export default (values: TestimonialFormValues) => {
	const errors: FormErrors<TestimonialFormValues> = {}

	if (!values.title_sk) {
		errors.title_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if (!values.customerName) {
		errors.customerName = i18next.t('Toto pole je povinné')
	}

	if (!values.order) {
		errors.order = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 0) {
		errors.order = i18next.t('Poradie nemôže byť záporné číslo')
	}

	each(LANGUAGES, (item) => {
		const title = get(values, `title_${item}`)
		const description = get(values, `description_${item}`)
		const customerDescription = get(values, `customerDescription_${item}`)
		const buttonUrl = get(values, `buttonUrl_${item}`)
		const buttonText = get(values, `buttonText_${item}`)

		if (title || description || customerDescription || buttonUrl || buttonText) {
			if (!values[`title_${item}`]) {
				errors[`title_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`description_${item}`]) {
				errors[`description_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`customerDescription_${item}`]) {
				errors[`customerDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`buttonUrl_${item}`]) {
				errors[`buttonUrl_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`buttonText_${item}`]) {
				errors[`buttonText_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	return errors
}
