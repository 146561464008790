import { useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { Tabs } from 'antd'
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS, UPLOAD_CATEGORY, LANGUAGES, LANGUAGE } from '../../../utils/enums'
import { getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validateTestimonialForm, { TestimonialFormValues } from './validateTestimonialForm'

// atoms
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type TestimonialFormProps = {
	onSubmit: (values: TestimonialFormValues) => void
}

const TestimonialForm = ({ handleSubmit, onSubmit }: TestimonialFormProps & InjectedFormProps<TestimonialFormValues, TestimonialFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.LANDING_TESTIMONIAL_FORM)(state)) as TestimonialFormValues

	// states
	const [image, setImage] = useState({
		isLoading: false,
		imageUrl: ''
	})

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.LANDING_TESTIMONIAL_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				name={'image'}
				imageUrl={get(fieldValues, 'image')}
				component={UploadInputField}
				label={t('Titulný obrázok (upload)')}
				customHelper={t('recommendedSize|homepageTestimonial')}
				customRequest={uploadImage}
				isLoading={image.isLoading}
				required
			/>
			<Field
				name={'customerName'}
				label={t('Zákazník')}
				type={'text'}
				placeholder={t('Zákazník')}
				component={TextField}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
				required
			/>
			<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
				{map(LANGUAGES, (item) => (
					<TabPane tab={getLanguageName(item)} key={item}>
						<Field
							name={`title_${item}`}
							label={t('Nadpis')}
							type={'text'}
							placeholder={t('Nadpis')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`description_${item}`}
							label={t('Popis')}
							type={'text'}
							placeholder={t('Popis')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`customerDescription_${item}`}
							label={t('Popis zákazníka')}
							type={'text'}
							placeholder={t('Popis zákazníka')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`buttonUrl_${item}`}
							label={t('Odkaz tlačidla')}
							type={'text'}
							placeholder={t('Odkaz tlačidla')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`buttonText_${item}`}
							label={t('Text tlačidla')}
							type={'text'}
							placeholder={t('Text tlačidla')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
					</TabPane>
				))}
			</Tabs>
		</form>
	)
}

export default reduxForm<TestimonialFormValues, TestimonialFormProps>({
	form: FORMS.LANDING_TESTIMONIAL_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateTestimonialForm
})(TestimonialForm)
