import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import type { PaginationProps } from 'antd'
import { Col, Row, Table, DatePicker, Form, Empty, Spin, Button, Modal, Tooltip } from 'antd'
import { get, map, toNumber } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { InfoCircleOutlined } from '@ant-design/icons'
import { getPaymentsStatistics } from '../../../redux/dashboard/actions'

// utils
import { formatAcquirerResponseId, formatPaymentMethod } from '../../../utils/helpers'

// enums
import { EMPTY_VALUE, STATIC_DATES } from '../../../utils/enums'

// styles
import { StyledTable, PaymentsTableCell } from '../DashboardStyles'

// icons & images
import { ReactComponent as CzechiaFlagIcon } from '../../../assets/images/icons/flag-czechia.svg'
import { ReactComponent as SlovakiaFlagIcon } from '../../../assets/images/icons/flag-slovakia.svg'
import { RootState } from '../../../redux'

const { RangePicker } = DatePicker

interface IProgramsTableData {
	page?: number
	limit?: number
	dateFrom?: any
	dateTo?: any
}

interface ITableCellValues {
	EUR: {
		count: number
		value: number
	}
	CZK: {
		count: number
		value: number
	}
}

interface IPaymentType {
	errors: any
	executedOnetime: ITableCellValues
	executedRecurring: ITableCellValues
	executedTotal: ITableCellValues
	failed: ITableCellValues
	name: string
	planned: ITableCellValues
}

const DashboardPayments = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const RELEASE_DATE = dayjs(window.__RUNTIME_CONFIG__.REACT_APP_RELEASE_DATE_ENV)

	// selectors
	const paymentsStatistics = useSelector((state: RootState) => state.dashboard?.paymentsStatistic?.data)
	const { context } = paymentsStatistics
	const paymentsStatisticsLoading = useSelector((state) => get(state, 'dashboard.paymentsStatistic.isLoading'))

	// states
	const [paymentsTableData, setPaymentsTableData] = useState<IProgramsTableData>({
		page: 1,
		limit: 50,
		dateFrom: STATIC_DATES.START_OF_MONTH.isBefore(RELEASE_DATE) ? RELEASE_DATE : STATIC_DATES.START_OF_MONTH,
		dateTo: STATIC_DATES.END_OF_MONTH
	})

	useEffect(() => {
		// send request only if date from is before or same date as dateTo
		if (dayjs(paymentsTableData?.dateFrom).isBefore(dayjs(paymentsTableData?.dateTo).endOf('day'))) {
			dispatch(
				getPaymentsStatistics({
					limit: paymentsTableData?.limit,
					page: paymentsTableData?.page,
					dateFrom: dayjs(paymentsTableData?.dateFrom).format('YYYY-MM-DD'),
					dateTo: dayjs(paymentsTableData?.dateTo).format('YYYY-MM-DD')
				})
			)
		}
	}, [dispatch, paymentsTableData])

	const disabledRange = (current: any) => {
		const { dateFrom, dateTo } = paymentsTableData
		const tooEarly = dateTo && dateTo.diff(current, 'months') > 11
		const tooLate = dateFrom && current.diff(dateFrom, 'months') > 11
		const beforeRelease = dayjs(current).isBefore(RELEASE_DATE.startOf('day'))
		// && dayjs(current).isBefore(END_OF_MONTH.endOf('day'))

		return !(!tooEarly && !tooLate && !beforeRelease)
	}

	const getValue = (value: number) => (value === 0 ? value : (value / 100).toFixed(2))

	const getPercentage = (value: number) => toNumber((value * 100).toFixed(2))

	const parseTotalStatisticsInRange = () => [
		{
			date: null,
			...paymentsStatistics.totalStatistics
		}
	]

	const handleTableChange = (pagination: PaginationProps) => {
		setPaymentsTableData({
			...paymentsTableData,
			limit: pagination?.pageSize,
			page: pagination?.current
		})
	}

	const columns = [
		{
			title: t('Dátum'),
			dataIndex: 'date',
			key: 'date',
			ellipsis: true,
			render: (date: string) => (date ? dayjs(date).format('DD.MM.YYYY') : t('Spolu za obdobie'))
		},
		{
			key: 'country',
			width: 40,
			render: () => (
				<PaymentsTableCell>
					<div className={'flag-wrapper'}>
						<SlovakiaFlagIcon />
					</div>
					<div className={'flag-wrapper'}>
						<CzechiaFlagIcon />
					</div>
				</PaymentsTableCell>
			)
		},
		{
			title: t('Naplánované'),
			dataIndex: ['total', 'planned'],
			key: 'planned',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			title: t('Vykonané opakujúce'),
			dataIndex: ['total', 'executedRecurring'],
			key: 'executedRecurring',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			title: t('Vykonané jednorazové'),
			dataIndex: ['total', 'executedOnetime'],
			key: 'executedOnetime',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			title: t('Vykonané spolu'),
			dataIndex: ['total', 'executedTotal'],
			key: 'executedTotal',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			title: t('Percento neúspešných spolu'),
			dataIndex: ['total', 'failed'],
			key: 'failed',
			width: 100,
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span className={'percentage'}>{getPercentage(values?.EUR?.value)}%</span>
					</div>
					<div className={'column'}>
						<span className={'percentage'}>{getPercentage(values?.CZK?.value)}%</span>
					</div>
				</PaymentsTableCell>
			)
		}
	]

	const expandedRowColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (paymentMethod: string) => (paymentMethod ? formatPaymentMethod(paymentMethod) : EMPTY_VALUE)
		},
		{
			key: 'country',
			width: 40,
			render: () => (
				<PaymentsTableCell>
					<div className={'flag-wrapper'}>
						<SlovakiaFlagIcon />
					</div>
					<div className={'flag-wrapper'}>
						<CzechiaFlagIcon />
					</div>
				</PaymentsTableCell>
			)
		},
		{
			dataIndex: 'planned',
			key: 'planned',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<Row align={'middle'} gutter={12}>
						<Col className={'column'}>
							<span>{values?.EUR?.count}x</span>
							<span>{getValue(values?.EUR?.value)} €</span>
						</Col>
					</Row>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			dataIndex: 'executedRecurring',
			key: 'executedRecurring',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			dataIndex: 'executedOnetime',
			key: 'executedOnetime',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			dataIndex: 'executedTotal',
			key: 'executedTotal',
			render: (values: ITableCellValues) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<span>{values?.EUR?.count}x</span>
						<span>{getValue(values?.EUR?.value)} €</span>
					</div>
					<div className={'column'}>
						<span>{values?.CZK?.count}x</span>
						<span>{getValue(values?.CZK?.value)} Kč</span>
					</div>
				</PaymentsTableCell>
			)
		},
		{
			dataIndex: 'failed',
			key: 'failed',
			width: 100,
			render: (values: ITableCellValues, record: IPaymentType) => (
				<PaymentsTableCell>
					<div className={'column'}>
						<Row align={'middle'} justify={'center'} gutter={4}>
							<Col>
								<span className={'percentage'}>{getPercentage(values?.EUR?.value)}%</span>
							</Col>
							{getPercentage(values?.EUR?.value) > 0 && (
								<Col>
									<Tooltip title={t('Zobraziť podrobnosti')}>
										<Button
											type='primary'
											size={'small'}
											onClick={() => {
												Modal.info({
													title: t('Dôvody zlyhania platieb'),
													icon: <InfoCircleOutlined />,
													content: map(record?.errors?.EUR, (err, index) => (
														<Row key={index}>{`${formatAcquirerResponseId(err?.value)}: ${err?.count}x`}</Row>
													)),
													okText: t('Zavrieť')
												})
											}}
											icon={<InfoCircleOutlined />}
										/>
									</Tooltip>
								</Col>
							)}
						</Row>
					</div>
					<div className={'column'}>
						<Row align={'middle'} justify={'center'} gutter={4}>
							<Col>
								<span className={'percentage'}>{getPercentage(values?.CZK?.value)}%</span>
							</Col>
							{getPercentage(values?.CZK?.value) > 0 && (
								<Col>
									<Tooltip title={t('Zobraziť podrobnosti')}>
										<Button
											type='primary'
											size={'small'}
											onClick={() => {
												Modal.info({
													title: t('Dôvody zlyhania platieb'),
													icon: <InfoCircleOutlined />,
													content: map(record?.errors?.CZK, (err, index) => (
														<Row key={index}>{`${formatAcquirerResponseId(err?.value)}: ${err?.count}x`}</Row>
													)),
													okText: t('Zavrieť')
												})
											}}
											icon={<InfoCircleOutlined />}
										/>
									</Tooltip>
								</Col>
							)}
						</Row>
					</div>
				</PaymentsTableCell>
			)
		}
	]

	return (
		<div className={'dashboard-card'}>
			<div className={'grid'}>
				<Row align={'middle'} justify={'space-between'}>
					<Col>
						<h2>{t('Naplánované a vykonané mesačné platby')}</h2>
					</Col>
					<Col>
						<Form.Item style={{ marginBottom: '10.5px' }}>
							<RangePicker
								defaultValue={[
									STATIC_DATES.END_OF_MONTH,
									STATIC_DATES.START_OF_MONTH.isBefore(RELEASE_DATE) ? RELEASE_DATE : STATIC_DATES.START_OF_MONTH
								]}
								format={'D.M.YYYY'}
								disabledDate={disabledRange}
								onChange={(rangeValues) => {
									if (rangeValues) setPaymentsTableData({ ...paymentsTableData, page: 1, dateFrom: rangeValues[0], dateTo: rangeValues[1] })
									else setPaymentsTableData({ ...paymentsTableData, page: 1, dateFrom: undefined, dateTo: undefined })
								}}
								onCalendarChange={(rangeValues) => {
									if (rangeValues) setPaymentsTableData({ ...paymentsTableData, page: 1, dateFrom: rangeValues[0], dateTo: rangeValues[1] })
									else setPaymentsTableData({ ...paymentsTableData, page: 1, dateFrom: undefined, dateTo: undefined })
								}}
								placeholder={[t('Od'), t('Do')]}
								allowEmpty={[true, true]}
								style={{ width: 250 }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Spin spinning={paymentsStatisticsLoading}>
					{/* Total statistics in selected range */}
					<StyledTable
						className={'general-table'}
						columns={columns}
						rowKey={'date'}
						rowClassName={'bold'}
						dataSource={parseTotalStatisticsInRange()}
						expandable={{
							expandedRowRender: (row: any, index) => (
								<Table
									bordered
									columns={expandedRowColumns}
									rowKey={`row-${index}`}
									dataSource={row.paymentMethods}
									showHeader={false}
									pagination={false}
								/>
							),
							expandRowByClick: true
						}}
						locale={{
							emptyText: <Empty description={t('Žiadne dáta')} />
						}}
						size={'small'}
						pagination={false}
						scroll={{ x: 991 }}
					/>
					{/* Daily statistics in selected range */}
					<StyledTable
						showHeader={false}
						className={'general-table'}
						columns={columns}
						rowKey={'date'}
						rowClassName={'bold'}
						dataSource={paymentsStatistics.dailyStatistics}
						expandable={{
							expandedRowRender: (row: any, index) => (
								<Table
									columns={expandedRowColumns}
									rowKey={`row-${index}`}
									dataSource={row.paymentMethods}
									showHeader={false}
									pagination={false}
								/>
							),
							expandRowByClick: true
						}}
						onChange={(pagination: PaginationProps) => handleTableChange(pagination)}
						locale={{
							emptyText: <Empty description={t('Žiadne dáta')} />
						}}
						size={'small'}
						pagination={{
							pageSize: paymentsTableData?.limit,
							total: get(context, 'totalCount'),
							current: get(context, 'page'),
							showSizeChanger: true,
							pageSizeOptions: [5, 10, 20, 30, 50],
							locale: { items_per_page: t('na stranu') }
						}}
						scroll={{ x: 991 }}
					/>
				</Spin>
			</div>
		</div>
	)
}

export default DashboardPayments
