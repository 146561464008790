import { useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Modal } from 'antd'
import { initialize, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { createChallenge, updateChallenge } from '../../../redux/landing/actions'

// utils
import { FORMS, LANGUAGES } from '../../../utils/enums'

// types
import { ILandingModalState } from '../../../types/interfaces'

// forms
import ChallengeForm from '../forms/ChallengeForm'
import { ChallengeFormValues } from '../forms/validateChallengeForm'

type ChallengeModalProps = {
	modalState: ILandingModalState
	setModalState: (args: ILandingModalState) => void
}

const ChallengeModal = ({ modalState, setModalState }: ChallengeModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const record = useSelector((state: RootState) => state.landing?.page?.data.challenges.filter((item: any) => item.id === modalState?.id))

	useEffect(() => {
		if (record.length > 0) {
			const langData = get(record[0], 'translations')
			const langValues: any = {}

			const initValues = {
				...record[0]
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = get(lang, 'title')
				langValues[`subtitle_${item}`] = get(lang, 'subtitle')
				langValues[`description_${item}`] = get(lang, 'description')
				langValues[`buttonUrl_${item}`] = get(lang, 'buttonUrl')
				langValues[`buttonText_${item}`] = get(lang, 'buttonText')
			})

			dispatch(initialize(FORMS.LANDING_CHALLENGE_FORM, { ...initValues, ...langValues }))
		} else {
			dispatch(initialize(FORMS.LANDING_CHALLENGE_FORM, {}))
		}
	}, [dispatch])

	const handleSubmitForm = (values: ChallengeFormValues) => {
		const body: any = {
			id: values.id,
			image: values.image,
			logo: values.logo,
			inverseLogo: values.inverseLogo,
			order: values.order,
			translations: []
		}

		each(LANGUAGES, (item) => {
			const title = get(values, `title_${item}`)
			const subtitle = get(values, `subtitle_${item}`)
			const description = get(values, `description_${item}`)
			const buttonUrl = get(values, `buttonUrl_${item}`)
			const buttonText = get(values, `buttonText_${item}`)
			if (title && subtitle && description && buttonUrl && buttonText) {
				const translation = {
					title: get(values, `title_${item}`),
					subtitle: get(values, `subtitle_${item}`),
					description: get(values, `description_${item}`),
					buttonUrl: get(values, `buttonUrl_${item}`),
					buttonText: get(values, `buttonText_${item}`),
					language: item
				}

				body.translations.push(translation)
			}
		})

		if (modalState?.id) {
			dispatch(updateChallenge(modalState?.id, body))
		} else {
			body.id = dayjs().valueOf()
			dispatch(createChallenge(body))
		}
		setModalState({ open: false })
	}

	return (
		<Modal
			visible
			title={modalState?.id ? t('Upraviť výzvu') : t('Pridať výzvu')}
			okText={modalState?.id ? t('Uložiť') : t('Pridať')}
			cancelText={t('Zrušiť')}
			onCancel={() => setModalState({ open: false })}
			onOk={() => {
				dispatch(submit(FORMS.LANDING_CHALLENGE_FORM))
			}}
		>
			<ChallengeForm onSubmit={handleSubmitForm} />
		</Modal>
	)
}

export default ChallengeModal
