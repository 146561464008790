import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createBlogCategory, getBlogCategories, getBlogCategory, updateBlogCategory } from '../../redux/blogCategories/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// types
import { BlogCategoryPayload } from '../../redux/blogCategories/types'
import { IComputedMatch } from '../../types/interfaces'
import { Paths } from '../../types/api'

// forms
import { BlogCategoryFormValues } from './forms/validateBlogCategoryForm'
import BlogCategoryForm from './forms/BlogCategoryForm'

type DetailBlogCategoryProps = {
	computedMatch: IComputedMatch
}

const DetailBlogCategory = ({ computedMatch }: DetailBlogCategoryProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.accessories?.detail)

	// constants
	const isLoading = detail?.isLoading

	const getAllLists = useCallback(() => {
		dispatch(getBlogCategories({ limit: 'all' }))
	}, [dispatch])

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			color: '#FEA385',
			order: 1
		}

		dispatch(initialize(FORMS.BLOG_CATEGORY_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: BlogCategoryPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				...data,
				color: data?.color || '#FEA385',
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY ')
			}

			forEach(LANGUAGES, (item: LANGUAGE) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = lang?.name
				langValues[`urlSlug_${item}`] = lang?.urlSlug
				langValues[`briefDescription_${item}`] = lang?.briefDescription
			})

			dispatch(
				initialize(FORMS.BLOG_CATEGORY_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getBlogCategory(id, (data: BlogCategoryPayload) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}

		getAllLists()
	}, [dispatch, getAllLists, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: Paths.PostAdminBlogCategories.RequestBody) => {
		dispatch(createBlogCategory(body, () => history.push(t('paths:blogCategories|path'))))
	}

	const handleUpdate = (body: Paths.PutAdminBlogCategoriesId.RequestBody) => {
		dispatch(
			updateBlogCategory(id, body, () => {
				dispatch(getBlogCategory(id, (data: BlogCategoryPayload) => initDetailForm(data)))
			})
		)
	}

	const handleSubmit = (values: BlogCategoryFormValues) => {
		const body: Paths.PostAdminBlogCategories.RequestBody | Paths.PutAdminBlogCategoriesId.RequestBody = {
			translations: [],
			color: values?.color,
			order: values?.order,
			parentCategoryID: values?.parentCategoryID || null
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)

			if (name && urlSlug) {
				body.translations.push({
					name: get(values, `name_${item}`, ''),
					urlSlug: get(values, `urlSlug_${item}`, ''),
					briefDescription: get(values, `briefDescription_${item}`),
					language: item
				})
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<BlogCategoryForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailBlogCategory
