import PropTypes from 'prop-types'
import { Button, Empty, Input, Table } from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { each, trim } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const PreparationTime = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const addStep = () => {
		const updatedContent = []

		each(item?.content, (contentItem, index) => {
			updatedContent.push({
				key: index,
				time: contentItem?.time,
				label: contentItem?.label
			})
		})

		itemContentSetter(item?.id, [...updatedContent, { time: '', label: '', key: updatedContent.length }])
	}

	const deleteStep = (row) => {
		const updatedContent = []

		each(item?.content, (contentItem, index) => {
			if (contentItem?.key !== row.key) {
				updatedContent.push({
					key: index,
					time: contentItem?.time,
					label: contentItem?.label
				})
			}
		})

		itemContentSetter(item?.id, updatedContent)
	}

	const updateTime = (value, row) => {
		const updatedContent = []

		each(item?.content, (contentItem) => {
			updatedContent.push({
				key: contentItem?.key,
				time: contentItem?.key === row.key ? value : contentItem?.time,
				label: contentItem?.label
			})
		})

		itemContentSetter(item?.id, updatedContent)
	}

	const updateLabel = (value, row) => {
		const updatedContent = []

		each(item?.content, (contentItem) => {
			updatedContent.push({
				key: contentItem?.key,
				label: contentItem?.key === row.key ? value : contentItem?.label,
				time: contentItem?.time
			})
		})

		itemContentSetter(item?.id, updatedContent)
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex direction-col'}>
				<Table
					className={'general-table'}
					style={{ width: '100%' }}
					columns={[
						{
							title: t('Čas kroku'),
							dataIndex: 'time',
							key: 'time',
							render: (value, row) => (
								<Input
									value={value}
									placeholder={t('Zadajte časový údaj, napr 2-3 hodiny')}
									maxLength={INPUT_MAX_LENGTH}
									onChange={(e) => updateTime(e.target.value, row)}
									onBlur={(e) => updateTime(trim(e.target.value), row)}
								/>
							)
						},
						{
							title: t('Popis kroku'),
							dataIndex: 'label',
							key: 'label',
							render: (value, row) => (
								<Input
									value={value}
									placeholder={t('Zadajte slovný popis, napr kysnutie cesta')}
									maxLength={INPUT_MAX_LENGTH}
									onChange={(e) => updateLabel(e.target.value, row)}
									onBlur={(e) => updateLabel(trim(e.target.value), row)}
								/>
							)
						},
						{
							title: '',
							key: 'operation',
							fixed: 'right',
							width: 50,
							render: (row) => <Button icon={<DeleteOutlined />} type={'danger'} onClick={() => deleteStep(row)} />
						}
					]}
					dataSource={item?.content}
					locale={{
						emptyText: <Empty description={i18next.t('Žiadne dáta')} />
					}}
					size={'small'}
					pagination={false}
				/>
				<Button type={'primary'} onClick={addStep}>
					{t('Pridať krok')}
				</Button>
			</div>
		</div>
	)
}

PreparationTime.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number,
				time: PropTypes.string,
				label: PropTypes.string
			})
		)
	}),
	itemContentSetter: PropTypes.func.isRequired
}

PreparationTime.defaultProps = {
	item: {}
}

export default PreparationTime
