import { useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { Tabs } from 'antd'
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS, UPLOAD_CATEGORY, LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validateChallengeForm, { ChallengeFormValues } from './validateChallengeForm'

// atoms
import TextAreaField from '../../../atoms/form/TextAreaField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type ChallengeFormProps = {
	onSubmit: (values: ChallengeFormValues) => void
}

const ChallengeForm = ({ handleSubmit, onSubmit }: ChallengeFormProps & InjectedFormProps<ChallengeFormValues, ChallengeFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.LANDING_CHALLENGE_FORM)(state)) as ChallengeFormValues

	// states
	const [image, setImage] = useState({
		isLoading: false,
		imageUrl: ''
	})
	const [logo, setLogo] = useState({
		isLoading: false,
		imageUrl: ''
	})

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.LANDING_CHALLENGE_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	const uploadLogo = async ({ file }: any) => {
		setLogo({
			isLoading: true,
			imageUrl: ''
		})
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.LANDING_CHALLENGE_FORM, 'logo', fileUrl))
			setLogo({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				name={'image'}
				imageUrl={get(fieldValues, 'image')}
				component={UploadInputField}
				label={t('Titulný obrázok (upload)')}
				customHelper={t('recommendedSize|homepageChallengeBackground')}
				customRequest={uploadImage}
				isLoading={image.isLoading}
				required
			/>

			<Field
				name={'logo'}
				imageUrl={get(fieldValues, 'logo')}
				component={UploadInputField}
				customHelper={t('recommendedSize|programLogo')}
				label={t('Logo (upload)')}
				customRequest={uploadLogo}
				isLoading={logo.isLoading}
				required
			/>
			<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
				{map(LANGUAGES, (item) => (
					<TabPane tab={getLanguageName(item)} key={item}>
						<Field
							name={`title_${item}`}
							label={t('Nadpis')}
							type={'text'}
							placeholder={t('Nadpis')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`subtitle_${item}`}
							label={t('Podnadpis')}
							type={'text'}
							placeholder={t('Podnadpis')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`description_${item}`}
							label={t('Popis')}
							type={'text'}
							placeholder={t('Popis')}
							component={TextAreaField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`buttonUrl_${item}`}
							label={t('Odkaz tlačidla')}
							type={'text'}
							placeholder={t('Odkaz tlačidla')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
						<Field
							name={`buttonText_${item}`}
							label={t('Text tlačidla')}
							type={'text'}
							placeholder={t('Text tlačidla')}
							component={TextField}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 20 }}
							required
						/>
					</TabPane>
				))}
			</Tabs>
		</form>
	)
}

export default reduxForm<ChallengeFormValues, ChallengeFormProps>({
	form: FORMS.LANDING_CHALLENGE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateChallengeForm
})(ChallengeForm)
