import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { get, debounce, map } from 'lodash'
import { Select } from 'antd'
import { getUsers } from '../../../redux/users/actions'
import { EMPTY_VALUE, FORMS } from '../../../utils/enums'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import validateUserSelect from './validateUserSelect'

const PAGE_SIZE = 20

const TransferSubscriptionForm = ({ handleSubmit, onSubmit }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { Option } = Select

	const searchUsers = useSelector((state) => get(state, 'users.list.tableList'))

	const handleOnSearchDebounced = debounce((value) => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			search: value
		}
		dispatch(getUsers(body))
	}, 300)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				name={'newUserID'}
				label={t('Nový používateľ')}
				placeholder={t('Vyber nového používateľa')}
				component={CustomOptionsSelectField}
				required
				defaultActiveFirstOption={false}
				showSearch
				onSearch={(value) => handleOnSearchDebounced(value)}
				options={map(searchUsers, (user, index) => (
					<Option key={index} value={get(user, 'id')}>
						{get(user, 'fullName') && get(user, 'fullName') !== EMPTY_VALUE
							? `${get(user, 'email')} (${get(user, 'fullName')})`
							: `${get(user, 'email')}`}
					</Option>
				))}
				filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			/>
		</form>
	)
}

TransferSubscriptionForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.TRANSFER_SUBSCRIPTION_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateUserSelect
})(TransferSubscriptionForm)
