import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createAccessory, getAccessory, updateAccessory } from '../../redux/accessories/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// types
import { AccessoryPayload } from '../../redux/accessories/types'
import { IComputedMatch } from '../../types/interfaces'
import { Paths } from '../../types/api'

// forms
import AccessoryForm from './forms/AccessoryForm'
import { AccessoryFormValues } from './forms/validateAccessoryForm'

type DetailAccessoryProps = {
	computedMatch: IComputedMatch
}

const DetailAccessory = ({ computedMatch }: DetailAccessoryProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.accessories?.detail)
	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			order: 1
		}

		dispatch(initialize(FORMS.ACCESSORY_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: AccessoryPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				id: data?.id,
				image: data?.image,
				isPublished: data?.isPublished,
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY ')
			}

			forEach(LANGUAGES, (item: LANGUAGE) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = lang?.name
			})

			dispatch(
				initialize(FORMS.ACCESSORY_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(
				getAccessory(id, (data: AccessoryPayload) => {
					initDetailForm(data)
				})
			)
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: Paths.PostAdminAccessories.RequestBody) => {
		dispatch(createAccessory(body, () => history.push(t('paths:accessories|path'))))
	}

	const handleUpdate = (body: Paths.PutAdminAccessoriesId.RequestBody) => {
		dispatch(
			updateAccessory(id, body, () => {
				dispatch(
					getAccessory(id, (data: AccessoryPayload) => {
						initDetailForm(data)
					})
				)
			})
		)
	}

	const handleSubmit = (values: AccessoryFormValues) => {
		const body: Paths.PostAdminAccessories.RequestBody | Paths.PutAdminAccessoriesId.RequestBody = {
			translations: [],
			image: get(values, 'image'),
			isPublished: get(values, 'isPublished')
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)

			if (name) {
				body.translations.push({
					name,
					language: item
				})
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<AccessoryForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailAccessory
