import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createTag, getTag, updateTag } from '../../redux/tags/actions'

// utils
import { FORMS, LANGUAGES } from '../../utils/enums'

// types
import { IComputedMatch } from '../../types/interfaces'
import { Paths } from '../../types/api'
import { TagPayload } from '../../redux/tags/types'

// forms
import TagForm from './forms/TagForm'
import { TagFormValues } from './forms/validateTagForm'

type TagDetailProps = {
	computedMatch: IComputedMatch
}

const DetailTag = ({ computedMatch }: TagDetailProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.tags?.detail)

	// constants
	const isLoading = detail?.isLoading

	const initDetailForm = useCallback(
		(data: TagPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				id: data?.id,
				// name: data?.name,
				// urlSlug: data?.urlSlug,
				createdAt: dayjs(data?.createdAt).format('DD.MM.YYYY HH:mm'),
				updatedAt: dayjs(data?.updatedAt).format('DD.MM.YYYY HH:mm')
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'urlSlug')
				langValues[`briefDescription_${item}`] = get(lang, 'briefDescription')
			})

			dispatch(
				initialize(FORMS.TAG_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	const initEmptyForm = () => {
		const initValues = {
			name: '',
			urlSlug: '',
			createdAt: dayjs().format('DD.MM.YYYY HH:mm')
		}

		const langValues = {}

		dispatch(
			initialize(FORMS.TAG_FORM, {
				...initValues,
				...langValues
			})
		)
	}

	useEffect(() => {
		if (id) {
			dispatch(getTag(id, (data) => initDetailForm(data)))
		} else {
			initEmptyForm()
		}
	}, [dispatch, id])

	const handleCreate = (body: Paths.PostAdminTags.RequestBody) => {
		dispatch(createTag(body, (newId) => history.push(`${t('paths:tags|path')}/${newId}`)))
	}

	const handleUpdate = (body: Paths.PutAdminTagsTagId.RequestBody) => {
		dispatch(updateTag(id, body, () => dispatch(getTag(id, (data) => initDetailForm(data)))))
	}

	const handleSubmit = (values: TagFormValues) => {
		const body: any = { translations: [] }

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)

			if (name && urlSlug) {
				body.translations.push({
					name,
					urlSlug,
					briefDescription: get(values, `briefDescription_${item}`, null),
					language: item
				})
			}
		})
		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<TagForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailTag
