// types
import { Paths } from '../../types/api'

export type MotivationAdvicesPayload = Paths.GetAdminMotivationAdvices.Responses.$200['motivationAdvices']
export type MotivationAdvicePayload = Paths.GetAdminMotivationAdvicesId.Responses.$200

export enum GET_MOTIVATION_ADVICES {
	START = 'GET_MOTIVATION_ADVICES_START',
	DONE = 'GET_MOTIVATION_ADVICES_DONE',
	FAILED = 'GET_MOTIVATION_ADVICES_FAILED'
}

export enum GET_MOTIVATION_ADVICE {
	START = 'GET_MOTIVATION_ADVICE_START',
	DONE = 'GET_MOTIVATION_ADVICE_DONE',
	FAILED = 'GET_MOTIVATION_ADVICE_FAILED'
}

export enum CREATE_MOTIVATION_ADVICE {
	START = 'CREATE_MOTIVATION_ADVICE_START',
	DONE = 'CREATE_MOTIVATION_ADVICE_DONE',
	FAILED = 'CREATE_MOTIVATION_ADVICE_FAILED'
}

export enum UPDATE_MOTIVATION_ADVICE {
	START = 'UPDATE_MOTIVATION_ADVICE_START',
	DONE = 'UPDATE_MOTIVATION_ADVICE_DONE',
	FAILED = 'UPDATE_MOTIVATION_ADVICE_FAILED'
}

export enum DELETE_MOTIVATION_ADVICE {
	START = 'DELETE_MOTIVATION_ADVICE_START',
	DONE = 'DELETE_MOTIVATION_ADVICE_DONE',
	FAILED = 'DELETE_MOTIVATION_ADVICE_FAILED'
}
