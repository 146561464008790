import PropTypes from 'prop-types'
import { Checkbox, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { ChromePicker } from 'react-color'

const { TextArea } = Input

const ColoredBox = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const setContent = (value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			value
		})
	}

	const setColor = (color) => {
		itemContentSetter(item?.id, {
			...item?.content,
			color
		})
	}

	const setCenter = (center) => {
		itemContentSetter(item?.id, {
			...item?.content,
			center
		})
	}

	return (
		<div className={'custom-item colored-box'}>
			<TextArea
				placeholder={t('Sem zadajte citáciu')}
				className={'text-area'}
				value={item?.content?.value || ''}
				onChange={(e) => setContent(e.target.value)}
				allowClear
			/>
			<ChromePicker className={'color-picker'} color={item?.content?.color || '#FEA385'} onChange={(e) => setColor(e.hex)} />
			<div className={'flex justify-end'} style={{ position: 'absolute', bottom: 31, left: 31 }}>
				<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
					{t('Centrovať')}
				</Checkbox>
			</div>
		</div>
	)
}

ColoredBox.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			value: PropTypes.string,
			color: PropTypes.string,
			center: PropTypes.bool
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

ColoredBox.defaultProps = {
	item: {}
}

export default ColoredBox
