export enum LOAD_PUSH_NOTIFICATIONS {
	START = 'LOAD_PUSH_NOTIFICATIONS_START',
	DONE = 'LOAD_PUSH_NOTIFICATIONS_DONE',
	FAILED = 'LOAD_PUSH_NOTIFICATIONS_FAILED'
}

export enum LOAD_PUSH_NOTIFICATION {
	START = 'LOAD_PUSH_NOTIFICATION_START',
	DONE = 'LOAD_PUSH_NOTIFICATION_DONE',
	FAILED = 'LOAD_PUSH_NOTIFICATION_FAILED'
}

export enum CREATE_PUSH_NOTIFICATION {
	START = 'CREATE_PUSH_NOTIFICATION_START',
	DONE = 'CREATE_PUSH_NOTIFICATION_DONE',
	FAILED = 'CREATE_PUSH_NOTIFICATION_FAILED'
}

export enum UPDATE_PUSH_NOTIFICATION {
	START = 'UPDATE_PUSH_NOTIFICATION_START',
	DONE = 'UPDATE_PUSH_NOTIFICATION_DONE',
	FAILED = 'UPDATE_PUSH_NOTIFICATION_FAILED'
}

export enum DELETE_PUSH_NOTIFICATION {
	START = 'DELETE_PUSH_NOTIFICATION_START',
	DONE = 'DELETE_PUSH_NOTIFICATION_DONE',
	FAILED = 'DELETE_PUSH_NOTIFICATION_FAILED'
}

export enum LOAD_PUSH_NOTIFICATIONS_STATS {
	START = 'LOAD_PUSH_NOTIFICATIONS_STATS_START',
	DONE = 'LOAD_PUSH_NOTIFICATIONS_STATS_DONE',
	FAILED = 'LOAD_PUSH_NOTIFICATIONS_STATS_FAILED'
}
