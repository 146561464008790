import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { CURRENCY, SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../../../utils/enums'

export type CustomPaymentFormValues = {
	subscriptionType: SUBSCRIPTION_TYPE
	subscriptionTier: SUBSCRIPTION_TIER
	currency: CURRENCY
	activeFrom: string
	price: number
}

export default (values: CustomPaymentFormValues) => {
	const errors: FormErrors<CustomPaymentFormValues> = {}

	if (!values.subscriptionType) {
		errors.subscriptionType = i18next.t('Toto pole je povinné')
	}

	if (!values.subscriptionTier) {
		errors.subscriptionTier = i18next.t('Toto pole je povinné')
	}

	if (!values.currency) {
		errors.currency = i18next.t('Toto pole je povinné')
	}

	if (!values.activeFrom) {
		errors.activeFrom = i18next.t('Toto pole je povinné')
	}

	if (!values.price) {
		errors.price = i18next.t('Toto pole je povinné')
	}

	return errors
}
