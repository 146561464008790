import PropTypes from 'prop-types'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { trim } from 'lodash'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const Testimonial = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const isOldContent = typeof item?.content === 'string'

	const setContent = (value) => {
		itemContentSetter(item?.id, { text: value })
	}

	return (
		<div className={'custom-item'}>
			<div className={'input-field'}>
				<Input
					placeholder={t('Sem zadajte citáciu')}
					className={'input-field'}
					size={'large'}
					value={isOldContent ? item?.content : item?.content?.text || ''}
					maxLength={INPUT_MAX_LENGTH}
					onChange={(e) => setContent(e.target.value)}
					onBlur={(e) => setContent(trim(e.target.value))}
				/>
			</div>
		</div>
	)
}

Testimonial.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				text: PropTypes.string
			})
		])
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Testimonial.defaultProps = {
	item: {}
}

export default Testimonial
