import React from 'react'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FORMS } from '../../../utils/enums'
import DateField from '../../../atoms/form/DateField'

const SubscriptionMonthForm = ({ handleSubmit }) => {
	const disabledDateHandler = (currentDate) => {
		if (dayjs(currentDate).year() < dayjs().year() || dayjs(currentDate).month() < dayjs().month()) {
			return false
		}
		return true
	}

	return (
		<form onSubmit={handleSubmit} style={{ margin: '4px 0 0 0 ', display: 'inline-block', width: '200px' }}>
			<Field
				name={'selectedMonth'}
				component={DateField}
				disabledDate={(currentDate) => disabledDateHandler(currentDate)}
				dttmFormat={'MMMM YYYY'}
				picker={'month'}
			/>
		</form>
	)
}

SubscriptionMonthForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.SUBSCRIPTION_MONTH_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(SubscriptionMonthForm)
