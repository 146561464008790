// types
import { Paths } from '../../types/api'

export type ProfilePayload = Paths.GetAdminProfile.Responses.$200
export type LoginUserPayload = Paths.PostAdminAuthorizationLogin.Responses.$200

export enum GET_PROFILE {
	START = 'GET_PROFILE_START',
	DONE = 'GET_PROFILE_DONE',
	FAILED = 'GET_PROFILE_FAILED'
}

export enum CHANGE_PASSWORD {
	START = 'CHANGE_PASSWORD_START',
	DONE = 'CHANGE_PASSWORD_DONE',
	FAILED = 'CHANGE_PASSWORD_FAILED'
}

export enum ADD_FREE_SUBSCRIPTION {
	START = 'ADD_FREE_SUBSCRIPTION_START',
	DONE = 'ADD_FREE_SUBSCRIPTION_DONE',
	FAILED = 'ADD_FREE_SUBSCRIPTION_FAILED'
}

export enum TRANSFER_SUBSCRIPTION {
	START = 'TRANSFER_SUBSCRIPTION_START',
	DONE = 'TRANSFER_SUBSCRIPTION_DONE',
	FAILED = 'TRANSFER_SUBSCRIPTION_FAILED'
}

export enum CREATE_CUSTOM_PAYMENT {
	START = 'CREATE_CUSTOM_PAYMENT_START',
	DONE = 'CREATE_CUSTOM_PAYMENT_DONE',
	FAILED = 'CREATE_CUSTOM_PAYMENT_FAILED'
}

export enum CANCEL_SUBSCRIPTION {
	START = 'CANCEL_SUBSCRIPTION_START',
	DONE = 'CANCEL_SUBSCRIPTION_DONE',
	FAILED = 'CANCEL_SUBSCRIPTION_FAILED'
}

export enum DELETE_SUBSCRIPTION {
	START = 'DELETE_SUBSCRIPTION_START',
	DONE = 'DELETE_SUBSCRIPTION_DONE',
	FAILED = 'DELETE_SUBSCRIPTION_FAILED'
}

export enum GENERATE_TEMP_PASSWORD {
	START = 'START_GENERATE_TEMP_PASSWORD',
	DONE = 'DONE_GENERATE_TEMP_PASSWORD',
	FAILED = 'FAILED_GENERATE_TEMP_PASSWORD'
}

export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_NEW_PASSWORD_DONE = 'SET_NEW_PASSWORD_DONE'
