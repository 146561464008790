import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import TextArea from '../../atoms/form/TextAreaField'
import DateField from '../../atoms/form/DateField'
import SelectField from '../../atoms/form/SelectField'
import { FORMS, FREE_SUBSCRIPTION_REASON, SUBSCRIPTION_TIER } from '../../utils/enums'
import validateSubscriptionForm from '../validators/validateSubscriptionForm'

const SubscriptionForm = ({ handleSubmit, onSubmit }) => {
	const { t } = useTranslation()

	const tiers = [
		{ value: SUBSCRIPTION_TIER.PREMIUM, label: t('Premium') },
		{ value: SUBSCRIPTION_TIER.VIP, label: t('VIP') }
	]

	const reasons = [
		{ value: FREE_SUBSCRIPTION_REASON.FAILURE, label: t('Chyba') },
		{ value: FREE_SUBSCRIPTION_REASON.PROMO, label: t('Promo akcia') },
		{ value: FREE_SUBSCRIPTION_REASON.ILLNESS, label: t('Necvičil/a kvôli chorobe') },
		{ value: FREE_SUBSCRIPTION_REASON.PREGNANCY, label: t('Necvičila kvôli tehotenstvu') },
		{ value: FREE_SUBSCRIPTION_REASON.OTHER, label: t('Iný dôvod') }
	]

	return (
		<form className={'add-membership-form'} onSubmit={handleSubmit(onSubmit)}>
			<Field name={'tier'} component={SelectField} options={tiers} label={t('Typ členstva')} placeholder={t('Vyber si typ členstva')} required />
			<Field name={'activeFrom'} component={DateField} label={t('Dátum (od)')} dttmFormat={'D.M.YYYY'} showTime required />
			<Field name={'activeTo'} component={DateField} label={t('Dátum (do)')} dttmFormat={'D.M.YYYY'} showTime required />
			<Field name={'activateReason'} component={SelectField} options={reasons} label={t('Dôvod aktivácie')} required />
			<Field name={'note'} component={TextArea} label={t('Poznámka')} />
		</form>
	)
}

SubscriptionForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.SUBSCRIPTION_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateSubscriptionForm
})(SubscriptionForm)
