import { Modal } from 'antd'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import CustomPaymentForm from '../forms/CustomPaymentForm'

type CustomPaymentModalProps = {
	setShowModal: (value: boolean) => void
	submitPayment: (values: any) => void
}

const CustomPaymentModal = ({ setShowModal, submitPayment }: CustomPaymentModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<Modal
			visible
			title={t('Vytvoriť platbu')}
			okText={t('Vytvoriť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(false)}
			onOk={() => {
				dispatch(submit(FORMS.CUSTOM_PAYMENT_FORM))
			}}
		>
			<CustomPaymentForm onSubmit={submitPayment} />
		</Modal>
	)
}

export default CustomPaymentModal
