import get from 'lodash/get'
import map from 'lodash/map'
import { Button, Tabs, Row, Col, Tooltip } from 'antd'
import { Field, reduxForm, getFormSyncErrors, InjectedFormProps } from 'redux-form'
import { SaveOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { FORMS } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateSettingsForm, { SettingsFormValues } from './validateSettingsForm'

// atoms
import TextField from '../../../atoms/form/TextField'

const { TabPane } = Tabs

const SettingsForm = ({ handleSubmit, invalid, pristine }: InjectedFormProps<SettingsFormValues>) => {
	const { t } = useTranslation()

	// selectors
	const fieldValues = useSelector((state: RootState) => state.settings?.pricelist) as any
	const formErrors = useSelector(getFormSyncErrors(FORMS.SETTINGS_FORM))

	// constants
	const fieldLabels: IFieldLabels = {
		month_premium_CZK: `${t('Mesačné Premium členstvo')} (CZK)`,
		quarter_premium_CZK: `${t('3 mesačné Premium členstvo')} (CZK)`,
		year_premium_CZK: `${t('Ročné Premium členstvo')} (CZK)`,
		month_vip_CZK: `${t('Mesačné VIP členstvo')} (CZK)`,
		quarter_vip_CZK: `${t('3 mesačné VIP členstvo')} (CZK)`,
		year_vip_CZK: `${t('Ročné VIP členstvo')} (CZK)`,
		month_premium_EUR: `${t('Mesačné Premium členstvo')} (EUR)`,
		quarter_premium_EUR: `${t('3 mesačné Premium členstvo')} (EUR)`,
		year_premium_EUR: `${t('Ročné Premium členstvo')} (EUR)`,
		month_vip_EUR: `${t('Mesačné VIP členstvo')} (EUR)`,
		quarter_vip_EUR: `${t('3 mesačné VIP členstvo')} (EUR)`,
		year_vip_EUR: `${t('Ročné VIP členstvo')} (EUR)`
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<Col span={24}>
						<h2>{t('Nastavenie cenníka')}</h2>
						<div className={'flex direction-col justify-start main-content'}>
							<Tabs defaultActiveKey={get(fieldValues, '0.currency')} className={'translations'}>
								{map(fieldValues, (item) => (
									<TabPane tab={item.currency} key={item.currency}>
										<Row gutter={32} justify={'space-between'}>
											<Col>
												<h3>{t('Premium')}</h3>
												<Field
													name={`month_premium_${item.currency}`}
													component={TextField}
													label={t('Mesačné členstvo')}
													type={'number'}
													size={'large'}
													required
												/>
												<Field
													name={`quarter_premium_${item.currency}`}
													component={TextField}
													label={t('3 mesačné členstvo')}
													size={'large'}
													type={'number'}
													required
												/>
												<Field
													name={`year_premium_${item.currency}`}
													component={TextField}
													label={t('Ročné členstvo')}
													size={'large'}
													type={'number'}
													required
												/>
											</Col>
											<Col>
												<h3>{t('VIP')}</h3>
												<Field
													name={`month_vip_${item.currency}`}
													component={TextField}
													label={t('Mesačné členstvo')}
													type={'number'}
													size={'large'}
													required
												/>
												<Field
													name={`quarter_vip_${item.currency}`}
													component={TextField}
													label={t('3 mesačné členstvo')}
													size={'large'}
													type={'number'}
													required
												/>
												<Field
													name={`year_vip_${item.currency}`}
													component={TextField}
													label={t('Ročné členstvo')}
													size={'large'}
													type={'number'}
													required
												/>
											</Col>
										</Row>
									</TabPane>
								))}
							</Tabs>
						</div>
					</Col>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<SettingsFormValues>({
	form: FORMS.SETTINGS_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateSettingsForm
})(SettingsForm)
