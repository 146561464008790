// types
import {
	GET_LIVESTREAM_CATEGORIES,
	SET_LIVESTREAM_CATEGORY,
	ADD_LIVESTREAM_CATEGORY,
	REMOVE_LIVESTREAM_CATEGORY,
	CHANGE_LIVESTREAM_CATEGORIES_ORDER
} from './types'

export const initState = {
	categories: [],
	context: {},
	isLoading: false,
	isFailure: false
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_LIVESTREAM_CATEGORIES.START:
			return {
				...initState,
				...state,
				isLoading: true,
				isFailure: false
			}
		case GET_LIVESTREAM_CATEGORIES.DONE:
			return {
				...initState,
				...state,
				...action.payload,
				isLoading: false,
				isFailure: false
			}
		case GET_LIVESTREAM_CATEGORIES.FAILED:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case SET_LIVESTREAM_CATEGORY:
			return {
				...state,
				categories: state.categories.map((item: any, index) => (index === action.payload.index ? { ...item, programID: action.payload.id } : item))
			}
		case ADD_LIVESTREAM_CATEGORY:
			return {
				...state,
				categories: [...state.categories, { programID: null, name: null, order: state.categories.length + 1 }]
			}
		case REMOVE_LIVESTREAM_CATEGORY:
			return {
				...state,
				categories: state.categories.filter((item: any) => item.programID !== action.payload)
			}
		case CHANGE_LIVESTREAM_CATEGORIES_ORDER:
			return {
				...state,
				categories: action.payload
			}
		default:
			return state
	}
}
