import { useState, useEffect, ReactNode, FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Layout, BackTop, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import packageInfo from '../../package.json'

// utils
import { ENDPOINTS } from '../utils/enums'
import { getReq } from '../utils/request'

// types
import { IComputedMatch } from '../types/interfaces'

// components
import HeaderContent from '../components/HeaderContent'
import SideMenu from '../components/SideMenu'

const { Header, Content, Footer, Sider } = Layout

interface IAdminLayout {
	routeKey: string
	children: ReactNode
	computedMatch: IComputedMatch
}

const AdminLayout: FC<IAdminLayout> = ({ routeKey, children, computedMatch }) => {
	const { t } = useTranslation()
	const [collapsed, setCollapsed] = useState(false)
	const [apiVersion, setApiVersion] = useState(null)

	useEffect(() => {
		;(async () => {
			try {
				const { data } = await getReq(ENDPOINTS.VERSION)
				setApiVersion(data.version)
			} catch (error) {
				Promise.reject(error)
			}
		})()
	}, [])

	let clicks = {
		start: null as Dayjs | null,
		times: 0
	}
	const handleThrowError = () => {
		const now = dayjs()

		if (clicks.start && now.diff(clicks.start, 'seconds') < 3) {
			clicks = {
				start: clicks.start,
				times: clicks.times + 1
			}
		} else {
			clicks = {
				start: dayjs(),
				times: 1
			}
		}

		if (clicks.times > 10) {
			notification.warning({
				message: t('SENTRY test vykonaný')
			})

			clicks = {
				start: null,
				times: 0
			}

			throw new Error('Test sentry - ADMIN')
		}
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={() => setCollapsed((currentColapsed) => !currentColapsed)}
				style={{
					overflow: 'auto',
					height: '100vh',
					position: 'fixed',
					left: 0
				}}
				theme={window.location.host === 'fitshaker-admin-test.goodrequest.dev' ? 'light' : 'dark'}
			>
				<div
					className={'app-logo'}
					style={{
						backgroundColor: window.location.host === 'fitshaker-admin-test.goodrequest.dev' ? '#ffe2d8' : 'transparent',
						borderRadius: '15px'
					}}
				/>
				<SideMenu routeKey={routeKey} />
			</Sider>
			<Layout style={{ marginLeft: collapsed ? '80px' : '196px', transition: '0.3s' }}>
				<Header className={'site-layout-background'}>
					<HeaderContent routeKey={routeKey} computedMatch={computedMatch} />
				</Header>
				<Content style={{ margin: '0 16px' }}>
					<div className={'content-box'}>{children}</div>
					<BackTop />
				</Content>
				<Footer style={{ textAlign: 'center' }}>
					<div className={'copyrights'} onClick={handleThrowError}>
						{`Copyright © ${dayjs().year()} Fitshaker - Developed and designed by GoodRequest (Version: ${
							packageInfo?.version
						} API: ${apiVersion})`}
					</div>
				</Footer>
			</Layout>
		</Layout>
	)
}

export default AdminLayout
