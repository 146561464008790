export enum LOAD_SELECT_INSTRUCTORS {
	START = 'LOAD_SELECT_INSTRUCTORS_START',
	DONE = 'LOAD_SELECT_INSTRUCTORS_DONE',
	FAILED = 'LOAD_SELECT_INSTRUCTORS_FAILED'
}

export enum LOAD_SELECT_PROGRAMS {
	START = 'LOAD_SELECT_PROGRAMS_START',
	DONE = 'LOAD_SELECT_PROGRAMS_DONE',
	FAILED = 'LOAD_SELECT_PROGRAMS_FAILED'
}

export enum LOAD_SELECT_EXERCISES {
	START = 'LOAD_SELECT_EXERCISES_START',
	DONE = 'LOAD_SELECT_EXERCISES_DONE',
	FAILED = 'LOAD_SELECT_EXERCISES_FAILED'
}

export enum LOAD_SELECT_TARGETS {
	START = 'LOAD_SELECT_TARGETS_START',
	DONE = 'LOAD_SELECT_TARGETS_DONE',
	FAILED = 'LOAD_SELECT_TARGETS_FAILED'
}

export enum LOAD_SELECT_MOTIVATION_ADVICES {
	START = 'LOAD_SELECT_MOTIVATION_ADVICES_START',
	DONE = 'LOAD_SELECT_MOTIVATION_ADVICES_DONE',
	FAILED = 'LOAD_SELECT_MOTIVATION_ADVICES_FAILED'
}

export enum LOAD_SELECT_ACCESSORIES {
	START = 'LOAD_SELECT_ACCESSORIES_START',
	DONE = 'LOAD_SELECT_ACCESSORIES_DONE',
	FAILED = 'LOAD_SELECT_ACCESSORIES_FAILED'
}

export enum LOAD_SELECT_BLOG_POSTS {
	START = 'LOAD_SELECT_BLOG_POSTS_START',
	DONE = 'LOAD_SELECT_BLOG_POSTS_DONE',
	FAILED = 'LOAD_SELECT_BLOG_POSTS_FAILED'
}

export enum LOAD_SELECT_BLOG_CATEGORIES {
	START = 'LOAD_SELECT_BLOG_CATEGORIES_START',
	DONE = 'LOAD_SELECT_BLOG_CATEGORIES_DONE',
	FAILED = 'LOAD_SELECT_BLOG_CATEGORIES_FAILED'
}

export enum LOAD_SELECT_PREFERENCES {
	START = 'LOAD_SELECT_PREFERENCES_START',
	DONE = 'LOAD_SELECT_PREFERENCES_DONE',
	FAILED = 'LOAD_SELECT_PREFERENCES_FAILED'
}
