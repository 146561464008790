import { LOAD_USER_SUBSCRIPTIONS, LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE } from './types'

export const initState = {
	userSubscriptions: {
		payments: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	tierChangePossible: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOAD_USER_SUBSCRIPTIONS.START:
			return {
				...initState,
				...state,
				userSubscriptions: {
					...state.userSubscriptions,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_USER_SUBSCRIPTIONS.DONE:
			return {
				...initState,
				...state,
				userSubscriptions: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_USER_SUBSCRIPTIONS.FAILED:
			return {
				...state,
				userSubscriptions: {
					...state.userSubscriptions,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.START:
			return {
				...initState,
				...state
			}
		case LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.DONE:
			return {
				...initState,
				...state,
				tierChangePossible: action.payload?.tierChangePossible
			}
		case LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.FAILED:
			return {
				...state
			}
		default:
			return state
	}
}
