import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// requests
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// endpoint
import { ENDPOINTS } from '../../utils/enums'

// types
import {
	GET_DOWNLOAD_CATEGORIES,
	DELETE_DOWNLOAD_CATEGORY,
	GET_DOWNLOAD_CATEGORY,
	CREATE_DOWNLOAD_CATEGORY,
	UPDATE_DOWNLOAD_CATEGORY,
	DownloadCategoryPayload
} from './types'
import { Paths } from '../../types/api'

export const getDownloadCategories = (params: Paths.GetAdminDownloadCategories.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_DOWNLOAD_CATEGORIES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOAD_CATEGORIES, normalizeQueryParams)

		const tableList = map(data.downloadCategories, (item) => ({
			key: item?.id,
			name: item?.name || '---',
			downloadsCount: item?.downloadsCount || 0
		}))

		dispatch({
			type: GET_DOWNLOAD_CATEGORIES.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_DOWNLOAD_CATEGORIES.FAILED })
		Promise.reject(error)
	}
}

export const getDownloadCategory =
	(id: number, onSuccess?: (data: DownloadCategoryPayload) => void, onFailure?: (error: any) => void) => async (dispatch: any) => {
		dispatch({ type: GET_DOWNLOAD_CATEGORY.START })
		try {
			const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOAD_CATEGORY(id))
			dispatch({ type: GET_DOWNLOAD_CATEGORY.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_DOWNLOAD_CATEGORY.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createDownloadCategory =
	(body: Paths.PostAdminDownloadCategories.RequestBody, onSuccess?: () => void, onFailure?: (errors: any) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_DOWNLOAD_CATEGORY.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await postReq(ENDPOINTS.CREATE_ADMIN_DOWNLOAD_CATEGORY, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_DOWNLOAD_CATEGORY.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CREATE_DOWNLOAD_CATEGORY.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updateDownloadCategory =
	(id: number, body: Paths.PutAdminDownloadCategoriesCategoryId.RequestBody, onSuccess?: () => void, onFailure?: (error: any) => void) =>
	async (dispatch: any) => {
		dispatch({ type: UPDATE_DOWNLOAD_CATEGORY.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await putReq(ENDPOINTS.UPDATE_ADMIN_DOWNLOAD_CATEGORY(id), undefined, normalizeQueryParams)
			dispatch({ type: UPDATE_DOWNLOAD_CATEGORY.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_DOWNLOAD_CATEGORY.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const deleteDownloadCategory = (id: number, onSuccess?: () => void, onFailure?: (error: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_DOWNLOAD_CATEGORY.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_DOWNLOAD_CATEGORY(id))
		dispatch({ type: DELETE_DOWNLOAD_CATEGORY.DONE })
		return onSuccess && onSuccess()
	} catch (error: any) {
		dispatch({ type: DELETE_DOWNLOAD_CATEGORY.FAILED })
		return onFailure && onFailure(error)
	}
}
