import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import validatePaymentsRefundationForm, { PaymentsRefundationFormValues } from './validatePaymentsRefundationForm'

// atoms
import TextAreaField from '../../../atoms/form/TextAreaField'

type PaymentsRefundationFormProps = {
	onSubmit: (values: PaymentsRefundationFormValues) => void
}

const PaymentsRefundationForm = ({
	handleSubmit,
	onSubmit
}: PaymentsRefundationFormProps & InjectedFormProps<PaymentsRefundationFormValues, PaymentsRefundationFormProps>) => {
	const { t } = useTranslation()

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				name={'invoiceNumbers'}
				component={TextAreaField}
				label={t('Čísla faktúr')}
				placeholder={'Čísla faktúr treba dávať na nový riadok, alebo oddeľovať čiarkou'}
				size={'large'}
				rows={10}
				required
			/>
		</form>
	)
}

export default reduxForm<PaymentsRefundationFormValues, PaymentsRefundationFormProps>({
	form: FORMS.REFUND_PAYMENTS_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePaymentsRefundationForm
})(PaymentsRefundationForm)
