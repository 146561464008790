import { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Upload, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { get, each, trim } from 'lodash'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { uploadFile } from '../../../utils/fileUploader'
import { INPUT_MAX_LENGTH, UPLOAD_CATEGORY } from '../../../utils/enums'
import WYSIWYG from './WYSIWYG'

const Reference = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingGallery, setIsLoadingGallery] = useState(false)

	const [gallery, setGallery] = useState({
		previewVisible: false,
		previewImage: '',
		previewTitle: ''
	})

	const setField = (fieldName, value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			[fieldName]: value
		})
	}

	const renderProfilePhoto = item?.content?.profilePhoto ? (
		<img className={'upload-image'} src={item.content.profilePhoto} alt={'avatar'} />
	) : (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div>{t('Vyber profilovú fotku')}</div>
		</div>
	)

	const uploadImage = async ({ file }) => {
		setIsLoading(true)
		try {
			const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)

			if (fileUrl) {
				itemContentSetter(item?.id, {
					...item?.content,
					profilePhoto: fileUrl
				})
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handlePreview = async (file) => {
		setGallery({
			...gallery,
			previewImage: file.url || file.preview,
			previewVisible: true,
			previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
		})
	}

	const uploadGalleryImage = async ({ file }) => {
		setIsLoadingGallery(true)
		try {
			const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)

			if (fileUrl) {
				itemContentSetter(item?.id, {
					...item?.content,
					photoLibrary: [
						...(item?.content?.photoLibrary || []),
						{
							name: file.name,
							size: file.size,
							type: file.type,
							uid: file.uid,
							url: fileUrl
						}
					]
				})
			}
		} finally {
			setIsLoadingGallery(false)
		}
	}

	const onRemove = ({ uid }) => {
		const newLibrary = []

		each(item?.content?.photoLibrary, (img) => {
			if (img.uid !== uid) {
				newLibrary.push(img)
			}
		})

		itemContentSetter(item?.id, {
			...item?.content,
			photoLibrary: [...newLibrary]
		})
	}

	return (
		<div className={'custom-item reference'}>
			<div className={'block'}>
				<div style={{ marginRight: '20px' }}>
					<div className={'flex justify-center'} style={{ marginBottom: '15px' }}>
						<Upload
							listType={'picture-card'}
							className={'upload-field'}
							showUploadList={false}
							customRequest={uploadImage}
							disabled={isLoading || isLoadingGallery}
						>
							{/* eslint-disable-next-line no-nested-ternary */}
							{isLoading ? <LoadingOutlined /> : renderProfilePhoto}
						</Upload>
					</div>
				</div>
				<div style={{ width: '100%' }}>
					<Input
						placeholder={t('Meno')}
						className={'input-field'}
						style={{ marginBottom: '15px' }}
						value={item?.content?.name}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('name', e.target.value)}
						onBlur={(e) => setField('name', trim(e.target.value))}
					/>
					<Input
						placeholder={t('Mesto')}
						className={'input-field'}
						style={{ marginBottom: '15px' }}
						value={item?.content?.city}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('city', e.target.value)}
						onBlur={(e) => setField('city', trim(e.target.value))}
					/>
					<Input
						placeholder={t('Úbytok váhy')}
						className={'input-field'}
						style={{ marginBottom: '15px' }}
						type={'number'}
						value={item?.content?.weightLoss}
						maxLength={INPUT_MAX_LENGTH}
						onChange={(e) => setField('weightLoss', parseFloat(e.target.value, 10))}
						onBlur={(e) => setField('weightLoss', trim(parseFloat(e.target.value, 10)))}
					/>
				</div>
			</div>

			<div className={'block'} style={{ overflow: 'auto', paddingBottom: '20px' }}>
				<Upload
					listType={'picture-card'}
					fileList={get(item, 'content.photoLibrary')}
					onChange={null}
					onRemove={onRemove}
					onPreview={handlePreview}
					className={'upload-field'}
					customRequest={uploadGalleryImage}
					style={{ marginBottom: '15px' }}
					disabled={isLoading || isLoadingGallery}
				>
					{isLoadingGallery ? (
						<LoadingOutlined />
					) : (
						<div>
							<PlusOutlined />
							<div>{t('Vyber fotky do galérie')}</div>
						</div>
					)}
				</Upload>
				<Modal
					visible={gallery.previewVisible}
					title={gallery.previewTitle}
					footer={null}
					onCancel={() =>
						setGallery({
							...gallery,
							previewVisible: false
						})
					}
				>
					<img alt={'example'} style={{ width: '100%' }} src={gallery.previewImage} />
				</Modal>
			</div>

			<WYSIWYG
				itemId={get(item, 'id')}
				itemEditor={get(item, 'content.text.editor')}
				itemContentSetter={(itemId, value) => setField('text', value)}
				autofocus
			/>
		</div>
	)
}

Reference.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			name: PropTypes.string,
			city: PropTypes.string,
			text: PropTypes.shape({}),
			weightLoss: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			profilePhoto: PropTypes.string,
			photoLibrary: PropTypes.arrayOf(PropTypes.shape({}))
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Reference.defaultProps = {
	item: {}
}

export default Reference
