import { Modal } from 'antd'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// utils
import { formatPaymentMethod } from '../../../utils/helpers'
import { FORMS, PAYMENT_METHOD } from '../../../utils/enums'

// types
import { PaymentsRefundationFormValues } from '../forms/validatePaymentsRefundationForm'

// form
import PaymentsRefundationForm from '../forms/PaymentsRefundationForm'

type PaymentsRefundationModalProps = {
	setShowModal: (value: boolean) => void
	submitPaymentsRefundation: (values: PaymentsRefundationFormValues) => void
}

const PaymentsRefundationModal = ({ setShowModal, submitPaymentsRefundation }: PaymentsRefundationModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<Modal
			visible
			title={t('Refundovať platby hromadne')}
			okText={t('Refundovať')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(false)}
			onOk={() => dispatch(submit(FORMS.REFUND_PAYMENTS_FORM))}
		>
			<p style={{ padding: '16px', backgroundColor: '#eee', borderRadius: '4px' }}>
				{t('Refundácie platieb uplatnených cez')}
				{': '}
				<b>{formatPaymentMethod(PAYMENT_METHOD.BANK_TRANSACTION)}</b>
				{', '}
				<b>{formatPaymentMethod(PAYMENT_METHOD.TRUSTPAY_BANK)}</b>
				{', '}
				<b>{formatPaymentMethod(PAYMENT_METHOD.SMS)}</b>
				{', '}
				<b>{formatPaymentMethod(PAYMENT_METHOD.IOS_IN_APP_PURCHASE)}</b>
				{', '}
				<b>{formatPaymentMethod(PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE)}</b> {t('nebudú refundované automaticky')}
			</p>
			<PaymentsRefundationForm onSubmit={submitPaymentsRefundation} />
		</Modal>
	)
}

export default PaymentsRefundationModal
