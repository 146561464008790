import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

class PublicRoute extends Route {
	static propTypes = {
		component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
	}

	render() {
		const RouteLayout = this.props.layout
		const RouteComponent = this.props.component
		if (this.props.layout) {
			return (
				<RouteLayout {...this.props}>
					<RouteComponent {...this.props} />
				</RouteLayout>
			)
		}
		return <RouteComponent {...this.props} />
	}
}

export default PublicRoute
