import React, { FC, FormEvent, useState, useEffect, useCallback, useRef, ReactNode } from 'react'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { debounce, trim, some, map } from 'lodash'
import { Table, Popconfirm, Button, Empty, Modal, Input, Switch, Collapse } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, QuestionCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import update from 'immutability-helper'
import { EMPTY_VALUE, INPUT_MAX_LENGTH, LANGUAGE } from '../../utils/enums'
import { RootState } from '../../redux/index'

const { Panel } = Collapse

interface IDataItem {
	title: string
	id: number | string
	key?: number | string
	isVisibleInCalendar?: boolean
	isDifficultyRatingVisible?: boolean
	labelSK?: string
	labelCZ?: string
	program?: string
	customButton?: {
		icon: any
		handler: (arg: any) => void
	}
}

interface IGETConf {
	limit: number
	page: number
	search?: string
}

interface IAsyncTransferField {
	showPagination?: boolean // pagination should be disabled in array that it own item order depends
	customClass?: string
	maxItems?: number | null
	customLabelClass?: string
	showLabel: boolean
	label: string
	button: ReactNode | any
	required?: boolean
	customButton?: {
		icon: any
		handler: (arg: any) => void
	}
	disabledUse?: boolean
	dataSource: Array<IDataItem>
	reduxFetch: (args: IGETConf) => void
	modalDataSource: Array<IDataItem>
	noPageLimit?: boolean
	rowKey: string | 'key'
	includeSwitcher?: boolean
	includeDifficultySwitcher?: boolean
	onSwitchChange?: (...args: any) => void
	onDifficultySwitchChange?: (...args: any) => void
	context: {
		page: number
		count: number
		totalCount: number
	}
	hasAlternateName: boolean
}

type Props = IAsyncTransferField & WrappedFieldProps

interface IDraggableBodyRow {
	index: number
	moveRow: (currentIndex: number, index: number) => void
	className?: string
	style: any
}

const RNDContext = createDndContext(HTML5Backend)
const type = 'DragableBodyRow'
const PAGE_LIMIT = 15

const DraggableBodyRow: FC<IDraggableBodyRow> = ({ index, moveRow, className, style, ...restProps }) => {
	const ref = useRef<any>()
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor: any) => {
			const { index: dragIndex } = monitor.getItem() || {}
			if (dragIndex === index) {
				return {}
			}
			return {
				isOver: monitor.isOver(),
				dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
			}
		},
		drop: (item: any) => {
			moveRow(item.index, index)
		}
	})
	const [, drag] = useDrag({
		item: { type, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	})
	drop(drag(ref))
	return <tr ref={ref} className={cx(className, { [dropClassName || ' drop-over-downward']: isOver })} style={{ cursor: 'move', ...style }} {...restProps} />
}

const AsyncTransferField: FC<Props> = ({
	rowKey,
	customClass,
	meta: { touched, error },
	label,
	modalDataSource,
	context,
	input,
	button,
	reduxFetch,
	required = false,
	includeSwitcher = false,
	includeDifficultySwitcher = false,
	onSwitchChange = () => null,
	onDifficultySwitchChange = () => null,
	showPagination = true,
	maxItems = null,
	disabledUse = false,
	hasAlternateName = false,
	customButton
}) => {
	const isLoading = useSelector<RootState>((state) => state.lists.isLoading)
	const manager = useRef(RNDContext)

	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = useState(1)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [filterModal, setFilterModal] = useState({ page: 1, limit: PAGE_LIMIT })

	const components = {
		body: {
			row: DraggableBodyRow
		}
	}

	const moveRow = useCallback(
		(dragIndex: any, hoverIndex: any) => {
			const dragRow = input.value[dragIndex]

			input.onChange(
				update(input.value, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRow]
					]
				})
			)
		},
		[input]
	)

	useEffect(() => {
		if (!showModal) return
		reduxFetch({
			search,
			...filterModal
		})
	}, [filterModal])

	useEffect(() => {
		if (!showModal) return
		reduxFetch({
			search,
			...filterModal,
			page: 1
		})
	}, [search, showModal])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce((searchTerm) => setSearch(searchTerm), 300),
		[]
	)

	const handleSearch = (e: FormEvent<HTMLInputElement>) => {
		const searchInput = e.currentTarget.value || ''
		if (trim(searchInput).length === 0 || trim(searchInput).length > 2) {
			debounced(e.currentTarget.value)
		}
	}

	const handleAddItem = (item: IDataItem) => {
		if (!(maxItems && input.value?.length >= maxItems)) {
			if (!some(input.value, ['id', item.id])) {
				input.onChange([...input.value, item])
			}
		}
	}

	const handleAddItemLabelDebounced = debounce((item: IDataItem, labelType: string, value: string) => {
		input.onChange(
			[...input.value].map((record) => {
				return record.id === item.id ? { ...record, [labelType]: value } : record
			})
		)
	}, 300)

	const handleDeleteItem = (item: IDataItem) => {
		if (some(input.value, ['id', item.id])) {
			const newArray = input.value.filter((inputItem: IDataItem) => item.id !== inputItem.id)
			input.onChange(newArray as IDataItem[])
		}
	}

	const handleTableChange = (pagination: any) => {
		setFilterModal({
			...filterModal,
			page: pagination.current
		})
	}

	const handleFieldTableChange = (pagination: any) => {
		setCurrentPage(pagination.current)
	}

	const renderLabel = (row: IDataItem) => {
		if (row.title && row.program) {
			return (
				<>
					<span>{row.title}</span> <span style={{ color: 'gray', fontSize: '12px' }}>{`${row.program}`}</span>
				</>
			)
		}
		return <span>{row.title || EMPTY_VALUE}</span>
	}

	const modalColumns: ColumnsType<IDataItem> = [
		{
			title: t('Názov') as string,
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			render: (value: string, row: IDataItem) => {
				if (some(input.value, ['id', row?.id])) {
					return <span style={{ color: 'rgba(0, 0, 0, 0.2)' }}>{value}</span>
				}
				return renderLabel(row)
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (record: IDataItem) => {
				if (!some(input.value, ['id', record?.id])) {
					return (
						<Button
							icon={<PlusCircleOutlined />}
							type={'primary'}
							disabled={maxItems ? input.value?.length >= maxItems : false}
							size={'small'}
							onClick={(e) => {
								e.stopPropagation()
								handleAddItem(record)
							}}
						/>
					)
				}
				return (
					<Button
						icon={<DeleteOutlined />}
						type={'default'}
						size={'small'}
						danger={true}
						onClick={(e) => {
							e.stopPropagation()
							handleDeleteItem(record)
						}}
					/>
				)
			}
		}
	]

	const columns: ColumnsType<IDataItem> = [
		{
			title: t('Názov') as string,
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			render: (value: string, item: IDataItem) => (
				<>
					<Input type={'text'} value={item.title || EMPTY_VALUE} readOnly />
					{hasAlternateName && (
						<>
							<div style={{ display: 'flex', margin: '4px 0' }}>
								<Input
									type={'text'}
									defaultValue={item.labelSK}
									addonBefore={LANGUAGE.SK}
									onChange={(e) => handleAddItemLabelDebounced(item, 'labelSK', e.target.value)}
								/>
								<div style={{ width: '16px' }} />
								<Input
									type={'text'}
									defaultValue={item.labelCZ}
									addonBefore={LANGUAGE.CZ}
									onChange={(e) => handleAddItemLabelDebounced(item, 'labelCZ', e.target.value)}
								/>
							</div>
							<div className={'tooltip-error'}>{item.labelSK === '' || item.labelCZ === '' ? error : ''}</div>
						</>
					)}
				</>
			)
		},
		{
			title: () => (
				<Button
					icon={<PlusCircleOutlined />}
					type={'primary'}
					onClick={(e) => {
						e.stopPropagation()
						setShowModal(true)
					}}
					disabled={maxItems ? input.value?.length >= maxItems : false}
				>
					{t('Pridať') as ReactNode}
				</Button>
			),
			key: 'operation',
			fixed: 'right',
			width: 108,
			render: (record: IDataItem) => (
				<>
					{customButton && (
						<Button
							icon={customButton.icon}
							type={'default'}
							size={'small'}
							style={{ width: '40px', height: '27px', marginRight: '5px' }}
							onClick={(e) => {
								e.stopPropagation()
								customButton?.handler(record.id)
							}}
						/>
					)}
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?') as ReactNode}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť') as ReactNode}
						okText={t('Vymazať') as ReactNode}
						onConfirm={(e) => {
							e?.stopPropagation()
							handleDeleteItem(record)
						}}
						onCancel={(e) => e?.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button
							icon={<DeleteOutlined />}
							type={'default'}
							size={'small'}
							danger={true}
							className={cx('async-picker-delete-button', { 'with-custom-button': customButton })}
							onClick={(e) => {
								e.stopPropagation()
							}}
						/>
					</Popconfirm>
				</>
			)
		}
	]

	if (includeDifficultySwitcher) {
		columns?.unshift({
			title: t('Zmena obťiažnosti'),
			dataIndex: 'isDifficultyRatingVisible',
			key: 'isDifficultyRatingVisible',
			ellipsis: true,
			width: 140,
			render: (value: string, record: IDataItem) => (
				<Switch checked={record.isDifficultyRatingVisible || false} onChange={(e) => onDifficultySwitchChange(e, record)} />
			)
		})
	}

	if (includeSwitcher) {
		columns?.unshift({
			title: t('Zobraziť v kalendári') as string,
			dataIndex: 'isVisibleInCalendar',
			key: 'isVisibleInCalendar',
			ellipsis: true,
			width: 160,
			render: (value: string, record: IDataItem) => <Switch checked={record.isVisibleInCalendar || false} onChange={(e) => onSwitchChange(e, record)} />
		})
	}

	const itemCount = maxItems ? `${input.value?.length}/${maxItems} ${t('položiek')}` : `${input.value?.length} ${t('položiek')}`

	const lab = <span className={cx({ required })}>{label}</span>

	return (
		<>
			<Collapse bordered={false} className={'async-trans-field-colapse'}>
				<div className={'counter'}>{itemCount}</div>
				{disabledUse && <div className={'cover-loading'} />}
				{button && <div className={'buttonWrapper'}>{button}</div>}
				<Panel header={lab} key={'1'}>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore  */}
					<DndProvider manager={manager.current.dragDropManager as any}>
						<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
							<Table
								columns={
									map(columns, (item: IDataItem) => ({
										...item,
										key: item.id
									})) as unknown as ColumnsType<IDataItem>
								}
								dataSource={input.value}
								onChange={handleFieldTableChange}
								style={{ marginTop: 0 }}
								pagination={
									showPagination
										? {
												pageSize: 8,
												total: input?.value?.length,
												current: currentPage
											}
										: false
								}
								loading={false}
								locale={{
									emptyText: t('Pole je prázdne')
								}}
								size={'small'}
								components={components}
								onRow={(record, index) =>
									({
										index,
										moveRow
									}) as any
								}
								rowKey={rowKey || 'key'}
							/>
							<Modal
								visible={showModal}
								title={label}
								width={'700px'}
								okText={t('Hotovo') as string}
								cancelText={t('Zavrieť') as string}
								className={'thumbnail-generator-modal'}
								onCancel={() => {
									setShowModal(false)
									setSearch(undefined)
								}}
								onOk={() => setShowModal(false)}
							>
								<div className='search-bar'>
									<Input
										size={'large'}
										placeholder={t('Hľadaj...')}
										maxLength={INPUT_MAX_LENGTH}
										prefix={<SearchOutlined />}
										onChange={handleSearch}
									/>
								</div>
								<Table
									columns={
										map(modalColumns, (item: IDataItem) => ({
											...item,
											key: item.id
										})) as unknown as ColumnsType<IDataItem>
									}
									dataSource={modalDataSource}
									style={{ marginTop: 0 }}
									onChange={handleTableChange}
									pagination={{
										pageSize: PAGE_LIMIT,
										total: context?.totalCount,
										current: context?.page || 1,
										showSizeChanger: false
									}}
									loading={isLoading as boolean}
									locale={{
										emptyText: <Empty description={t('Žiadne dáta') as string} />
									}}
									size={'small'}
								/>
							</Modal>
						</div>
					</DndProvider>
				</Panel>
			</Collapse>
			<div className={'tooltip-error'} style={{ marginTop: '-8px', marginBottom: '8px' }}>
				{touched ? error : ''}
			</div>
		</>
	)
}

export default AsyncTransferField
