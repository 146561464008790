import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import TextField from '../atoms/form/TextField'
import { FORMS } from '../utils/enums'
import validateChangePasswordForm from './validators/validateChangePasswordForm'

const ChangePasswordForm = ({ handleSubmit, onSubmit }) => {
	const { t } = useTranslation()
	return (
		<form className={'new-password-form'} onSubmit={handleSubmit(onSubmit)}>
			<Field name={'oldPassword'} component={TextField} type={'password'} label={t('Staré heslo')} required />
			<Field name={'newPassword'} component={TextField} type={'password'} label={t('Nové heslo')} required />
			<Field name={'confirmPassword'} component={TextField} type={'password'} label={t('Zopakujte heslo')} required />
		</form>
	)
}

ChangePasswordForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.CHANGE_PASSWORD_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateChangePasswordForm
})(ChangePasswordForm)
