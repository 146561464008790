import {
	BarChartOutlined,
	// BarcodeOutlined,
	// CompassOutlined,
	DownloadOutlined,
	// DribbbleOutlined,
	EuroOutlined,
	// FileTextOutlined,
	// GiftOutlined,
	// HomeOutlined,
	NotificationOutlined,
	// OrderedListOutlined,
	PicLeftOutlined,
	// PictureOutlined,
	// PlayCircleOutlined,
	PlaySquareOutlined,
	// ProfileOutlined,
	// PushpinOutlined,
	// QuestionOutlined,
	SettingOutlined,
	TeamOutlined
	// UserOutlined,
	// VideoCameraOutlined
	// MoneyCollectOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import { get, includes } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { resetFilters } from '../redux/filters/actions'
import { ADMIN_ROLES_WEIGHTS, getRouteAllowedRoles } from '../utils/enums'

const MENU_OPTION_ITEM = {
	PAYMENTS: 'PAYMENTS_OPTIONS',
	BLOG: 'BLOG_OPTIONS',
	EXERCISES: 'EXERCISES_OPTIONS',
	DOWNLOAD: 'DOWNLOAD_OPTIONS',
	PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS_OPTIONS',
	SETTINGS: 'SETTINGS_OPTIONS'
}

const SideMenu = ({ routeKey }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const profile = useSelector((state) => get(state, 'user.profile'))
	const routeAllowedRoles = getRouteAllowedRoles()
	const isAllowed = (userProfile, key) => ADMIN_ROLES_WEIGHTS[get(userProfile, 'role')] <= ADMIN_ROLES_WEIGHTS[routeAllowedRoles[key]]

	useEffect(() => {
		dispatch(resetFilters())
	}, [routeKey, dispatch])

	const getItem = (key, icon, label, children) => {
		if (isAllowed(profile, key) || includes(MENU_OPTION_ITEM, key)) return { key, icon, children, label }
		return undefined
	}

	// flat level menu
	// const menuItems = [
	// 	getItem(t('paths:dashboard|key'), <BarChartOutlined />, <Link to={t('paths:dashboard|path')}>{t('paths:dashboard|title')}</Link>),
	// 	getItem(t('paths:instructors|key'), <UserOutlined />, <Link to={t('paths:instructors|path')}>{t('paths:instructors|title')}</Link>),
	// 	getItem(t('paths:videos|key'), <VideoCameraOutlined />, <Link to={t('paths:videos|path')}>{t('paths:videos|title')}</Link>),
	// 	getItem(t('paths:exercises|key'), <PlaySquareOutlined />, <Link to={t('paths:exercises|path')}>{t('paths:exercises|title')}</Link>),
	// 	getItem(t('paths:programs|key'), <ProfileOutlined />, <Link to={t('paths:programs|path')}>{t('paths:programs|title')}</Link>),
	// 	getItem(t('paths:exerciseTargets|key'), <CompassOutlined />, <Link to={t('paths:exerciseTargets|path')}>{t('paths:exerciseTargets|title')}</Link>),
	// 	getItem(
	// 		t('paths:motivationAdvices|key'),
	// 		<QuestionOutlined />,
	// 		<Link to={t('paths:motivationAdvices|path')}>{t('paths:motivationAdvices|title')}</Link>
	// 	),
	// 	getItem(t('paths:accessories|key'), <DribbbleOutlined />, <Link to={t('paths:accessories|path')}>{t('paths:accessories|title')}</Link>),
	// 	getItem(t('paths:users|key'), <TeamOutlined />, <Link to={t('paths:users|path')}>{t('paths:users|title')}</Link>),
	// 	getItem(t('paths:payments|key'), <EuroOutlined />, <Link to={t('paths:payments|path')}>{t('paths:payments|title')}</Link>),
	// 	getItem(t('paths:donations|key'), <GiftOutlined />, <Link to={t('paths:donations|path')}>{t('paths:donations|title')}</Link>),
	// 	getItem(
	// 		t('paths:liveStreamCategories|key'),
	// 		<OrderedListOutlined />,
	// 		<Link to={t('paths:liveStreamCategories|path')}>{t('paths:liveStreamCategories|title')}</Link>
	// 	),
	// 	getItem(t('paths:blog|key'), <PicLeftOutlined />, <Link to={t('paths:blog|path')}>{t('paths:blog|title')}</Link>),
	// 	getItem(t('paths:blogCategories|key'), <OrderedListOutlined />, <Link to={t('paths:blogCategories|path')}>{t('paths:blogCategories|title')}</Link>),
	// 	getItem(t('paths:downloads|key'), <DownloadOutlined />, <Link to={t('paths:downloads|path')}>{t('paths:downloads|title')}</Link>),
	// 	getItem(
	// 		t('paths:downloadCategories|key'),
	// 		<OrderedListOutlined />,
	// 		<Link to={t('paths:downloadCategories|path')}>{t('paths:downloadCategories|title')}</Link>
	// 	),
	// 	getItem(t('paths:tags|key'), <FileTextOutlined />, <Link to={t('paths:tags|path')}>{t('paths:tags|title')}</Link>),
	// 	getItem(t('paths:banners|key'), <PictureOutlined />, <Link to={t('paths:banners|path')}>{t('paths:banners|title')}</Link>),
	// 	getItem(t('paths:settings|key'), <SettingOutlined />, <Link to={t('paths:settings|path')}>{t('paths:settings|title')}</Link>),
	// 	getItem(t('paths:pages|key'), <FileTextOutlined />, <Link to={t('paths:pages|path')}>{t('paths:pages|title')}</Link>),
	// 	getItem(t('paths:landing|key'), <HomeOutlined />, <Link to={t('paths:landing|path')}>{t('paths:landing|title')}</Link>),
	// 	getItem(t('paths:promoCodes|key'), <BarcodeOutlined />, <Link to={t('paths:promoCodes|path')}>{t('paths:promoCodes|title')}</Link>),
	// 	getItem(t('paths:promoCodesUsage|key'), <BarcodeOutlined />, <Link to={t('paths:promoCodesUsage|path')}>{t('paths:promoCodesUsage|title')}</Link>),
	// 	getItem(t('paths:administrators|key'), <UserOutlined />, <Link to={t('paths:administrators|path')}>{t('paths:administrators|title')}</Link>),
	// 	getItem(t('paths:translations|key'), <FileTextOutlined />, <Link to={t('paths:translations|path')}>{t('paths:translations|title')}</Link>),
	// 	getItem(t('paths:preferences|key'), <PushpinOutlined />, <Link to={t('paths:preferences|path')}>{t('paths:preferences|title')}</Link>)
	// ]

	const menuItems = [
		getItem(t('paths:dashboard|key'), <BarChartOutlined />, <Link to={t('paths:dashboard|path')}>{t('paths:dashboard|title')}</Link>),
		getItem(MENU_OPTION_ITEM.EXERCISES, <PlaySquareOutlined />, t('Správa cvičení'), [
			getItem(t('paths:instructors|key'), null, <Link to={t('paths:instructors|path')}>{t('paths:instructors|title')}</Link>),
			getItem(t('paths:videos|key'), null, <Link to={t('paths:videos|path')}>{t('paths:videos|title')}</Link>),
			getItem(t('paths:exercises|key'), null, <Link to={t('paths:exercises|path')}>{t('paths:exercises|title')}</Link>),
			getItem(t('paths:programs|key'), null, <Link to={t('paths:programs|path')}>{t('paths:programs|title')}</Link>),
			getItem(t('paths:accessories|key'), null, <Link to={t('paths:accessories|path')}>{t('paths:accessories|title')}</Link>),
			getItem(t('paths:liveStreamCategories|key'), null, <Link to={t('paths:liveStreamCategories|path')}>{t('paths:liveStreamCategories|title')}</Link>),
			getItem(t('paths:exerciseTargets|key'), null, <Link to={t('paths:exerciseTargets|path')}>{t('paths:exerciseTargets|title')}</Link>),
			getItem(t('paths:preferences|key'), null, <Link to={t('paths:preferences|path')}>{t('paths:preferences|title')}</Link>),
			getItem(t('paths:motivationAdvices|key'), null, <Link to={t('paths:motivationAdvices|path')}>{t('paths:motivationAdvices|title')}</Link>)
		]),
		getItem(MENU_OPTION_ITEM.BLOG, <PicLeftOutlined />, t('Blog a stránky'), [
			getItem(t('paths:blog|key'), null, <Link to={t('paths:blog|path')}>{t('Články')}</Link>),
			getItem(t('paths:pages|key'), null, <Link to={t('paths:pages|path')}>{t('paths:pages|title')}</Link>),
			getItem(t('paths:tags|key'), null, <Link to={t('paths:tags|path')}>{t('paths:tags|title')}</Link>),
			getItem(t('paths:blogCategories|key'), null, <Link to={t('paths:blogCategories|path')}>{t('paths:blogCategories|title')}</Link>)
		]),
		getItem(t('paths:users|key'), <TeamOutlined />, <Link to={t('paths:users|path')}>{t('paths:users|title')}</Link>),
		getItem(MENU_OPTION_ITEM.PAYMENTS, <EuroOutlined />, t('paths:payments|title'), [
			getItem(t('paths:payments|key'), null, <Link to={t('paths:payments|path')}>{t('Zoznam platieb')}</Link>),
			getItem(t('paths:promoCodes|key'), null, <Link to={t('paths:promoCodes|path')}>{t('paths:promoCodes|title')}</Link>),
			getItem(t('paths:promoCodesUsage|key'), null, <Link to={t('paths:promoCodesUsage|path')}>{t('paths:promoCodesUsage|title')}</Link>),
			getItem(t('paths:donations|key'), null, <Link to={t('paths:donations|path')}>{t('paths:donations|title')}</Link>)
		]),
		getItem(MENU_OPTION_ITEM.DOWNLOAD, <DownloadOutlined />, t('paths:downloads|title'), [
			getItem(t('paths:downloads|key'), null, <Link to={t('paths:downloads|path')}>{t('Súbory')}</Link>),
			getItem(t('paths:downloadCategories|key'), null, <Link to={t('paths:downloadCategories|path')}>{t('paths:downloadCategories|title')}</Link>)
		]),
		getItem(MENU_OPTION_ITEM.PUSH_NOTIFICATIONS, <NotificationOutlined />, t('paths:pushNotifications|title'), [
			getItem(t('paths:pushNotifications|key'), null, <Link to={t('paths:pushNotifications|path')}>{t('Zoznam')}</Link>),
			getItem(
				t('paths:pushNotificationsStats|key'),
				null,
				<Link to={t('paths:pushNotificationsStats|path')}>{t('paths:pushNotificationsStats|title')}</Link>
			)
		]),
		getItem(MENU_OPTION_ITEM.SETTINGS, <SettingOutlined />, t('paths:settings|title'), [
			getItem(t('paths:landing|key'), null, <Link to={t('paths:landing|path')}>{t('paths:landing|title')}</Link>),
			getItem(t('paths:banners|key'), null, <Link to={t('paths:banners|path')}>{t('paths:banners|title')}</Link>),
			// getItem(t('paths:giftProducts|key'), null, <Link to={t('paths:giftProducts|path')}>{t('paths:giftProducts|title')}</Link>),
			getItem(t('paths:administrators|key'), null, <Link to={t('paths:administrators|path')}>{t('paths:administrators|title')}</Link>),
			getItem(t('paths:translations|key'), null, <Link to={t('paths:translations|path')}>{t('paths:translations|title')}</Link>),
			getItem(t('paths:settings|key'), null, <Link to={t('paths:settings|path')}>{t('Cenník')}</Link>)
		])
	]

	return (
		<Menu
			theme={window.location.host === 'fitshaker-admin-test.goodrequest.dev' ? 'light' : 'dark'}
			mode={'inline'}
			selectedKeys={[routeKey]}
			items={menuItems}
		/>
	)
}

SideMenu.propTypes = {
	routeKey: PropTypes.string
}

SideMenu.defaultProps = {
	routeKey: ''
}

export default SideMenu
