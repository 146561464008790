import { useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteMotivationAdvice } from '../../../redux/motivationAdvices/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateMotivationAdviceForm, { MotivationAdviceFormValues } from './validateMotivationAdviceForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'

const { TabPane } = Tabs

type MotivationAdviceFormProps = {
	isCreate?: boolean
}

const MotivationAdviceForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: MotivationAdviceFormProps & InjectedFormProps<MotivationAdviceFormValues, MotivationAdviceFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.MOTIVATION_ADVICE_FORM)(state)) as MotivationAdviceFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.MOTIVATION_ADVICE_FORM))

	// states
	const [image, setImage] = useState({
		isLoading: false
	})

	// constants
	const fieldLabels: IFieldLabels = {
		name: t('Názov'),
		text_sk: t('Text')
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať motivačný tip?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteMotivationAdvice(get(fieldValues, 'id'), () => {
						history.push(t('paths:motivationAdvices|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.MOTIVATION_ADVICE)

		if (fileUrl) {
			dispatch(change(FORMS.MOTIVATION_ADVICE_FORM, 'image', fileUrl))
			setImage({
				isLoading: false
			})
		}
	}

	const removeImage = () => {
		setImage({
			isLoading: true
		})
		dispatch(change(FORMS.MOTIVATION_ADVICE_FORM, 'image', null))
		setImage({
			isLoading: false
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať motivačný tip') : t('Detail motivačného tipu')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový motivačný tip'),
										path: t('paths:motivationAdviceCreate|path')
									}
								]
							}
						/>

						<Field name={'name'} component={TextField} label={t('Názov')} required />

						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field name={`link_${item}`} component={TextField} label={t('Odkaz')} />

									<Field name={`text_${item}`} component={WYSIWYGEditor} label={t('Text')} showUntouchedErrors required />
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}
						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Obrázok (upload)')}
							customHelper={t('recommendedSize|videoThumbnail')}
							customRequest={uploadImage}
							onRemove={removeImage}
							isLoading={image.isLoading}
						/>
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<MotivationAdviceFormValues, MotivationAdviceFormProps>({
	form: FORMS.MOTIVATION_ADVICE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateMotivationAdviceForm
})(MotivationAdviceForm)
