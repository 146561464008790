import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq } from '../../utils/request'

// types
import { Paths } from '../../types/api'
import {
	GET_DASHBOARD,
	GET_EXERCISES_VIEWS_STATISTICS,
	GET_PROGRAMS_ENGAGEMENT_STATISTICS,
	GET_SUBSCRIPTION_STATISTICS,
	GET_STATISTICS,
	GET_EXERCISES_FAVOURTITES_STATISTICS,
	GET_EXERCISES_VIEWS_TREND_STATISTICS,
	GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS,
	GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY,
	GET_PAYMENTS_STATISTICS,
	GET_RECIPE_BOOKS_STATISTICS,
	DashboardPagePayload,
	StatisticsPayload,
	SubscriptionStatisticsPayload,
	ProgramsEngagementStatisticsPayload,
	ProgramEngagementTrendStatisticsPayload,
	ExercisesViewsStatisticsPayload,
	ExercisesViewsTrendStatisticsPayload,
	ExercisesFavouritesStatisticsPayload,
	PaymentsStatisticsPayload,
	RecipeBooksStatisticsPayload
} from './types'

export const getDashboardPage = (onSuccess?: (data: DashboardPagePayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_DASHBOARD.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_DASHBOARD)
		dispatch({ type: GET_DASHBOARD.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_DASHBOARD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getStatistics =
	(params: Paths.GetAdminStatistics.QueryParameters, onSuccess?: (data: StatisticsPayload) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_STATISTICS.START })
		try {
			const queries = {
				limit: 15,
				page: 1,
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getSubscriptionStatistics =
	(
		params: Paths.GetAdminStatisticsSubscriptions.QueryParameters,
		onSuccess?: (data: SubscriptionStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_SUBSCRIPTION_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.SUBSCRIPTION_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_SUBSCRIPTION_STATISTICS.DONE, payload: data?.subscriptionStatistics })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_SUBSCRIPTION_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getProgramsEngagementStatistics =
	(
		params: Paths.GetAdminStatisticsProgramsEngagements.QueryParameters,
		onSuccess?: (data: ProgramsEngagementStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PROGRAMS_ENGAGEMENT_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.PROGRAMS_ENGAGEMENT_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_PROGRAMS_ENGAGEMENT_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PROGRAMS_ENGAGEMENT_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getProgramEngagementTrendStatistics =
	(
		params: Paths.GetAdminStatisticsProgramsEngagementsTrends.QueryParameters,
		onSuccess?: (data: ProgramEngagementTrendStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.PROGRAM_ENGAGEMENT_TREND_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getProgramEngagementTrendStatisticsPerDay =
	(
		params: Paths.GetAdminStatisticsProgramsEngagementsTrends.QueryParameters,
		onSuccess?: (data: ProgramEngagementTrendStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.PROGRAM_ENGAGEMENT_TREND_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getExercisesViewStatistics =
	(
		params: Paths.GetAdminStatisticsExercisesViews.QueryParameters,
		onSuccess?: (data: ExercisesViewsStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_EXERCISES_VIEWS_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.EXERCISES_VIEW_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_EXERCISES_VIEWS_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_EXERCISES_VIEWS_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getExercisesViewsTrendStatistics =
	(
		params: Paths.GetAdminStatisticsExercisesViewsTrends.QueryParameters,
		onSuccess?: (data: ExercisesViewsTrendStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_EXERCISES_VIEWS_TREND_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.EXERCISES_TREND_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_EXERCISES_VIEWS_TREND_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_EXERCISES_VIEWS_TREND_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getExercisesFavouritesStatistics =
	(
		params: Paths.GetAdminStatisticsExercisesFavourites.QueryParameters,
		onSuccess?: (data: ExercisesFavouritesStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_EXERCISES_FAVOURTITES_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.EXERCISES_FAVOURITES_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_EXERCISES_FAVOURTITES_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_EXERCISES_FAVOURTITES_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getPaymentsStatistics =
	(params: Paths.GetAdminStatisticsPayments.QueryParameters, onSuccess?: (data: PaymentsStatisticsPayload) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PAYMENTS_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.PAYMENTS_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_PAYMENTS_STATISTICS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PAYMENTS_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getRecipeBooksStatistics =
	(
		params: Paths.GetAdminStatisticsNutritionPlans.QueryParameters,
		onSuccess?: (data: RecipeBooksStatisticsPayload) => void,
		onFailure?: (error: unknown) => void
	) =>
	async (dispatch: any) => {
		dispatch({ type: GET_RECIPE_BOOKS_STATISTICS.START })
		try {
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.RECIPE_BOOKS_STATISTICS, normalizeQueryParams)
			dispatch({ type: GET_RECIPE_BOOKS_STATISTICS.DONE, payload: data?.nutritionPlanStatistics })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_RECIPE_BOOKS_STATISTICS.FAILED })
			return onFailure && onFailure(error)
		}
	}
