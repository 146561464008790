import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const DateChips = styled.div<{ color: string }>`
	display: inline-block;
	background-color: #fdfdfd;
	color: ${({ color }) => color};
	border-radius: 4px;
	padding: 2px 8px;
	text-align: center;
	width: 140px;
	border: 1px solid #f1f1f1;
`

interface IDateRepresent {
	value?: string | number | null
	text?: string
	showTime?: boolean
	color?: string
}

const DateRepresent: React.FC<IDateRepresent> = ({ text = null, value, showTime = true, color = '#000000' }) => {
	const { t } = useTranslation()
	const isEmptyDate = value === '' || value === undefined || value === null || value === 0
	const output = text || dayjs(value)?.format(showTime ? 'D. MMM YYYY HH:mm' : 'D. MMM YYYY') || '---'

	return (isEmptyDate && text === null) || !dayjs(value).isValid() ? (
		<Tooltip title={t('Dátum neexistuje')} placement={'bottom'}>
			<DateChips color={color}>{'---'}</DateChips>
		</Tooltip>
	) : (
		<DateChips color={color}>{output}</DateChips>
	)
}

export default DateRepresent
