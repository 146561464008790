import { useState } from 'react'
import PropTypes from 'prop-types'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { get, map, find } from 'lodash'
import cx from 'classnames'
import i18next, { t } from 'i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Checkbox, Popconfirm } from 'antd'

// types
import { ITEM_TYPES } from '../enums/enums'

// components
import Item from './Item'

// assets
import addIcon from '../assets/addIcon.svg'
import deleteIcon from '../assets/deleteIcon.svg'
import handleIcon from '../assets/handleIcon.svg'
import addIconWhite from '../assets/addIconWhite.svg'
import deleteIconWhite from '../assets/deleteIconWhite.svg'
import handleIconWhite from '../assets/handleIconWhite.svg'

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#ffefed' : '',
	borderRadius: '5px'
})

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	...draggableStyle
})

const GridItem = ({ index, item, items, onDeleteItem, onAddItem, itemSetter, itemContentSetter, name, staticGrid, specificData, noCenter }) => {
	const [borderBoxState, setBorderBoxState] = useState(null)

	const setCenter = (value) => {
		itemSetter(item.id, { ...item, center: value })
	}

	const renderContent = (provided) => (
		<>
			<div className={'row'}>
				<div className={'col-12 header'}>
					{!staticGrid && (
						<div
							{...(provided ? provided.dragHandleProps : [])}
							className={'tab-button handle'}
							onMouseEnter={() => setBorderBoxState('handle')}
							onMouseLeave={() => setBorderBoxState(null)}
						>
							<div style={{ backgroundImage: `url(${borderBoxState === 'handle' ? handleIconWhite : handleIcon})` }} />
						</div>
					)}
					<div
						className={'tab-button add'}
						onMouseEnter={() => setBorderBoxState('add')}
						onMouseLeave={() => setBorderBoxState(null)}
						onClick={() =>
							onAddItem(
								(() => {
									switch (get(item, 'type')) {
										case ITEM_TYPES.FAQ:
											return {
												item,
												questionIndex: get(specificData, 'questionIndex')
											}
										default:
											return item
									}
								})()
							)
						}
					>
						<div style={{ backgroundImage: `url(${borderBoxState === 'add' ? addIconWhite : addIcon})` }} />
					</div>
					{!staticGrid && (
						<Popconfirm
							title={i18next.t('Skutočne chcete vymazať komponent?')}
							icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
							cancelText={i18next.t('Zrušiť')}
							okText={i18next.t('Vymazať')}
							onConfirm={(e) => {
								e.stopPropagation()
								onDeleteItem(item)
							}}
							onCancel={(e) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'danger'
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<div className={'tab-button delete'} onMouseEnter={() => setBorderBoxState('delete')} onMouseLeave={() => setBorderBoxState(null)}>
								<div style={{ backgroundImage: `url(${borderBoxState === 'delete' ? deleteIconWhite : deleteIcon})` }} />
							</div>
						</Popconfirm>
					)}
					<div className={'tab-button name'}>{name || get(item, 'name')}</div>
				</div>
			</div>
			<div className={cx('border-box flex direction-col', { [borderBoxState]: borderBoxState })}>
				{!noCenter && item?.name !== '1' && (
					<div className={'flex justify-center width-100 pl-3 pr-3 pb-3 mb-3 border-bottom'}>
						<Checkbox checked={item?.center || false} onChange={(e) => setCenter(e.target.checked)}>
							{t('Centrovať GRID')}
						</Checkbox>
					</div>
				)}
				<Droppable
					droppableId={(() => {
						switch (get(item, 'type')) {
							case ITEM_TYPES.FAQ:
								return `${item.id}|${specificData.questionIndex}`
							default:
								return item.id
						}
					})()}
					type={(() => {
						switch (get(item, 'type')) {
							case ITEM_TYPES.FAQ:
								return 'droppableFaqQuestionItem'
							default:
								return 'droppableSubItem'
						}
					})()}
				>
					{(innerProvided, innerSnapshot) => (
						<div
							ref={innerProvided.innerRef}
							style={getListStyle(innerSnapshot.isDraggingOver)}
							className={cx('row inner-items', { 'is-dragging-over': innerSnapshot.isDraggingOver }, { 'allow-options': !noCenter })}
						>
							{(() => {
								let itemsOrder = []
								switch (get(item, 'type')) {
									case ITEM_TYPES.FAQ:
										itemsOrder = item.content.questions[specificData.questionIndex].itemsOrder
										break
									default:
										itemsOrder = item.itemsOrder
								}
								return map(itemsOrder, (itemId, innerIndex) => {
									const foundedItem = find(items, { id: itemId })

									if (foundedItem) {
										if (foundedItem.type === ITEM_TYPES.GRID) {
											return (
												<GridItem
													key={itemId}
													item={foundedItem}
													items={items}
													index={innerIndex}
													onDeleteItem={onDeleteItem}
													onAddItem={onAddItem}
													itemContentSetter={itemContentSetter}
												/>
											)
										}

										return (
											<Item
												key={itemId}
												item={foundedItem}
												index={innerIndex}
												onDeleteItem={onDeleteItem}
												itemContentSetter={itemContentSetter}
											/>
										)
									}
									return null
								})
							})()}
							{innerProvided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		</>
	)

	const renderDraggable = () => (
		<Draggable key={get(item, 'id')} draggableId={get(item, 'id')} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					className={cx('item', `col-${get(item, 'width', 12)}`, {
						dragging: snapshot.isDragging,
						'margin-auto auto-horizontal': item?.center
					})}
					style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
				>
					{renderContent(provided)}
				</div>
			)}
		</Draggable>
	)

	return staticGrid ? renderContent() : renderDraggable()
}

GridItem.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		content: PropTypes.oneOfType(PropTypes.shape({}), PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.shape({})))),
		itemsOrder: PropTypes.arrayOf(PropTypes.string),
		width: PropTypes.number,
		center: PropTypes.bool
	}),
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onDeleteItem: PropTypes.func.isRequired,
	onAddItem: PropTypes.func.isRequired,
	itemSetter: PropTypes.func,
	itemContentSetter: PropTypes.func.isRequired,
	name: PropTypes.string,
	staticGrid: PropTypes.bool,
	specificData: PropTypes.shape(),
	noCenter: PropTypes.bool
}

GridItem.defaultProps = {
	item: {
		id: null,
		name: null,
		content: [],
		width: 12,
		center: false
	},
	name: '',
	staticGrid: false,
	itemSetter: () => {},
	specificData: {},
	noCenter: false
}

export default GridItem
