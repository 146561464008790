import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES } from '../../../utils/enums'

export type ChallengeFormValues = {
	id: number
	image: string
	logo: string
	inverseLogo?: string
	order: number
	title_sk: string
	subtitle_sk: string
	description_sk: string
	buttonUrl_sk: string
	buttonText_sk: string
	title_cz: string
	subtitle_cz: string
	description_cz: string
	buttonUrl_cz: string
	buttonText_cz: string
	title_en: string
	subtitle_en: string
	description_en: string
	buttonUrl_en: string
	buttonText_en: string
}

export default (values: ChallengeFormValues) => {
	const errors: FormErrors<ChallengeFormValues> = {}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if (!values.title_sk) {
		errors.title_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.logo) {
		errors.logo = i18next.t('Toto pole je povinné')
	}

	if (!values.order) {
		errors.order = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 0) {
		errors.order = i18next.t('Poradie nemôže byť záporné číslo')
	}

	each(LANGUAGES, (item) => {
		const title = get(values, `title_${item}`)
		const subtitle = get(values, `subtitle_${item}`)
		const description = get(values, `description_${item}`)
		const buttonUrl = get(values, `buttonUrl_${item}`)
		const buttonText = get(values, `buttonText_${item}`)

		if (title || subtitle || description || buttonUrl || buttonText) {
			if (!values[`title_${item}`]) {
				errors[`title_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`subtitle_${item}`]) {
				errors[`subtitle_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`description_${item}`]) {
				errors[`description_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`buttonUrl_${item}`]) {
				errors[`buttonUrl_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`buttonText_${item}`]) {
				errors[`buttonText_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	return errors
}
