// types
import { Paths } from '../../types/api'

export type ExercisesPayload = Paths.GetAdminExercises.Responses.$200['exercises']
export type ExercisePayload = Paths.GetAdminExercisesId.Responses.$200

export enum GET_EXERCISES {
	START = 'GET_EXERCISES_START',
	DONE = 'GET_EXERCISES_DONE',
	FAILED = 'GET_EXERCISES_FAILED'
}

export enum GET_EXERCISE {
	START = 'GET_EXERCISE_START',
	DONE = 'GET_EXERCISE_DONE',
	FAILED = 'GET_EXERCISE_FAILED'
}

export enum DELETE_EXERCISE {
	START = 'DELETE_EXERCISE_START',
	DONE = 'DELETE_EXERCISE_DONE',
	FAILED = 'DELETE_EXERCISE_FAILED'
}

export enum CREATE_EXERCISE {
	START = 'CREATE_EXERCISE_START',
	DONE = 'CREATE_EXERCISE_DONE',
	FAILED = 'CREATE_EXERCISE_FAILED'
}

export enum UPDATE_EXERCISE {
	START = 'UPDATE_EXERCISE_START',
	DONE = 'UPDATE_EXERCISE_DONE',
	FAILED = 'UPDATE_EXERCISE_FAILED'
}

export enum DUPLICATE_EXERCISE {
	START = 'DUPLICATE_EXERCISE_START',
	DONE = 'DUPLICATE_EXERCISE_DONE',
	FAILED = 'DUPLICATE_EXERCISE_FAILED'
}
