import React, { FC, FormEvent, useEffect, useCallback, useState } from 'react'
import { Table, Button, Empty, Modal, Input } from 'antd'
import { get, map, debounce, trim, some } from 'lodash'
import { DeleteOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../../redux/index'
import { getBlogPostList } from '../../../redux/lists/actions'
import { EMPTY_VALUE, INPUT_MAX_LENGTH } from '../../../utils/enums'

interface IDataItem {
	title: string
	id: number | string
}

interface IAsyncTransferRedirect {
	redirectItem: any
	itemContentSetter: (id: number, content: any) => void
}

const PAGE_LIMIT = 15

const AsyncTransferRedirect: FC<IAsyncTransferRedirect> = ({ redirectItem, itemContentSetter }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [showModal, setShowModal] = useState<boolean>(false)
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [filterModal, setFilterModal] = useState({ page: 1, limit: PAGE_LIMIT })

	const isLoading = useSelector<RootState>((state) => state.lists.isLoading)
	const context = useSelector((state: RootState) => state.lists?.list?.context)
	const blogPosts = useSelector((state) => get(state, 'lists.list.blogPosts'))
	const blogPostsList = map(blogPosts, (post: any) => ({ id: post.id, title: post.title }))

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce((searchTerm) => setSearch(searchTerm), 300),
		[]
	)

	const handleSearch = (e: FormEvent<HTMLInputElement>) => {
		const searchInput = e.currentTarget.value || ''
		if (trim(searchInput).length === 0 || trim(searchInput).length > 2) {
			debounced(e.currentTarget.value)
		}
	}

	const handleAddItem = (item: IDataItem) => {
		if (!some(redirectItem?.content, ['id', item?.id])) {
			if (redirectItem?.content) {
				itemContentSetter(redirectItem?.id, [...(redirectItem?.content || []), item])
			} else {
				itemContentSetter(redirectItem?.id, [item])
			}
		}
	}

	const handleDeleteItem = (item: IDataItem) => {
		if (some(redirectItem?.content, ['id', item?.id])) {
			const newArray = redirectItem?.content.filter((inputItem: IDataItem) => item.title !== inputItem.title)
			itemContentSetter(redirectItem?.id, newArray as IDataItem[])
		}
	}

	const handleTableChange = (pagination: any) => {
		setFilterModal({
			...filterModal,
			page: pagination.current
		})
	}

	useEffect(() => {
		if (!showModal) return
		dispatch(
			getBlogPostList({
				search,
				...filterModal
			}) as any
		)
	}, [filterModal, showModal])

	useEffect(() => {
		if (!showModal) return
		dispatch(
			getBlogPostList({
				search,
				...filterModal,
				page: 1
			}) as any
		)
	}, [search])

	const columns: ColumnsType<IDataItem> = [
		{
			title: t('Názov'),
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: () => (
				<Button
					icon={<PlusCircleOutlined />}
					type={'primary'}
					onClick={(e) => {
						e.stopPropagation()
						setShowModal(true)
					}}
				>
					{t('Pridať')}
				</Button>
			),
			key: 'operation',
			fixed: 'right',
			width: 108,
			render: (record: IDataItem) => (
				<Button
					icon={<DeleteOutlined />}
					type={'default'}
					size={'small'}
					danger={true}
					className={'async-picker-delete-button'}
					onClick={(e) => {
						e.stopPropagation()
						handleDeleteItem(record)
					}}
				/>
			)
		}
	]

	const modalColumns: ColumnsType<IDataItem> = [
		{
			title: t('Názov'),
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			render: (value: string) => {
				if (some(redirectItem?.content, ['title', value])) {
					return <span style={{ color: 'rgba(0, 0, 0, 0.2)' }}>{value}</span>
				}
				return value || EMPTY_VALUE
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (record: IDataItem) => {
				if (!some(redirectItem?.content, ['title', record?.title])) {
					return (
						<Button
							icon={<PlusCircleOutlined />}
							type={'primary'}
							size={'small'}
							onClick={(e) => {
								e.stopPropagation()
								handleAddItem(record)
							}}
						/>
					)
				}
				return (
					<Button
						icon={<DeleteOutlined />}
						type={'default'}
						size={'small'}
						danger={true}
						onClick={(e) => {
							e.stopPropagation()
							handleDeleteItem(record)
						}}
					/>
				)
			}
		}
	]

	return (
		<>
			<div style={{ paddingBottom: '16px' }}>
				<Table
					columns={
						map(columns, (column: IDataItem) => ({
							...column,
							key: column?.id
						})) as unknown as ColumnsType<IDataItem>
					}
					dataSource={redirectItem?.content}
					style={{ marginTop: 0 }}
					pagination={false}
					loading={false}
					locale={{
						emptyText: t('Pole je prázdne')
					}}
					size={'small'}
				/>
			</div>
			<Modal
				visible={showModal}
				title={t('Blogové články')}
				okText={t('Hotovo')}
				cancelText={t('Zavrieť')}
				className={'thumbnail-generator-modal'}
				onCancel={() => {
					setShowModal(false)
					setSearch(undefined)
				}}
				onOk={() => setShowModal(false)}
			>
				<div className='search-bar'>
					<Input size={'large'} placeholder={t('Hľadaj...')} prefix={<SearchOutlined />} maxLength={INPUT_MAX_LENGTH} onChange={handleSearch} />
				</div>
				<Table
					columns={
						map(modalColumns, (modalColumn: IDataItem) => ({
							...modalColumn,
							key: modalColumn?.id
						})) as unknown as ColumnsType<IDataItem>
					}
					dataSource={blogPostsList}
					style={{ marginTop: 0 }}
					onChange={handleTableChange}
					pagination={{
						pageSize: context?.count,
						total: context?.totalCount,
						current: context?.page || 1,
						showSizeChanger: false
					}}
					loading={isLoading as boolean}
					locale={{
						emptyText: <Empty description={t('Žiadne dáta')} />
					}}
					size={'small'}
				/>
			</Modal>
		</>
	)
}

export default AsyncTransferRedirect
