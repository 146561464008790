import { useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Input } from 'antd'
import slugify from 'slugify'
import { useTranslation } from 'react-i18next'

const Anchor = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()
	const isCreate = useRef(!get(item, 'content.urlHashTag'))

	const setUrlName = (urlName) => {
		itemContentSetter(item?.id, {
			urlName,
			urlHashTag: isCreate.current
				? slugify(urlName.toLowerCase(), {
						replacement: '-',
						remove: /[^A-Za-z0-9\s-]/g,
						lower: true
					})
				: get(item, 'content.urlHashTag')
		})
	}

	const setUrlHashTag = (urlHashTag) => {
		itemContentSetter(item?.id, {
			urlName: get(item, 'content.urlName'),
			urlHashTag
		})
	}
	return (
		<div className={'custom-item'}>
			<div className={'flex'}>
				<Input
					placeholder={t('Sem zadajte názov linku')}
					className={'input-field'}
					style={{ marginRight: '15px' }}
					size={'large'}
					value={item?.content?.urlName}
					onChange={(e) => setUrlName(e.target.value)}
				/>
				<Input
					placeholder={t('Sem zadajte hash tag linku')}
					className={'input-field'}
					size={'large'}
					value={item?.content?.urlHashTag}
					onChange={(e) => setUrlHashTag(e.target.value)}
				/>
			</div>
		</div>
	)
}

Anchor.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			urlName: PropTypes.string,
			urlHashTag: PropTypes.string
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Anchor.defaultProps = {
	item: {}
}

export default Anchor
