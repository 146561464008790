import { DownOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { get } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logoutUser } from '../redux/user/userActions'
import PasswordModal from './PasswordModal'

const ProfileDropdown = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const user = useSelector((state) => get(state, 'user.profile'))

	const [showModal, setShowModal] = useState(false)

	const handleLogout = () => {
		dispatch(logoutUser(() => history.push(t('paths:login|path'))))
	}

	return (
		<>
			<Dropdown
				overlay={
					<Menu
						items={[
							{
								key: 0,
								icon: <LockOutlined style={{ marginRight: 5 }} />,
								label: t('Zmeniť heslo'),
								onClick: () => setShowModal(true)
							},
							{
								key: 1,
								icon: <LogoutOutlined style={{ marginRight: 5 }} />,
								label: t('Odhlásiť'),
								onClick: handleLogout
							}
						]}
					/>
				}
				trigger={['click']}
				placement={'bottomRight'}
			>
				<span className={'profile-dropdown-item'} onClick={(e) => e.preventDefault()}>
					{`${get(user, 'email', t('Profil'))}`}
					<DownOutlined style={{ marginLeft: 5 }} />
				</span>
			</Dropdown>
			{showModal && <PasswordModal setShowModal={setShowModal} />}
		</>
	)
}

export default ProfileDropdown
