import i18next from 'i18next'

export default (values) => {
	const errors = {}

	if (!values.tier) {
		errors.tier = i18next.t('Toto pole je povinné')
	}

	if (!values.activeFrom) {
		errors.activeFrom = i18next.t('Toto pole je povinné')
	}

	if (!values.activeTo) {
		errors.activeTo = i18next.t('Toto pole je povinné')
	}

	if (values.activeFrom && values.activeTo && values.activeFrom >= values.activeTo) {
		errors.activeTo = i18next.t('Deň expirácie musí byť neskôr ako dátum začatia')
	}

	if (!values.activateReason) {
		errors.activateReason = i18next.t('Toto pole je povinné')
	}

	return errors
}
