import i18next from 'i18next'

export default (values) => {
	const errors = {}

	if (!values.oldPassword) {
		errors.oldPassword = i18next.t('Toto pole je povinné')
	}

	if (values.oldPassword && values.oldPassword.length < 5) {
		errors.oldPassword = i18next.t('Minimálna dĺžka hesla je 5 znakov')
	}

	if (!values.newPassword) {
		errors.newPassword = i18next.t('Toto pole je povinné')
	}

	if (values.newPassword && values.newPassword.length < 5) {
		errors.newPassword = i18next.t('Minimálna dĺžka hesla je 5 znakov')
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = i18next.t('Toto pole je povinné')
	}

	if (values.confirmPassword && values.newPassword && values.confirmPassword !== values.newPassword) {
		errors.confirmPassword = i18next.t('Heslá sa nezhodujú')
	}

	return errors
}
