import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Field, reduxForm, getFormValues, change, initialize, InjectedFormProps } from 'redux-form'
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'
import { get, upperFirst, find, map, forEach } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { getPrograms } from '../../../redux/programs/actions'

// utils
import { getProgramDifficultyOptions, onlyNumbers } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// enums
import { FORMS, UPLOAD_CATEGORY, CHALLENGE_TYPE, LANGUAGE, PROGRAM_DIFFICULTY } from '../../../utils/enums'

// validators
import validateProgramForm from './validateProgramForm'

// atoms
import DateField from '../../../atoms/form/DateField'
import RadioGroupField from '../../../atoms/form/RadioGroupField'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextAreaField from '../../../atoms/form/TextAreaField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

export const MEASUREMENTS_TIME = {
	BEFORE: 'Before',
	AFTER: 'After'
}
interface UserProgramFormProps {
	programName: string
	programDifficulties?: PROGRAM_DIFFICULTY[]
	isChallenge: boolean
	isCreate?: boolean
}

const UserProgramForm = ({
	isChallenge,
	handleSubmit,
	programName,
	programDifficulties,
	isCreate = false
}: UserProgramFormProps & InjectedFormProps<{}, UserProgramFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const { firstName, lastName } = useSelector((state: RootState) => getFormValues(FORMS.USER_FORM)(state)) as any
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.USER_PROGRAM_FORM)(state)) as any
	const userProgramData = useSelector((state: RootState) => state.users?.userProgram?.data)
	const allPrograms = useSelector((state: RootState) => state.programs?.list?.selectList)
	const loading = useSelector((state: RootState) => state.users?.userProgram?.isLoading)
	const postLoading = useSelector((state: RootState) => state.users?.userPrograms?.isLoading)

	// states
	const [isSelectedChallenge, setIsSelectedChallenge] = useState(false)
	const [isCompetitive, setIsCompetitive] = useState(false)
	const [imageBefore, setImageBefore] = useState({ isLoading: false, imageUrl: '' })
	const [imageAfter, setImageAfter] = useState({ isLoading: false, imageUrl: '' })

	// constants
	const selectedProgram = allPrograms?.find((item: any) => item?.value === fieldValues?.program)

	const uploadImageBefore = async (file: any) => {
		setImageBefore({ isLoading: true, imageUrl: '' })
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)
		if (fileUrl) {
			dispatch(change(FORMS.USER_PROGRAM_FORM, `image${MEASUREMENTS_TIME.BEFORE}`, fileUrl))
			setImageBefore({ isLoading: false, imageUrl: fileUrl })
		}
	}

	const uploadImageAfter = async (file: any) => {
		setImageAfter({ isLoading: true, imageUrl: '' })
		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)
		if (fileUrl) {
			dispatch(change(FORMS.USER_PROGRAM_FORM, `image${MEASUREMENTS_TIME.AFTER}`, fileUrl))
			setImageAfter({ isLoading: false, imageUrl: fileUrl })
		}
	}

	const initUserProgramForm = (initData: any) => {
		let initValues: any = {}
		if (!isCreate) {
			initValues = {
				startedAt: get(initData, 'startedAt', dayjs()),
				finishedAt: get(initData, 'finishedAt'),
				isCompetition: get(initData, 'isCompetition'),
				difficulty: initData?.difficulty
			}
			if (get(initData, 'isChallenge')) {
				if (initData.challengeType === CHALLENGE_TYPE.COMPETITIVE) {
					initValues = {
						...initValues,
						imageBefore: get(initData, 'measurementsBefore.image'),
						weightBefore: get(initData, 'measurementsBefore.weight'),
						hipsBefore: get(initData, 'measurementsBefore.hips'),
						waistBefore: get(initData, 'measurementsBefore.waist'),
						thighBefore: get(initData, 'measurementsBefore.thigh'),
						imageAfter: get(initData, 'measurementsAfter.image'),
						weightAfter: get(initData, 'measurementsAfter.weight'),
						hipsAfter: get(initData, 'measurementsAfter.hips'),
						waistAfter: get(initData, 'measurementsAfter.waist'),
						thighAfter: get(initData, 'measurementsAfter.thigh')
					}
				}
				if (initData.challengeType === CHALLENGE_TYPE.HARMONIC) {
					forEach(initData?.questions, (question) => {
						initValues[`question-${MEASUREMENTS_TIME.BEFORE}-${question?.id}-value`] = find(initData?.answersBefore, [
							'questionID',
							question?.id
						])?.value
						initValues[`question-${MEASUREMENTS_TIME.AFTER}-${question?.id}-value`] = find(initData?.answersAfter, [
							'questionID',
							question?.id
						])?.value
						initValues[`question-${MEASUREMENTS_TIME.BEFORE}-${question?.id}-comment`] = find(initData?.answersBefore, [
							'questionID',
							question?.id
						])?.comment
						initValues[`question-${MEASUREMENTS_TIME.AFTER}-${question?.id}-comment`] = find(initData?.answersAfter, [
							'questionID',
							question?.id
						])?.comment
					})
				}
			}
		} else {
			initValues = {
				program: null,
				difficulty: PROGRAM_DIFFICULTY.NORMAL,
				startedAt: dayjs()
			}
		}

		dispatch(initialize(FORMS.USER_PROGRAM_FORM, initValues))
	}

	useEffect(() => {
		initUserProgramForm(userProgramData)
		if (isCreate) {
			// Using limit 500 as high number to fetch all programs
			dispatch(getPrograms({ limit: 500 }))
		}
	}, [userProgramData])

	useEffect(() => {
		const program = find(allPrograms, ['value', get(fieldValues, 'program')])
		setIsSelectedChallenge(get(program, 'isChallenge', false))
		setIsCompetitive(get(program, 'challengeType') === CHALLENGE_TYPE.COMPETITIVE)
	}, [get(fieldValues, 'program')])

	const questionsFields = (prefix: any) => {
		let fields = null

		const questionField = (index: any, questionID: any, translation: any, options: any) => (
			<>
				<h3 className={'challenge-question'}>
					{`${translation?.text}`}
					{translation?.note ? <span>{`(${translation?.note})`}</span> : null}
				</h3>
				<Field key={`question-value-${index}`} name={`question-${prefix}-${questionID}-value`} component={RadioGroupField} options={options} nullable />
				<Field
					key={`question-coment-${index}`}
					name={`question-${prefix}-${questionID}-comment`}
					component={TextAreaField}
					type={'text'}
					label={t('Komentár')}
				/>
			</>
		)

		if (isCreate) {
			const program = find(allPrograms, ['value', get(fieldValues, 'program')])
			fields = map(program?.questions, (question, i) => {
				const optionsLength = question.maxValue - question.minValue + 1 || 0
				const options = [...Array(optionsLength)].map((_, index) => ({
					id: index + 1,
					key: `selectedProgram-${index + 1}`,
					title: index + 1
				}))

				return questionField(i, question.id, find(question?.translations, ['language', LANGUAGE.SK]), options)
			})
		} else {
			fields = map(userProgramData?.questions, (question, index) => {
				const optionsLength = question.maxValue - question.minValue + 1 || 0
				const options = [...Array(optionsLength)].map((_, i) => ({
					id: i + 1,
					key: `option-${i + 1}`,
					title: i + 1
				}))

				return questionField(index, question.id, find(question?.translations, ['language', LANGUAGE.SK]), options)
			})
		}
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{fields}</>
	}

	const measurementFields = (prefix: any) => (
		<>
			<Field
				name={`image${prefix}`}
				imageUrl={get(fieldValues, `image${prefix}`)}
				component={UploadInputField}
				onRemove={() => dispatch(change(FORMS.USER_PROGRAM_FORM, `image${prefix}`, null))}
				label={t('Foto')}
				customRequest={prefix === MEASUREMENTS_TIME.BEFORE ? ({ file }: any) => uploadImageBefore(file) : ({ file }: any) => uploadImageAfter(file)}
				isLoading={prefix === MEASUREMENTS_TIME.BEFORE ? imageBefore.isLoading : imageAfter.isLoading}
			/>
			<Field name={`weight${prefix}`} component={TextField} label={t('Váha')} type={'number'} onKeyDown={(e: any) => onlyNumbers(e)} step={0.01} />
			<Field name={`hips${prefix}`} component={TextField} label={t('Boky')} type={'number'} onKeyDown={(e: any) => onlyNumbers(e)} step={0.01} />
			<Field name={`thigh${prefix}`} component={TextField} label={t('Stehna')} type={'number'} onKeyDown={(e: any) => onlyNumbers(e)} step={0.01} />
			<Field name={`waist${prefix}`} component={TextField} label={t('Pás')} type={'number'} onKeyDown={(e: any) => onlyNumbers(e)} step={0.01} />
		</>
	)

	const renderForm = () => {
		const program = isCreate && find(allPrograms, ['value', get(fieldValues, 'program')])
		if ((!isCreate && userProgramData?.challengeType === CHALLENGE_TYPE.HARMONIC) || (isCreate && program?.challengeType === CHALLENGE_TYPE.HARMONIC)) {
			return (
				<Row gutter={24}>
					<Col span={24} className={'grid center-content questions-form'}>
						<h2>{t('Otázky pred')}</h2>
						{questionsFields(MEASUREMENTS_TIME.BEFORE)}
					</Col>
					<Col span={24} className={'grid center-content questions-form'}>
						<h2>{t('Otázky po')}</h2>
						{questionsFields(MEASUREMENTS_TIME.AFTER)}
					</Col>
				</Row>
			)
		}
		return (
			<Row gutter={16}>
				<Col span={11} className={'grid center-content'}>
					<h2>{t('Miery pred')}</h2>
					{measurementFields(MEASUREMENTS_TIME.BEFORE)}
				</Col>
				<Col span={2} className={'grid center-content'}>
					<ArrowRightOutlined />
				</Col>
				<Col span={11} className={'grid center-content'}>
					<h2>{t('Miery po')}</h2>
					{measurementFields(MEASUREMENTS_TIME.AFTER)}
				</Col>
			</Row>
		)
	}

	return loading && !userProgramData ? (
		<div style={{ padding: '24px' }} className={'center-content'}>
			<span>
				<LoadingOutlined />
				{` ${t('Načítavam')}...`}
			</span>
		</div>
	) : (
		<form onSubmit={handleSubmit} className={'user-program-form'}>
			<Row gutter={16}>
				<Col span={14}>
					{isCreate ? (
						<Col span={11} className={'grid'}>
							<Field
								name={'program'}
								customClass={'program-select'}
								component={SelectField}
								options={allPrograms}
								onChange={() => dispatch(change(FORMS.USER_PROGRAM_FORM, 'difficulty', PROGRAM_DIFFICULTY.NORMAL))} // NOTE: Reset difficulty on program change
								label={t('Program')}
								placeholder={t('Vyber si program')}
								required
								show
							/>
						</Col>
					) : (
						<h2>{`${upperFirst(programName)} (${upperFirst(firstName)} ${upperFirst(lastName)})`}</h2>
					)}
				</Col>
				{((isChallenge && userProgramData?.challengeType === CHALLENGE_TYPE.COMPETITIVE) || (isSelectedChallenge && isCompetitive && isCreate)) && (
					<Col span={10} className={'grid'}>
						<Field name={'isCompetition'} component={SwitchField} label={t('Zúčastniť sa súťažne')} />
					</Col>
				)}
			</Row>
			<Row>
				<Col span={10}>
					<Field
						name={'difficulty'}
						component={SelectField}
						options={getProgramDifficultyOptions(t, isCreate ? selectedProgram?.difficulties : programDifficulties)}
						label={t('Náročnosť')}
						placeholder={t('Vyber si náročnosť')}
						required
					/>
				</Col>
			</Row>
			{!get(fieldValues, 'program') && isCreate ? null : (
				<Row gutter={16} align={'middle'}>
					<Col span={11} className={'grid'}>
						<Field
							name={'startedAt'}
							component={DateField}
							label={t('Začiatok')}
							dttmFormat={'D.M.YYYY HH:mm'}
							showTime
							allowClear={false}
							required
						/>
					</Col>
					<Col span={2} className={'grid center-content'}>
						<ArrowRightOutlined />
					</Col>
					<Col span={11} className={'grid'}>
						<Field name={'finishedAt'} component={DateField} label={t('Koniec')} dttmFormat={'D.M.YYYY HH:mm'} showTime />
					</Col>
				</Row>
			)}
			{(isChallenge || (isSelectedChallenge && isCreate)) && renderForm()}
			{!isCreate && (
				<Row gutter={32}>
					<Col span={24} className={'grid center-content'}>
						<Button className={'submit-button'} onClick={handleSubmit} type={'primary'}>
							{t('Upraviť detail programu')}
						</Button>
					</Col>
				</Row>
			)}

			{(loading || postLoading) && (
				<div className={'overlay-loading'}>
					<LoadingOutlined />
				</div>
			)}
		</form>
	)
}

export default reduxForm<{}, UserProgramFormProps>({
	form: FORMS.USER_PROGRAM_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateProgramForm
})(UserProgramForm)
