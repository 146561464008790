import React, { FC } from 'react'
import cx from 'classnames'
import { Input } from 'antd'
import { trim } from 'lodash'
import { INPUT_MAX_LENGTH } from '../../utils/enums'

interface ITextField {
	name: string | undefined
	placeholder?: string
	showLabel?: boolean
	label?: string
	meta: {
		touched?: boolean
		error: string
	}
	input: any
	required?: boolean
	integer?: boolean
	type?: string
	customClass?: string
	customLabelClass?: string
	customInputClass?: string
	disabled?: boolean
	suffix?: string
	onInput?: () => void
	onKeyUp?: () => void
	onKeyDown?: () => void
	onBlur?: () => void
	min?: number
	max?: number
	size: string
	step?: number
	showUntouchedErrors?: boolean
	maxLength?: number
	counter: {
		label?: string
		actualCount?: number
		totalCount?: number
	}
}

const TextField: FC<ITextField> = ({
	name = null,
	showLabel = true,
	type = 'text',
	placeholder = '',
	suffix = null,
	label = '',
	required = false,
	disabled = false,
	customClass = null,
	customLabelClass = null,
	customInputClass = null,
	onInput = null,
	onBlur = null,
	onKeyUp = null,
	onKeyDown = null,
	min = 0,
	max,
	size = 'middle',
	step = 1,
	counter = null,
	integer = false,
	showUntouchedErrors = false,
	input,
	meta,
	maxLength = INPUT_MAX_LENGTH
}) => {
	const onChange = (e: any) => {
		const { value } = e.target

		if (type === 'number' && integer) {
			input.onChange(parseInt(value, 10))
		} else {
			input.onChange(value)
		}
	}

	return (
		<div className={cx('input-wrapper', { error: meta.touched && meta.error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={(name as string) || undefined}>
					{counter ? (
						<>
							{`${label}: `}
							<span className={'counter'}>{`${counter?.label} ${counter?.actualCount}/${counter?.totalCount}`}</span>
						</>
					) : (
						`${label}`
					)}
				</label>
			)}
			<div className={'input-field'}>
				<Input
					{...input}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
					className={cx('input-field', customInputClass, { disabled })}
					onInput={onInput}
					onKeyUp={onKeyUp}
					onKeyDown={onKeyDown}
					required={required}
					min={min}
					max={max}
					maxLength={maxLength}
					onBlur={() => {
						if (onBlur) {
							onBlur()
						}

						input.onChange(trim(input.value))
					}}
					suffix={suffix}
					size={size}
					step={step}
					onChange={onChange}
				/>
			</div>
			<div className={'tooltip-error'}>{showUntouchedErrors || meta.touched ? meta.error : ''}</div>
		</div>
	)
}

export default TextField
