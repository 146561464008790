import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { isLoggedIn } from '../utils/auth'
import { getUser } from '../redux/user/userActions'
import { ADMIN_ROLES_WEIGHTS, getRouteAllowedRoles } from '../utils/enums'

class AdminRoute extends Route {
	static propTypes = {
		component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
		routeKey: PropTypes.string
	}

	componentDidMount = async () => {
		// await this.props.loadUser()
	}

	render() {
		const { t, profile, routeKey } = this.props
		const RouteLayout = this.props.layout
		const RouteComponent = this.props.component

		if (!profile) {
			return <Redirect to={t('paths:login|path')} />
		}
		const routeAllowedRoles = getRouteAllowedRoles()

		if (ADMIN_ROLES_WEIGHTS[get(profile, 'role')] > ADMIN_ROLES_WEIGHTS[routeAllowedRoles[routeKey]]) {
			return <Redirect to={t('paths:base|path')} />
		}

		if (!isLoggedIn()) {
			return <Redirect to={t('paths:instructors|path')} />
		}

		if (this.props.layout) {
			return (
				<RouteLayout {...this.props}>
					<RouteComponent {...this.props} />
				</RouteLayout>
			)
		}
		return <RouteComponent {...this.props} />
	}
}

const mapStateToProps = (state) => ({
	profile: get(state, 'user.profile')
})

const mapDispatchToProps = {
	loadUser: getUser
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('paths'))(AdminRoute)
