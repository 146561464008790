import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES, slugErrorText } from '../../../utils/enums'
import { slugRegex } from '../../../utils/regex'

export type ExerciseTargetFormValues = {
	id: number
	name_sk: string
	name_cz?: string
	name_en?: string
	urlSlug_sk: string
	urlSlug_cz?: string
	urlSlug_en?: string
}

export default (values: ExerciseTargetFormValues) => {
	const errors: FormErrors<ExerciseTargetFormValues> = {}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug_sk) {
		errors.urlSlug_sk = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		const name = get(values, `name_${item}`)
		const urlSlug = get(values, `name_${item}`)

		if (name || urlSlug) {
			if (!values[`name_${item}`]) {
				errors[`name_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`urlSlug_${item}`]) {
				errors[`urlSlug_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (values[`urlSlug_${item}`] && !slugRegex.test(values[`urlSlug_${item}`] as string)) {
				errors[`urlSlug_${item}`] = i18next.t(slugErrorText)
			}
		}
	})

	return errors
}
