import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Input } from 'antd'

const { TextArea } = Input

const TextAreaField = ({
	input,
	name,
	label,
	placeholder,
	showLabel,
	customClass,
	customLabelClass,
	customInputClass,
	meta: { touched, error },
	type,
	disabled,
	onInput,
	onKeyUp,
	suffix,
	required,
	rows
}) => (
	<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
		{showLabel && (
			<label className={cx(customLabelClass, { required })} htmlFor={name}>
				{label}
			</label>
		)}
		<div className={'input-field'}>
			<TextArea
				{...input}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				className={cx('input-field', customInputClass)}
				onInput={onInput}
				onKeyUp={onKeyUp}
				required={required}
				rows={rows}
			/>
			{!!suffix && !!input.value && <p className={'suffix'}>{suffix}</p>}
		</div>
		<div className={'tooltip-error'}>{touched ? error : ''}</div>
	</div>
)

TextAreaField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.string
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	rows: PropTypes.number
}

TextAreaField.defaultProps = {
	name: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onKeyUp: null,
	rows: 4
}

export default TextAreaField
