import each from 'lodash/each'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import { Button, List, Modal } from 'antd'
import { DeleteOutlined, MenuOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { MouseEventHandler, useEffect } from 'react'
import { arrayMoveImmutable } from 'array-move'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { Field, initialize, reduxForm } from 'redux-form'

// redux
import {
	addLiveStreamCategory,
	changeLiveStreamCategoriesOrder,
	getLiveStreamCategories,
	removeLiveStreamCategory,
	setLiveStreamCategory,
	updateLiveStreamCategories
} from '../../redux/liveStreamCategories/actions'
import { getPrograms } from '../../redux/select/actions'

// utils
import { FORMS } from '../../utils/enums'

// components
import SelectField from '../../atoms/form/SelectField'
import { RootState } from '../../redux'

type LiveStreamCategoriesProps = {
	handleSubmit: (values: any) => MouseEventHandler<HTMLElement>
	invalid?: any
}

const LiveStreamCategories = ({ handleSubmit, invalid }: LiveStreamCategoriesProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const programsArray = useSelector((state) => get(state, 'select.programs.list'))
	const categories = useSelector((state: RootState) => state.liveStreamCategories?.categories)
	const isLoadingDetail = useSelector((state) => get(state, 'liveStreamCategories.isLoading'))

	const programOptions = map(programsArray, (program, index) => ({
		key: index,
		value: get(program, 'id'),
		label: get(program, 'name')
	}))

	const initForm = () => {
		const initValues = {}
		each(categories, (item, index) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			initValues[`category-${index}`] = item.programID
		})
		dispatch(initialize(FORMS.LIVESTREAM_CATEGORIES_FORM, initValues))
	}

	useEffect(() => {
		initForm()
	}, [dispatch, categories])

	useEffect(() => {
		const fetchData = () => {
			dispatch(
				getPrograms(null, () => {
					dispatch(getLiveStreamCategories())
				})
			)
		}

		fetchData()
	}, [dispatch])

	const handleSave = (values: any) => {
		const data: any = {
			categories: map(
				filter(Object.values(values), (id) => id != null),
				(id: number, index: number) => ({ programID: id, order: index + 1 })
			)
		}
		dispatch(
			updateLiveStreamCategories(data, () => {
				dispatch(getLiveStreamCategories())
			})
		)
	}

	const handleAddLiveStreamCategory = () => {
		dispatch(addLiveStreamCategory())
	}

	const handleRemoveLiveStreamCategory = (deleteItem: any) => {
		Modal.confirm({
			title: t('Skutočne si prajete odstrániť túto kategóriu?'),
			icon: <DeleteOutlined />,
			width: '500px',
			okText: t('Odstrániť'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: async () => {
				await dispatch(removeLiveStreamCategory(deleteItem.programID))

				const data = {
					categories: map(
						filter(Object.values(categories), (item: any) => item.programID !== deleteItem.programID),
						(item: any, index: number) => ({ programID: item.programID, order: index + 1 })
					)
				}

				await dispatch(
					updateLiveStreamCategories(data, async () => {
						await dispatch(getLiveStreamCategories())
					})
				)
			}
		})
	}

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable([].concat(categories), oldIndex, newIndex).filter((el) => !!el)
			dispatch(changeLiveStreamCategoriesOrder(newData))
		}
	}

	const SortableContainer = sortableContainer((props: any) => <List {...props} />)
	const DraggableContainer = (props: any) => <SortableContainer useDragHandle onSortEnd={onSortEnd} helperClass={'demo-loadmore-list'} {...props} />
	const SortableItem = sortableElement((props: any) => {
		const DragHandle = sortableHandle(() => <MenuOutlined className={'drag-handle'} />)
		return (
			<List.Item
				actions={[
					<Button key={'remove'} onClick={() => props.handleRemoveLiveStreamCategory(props.item)} icon={<DeleteOutlined />} type={'primary'} danger />
				]}
			>
				<div className={'category-item'}>
					<DragHandle />
					<Field
						name={`category-${props.in}`}
						component={SelectField}
						style={{ width: 400 }}
						label={t('Kategória')}
						options={props.programOptions}
						showSearch
						onChange={(value: any) => dispatch(setLiveStreamCategory({ id: value, index: props.in }))}
						filterOption={(input: any, option: any) => get(option, 'label', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
					/>
				</div>
			</List.Item>
		)
	})

	return (
		<div className={'page-wrapper'}>
			<div className={'dashboard-card'}>
				<DraggableContainer
					loading={isLoadingDetail}
					itemLayout={'horizontal'}
					dataSource={categories}
					renderItem={(item: any, index: number) => (
						<SortableItem
							index={index}
							item={item}
							in={index}
							handleRemoveLiveStreamCategory={handleRemoveLiveStreamCategory}
							programOptions={programOptions}
						/>
					)}
				/>
				<div className={'flex justify-between'} style={{ paddingTop: 30 }}>
					<Button icon={<PlusCircleOutlined />} onClick={handleAddLiveStreamCategory} type={'primary'}>
						{t('Pridať kategóriu')}
					</Button>
					<Button icon={<SaveOutlined />} onClick={handleSubmit((values: any) => handleSave(values))} type={'primary'} disabled={invalid}>
						{t('Uložiť')}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default reduxForm<{}, LiveStreamCategoriesProps>({
	form: FORMS.LIVESTREAM_CATEGORIES_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(LiveStreamCategories)
