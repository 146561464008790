import { get, mapValues } from 'lodash'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { LOAD_DOWNLOADS, LOAD_DOWNLOAD, CREATE_DOWNLOAD, UPDATE_DOWNLOAD, DELETE_DOWNLOAD, LOAD_DOWNLOAD_CATEGORIES } from './types'

export const loadDownloads = (params, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: LOAD_DOWNLOADS.START })
		const queries = {
			limit: 20,
			page: 1,
			...params
		}
		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOADS, normalizeQueryParams)
		const payload = {
			list: data.downloads,
			context: data.context
		}
		dispatch({ type: LOAD_DOWNLOADS.DONE, payload })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_DOWNLOADS.FAILED })
		return onFailure && onFailure(error)
	}
}

export const loadDownload = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({ type: LOAD_DOWNLOAD.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOAD(id))
		dispatch({ type: LOAD_DOWNLOAD.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createDownload = (values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({ type: CREATE_DOWNLOAD.START })
	try {
		const { data } = await postReq(ENDPOINTS.CREATE_DOWNLOAD, null, values)
		dispatch({ type: CREATE_DOWNLOAD.DONE })
		return onSuccess && onSuccess(get(data, 'data.id'))
	} catch (error) {
		dispatch({ type: CREATE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateDownload = (id, values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({ type: UPDATE_DOWNLOAD.START })
	try {
		await putReq(ENDPOINTS.UPDATE_DOWNLOAD(id), null, values)
		dispatch({ type: UPDATE_DOWNLOAD.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteDownload = (id, onSuccess, onFailure) => async (dispatch) => {
	dispatch({ type: DELETE_DOWNLOAD.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_DOWNLOAD(id))
		dispatch({ type: DELETE_DOWNLOAD.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const loadDownloadCategories = () => async (dispatch) => {
	try {
		dispatch({ type: LOAD_DOWNLOAD_CATEGORIES.START })
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOAD_CATEGORIES)
		const payload = {
			list: data.downloadCategories
		}
		dispatch({ type: LOAD_DOWNLOAD_CATEGORIES.DONE, payload })
	} catch (error) {
		dispatch({ type: LOAD_DOWNLOAD_CATEGORIES.FAILED })
	}
}
