import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type SettingsFormValues = {
	month_premium_CZK: string
	quarter_premium_CZK: string
	year_premium_CZK: string
	month_vip_CZK: string
	quarter_vip_CZK: string
	year_vip_CZK: string
	month_premium_EUR: string
	quarter_premium_EUR: string
	year_premium_EUR: string
	month_vip_EUR: string
	quarter_vip_EUR: string
	year_vip_EUR: string
}

export default (values: SettingsFormValues) => {
	const errors: FormErrors<SettingsFormValues> = {}

	if (!values.month_premium_CZK) {
		errors.month_premium_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.quarter_premium_CZK) {
		errors.quarter_premium_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.year_premium_CZK) {
		errors.year_premium_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.month_vip_CZK) {
		errors.month_vip_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.quarter_vip_CZK) {
		errors.quarter_vip_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.year_vip_CZK) {
		errors.year_vip_CZK = i18next.t('Toto pole je povinné')
	}

	if (!values.month_premium_EUR) {
		errors.month_premium_EUR = i18next.t('Toto pole je povinné')
	}

	if (!values.quarter_premium_EUR) {
		errors.quarter_premium_EUR = i18next.t('Toto pole je povinné')
	}

	if (!values.year_premium_EUR) {
		errors.year_premium_EUR = i18next.t('Toto pole je povinné')
	}

	if (!values.month_vip_EUR) {
		errors.month_vip_EUR = i18next.t('Toto pole je povinné')
	}

	if (!values.quarter_vip_EUR) {
		errors.quarter_vip_EUR = i18next.t('Toto pole je povinné')
	}

	if (!values.year_vip_EUR) {
		errors.year_vip_EUR = i18next.t('Toto pole je povinné')
	}

	return errors
}
