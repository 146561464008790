import cx from 'classnames'
import { CirclePicker } from 'react-color'

// utils
import { circlePickerColors } from '../../utils/helpers'

type CircleColorFieldProps = {
	name: string
	label: string
	input: any
	meta: {
		error: string
		touched: boolean
	}
	customClass?: string
	customLabelClass?: string
	showLabel?: boolean
	required?: boolean
}

const CircleColorField = ({
	input,
	customClass,
	showLabel = true,
	label,
	customLabelClass,
	name,
	meta: { error, touched },
	required
}: CircleColorFieldProps) => {
	const handleChange = (value: { hex: string | undefined }) => {
		input.onChange(value.hex)
	}

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={name}>
					{label}
				</label>
			)}
			<div className={'input-field'} style={{ padding: '0 6px' }}>
				<CirclePicker color={input.value} onChange={handleChange} colors={circlePickerColors} />
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

export default CircleColorField
