import React from 'react'
import { useTranslation } from 'react-i18next'

const RecaptchaTerms = () => {
	const [t] = useTranslation()

	return (
		<div className={'recaptcha-terms'}>
			<p>
				<span>{`${t('Táto stránka je chránená službou reCaptcha od spoločnosti Google a vzťahujú sa na ňu podmienky')} `}</span>
				<a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noopener noreferrer'}>{`${t('Ochrany osobných údajov')}`}</a>
				<span>{` ${t('a')} `}</span>
				<a href={'https://policies.google.com/terms'} target={'_blank'} rel={'noopener noreferrer'}>{`${t('Obchodné podmienky')}`}</a>
			</p>
		</div>
	)
}

export default RecaptchaTerms
