import i18next from 'i18next'

export default (values) => {
	const errors = {}

	if (!values.newPassword) {
		errors.newPassword = i18next.t('Toto pole je povinné')
	}

	if (values.newPassword && values.newPassword.length < 5) {
		errors.newPassword = i18next.t('Minimálna dĺžka hesla je 5 znakov')
	}

	return errors
}
