import {
	LOAD_USERS,
	LOAD_DETAIL_USER,
	DELETE_USER,
	CREATE_USER,
	UPDATE_USER,
	LOAD_USER_PROGRAM,
	LOAD_USER_PROGRAMS,
	UPDATE_USER_PROGRAM,
	LOAD_USER_USER_AGENTS
} from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLastPage: false,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	userPrograms: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	userProgram: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	userAgents: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOAD_USERS.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_USERS.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_USERS.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_DETAIL_USER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_DETAIL_USER.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_DETAIL_USER.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_USER.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_USER.DONE:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_USER.FAILED:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_USER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_USER.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_USER.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_USER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_USER.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_USER.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_USER_PROGRAM.START:
			return {
				...initState,
				...state,
				userProgram: {
					...state.userProgram,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_USER_PROGRAM.DONE:
			return {
				...initState,
				...state,
				userProgram: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_USER_PROGRAM.FAILED:
			return {
				...initState,
				...state,
				userProgram: {
					...state.userProgram,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_USER_PROGRAMS.START:
			return {
				...initState,
				...state,
				userPrograms: {
					...state.userPrograms,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_USER_PROGRAMS.DONE:
			return {
				...initState,
				...state,
				userPrograms: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_USER_PROGRAMS.FAILED:
			return {
				...initState,
				...state,
				userPrograms: {
					...state.userPrograms,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_USER_PROGRAM.START:
			return {
				...initState,
				...state,
				userPrograms: {
					...state.userPrograms,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_USER_PROGRAM.DONE:
			return {
				...initState,
				...state,
				userPrograms: {
					...state.userPrograms,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_USER_PROGRAM.FAILED:
			return {
				...initState,
				...state,
				userPrograms: {
					...state.userPrograms,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_USER_USER_AGENTS.START:
			return {
				...initState,
				...state,
				userAgents: {
					...state.userAgents,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_USER_USER_AGENTS.DONE:
			return {
				...initState,
				...state,
				userAgents: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_USER_USER_AGENTS.FAILED:
			return {
				...initState,
				...state,
				userAgents: {
					...state.userAgents,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
