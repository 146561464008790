// types
import {
	GET_DASHBOARD,
	GET_STATISTICS,
	GET_SUBSCRIPTION_STATISTICS,
	GET_EXERCISES_VIEWS_STATISTICS,
	GET_EXERCISES_VIEWS_TREND_STATISTICS,
	GET_PROGRAMS_ENGAGEMENT_STATISTICS,
	GET_EXERCISES_FAVOURTITES_STATISTICS,
	GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS,
	GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY,
	GET_PAYMENTS_STATISTICS,
	GET_RECIPE_BOOKS_STATISTICS
} from './types'

const initSubscriptionStatistics = {
	allSubscriptions: 0,
	newSubscriptions: 0,
	canceledSubscriptions: 0,
	paidAllEur: 0,
	paidAllCzk: 0,
	paidNewCzk: 0,
	paidNewEur: 0,
	newMonthly: 0,
	newYearly: 0,
	newQuarterly: 0,
	withPromocode: 0,
	withCustom: 0,
	paidFromCanceledEur: 0,
	paidFromCanceledCzk: 0
}

export const initState = {
	page: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	userStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	subscriptionStatistics: {
		data: { ...initSubscriptionStatistics },
		isLoading: false,
		isFailure: false
	},
	programsStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	programEngagementTrendStatisticsPerDay: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	programEngagementTrendStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	exercisesViewStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	exercisesViewsTrendStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	exercisesFavouritesStatistics: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	paymentsStatistic: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	recipeBooksStatistics: {
		data: {
			basic: {
				all: 0,
				requested: 0,
				created: 0
			},
			bonus: {
				all: 0,
				created: 0
			}
		},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_DASHBOARD.START:
			return {
				...initState,
				...state,
				page: {
					...state.page,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_DASHBOARD.DONE:
			return {
				...initState,
				...state,
				page: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_DASHBOARD.FAILED:
			return {
				...state,
				page: {
					...state.page,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_STATISTICS.START:
			return {
				...state,
				userStatistics: {
					...state.userStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_STATISTICS.DONE:
			return {
				...state,
				userStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_STATISTICS.FAILED:
			return {
				...state,
				userStatistics: {
					...state.userStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_SUBSCRIPTION_STATISTICS.START:
			return {
				...state,
				subscriptionStatistics: {
					...state.subscriptionStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_SUBSCRIPTION_STATISTICS.DONE:
			return {
				...state,
				subscriptionStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_SUBSCRIPTION_STATISTICS.FAILED:
			return {
				...state,
				subscriptionStatistics: {
					...state.subscriptionStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROGRAMS_ENGAGEMENT_STATISTICS.START:
			return {
				...state,
				programsStatistics: {
					...state.programsStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAMS_ENGAGEMENT_STATISTICS.DONE:
			return {
				...state,
				programsStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAMS_ENGAGEMENT_STATISTICS.FAILED:
			return {
				...state,
				programsStatistics: {
					...state.programsStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.START:
			return {
				...state,
				programEngagementTrendStatisticsPerDay: {
					...state.programEngagementTrendStatisticsPerDay,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.DONE:
			return {
				...state,
				programEngagementTrendStatisticsPerDay: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY.FAILED:
			return {
				...state,
				programEngagementTrendStatisticsPerDay: {
					...state.programEngagementTrendStatisticsPerDay,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.START:
			return {
				...state,
				programEngagementTrendStatistics: {
					...state.programEngagementTrendStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.DONE:
			return {
				...state,
				programEngagementTrendStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS.FAILED:
			return {
				...state,
				programEngagementTrendStatistics: {
					...state.programEngagementTrendStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_EXERCISES_VIEWS_STATISTICS.START:
			return {
				...state,
				exercisesViewStatistics: {
					...state.exercisesViewStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_EXERCISES_VIEWS_STATISTICS.DONE:
			return {
				...state,
				exercisesViewStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_EXERCISES_VIEWS_STATISTICS.FAILED:
			return {
				...state,
				exercisesViewStatistics: {
					...state.exercisesViewStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_EXERCISES_VIEWS_TREND_STATISTICS.START:
			return {
				...state,
				exercisesViewsTrendStatistics: {
					...state.exercisesViewsTrendStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_EXERCISES_VIEWS_TREND_STATISTICS.DONE:
			return {
				...state,
				exercisesViewsTrendStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_EXERCISES_VIEWS_TREND_STATISTICS.FAILED:
			return {
				...state,
				exercisesViewsTrendStatistics: {
					...state.exercisesViewsTrendStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_EXERCISES_FAVOURTITES_STATISTICS.START:
			return {
				...state,
				exercisesFavouritesStatistics: {
					...state.exercisesFavouritesStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_EXERCISES_FAVOURTITES_STATISTICS.DONE:
			return {
				...state,
				exercisesFavouritesStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_EXERCISES_FAVOURTITES_STATISTICS.FAILED:
			return {
				...state,
				exercisesFavouritesStatistics: {
					...state.exercisesFavouritesStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PAYMENTS_STATISTICS.START:
			return {
				...state,
				paymentsStatistic: {
					...state.paymentsStatistic,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PAYMENTS_STATISTICS.DONE:
			return {
				...state,
				paymentsStatistic: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PAYMENTS_STATISTICS.FAILED:
			return {
				...state,
				paymentsStatistic: {
					...state.paymentsStatistic,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_RECIPE_BOOKS_STATISTICS.START:
			return {
				...state,
				recipeBooksStatistics: {
					...state.recipeBooksStatistics,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_RECIPE_BOOKS_STATISTICS.DONE:
			return {
				...state,
				recipeBooksStatistics: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_RECIPE_BOOKS_STATISTICS.FAILED:
			return {
				...state,
				recipeBooksStatistics: {
					...state.recipeBooksStatistics,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
