import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Select } from 'antd'

const CustomOptionsSelectField = ({
	input,
	label,
	placeholder,
	showLabel,
	customClass,
	customLabelClass,
	customInputClass,
	meta: { touched, error },
	type,
	disabled,
	onInput,
	onKeyUp,
	suffix,
	required,
	min,
	onBlur,
	options,
	width,
	multiple,
	allowClear,
	...rest
}) => {
	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && <label className={cx(customLabelClass, { required })}>{label}</label>}
			<div className={'input-field'}>
				<Select
					{...input}
					value={checkValue()}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
					className={cx('input-field', customInputClass)}
					onInput={onInput}
					onKeyUp={onKeyUp}
					required={required}
					min={min}
					onBlur={onBlur}
					onChange={onInputChange}
					suffix={suffix}
					style={{ width }}
					mode={multiple && 'multiple'}
					allowClear={allowClear}
					{...rest}
				>
					{options}
				</Select>
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

CustomOptionsSelectField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.number)]),
		onChange: PropTypes.func
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	width: PropTypes.string,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	allowClear: PropTypes.bool
}

CustomOptionsSelectField.defaultProps = {
	name: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	width: '100%',
	multiple: false,
	options: [],
	allowClear: false
}

export default CustomOptionsSelectField
