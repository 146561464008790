export const emailRegEx =
	/^(([^<>()\\[\]\\.,;:\s@“]+(\.[^<>()\\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneNumber = /(([+,0][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/

export const urlRegEx = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}[\\.]{0,1}/

export const slugRegex = /^[a-z0-9-]+$/

export const twoWords = /^\p{L}+\s\p{L}+$/u

export const hasNumber = /\d/

export const hasUpperLetter = /[A-Z]/

export const hasLowerLetter = /[a-z]/
