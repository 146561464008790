// types
import { LOGOUT_USER, GET_PROFILE } from './userTypes'

export const initState = {
	profile: null,
	isLoading: false,
	isFailure: false
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_PROFILE.START:
			return {
				...state,
				isLoading: true,
				isFailure: false
			}
		case GET_PROFILE.DONE:
			return {
				...state,
				profile: action.payload,
				isLoading: false,
				isFailure: false
			}
		case GET_PROFILE.FAILED:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case LOGOUT_USER:
			return initState
		default:
			return state
	}
}
