import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, DatePicker, Empty, Form, Input, Table } from 'antd'
import { get, debounce } from 'lodash'
import dayjs from 'dayjs'
import { formatPromoCodeType, setFiltersForPage } from '../../utils/helpers'
import { getPromoCodesApplications, resetList } from '../../redux/promoCodes/actions'
import { setFilters } from '../../redux/filters/actions'
import { EMPTY_VALUE, INPUT_MAX_LENGTH, PROMOCODE_TYPE } from '../../utils/enums'
import DateRepresent from '../../atoms/DateRepresent'

const PAGE_SIZE = 20
const PromoCodesUsagePage = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation()

	const persistFilter = useSelector((state) => get(state, 'filters'))
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:promoCodes|key'), persistFilter))

	const dataSource = useSelector((state) => get(state, 'promoCodes.list.tableList'))
	const isLoadingList = useSelector((state) => get(state, 'promoCodes.list.isLoading'))
	const context = useSelector((state) => get(state, 'promoCodes.list.context'))

	useEffect(() => {
		dispatch(resetList())
	}, [dispatch])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter,
			dateFrom: filter.dateFrom ? filter.dateFrom.format('x') : undefined,
			dateTo: filter.dateTo ? filter.dateTo.format('x') : undefined
		}

		dispatch(getPromoCodesApplications(body))

		if (filter?.dateFrom) {
			body.dateFrom = dayjs(filter.dateFrom)
		}

		if (filter?.dateTo) {
			body.dateTo = dayjs(filter.dateTo)
		}

		dispatch(setFilters(t('paths:promoCodesUsage|key'), body))
	}, [filter, dispatch, t])

	const columns = [
		{
			title: i18next.t('Kód'),
			dataIndex: 'code',
			key: 'code',
			ellipsis: true,
			render: (value) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Uplatnil'),
			dataIndex: 'email',
			key: 'email',
			ellipsis: true
		},
		{
			title: i18next.t('Typ'),
			dataIndex: 'type',
			key: 'type',
			ellipsis: true,
			render: (value) => formatPromoCodeType(value)
		},
		{
			title: i18next.t('Hodnota'),
			dataIndex: 'argument',
			key: 'argument',
			render: (value, record) => {
				switch (record.type) {
					case PROMOCODE_TYPE.DISCOUNT:
						return `${value * 100} %`
					case PROMOCODE_TYPE.FREE_MONTHS:
						return `${value} ${t('mesiace', { count: value })}`
					default:
						return EMPTY_VALUE
				}
			}
		},
		{
			title: i18next.t('Dátum použitia'),
			dataIndex: 'appliedAt',
			key: 'appliedAt',
			width: 155,
			render: (value) => <DateRepresent value={value} />
		}
	]

	const handleTableChange = (pagination, filters, sorter) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const resetFilter = () => {
		setFilter({})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e) => {
		debounced(e.target.value)
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Form.Item>
					<Input.Search onChange={handleOnChange} defaultValue={filter?.search} maxLength={INPUT_MAX_LENGTH} style={{ width: 300 }} allowClear />
				</Form.Item>
			</div>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<div className={'flex'} style={{ flexWrap: 'wrap' }}>
					<Form.Item>
						<DatePicker
							style={{ width: 200, marginRight: 20 }}
							onChange={(value) => setFilter({ ...filter, dateFrom: value && value.startOf('day'), page: 1 })}
							value={filter.dateFrom}
							format={'D.M.YYYY'}
							placeholder={t('Dátum od')}
							showToday
						/>
					</Form.Item>
					<Form.Item>
						<DatePicker
							style={{ width: 200, marginRight: 20 }}
							onChange={(value) => setFilter({ ...filter, dateTo: value && value.endOf('day'), page: 1 })}
							value={filter.dateTo}
							format={'D.M.YYYY'}
							placeholder={t('Dátum do')}
							showToday
						/>
					</Form.Item>
					<Form.Item>
						<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
							{t('Zrušiť filter')}
						</Button>
					</Form.Item>
				</div>
				<div>
					<p style={{ fontWeight: 600, lineHeight: '32px' }}>{`${t('Počet zobrazených promo kódov')}: ${context.totalCount}`}</p>
				</div>
			</div>
			<Table
				className={'general-table promo-codes-usage'}
				columns={columns}
				dataSource={dataSource}
				onChange={handleTableChange}
				style={{ marginTop: -20 }}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

PromoCodesUsagePage.propTypes = {}

export default PromoCodesUsagePage
