import { Button, Form } from 'antd'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import validateForgottenPasswordForm, { ForgottenPasswordFormValues } from './validateForgottenPasswordForm'

// atoms
import TextField from '../../../atoms/form/TextField'

const ForgottenPasswordForm = ({ invalid, pristine, handleSubmit }: InjectedFormProps<ForgottenPasswordFormValues>) => {
	const { t } = useTranslation()

	return (
		<form onSubmit={handleSubmit}>
			<Field
				name={'email'}
				label={t('Email')}
				type={'text'}
				placeholder={t('Email')}
				component={TextField}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
			/>
			<Form.Item>
				<Button type={'primary'} htmlType={'submit'} disabled={invalid || pristine}>
					{t('Odoslať')}
				</Button>
			</Form.Item>
		</form>
	)
}

export default reduxForm<ForgottenPasswordFormValues>({
	form: FORMS.FORGOTTEN_PASSWORD_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateForgottenPasswordForm
})(ForgottenPasswordForm)
