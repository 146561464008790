import { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { get, trim } from 'lodash'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { uploadFile } from '../../../utils/fileUploader'
import { INPUT_MAX_LENGTH, UPLOAD_CATEGORY } from '../../../utils/enums'

const UserProfile = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)

	const setName = (name) => {
		itemContentSetter(item?.id, {
			image: get(item, 'content.image'),
			name,
			description: get(item, 'content.description')
		})
	}

	const setDescription = (description) => {
		itemContentSetter(item?.id, {
			image: get(item, 'content.image'),
			name: get(item, 'content.name'),
			description
		})
	}

	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div>{t('Vyber súbor')}</div>
		</div>
	)

	const renderImage = item?.content?.image ? <img className={'upload-image'} src={item.content.image} alt={'avatar'} /> : uploadButton

	const uploadImage = async ({ file }) => {
		setIsLoading(true)

		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)

		if (fileUrl) {
			setIsLoading(false)
			itemContentSetter(item?.id, {
				image: fileUrl,
				name: get(item, 'content.name'),
				description: get(item, 'content.description')
			})
		}
	}

	return (
		<div className={'custom-item'}>
			<div className={'flex justify-center'} style={{ marginBottom: '15px' }}>
				<Upload
					listType={'picture-card'}
					className={'upload-field'}
					showUploadList={false}
					customRequest={uploadImage}
					style={{ marginBottom: '15px' }}
				>
					{/* eslint-disable-next-line no-nested-ternary */}
					{isLoading ? <LoadingOutlined /> : renderImage}
				</Upload>
			</div>

			<Input
				placeholder={t('Meno používateľa')}
				className={'input-field'}
				style={{ marginBottom: '15px' }}
				size={'large'}
				value={item?.content?.name}
				maxLength={INPUT_MAX_LENGTH}
				onChange={(e) => setName(e.target.value)}
				onBlur={(e) => setName(trim(e.target.value))}
			/>
			<Input
				placeholder={t('Krátky popis')}
				className={'input-field'}
				style={{ marginBottom: '15px' }}
				size={'large'}
				value={item?.content?.description}
				maxLength={INPUT_MAX_LENGTH}
				onChange={(e) => setDescription(e.target.value)}
				onBlur={(e) => setDescription(trim(e.target.value))}
			/>
		</div>
	)
}

UserProfile.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			image: PropTypes.string,
			name: PropTypes.string,
			description: PropTypes.string
		})
	}),
	itemContentSetter: PropTypes.func.isRequired
}

UserProfile.defaultProps = {
	item: {}
}

export default UserProfile
