import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import packageInfo from '../../package.json'

import { NAMESPACE, DEFAULT_LOCALE } from './enums'

i18n.use(initReactI18next)
	.use(Backend)
	.init({
		backend: {
			backendOptions: [
				{
					expirationTime: 0, // 7*24*60*60*1000 // 1 week
					prefix: 'i18next_res_'
				},
				{
					loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
					queryStringParams: { v: packageInfo.version }
				}
			],
			backends: [
				LocalStorageBackend, // primary
				XHR // fallback
			]
		},
		// debug: window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV === 'development',
		defaultNS: 'common',
		fallbackLng: DEFAULT_LOCALE,
		interpolation: {
			escapeValue: false
		},
		keySeparator: '|',
		lng: DEFAULT_LOCALE,
		load: 'languageOnly',
		ns: Object.values(NAMESPACE),
		nsSeparator: ':',
		react: {
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default',
			wait: true
		}
	})

export default i18n
