export enum LOAD_DOWNLOADS {
	START = 'LOAD_DOWNLOADS_START',
	DONE = 'LOAD_DOWNLOADS_DONE',
	FAILED = 'LOAD_DOWNLOADS_FAILED'
}

export enum LOAD_DOWNLOAD {
	START = 'LOAD_DOWNLOAD_START',
	DONE = 'LOAD_DOWNLOAD_DONE',
	FAILED = 'LOAD_DOWNLOAD_FAILED'
}

export enum CREATE_DOWNLOAD {
	START = 'CREATE_DOWNLOAD_START',
	DONE = 'CREATE_DOWNLOAD_DONE',
	FAILED = 'CREATE_DOWNLOAD_FAILED'
}

export enum UPDATE_DOWNLOAD {
	START = 'UPDATE_DOWNLOAD_START',
	DONE = 'UPDATE_DOWNLOAD_DONE',
	FAILED = 'UPDATE_DOWNLOAD_FAILED'
}

export enum DELETE_DOWNLOAD {
	START = 'DELETE_DOWNLOAD_START',
	DONE = 'DELETE_DOWNLOAD_DONE',
	FAILED = 'DELETE_DOWNLOAD_FAILED'
}

export enum LOAD_DOWNLOAD_CATEGORIES {
	START = 'LOAD_DOWNLOAD_CATEGORIES_START',
	DONE = 'LOAD_DOWNLOAD_CATEGORIES_DONE',
	FAILED = 'LOAD_DOWNLOAD_CATEGORIES_FAILED'
}
