import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import TextField from '../../../atoms/form/TextField'
import { FORMS } from '../../../utils/enums'
import validatePassword from './validatePassword'

const PasswordChangeForm = ({ handleSubmit, onSubmit }) => {
	const { t } = useTranslation()

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field name={'newPassword'} component={TextField} type={'text'} label={t('Nové heslo')} required />
		</form>
	)
}

PasswordChangeForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.PASSWORD_CHANGE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePassword
})(PasswordChangeForm)
