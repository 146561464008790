import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../../utils/enums'
import TransferSubscriptionForm from '../forms/TransferSubscriptionForm'

const TransferSubscriptionModal = ({ setShowModal, submitTransferSubscription }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<Modal
			visible
			title={t('Presunúť členstvo na iného používateľa')}
			okText={t('Presunúť')}
			onOk={() => {
				dispatch(submit(FORMS.TRANSFER_SUBSCRIPTION_FORM))
			}}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(null)}
		>
			<TransferSubscriptionForm onSubmit={submitTransferSubscription} />
		</Modal>
	)
}

TransferSubscriptionModal.propTypes = {
	setShowModal: PropTypes.func.isRequired,
	submitTransferSubscription: PropTypes.func.isRequired
}

export default TransferSubscriptionModal
