import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createMotivationAdvice, getMotivationAdvice, updateMotivationAdvice } from '../../redux/motivationAdvices/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// types
import { IComputedMatch } from '../../types/interfaces'
import { MotivationAdvicePayload } from '../../redux/motivationAdvices/types'
import { Paths } from '../../types/api'

// forms
import MotivationAdviceForm from './forms/MotivationAdviceForm'
import { MotivationAdviceFormValues } from './forms/validateMotivationAdviceForm'

type DetailMotivationAdviceProps = {
	computedMatch: IComputedMatch
}

const DetailMotivationAdvice = ({ computedMatch }: DetailMotivationAdviceProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.payments?.detail)

	// constants
	const isLoading = detail?.isLoading

	const initDetailForm = useCallback(
		(data: MotivationAdvicePayload & { processedAt?: string }) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				id: data?.id,
				name: data?.name,
				image: data?.image,
				updatedAt: dayjs(data?.processedAt).format('H:mm:ss D. MMM YYYY ')
			}

			each(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`link_${item}`] = get(lang, 'link')
				langValues[`text_${item}`] = get(lang, 'text')
			})

			dispatch(
				initialize(FORMS.MOTIVATION_ADVICE_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {}

		dispatch(initialize(FORMS.MOTIVATION_ADVICE_FORM, initValues))
	}, [dispatch])

	useEffect(() => {
		if (id) {
			dispatch(getMotivationAdvice(id, (data) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [id, dispatch, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: Paths.PostAdminMotivationAdvices.RequestBody) => {
		dispatch(createMotivationAdvice(body, (motivationID) => history.push(`${t('paths:motivationAdvice|path')}/${motivationID}`)))
	}

	const handleUpdate = (body: Paths.PutAdminMotivationAdvicesId.RequestBody) => {
		dispatch(updateMotivationAdvice(id, body, () => dispatch(getMotivationAdvice(id, (data) => initDetailForm(data)))))
	}

	const handleSubmit = (values: MotivationAdviceFormValues) => {
		const body: any = {
			name: values?.name,
			image: values?.image,
			translations: []
		}

		each(LANGUAGES, (item) => {
			const text = get(values, `text_${item}`)
			const link = get(values, `link_${item}`)

			if (text && text !== '') {
				const translation: { text: string; language: LANGUAGE; link?: string } = {
					text,
					language: item
				}

				if (link && link !== '') {
					translation.link = get(values, `link_${item}`)
				}

				body.translations.push(translation)
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<MotivationAdviceForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailMotivationAdvice
