import PropTypes from 'prop-types'
import { Checkbox, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { trim } from 'lodash'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const YoutubeVideo = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const isOldContent = typeof item?.content === 'string'

	const setContent = (value) => {
		if (isOldContent) {
			itemContentSetter(item?.id, { center: false, src: value })
		} else {
			itemContentSetter(item?.id, { ...item?.content, src: value })
		}
	}

	const setCenter = (value) => {
		if (isOldContent) {
			itemContentSetter(item?.id, { src: item.content, center: value })
		} else {
			itemContentSetter(item?.id, { ...item?.content, center: value })
		}
	}

	return (
		<div className={'custom-item embed-element'}>
			<Input
				placeholder={t('Sem zadajte link na youtube video')}
				className={'input-field'}
				size={'large'}
				value={isOldContent ? item.content : item?.content?.src}
				maxLength={INPUT_MAX_LENGTH}
				onChange={(e) => setContent(e.target.value)}
				onBlur={(e) => setContent(trim(e.target.value))}
			/>
			<div className={'flex justify-end mt-3'}>
				<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
					{t('Centrovať')}
				</Checkbox>
			</div>
		</div>
	)
}

YoutubeVideo.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				src: PropTypes.string,
				center: PropTypes.bool
			})
		])
	}),
	itemContentSetter: PropTypes.func.isRequired
}

YoutubeVideo.defaultProps = {
	item: {}
}

export default YoutubeVideo
