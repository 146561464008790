import { mapValues } from 'lodash'
import { getReq, postReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { CHANGE_USER_SUBSCRIPTIONS_TIER, LOAD_USER_SUBSCRIPTIONS, LOAD_USER_SUBSCRIPTIONS_TIER, LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE } from './types'

export const getUserSubscriptions = (userID, params, onSuccess, onFailure) => async (dispatch) => {
	try {
		dispatch({ type: LOAD_USER_SUBSCRIPTIONS.START })

		const queries = {
			limit: 20,
			page: 1,
			userID,
			orderBy: 'id',
			orderDirection: 'desc',
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_SUBSCRIPTIONS, normalizeQueryParams)

		dispatch({
			type: LOAD_USER_SUBSCRIPTIONS.DONE,
			payload: data
		})

		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_USER_SUBSCRIPTIONS.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getSubscriptions = () => async (dispatch) => dispatch()

export const getUserSubscriptionsTierValidate =
	(userID, onSuccess = undefined, onFailure = undefined) =>
	async (dispatch) => {
		dispatch({ type: LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.START })

		try {
			const { data } = await getReq(ENDPOINTS.GET_USER_SUBSCRIPTIONS_TIER_VALIDATE(userID))
			dispatch({
				type: LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.DONE,
				payload: data
			})
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: LOAD_USER_SUBSCRIPTIONS_TIER_VALIDATE.FAILED })
			return onFailure && onFailure()
		}
	}

export const getUserSubscriptionsTier = (userID, params, onSuccess, onFailure) => async (dispatch) => {
	dispatch({ type: LOAD_USER_SUBSCRIPTIONS_TIER.START })
	try {
		const queries = {
			...params,
			tier: params?.tier
		}
		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_USER_SUBSCRIPTIONS_TIER(userID), normalizeQueryParams)
		dispatch({
			type: LOAD_USER_SUBSCRIPTIONS_TIER.DONE
		})
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_USER_SUBSCRIPTIONS_TIER.FAILED })
		return onFailure && onFailure()
	}
}

export const changeUserSubscriptionsTier = (userID, body, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: CHANGE_USER_SUBSCRIPTIONS_TIER.START
	})
	try {
		await postReq(ENDPOINTS.CHANGE_USER_SUBSCRIPTIONS_TIER(userID), null, body)
		dispatch({ type: CHANGE_USER_SUBSCRIPTIONS_TIER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CHANGE_USER_SUBSCRIPTIONS_TIER.FAILED })
		return onFailure && onFailure(error)
	}
}
