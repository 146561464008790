import { GET_RECIPE_BOOKS, RecipeBooksPayload } from './types'
import { ILoadingAndFailure } from '../../types/interfaces'

const initState = {
	standardNutritionPlan: null,
	isLoading: false,
	isFailure: false
} as RecipeBooksPayload & ILoadingAndFailure

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_RECIPE_BOOKS.START:
			return {
				...initState,
				...state,
				isLoading: true,
				isFailure: false
			}
		case GET_RECIPE_BOOKS.DONE:
			return {
				...initState,
				...state,
				standardNutritionPlan: action?.payload?.standardNutritionPlan,
				isLoading: false,
				isFailure: false
			}
		case GET_RECIPE_BOOKS.FAILED:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		default:
			return state
	}
}
