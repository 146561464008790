import { useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { deleteAdministrator } from '../../../redux/administrators/actions'

// utils
import { ADMIN_ROLE, FORMS, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateAdministratorForm, { AdministratorFormValues } from './validateAdministratorForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import SelectField from '../../../atoms/form/SelectField'
import TextAreaField from '../../../atoms/form/TextAreaField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type AdministratorFormProps = {
	isCreate?: boolean
}

const AdministratorForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: AdministratorFormProps & InjectedFormProps<AdministratorFormValues, AdministratorFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state) => getFormValues(FORMS.ADMINISTRATOR_FORM)(state)) as AdministratorFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.ADMINISTRATOR_FORM))

	// states
	const [image, setImage] = useState<any>({
		isLoading: false,
		imageUrl: ''
	})

	// constants
	const roleOptions = [
		{ value: ADMIN_ROLE.BLOGGER, label: t('Editor') },
		{ value: ADMIN_ROLE.ADMIN, label: t('Administrátor') },
		{ value: ADMIN_ROLE.SUPERADMIN, label: t('Superadmin') }
	]

	const fieldLabels: IFieldLabels = {
		firstName: t('Meno'),
		lastName: t('Priezvisko'),
		email: t('Email'),
		urlSlug: t('Slug'),
		role: t('Rola')
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať administrátora?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteAdministrator(fieldValues?.id, () => {
						history.push(t('paths:administrators|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})

		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROFILE)

		if (fileUrl) {
			dispatch(change(FORMS.ADMINISTRATOR_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	const debounceChangeField = debounce((value, input) => {
		let fullName = ''
		if (input === 0) {
			fullName = `${value} ${get(fieldValues, 'lastName', '')}`
		} else {
			fullName = `${get(fieldValues, 'firstName', '')} ${value}`
		}
		const slug = slugify(fullName.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.ADMINISTRATOR_FORM, 'urlSlug', slug))
	}, 300)

	const changeUrlSlugByName = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value, 0)
		}
	}

	const changeUrlSlugBySurname = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value, 1)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať administrátora') : t('Detail administrátora')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nového administrátora'),
										path: t('paths:administratorCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={'general'} className={'translations'}>
							<TabPane tab={t('Všeobecné')} key={'general'}>
								<Field name={'firstName'} component={TextField} onInput={changeUrlSlugByName} label={t('Meno')} required />
								<Field name={'lastName'} component={TextField} onInput={changeUrlSlugBySurname} label={t('Priezvisko')} required />
								<Field name={'urlSlug'} component={TextField} label={t('Slug')} required />
								<Field name={'email'} component={TextField} label={t('Email')} required />
								<Field name={'role'} component={SelectField} label={t('Rola')} options={roleOptions} required />
							</TabPane>
							{!isCreate &&
								map(LANGUAGES, (item) => (
									<TabPane tab={getLanguageName(item)} key={item}>
										<Field name={`briefDescription_${item}`} component={TextAreaField} label={t('Krátky popis')} size={'large'} />
										<Field name={`detailedDescription_${item}`} component={TextAreaField} label={t('Popis')} size={'large'} />
									</TabPane>
								))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'lastLoginAt'} component={TextField} disabled label={t('Dátum posledného prihlásenia')} />}

						{!isCreate && <Field name={'isConfirmed'} component={TextField} label={t('Potvrdený')} disabled />}

						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Profilová fotka (upload)')}
							customRequest={uploadImage}
							isLoading={image.isLoading}
						/>

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať administrátora')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<AdministratorFormValues, AdministratorFormProps>({
	form: FORMS.ADMINISTRATOR_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateAdministratorForm
})(AdministratorForm)
