import Icon, { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter'
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore'
import localeDataPlugin from 'dayjs/plugin/localeData'
import utcPlugin from 'dayjs/plugin/utc'
import weekdayPlugin from 'dayjs/plugin/weekday'
import timezonePlugin from 'dayjs/plugin/timezone'

import { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './atoms/ScrollToTop'
import Interceptors from './routes/Interceptors'
import Routes from './routes/Routes'
import i18n from './utils/i18n'

import 'dayjs/locale/sk'

dayjs.locale('sk')
dayjs.extend(utcPlugin)
dayjs.extend(weekdayPlugin)
dayjs.extend(localeDataPlugin)
dayjs.extend(isSameOrBeforePlugin)
dayjs.extend(isSameOrAfterPlugin)
dayjs.extend(timezonePlugin)

const antIcon = <Icon component={LoadingOutlined as any} style={{ fontSize: '24px', color: '#FD7755' }} spin />

const App: React.FC<{ store: any }> = ({ store }) => {
	return (
		<Suspense fallback={<Spin indicator={antIcon} style={{ position: 'absolute', left: '50%', top: '50%' }} />}>
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
					<BrowserRouter>
						<ScrollToTop>
							<Interceptors />
							<Routes />
						</ScrollToTop>
					</BrowserRouter>
				</I18nextProvider>
			</Provider>
		</Suspense>
	)
}

export default App
