import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'

export enum HORIZONTAL_GAP_TYPE {
	LARGE = 'LARGE',
	SMALL = 'SMALL',
	NONE = 'NONE'
}

type HorizontalGapContent = {
	type?: HORIZONTAL_GAP_TYPE
}

type HorizontalGapProps = {
	item?: {
		id?: string
		content?: HorizontalGapContent
	}
	itemContentSetter?: (id?: string, content?: HorizontalGapContent) => any
}

const HorizontalGap = ({ item, itemContentSetter }: HorizontalGapProps) => {
	const type = item?.content?.type

	const { t } = useTranslation()

	const setType = (value?: HORIZONTAL_GAP_TYPE) => {
		if (itemContentSetter) {
			itemContentSetter(item?.id, {
				...item?.content,
				type: value
			})
		}
	}

	return (
		<div className={'custom-item flex flex-wrap'} style={{ gap: 8 }}>
			<Radio checked={!type || type === HORIZONTAL_GAP_TYPE.LARGE} onChange={() => setType(HORIZONTAL_GAP_TYPE.LARGE)}>
				{t('Veľké')}
			</Radio>
			<Radio checked={type === HORIZONTAL_GAP_TYPE.SMALL} onChange={() => setType(HORIZONTAL_GAP_TYPE.SMALL)}>
				{t('Malé')}
			</Radio>
			<Radio checked={type === HORIZONTAL_GAP_TYPE.NONE} onChange={() => setType(HORIZONTAL_GAP_TYPE.NONE)}>
				{t('Žiadne')}
			</Radio>
		</div>
	)
}

export default HorizontalGap
