import debounce from 'lodash/debounce'
import get from 'lodash/get'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deletePreference } from '../../../redux/preferences/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES, PREFERENCE_TYPE } from '../../../utils/enums'

import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validatePreferenceForm, { PreferenceFormValues } from './validatePreferenceForm'

// atoms
import TextField from '../../../atoms/form/TextField'
import SelectField from '../../../atoms/form/SelectField'

const { TabPane } = Tabs

type PreferenceFormProps = {
	isCreate?: boolean
}

const PreferenceForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: PreferenceFormProps & InjectedFormProps<PreferenceFormValues, PreferenceFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PREFERENCE_FORM)(state)) as PreferenceFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PREFERENCE_FORM))

	// constants
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		description_sk: t('Popis'),
		type: t('Typ'),
		order: t('Poradie')
	}

	const preferenceTypes = [
		{ value: PREFERENCE_TYPE.LEVEL, label: PREFERENCE_TYPE.LEVEL },
		{ value: PREFERENCE_TYPE.GOAL, label: PREFERENCE_TYPE.GOAL },
		{ value: PREFERENCE_TYPE.LIFESTYLE, label: PREFERENCE_TYPE.LIFESTYLE },
		{ value: PREFERENCE_TYPE.CATEGORY, label: PREFERENCE_TYPE.CATEGORY }
	]

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať preferenciu?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deletePreference(get(fieldValues, 'id'), () => {
						history.push(t('paths:preferences|path'))
					})
				)
			}
		})
	}

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.PREFERENCE_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, lang: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${lang}`)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`title_${item}`}
										component={TextField}
										label={t('Názov')}
										onInput={(e: any) => changeUrlSlug(e, item)}
										size={'large'}
										required
									/>

									<Field name={`description_${item}`} component={TextField} label={t('popis')} required />
								</TabPane>
							))}
						</Tabs>
						<Field name={'type'} component={SelectField} options={preferenceTypes} label={t('Typ')} required />
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Tooltip
							title={
								invalid
									? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
											getFieldLabel(field, fieldLabels)
										)}`
									: null
							}
							placement={'bottom'}
						>
							<Button icon={<SaveOutlined />} onClick={handleSubmit} disabled={pristine || invalid} type={'primary'} style={{ margin: '20px' }}>
								{t('Uložiť')}
							</Button>
						</Tooltip>

						<div className={'flex direction-col justify-center'}>
							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať preferenciu')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<PreferenceFormValues, PreferenceFormProps>({
	form: FORMS.PREFERENCE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePreferenceForm
})(PreferenceForm)
