import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import i18next from 'i18next'
import slugify from 'slugify'
import { Button, Col, Empty, Modal, Row, Select, Table, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, ExportOutlined, SaveOutlined, EyeOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { debounce, find, get, isNaN, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteChallenge, exportChallengeUsers, getProgramUsers } from '../../../redux/programs/actions'
import { getBlogPostList, getExerciseList, getMotivationAdviceList, getProgramList, getTrainerList, getVideosList } from '../../../redux/lists/actions'

// utils
import { CHALLENGE_TYPE, EMPTY_VALUE, FORMS, LANGUAGE, LANGUAGES, PLATFORM_OPTIONS, PROGRAM_DIFFICULTY, UPLOAD_CATEGORY } from '../../../utils/enums'
import { formatProgramDifficulty, getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// validators
import validateChallengeForm, { ChallengeFormValues } from './validateChallengeForm'

// components
import DetailHeader from '../../../components/DetailHeader'
import LightBox from '../../../components/LightBox'

// atoms
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CircleColorField from '../../../atoms/form/CircleColorField'
import ColorField from '../../../atoms/form/ColorField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'

const { Option } = Select

type ChallengeFormProps = {
	isCreate?: boolean
}

const formatMeasurements = (value: any) =>
	`${value?.hips || EMPTY_VALUE} / ${value?.thigh || EMPTY_VALUE} / ${value?.waist || EMPTY_VALUE} / ${value?.weight || EMPTY_VALUE}`

const ChallengeForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: ChallengeFormProps & InjectedFormProps<ChallengeFormValues, ChallengeFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector(getFormValues(FORMS.CHALLENGE_FORM)) as any
	const formErrors = useSelector(getFormSyncErrors(FORMS.CHALLENGE_FORM))
	const detail = useSelector((state: RootState) => state.programs?.detail)
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)
	const motivationAdvices = useSelector((state: RootState) => state.lists?.list?.motivationAdvices)
	const blogPosts = useSelector((state: RootState) => state.lists?.list?.blogPosts)
	const videos = useSelector((state: RootState) => state.lists?.list?.videos)
	const recommendations = useSelector((state: RootState) => state.lists?.list?.programs)
	const exercises = useSelector((state: RootState) => state.lists?.list?.exercises)
	const trainers = useSelector((state: RootState) => state.lists?.list?.trainers)

	// states
	const [showLightBox, setShowLightBox] = useState<any>(false)
	const [challengeUsersPhotoGallery, setChallengeUsersPhotoGallery] = useState([])
	const [image, setImage] = useState({ isLoading: false })
	const [logo, setLogo] = useState({ isLoading: false })
	const [inverseLogo, setInverseLogo] = useState({ isLoading: false })
	const [whiteLogo, setWhiteLogo] = useState({ isLoading: false })
	const [prizesBanner, setPrizesBanner] = useState({ isLoading: false })
	const [badgeLogo, setBadgeLogo] = useState({ isLoading: false })
	const [lightBadgeLogo, setLightBadgeLogo] = useState({ isLoading: false })
	const [largeBannerImage, setLargeBannerImage] = useState({
		isLoading: false,
		imageUrl: ''
	})

	// constants
	const isLoading = detail?.isLoading
	const exercisesList = map(exercises, (item) => ({ id: item.id, title: item.title, isVisibleInCalendar: item.isVisibleInCalendar, program: item.program }))
	const trainersList = map(trainers, (item) => ({ id: item.id, title: item.title }))
	const challengeUsers = detail?.users
	const context = challengeUsers?.context
	const challengeUsersContentData = map(challengeUsers?.users, (user, index) => ({ ...user, key: index }))
	const challengeType = [
		{ value: CHALLENGE_TYPE.COMPETITIVE, label: t('Súťažná') },
		{ value: CHALLENGE_TYPE.HARMONIC, label: t('Harmonická') }
	]

	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		briefDescription_sk: t('Krátky popis'),
		detailedDescription_sk: t('Detailný popis'),
		logo_sk: t('Logo'),
		image_sk: t('Titulný obrázok'),
		videoPreviewID_sk: t('Náhľadové video'),
		domain_sk: t('Doména stránky'),
		name_cz: t('Názov [CZ]'),
		urlSlug_cz: t('URL [CZ]'),
		briefDescription_cz: t('Krátky popis [CZ]'),
		detailedDescription_cz: t('Detailný popis [CZ]'),
		logo_cz: t('Logo [CZ]'),
		image_cz: t('Titulný obrázok [CZ]'),
		videoPreviewID_cz: t('Náhľadové video [CZ]'),
		domain_cz: t('Doména stránky [CZ]'),
		challengeType: t('Typ výzvy'),
		startedAt: t('Začiatok výzvy'),
		finishedAt: t('Koniec výzvy'),
		visibleFrom: t('Začiatok zobrazovania výzvy'),
		visibleTo: t('Koniec zobrazovania výzvy'),
		signupDeadline: t('Koniec prihlasovania a zadávania mier na začiatku výzvy'),
		finishMeasurementsDeadline: t('Koniec zadávania mier na konci výzvy'),
		backgroundColor: t('Farba banneru'),
		order: t('Poradie')
	}

	useEffect(() => {
		if ((fieldValues?.isPublished === true && fieldValues?.publishDate === null) || fieldValues?.publishDate === undefined) {
			dispatch(change(FORMS.CHALLENGE_FORM, 'publishDate', dayjs()))
		}
	}, [fieldValues?.isPublished])

	useEffect(() => {
		;(() => {
			const tempGallery: any = []
			challengeUsersContentData.forEach((user) => {
				if (user.measurementsBefore?.gallery?.length > 0) {
					tempGallery.push({
						imagePath: user.measurementsBefore.gallery[0],
						caption: `${t('Pred')} / ${user.email} / ${user.isCompetition ? t('zapojená súťažne') : t('nezapojená súťažne')}`
					})
				}
				if (user.measurementAfter?.gallery?.length > 0) {
					tempGallery.push({
						imagePath: user.measurementAfter.gallery[0],
						caption: `${t('Po')} / ${user.email} / ${user.isCompetition ? t('zapojená súťažne') : t('nezapojená súťažne')}`
					})
				}
			})
			setChallengeUsersPhotoGallery(tempGallery)
		})()
	}, [detail?.users])

	const formatGallery = (gallery: any) => {
		if (gallery?.length > 0) {
			const firstImageIndex = challengeUsersPhotoGallery.findIndex((item: any) => item.imagePath === gallery[0])
			return (
				<Button onClick={() => setShowLightBox({ challengeUsersPhotoGallery, firstImageIndex })} type={'primary'}>
					{t('Zobraziť')}
				</Button>
			)
		}
		return null
	}

	// const [banner, setBanner] = useState({
	// 	isLoading: false,
	// 	imageUrl: ''
	// })

	const handleExportChallengeUsers = () => {
		const id = get(fieldValues, 'id')
		const slug = get(fieldValues, 'urlSlug_sk', `challenge-${id}`)
		dispatch(
			exportChallengeUsers(id, (csvData: any) => {
				const csvContent = `data:text/csv;charset=utf-8,${csvData}`
				const encodedUri = encodeURI(csvContent)
				const link = document.createElement('a')
				link.setAttribute('href', encodedUri)
				link.setAttribute('download', `export-${slug}.csv`)
				document.body.appendChild(link)
				link.click()
			})
		)
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať výzvu?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteChallenge(get(fieldValues, 'id'), () => {
						history.push(t('paths:programs|path'))
					})
				)
			}
		})
	}

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.CHALLENGE_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, lang: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${lang}`)
		}
	}

	const uploadImage = async ({ file }: any, lng: LANGUAGE) => {
		setImage({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `image_${lng}`, fileUrl))
			setImage({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadLogo = async ({ file }: any, lng: LANGUAGE) => {
		setLogo({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `logo_${lng}`, fileUrl))
			setLogo({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadInverseLogo = async ({ file }: any, lng: LANGUAGE) => {
		setInverseLogo({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `inverseLogo_${lng}`, fileUrl))
			setInverseLogo({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadWhiteLogo = async ({ file }: any, lng: LANGUAGE) => {
		setWhiteLogo({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `whiteLogo_${lng}`, fileUrl))
			setWhiteLogo({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadPrizesBanner = async ({ file }: any, lng: LANGUAGE) => {
		setPrizesBanner({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})

		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `prizeBanner_${lng}`, fileUrl))
			setPrizesBanner({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadBadgeLogo = async ({ file }: any, lng: LANGUAGE) => {
		setBadgeLogo({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_BADGE_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `badgeLogo_${lng}`, fileUrl))
			setBadgeLogo({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadLightBadgeLogo = async ({ file }: any, lng: LANGUAGE) => {
		setLightBadgeLogo({
			isLoading: true,
			[`imageUrl_${lng}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_BADGE_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, `lightBadgeLogo_${lng}`, fileUrl))
			setLightBadgeLogo({
				isLoading: false,
				[`imageUrl_${lng}`]: fileUrl
			})
		}
	}

	const uploadLargeBannerImage = async ({ file }: any) => {
		setLargeBannerImage({
			isLoading: true,
			imageUrl: ''
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.CHALLENGE_FORM, 'largeBannerImage', fileUrl))
			setLargeBannerImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	// const uploadBanner = async ({ file }) => {
	// 	setBanner({
	// 		isLoading: true,
	// 		imageUrl: ''
	// 	})
	//
	// 	const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.CHALLANGE_BANNER)
	//
	// 	if (fileUrl) {
	// 		dispatch(change(FORMS.CHALLENGE_FORM, 'bannerImage', fileUrl))
	// 		setBanner({
	// 			isLoading: false,
	// 			imageUrl: fileUrl
	// 		})
	// 	}
	// }

	const removeWhiteLogo = (lng: LANGUAGE) => {
		dispatch(change(FORMS.CHALLENGE_FORM, `whiteLogo_${lng}`, null))
	}

	const removeBadgeLogo = (lng: LANGUAGE) => {
		dispatch(change(FORMS.CHALLENGE_FORM, `badgeLogo_${lng}`, null))
	}

	const removeLightBadgeLogo = (lng: LANGUAGE) => {
		dispatch(change(FORMS.CHALLENGE_FORM, `lightBadgeLogo_${lng}`, null))
	}

	const renderDifficultyText = (difficulty: PROGRAM_DIFFICULTY) => {
		switch (difficulty) {
			case PROGRAM_DIFFICULTY.NORMAL:
				return <p style={{ margin: 0, color: 'green' }}>{formatProgramDifficulty(difficulty)}</p>
			case PROGRAM_DIFFICULTY.HARD:
				return <p style={{ margin: 0, color: 'orange' }}>{formatProgramDifficulty(difficulty)}</p>
			default:
				return null
		}
	}

	const usersTableColumns = [
		{
			title: i18next.t('Email'),
			dataIndex: 'email',
			key: 'email',
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Dátum prihlásenia'),
			dataIndex: 'startedAt',
			key: 'startedAt',
			render: (value: string) => dayjs(value).format('H:mm D.M.YYYY')
		},
		{
			title: i18next.t('Náročnosť'),
			dataIndex: 'difficulty',
			key: 'difficulty',
			render: (value: PROGRAM_DIFFICULTY) => renderDifficultyText(value)
		},
		{
			title: i18next.t('Miery pred (boky/stehná/pás/váha)'),
			dataIndex: 'measurementsBefore',
			key: 'measurementsBefore',
			render: (value: any) => formatMeasurements(value)
		},
		{
			title: i18next.t('Galéria pred'),
			dataIndex: 'measurementsBefore',
			key: 'galleryBefore',
			render: (value: any) => formatGallery(value?.gallery || [])
		},
		{
			title: i18next.t('Miery po (boky/stehná/pás/váha)'),
			dataIndex: 'measurementAfter',
			key: 'measurementAfter',
			render: (value: any) => formatMeasurements(value)
		},
		{
			title: i18next.t('Galéria po'),
			dataIndex: 'measurementAfter',
			key: 'galleryAfter',
			render: (value: any) => formatGallery(get(value, 'gallery', []))
		},
		{
			title: i18next.t('Ukončené cvičenia'),
			dataIndex: 'finishedExercises',
			key: 'finishedExercises',
			render: (value: any) => value
		},
		{
			title: i18next.t('Zapojený v súťaži'),
			dataIndex: 'isCompetition',
			key: 'isCompetition',
			render: (value: boolean) => (value ? t('Áno') : t('Nie'))
		},
		{
			title: i18next.t('Úbytok váhy'),
			dataIndex: 'weightLoss',
			key: 'weightLoss',
			render: (value: number) => {
				if (!isNaN(Math.round(value * 100) / 100)) {
					return `${Math.round(value * 100) / 100} kg`
				}
				return EMPTY_VALUE
			}
		}
	]

	const usersTableColumnsShort = [
		{
			title: i18next.t('Email'),
			dataIndex: 'email',
			key: 'email',
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Dátum prihlásenia'),
			dataIndex: 'startedAt',
			key: 'startedAt',
			render: (value: string) => dayjs(value).format('H:mm D.M.YYYY')
		},
		{
			title: i18next.t('Ukončené cvičenia'),
			dataIndex: 'finishedExercises',
			key: 'finishedExercises',
			render: (value: any) => value
		}
	]

	const handleTableChange = (pagination: any) => {
		dispatch(getProgramUsers(get(fieldValues, 'id'), { page: pagination.current }))
	}

	const handleSwitchExercisesChange = (value: any, record: any, attribute: any) => {
		let focusedRow = find(fieldValues?.exercises, ['id', get(record, 'id')])
		const exerciseArray: any = []
		map(fieldValues?.exercises, (item) => {
			if (item === focusedRow) {
				focusedRow = {
					...focusedRow,
					[attribute]: value
				}
				exerciseArray.push(focusedRow)
			} else {
				exerciseArray.push(item)
			}
		})
		dispatch(change(FORMS.CHALLENGE_FORM, 'exercises', exerciseArray))
	}

	const handleSwitchHardExercisesChange = (value: any, record: any, attribute: any) => {
		let focusedRow = find(fieldValues?.hardExercises, ['id', record?.id])
		const hardExerciseArray: any = []
		map(fieldValues?.hardExercises, (item) => {
			if (item === focusedRow) {
				focusedRow = {
					...focusedRow,
					[attribute]: value
				}
				hardExerciseArray.push(focusedRow)
			} else {
				hardExerciseArray.push(item)
			}
		})
		dispatch(change(FORMS.CHALLENGE_FORM, 'hardExercises', hardExerciseArray))
	}

	const expandedRowRender = (row: any) => {
		const { answersAfter, answersBefore } = row
		const questionsDataSource = map(detail?.data?.questions, (question) => ({
			question: find(question?.translations, ['language', LANGUAGE.SK])?.text || EMPTY_VALUE,
			answerAfter: find(answersAfter, ['questionID', question?.id])?.value,
			commentAfter: find(answersAfter, ['questionID', question?.id])?.comment,
			answerBefore: find(answersBefore, ['questionID', question?.id])?.value,
			commentBefore: find(answersBefore, ['questionID', question?.id])?.comment
		}))

		const emptyAnswer = <span>{t('Žiadna odpoveď')}</span>
		const emptyComment = <span>{t('Žiadny komentár')}</span>

		return (
			<div className={'questions-expander'}>
				{map(questionsDataSource, (item) => (
					<div className={'question-table'}>
						<Row className={'question'}>
							<Col span={24} className={'grid'}>
								{item?.question}
							</Col>
						</Row>
						<Row>
							<Col span={3} className={'grid'}>
								<strong>{`${t('Odpoveď (Pred)')}: `}</strong>
							</Col>
							<Col span={21} className={'grid'}>
								{item?.answerBefore || emptyAnswer}
							</Col>
						</Row>
						<Row>
							<Col span={3} className={'grid'}>
								<strong>{`${t('Komentár (Pred)')}: `}</strong>
							</Col>
							<Col span={21} className={'grid'}>
								{item?.commentBefore || emptyComment}
							</Col>
						</Row>
						<Row>
							<Col span={3} className={'grid'}>
								<strong>{`${t('Odpoveď (Po)')}: `}</strong>
							</Col>
							<Col span={21} className={'grid'}>
								{item?.answerAfter || emptyAnswer}
							</Col>
						</Row>
						<Row>
							<Col span={3} className={'grid'}>
								<strong>{`${t('Komentár (Po)')}: `}</strong>
							</Col>
							<Col span={21} className={'grid'}>
								{item?.commentAfter || emptyComment}
							</Col>
						</Row>
					</div>
				))}
			</div>
		)
	}

	const tabItems = map(LANGUAGES, (item) => ({
		label: getLanguageName(item),
		key: item,
		forceRender: true,
		children: (
			<>
				<Field
					name={`name_${item}`}
					component={TextField}
					label={t('Názov')}
					onInput={(e: any) => changeUrlSlug(e, item)}
					size={'large'}
					required={item !== LANGUAGE.EN}
				/>

				<Row gutter={[8, 8]} justify={'center'} style={{ margin: '16px 0px', background: 'whitesmoke', padding: '16px', justifyContent: 'flex-start' }}>
					<Col span={6}>
						<Field
							name={`image_${item}`}
							imageUrl={get(fieldValues, `image_${item}`)}
							component={UploadInputField}
							label={t('Titulný obrázok')}
							customHelper={t('recommendedSize|videoThumbnail')}
							customRequest={(file: any) => uploadImage(file, item)}
							isLoading={image.isLoading}
							required={item !== LANGUAGE.EN}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`logo_${item}`}
							imageUrl={get(fieldValues, `logo_${item}`)}
							component={UploadInputField}
							label={t('Logo')}
							customRequest={(file: any) => uploadLogo(file, item)}
							customHelper={t('recommendedSize|programLogo')}
							isLoading={logo.isLoading}
							required={item !== LANGUAGE.EN}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`inverseLogo_${item}`}
							imageUrl={get(fieldValues, `inverseLogo_${item}`)}
							component={UploadInputField}
							label={t('Inverzne logo')}
							customClass={'logo-uploader-dark'}
							customHelper={t('recommendedSize|programLogo')}
							customRequest={(file: any) => uploadInverseLogo(file, item)}
							isLoading={inverseLogo.isLoading}
							required={item !== LANGUAGE.EN}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`whiteLogo_${item}`}
							imageUrl={get(fieldValues, `whiteLogo_${item}`)}
							component={UploadInputField}
							label={t('Biele logo')}
							customClass={'logo-uploader-dark'}
							customHelper={t('recommendedSize|programLogo')}
							customRequest={(file: any) => uploadWhiteLogo(file, item)}
							isLoading={whiteLogo.isLoading}
							onRemove={() => removeWhiteLogo(item)}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`prizeBanner_${item}`}
							customHelper={t('recommendedSize|videoThumbnail')}
							component={UploadInputField}
							imageUrl={get(fieldValues, `prizeBanner_${item}`)}
							label={t('Banner s cenami')}
							customRequest={(file: any) => uploadPrizesBanner(file, item)}
							isLoading={prizesBanner.isLoading}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`badgeLogo_${item}`}
							imageUrl={get(fieldValues, `badgeLogo_${item}`)}
							component={UploadInputField}
							label={t('Tmavý badge')}
							customHelper={t('recommendedSize|programBadgeLogo')}
							customRequest={(file: any) => uploadBadgeLogo(file, item)}
							isLoading={badgeLogo.isLoading}
							onRemove={() => removeBadgeLogo(item)}
						/>
					</Col>
					<Col span={6}>
						<Field
							name={`lightBadgeLogo_${item}`}
							imageUrl={get(fieldValues, `lightBadgeLogo_${item}`)}
							component={UploadInputField}
							label={t('Bledší badge')}
							customHelper={t('recommendedSize|programBadgeLogo')}
							customRequest={(file: any) => uploadLightBadgeLogo(file, item)}
							isLoading={lightBadgeLogo.isLoading}
							onRemove={() => removeLightBadgeLogo(item)}
						/>
					</Col>
				</Row>

				<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required={item !== LANGUAGE.EN} />

				<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} required={item !== LANGUAGE.EN} />

				<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} required={item !== LANGUAGE.EN} />

				<Field name={`recommendedProgramsText_${item}`} component={WYSIWYGEditor} label={t('Text pre odporúčané programy')} />

				<Field
					name={`videoPreviewID_${item}`}
					component={AsyncTransferField}
					customButton={{
						icon: <EyeOutlined />,
						handler: (id: number) => {
							window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
						}
					}}
					label={t('Náhľadové video (link na Vimeo)')}
					reduxFetch={(config: any) => dispatch(getVideosList(config))}
					modalDataSource={videos}
					showPagination={false}
					context={listContext}
					maxItems={1}
					required={item !== LANGUAGE.EN}
				/>

				<Field name={`stickerText_${item}`} component={TextField} label={t('Text nálepky')} />

				<Field name={`stickerColor_${item}`} component={CircleColorField} label={t('Farba nálepky')} />

				<Field name={`smartEmailingContactListID_${item}`} component={TextField} type={'number'} label={t('SmartEmailing Contact list ID')} />

				<Field
					name={`smartEmailingContactListHardDifficultyID_${item}`}
					component={TextField}
					type={'number'}
					label={t('SmartEmailing Contact list ID (náročnejšia verzia)')}
				/>

				<Field name={`prizeValue_${item}`} component={TextField} label={t('Hodnota výhier')} />

				<Field name={`domain_${item}`} component={TextField} label={t('Doména výzvovej stránky')} required={item !== LANGUAGE.EN} />

				<Field name={`conditionsUrl_${item}`} component={TextField} label={t('Link na podmienky súťaže')} />

				<Field name={`faqUrl_${item}`} component={TextField} label={t('Link na najčastejšie otázky')} />
			</>
		)
	}))

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať výzvu') : t('Detail výzvy')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový program'),
										path: t('paths:programCreate|path')
									},
									{
										title: t('Pridať novú výzvu'),
										path: t('paths:challengeCreate|path')
									}
								]
							}
						/>
						<Field name={'challengeType'} component={SelectField} options={challengeType} disabled={!isCreate} required label={t('Typ výzvy')} />
						{get(fieldValues, 'challengeType') === CHALLENGE_TYPE.COMPETITIVE && (
							<Field name={'allowCompetition'} component={SwitchField} label={t('Povoliť súťažné zapojenie')} />
						)}

						<Field
							name={'allowedPlatforms'}
							component={CustomOptionsSelectField}
							label={t('Platformy (na ktorých sa zobrazuje)')}
							options={map(PLATFORM_OPTIONS, (part, index) => (
								<Option key={index} value={get(part, 'value')}>
									{get(part, 'label') || ''}
								</Option>
							))}
							multiple
							required
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						/>

						<Tabs items={tabItems} defaultActiveKey={LANGUAGE.SK} className={'translations'} />

						<Row>
							<Col span={7} className={'grid'}>
								<Field name={'firstGradientColor'} component={ColorField} showLabel label={t('Gradient 1/2')} required />
							</Col>
							<Col span={1} className={'grid'} />
							<Col span={7} className={'grid'}>
								<Field name={'secondGradientColor'} component={ColorField} showLabel label={t('Gradient 2/2')} />
							</Col>
						</Row>
						<Field
							name={'visibleFrom'}
							component={DateField}
							label={t('Začiatok zobrazovania výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>
						<Field
							name={'startedAt'}
							component={DateField}
							label={t('Začiatok výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>
						<Field
							name={'signupDeadline'}
							component={DateField}
							label={t('Koniec prihlasovania a zadávania mier na začiatku výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>
						<Field
							name={'finishedAt'}
							component={DateField}
							label={t('Koniec výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>
						<Field
							name={'finishMeasurementsDeadline'}
							component={DateField}
							label={t('Koniec zadávania mier na konci výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>

						<Field
							name={'visibleTo'}
							component={DateField}
							label={t('Koniec zobrazovania výzvy')}
							dttmFormat={'D.M.YYYY'}
							required
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'signupDeadline')))}
						/>

						<Field
							name={'recommendedBlogPosts'}
							component={AsyncTransferField}
							label={t('Odporúčané blogové články')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
							modalDataSource={blogPosts}
							context={listContext}
						/>
						<Field
							name={'motivationAdvices'}
							showPagination={false} // sortable
							component={AsyncTransferField}
							label={t('Motivačné tipy')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:motivationAdvice|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getMotivationAdviceList(config))}
							modalDataSource={motivationAdvices}
							context={listContext}
						/>
						<Field
							name={'exercises'}
							component={AsyncTransferField}
							label={t('Cvičenia')}
							reduxFetch={(config: any) => dispatch(getExerciseList(config))}
							modalDataSource={exercisesList}
							context={listContext}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							includeSwitcher
							includeDifficultySwitcher
							onSwitchChange={(value: any, record: any) => handleSwitchExercisesChange(value, record, 'isVisibleInCalendar')}
							onDifficultySwitchChange={(value: any, record: any) => handleSwitchExercisesChange(value, record, 'isDifficultyRatingVisible')}
							required
						/>
						<Field
							name={'hardExercises'}
							component={AsyncTransferField}
							label={t('Náročnejšie cvičenia')}
							reduxFetch={(config: any) => dispatch(getExerciseList(config))}
							modalDataSource={exercisesList}
							context={listContext}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							includeSwitcher
							includeDifficultySwitcher
							onSwitchChange={(value: any, record: any) => handleSwitchHardExercisesChange(value, record, 'isVisibleInCalendar')}
							onDifficultySwitchChange={(value: any, record: any) => handleSwitchHardExercisesChange(value, record, 'isDifficultyRatingVisible')}
						/>
						<Field
							name={'trainers'}
							component={AsyncTransferField}
							label={t('Inštruktori')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:instructor|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getTrainerList(config))}
							modalDataSource={trainersList}
							context={listContext}
						/>
						<Field
							name={'recommendations'}
							component={AsyncTransferField}
							label={t('Programy navrhované po výzve')}
							reduxFetch={(config: any) => dispatch(getProgramList(config))}
							modalDataSource={recommendations}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:programs|path')}/${id}`, '_blank')
								}
							}}
							context={listContext}
							maxItems={3}
							showPagination={false} // sortable
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field
							name={`largeBannerImage`}
							imageUrl={get(fieldValues, `largeBannerImage`)}
							component={UploadInputField}
							label={t('Obrázok pre veľký banner výzvy')}
							customHelper={t('recommendedSize|largeImageChallenge')}
							customRequest={uploadLargeBannerImage}
							isLoading={largeBannerImage.isLoading}
						/>

						{/* NOTE: From release of rebrand is this field not neccessary */}
						{/* <Field */}
						{/*	name={'bannerImage'} */}
						{/*	imageUrl={get(fieldValues, 'bannerImage')} */}
						{/*	component={UploadInputField} */}
						{/*	label={t('Pozadie banneru v kalendári')} */}
						{/*	customHelper={'258x258 (1:1)'} // NOTE: Maximum size of this image on web is 172px (172 * 1.5 = 258) */}
						{/*	customRequest={uploadBanner} */}
						{/*	isLoading={banner.isLoading} */}
						{/* /> */}

						{/* TODO: After release of rebrand is this color not necessary. Should be deleted. */}
						<Field name={'backgroundColor'} component={ColorField} showLabel label={t('Farba banneru')} required />

						<Field name={'primaryColor'} component={ColorField} showLabel label={t('Primárna farba')} required />
						<Field name={'secondaryColor'} component={ColorField} showLabel label={t('Sekundárna farba')} required />

						<Field name={'publishDate'} component={DateField} label={t('Dátum a čas publikovania')} dttmFormat={'D.M.YYYY HH:mm'} showTime />
						<Field
							name={'isPublished'}
							component={SwitchField}
							label={t('Publikované')}
							disabled={dayjs().isAfter(dayjs(get(fieldValues, 'startedAt')))}
						/>

						<Field name={'isPregnantSuitable'} component={SwitchField} label={t('Vhodné pre tehotné')} />

						<Field name={'isVisibleOnLandingPage'} component={SwitchField} label={t('Viditeľné na landing page')} />

						<Field name={'showExercisesInLists'} component={SwitchField} label={t('Zobraziť v zozname cvičení')} />

						<div className={'flex direction-col justify-center'}>
							{fieldValues?.challengeType !== CHALLENGE_TYPE.HARMONIC && (
								<Button icon={<ExportOutlined />} onClick={() => handleExportChallengeUsers()} style={{ margin: '20px' }}>
									{t('Exportovať zoznam používateľov')}
								</Button>
							)}
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>
							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať výzvu')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
			{fieldValues?.challengeType === CHALLENGE_TYPE.HARMONIC ? (
				<Table
					className={'general-table extended'}
					columns={usersTableColumnsShort}
					dataSource={challengeUsersContentData}
					onChange={handleTableChange}
					showSorterTooltip={false}
					expandable={{ expandedRowRender }}
					pagination={{
						pageSize: 20,
						total: get(context, 'totalCount'),
						current: get(context, 'page'),
						showSizeChanger: false
					}}
					loading={isLoading}
					locale={{
						emptyText: <Empty description={t('Žiadne dáta')} />
					}}
					size={'small'}
				/>
			) : (
				<Table
					className={'general-table'}
					columns={usersTableColumns}
					dataSource={challengeUsers?.users}
					onChange={handleTableChange}
					showSorterTooltip={false}
					pagination={{
						pageSize: 20,
						total: get(context, 'totalCount'),
						current: get(context, 'page'),
						showSizeChanger: false
					}}
					loading={isLoading}
					locale={{
						emptyText: <Empty description={t('Žiadne dáta')} />
					}}
					size={'small'}
				/>
			)}
			<LightBox
				isOpen={!!showLightBox}
				photoGallery={showLightBox.challengeUsersPhotoGallery}
				firstImageIndex={showLightBox.firstImageIndex}
				onClose={() => setShowLightBox(false)}
			/>
		</form>
	)
}

export default reduxForm<ChallengeFormValues, ChallengeFormProps>({
	form: FORMS.CHALLENGE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateChallengeForm
})(ChallengeForm)
