export enum LOAD_TRANSLATIONS {
	START = 'LOAD_TRANSLATIONS_START',
	DONE = 'LOAD_TRANSLATIONS_DONE',
	FAILED = 'LOAD_TRANSLATIONS_FAILED'
}

export enum UPDATE_TRANSLATION {
	START = 'UPDATE_TRANSLATION_START',
	DONE = 'UPDATE_TRANSLATION_DONE',
	FAILED = 'UPDATE_TRANSLATION_FAILED'
}
