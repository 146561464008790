import PropTypes from 'prop-types'
import { Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { get, map, each, forEach, includes, trim } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import GridItem from '../grid/GridItem'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const FAQ = ({ item, items, itemIndex, onDeleteItem, onAddItem, itemsSetter, itemContentSetter }) => {
	const { t } = useTranslation()

	const { captureTitle, questions } = get(item, 'content')

	const setField = (fieldName, value) => {
		itemContentSetter(item?.id, {
			...item?.content,
			[fieldName]: value
		})
	}

	const setQuestion = (fieldName, questionIndex, value) => {
		const newQuestionsList = map(questions, (question, index) => {
			if (index === questionIndex) {
				return {
					...question,
					[fieldName]: value
				}
			}

			return question
		})

		itemContentSetter(item?.id, {
			...item?.content,
			questions: newQuestionsList
		})
	}

	const addQuestion = () => {
		itemContentSetter(item?.id, {
			...item?.content,
			questions: [
				...(item?.content?.questions || []),
				{
					title: '',
					content: [],
					itemsOrder: []
				}
			]
		})
	}

	const removeQuestion = (questionIndex) => {
		const newQuestionsList = []

		each(questions, (question, index) => {
			if (index !== questionIndex) {
				newQuestionsList.push(question)
			}
		})

		const excludeIDs = get(item, `content.questions[${questionIndex}].content`, [])
		let newItems = {}

		forEach(items, (innerItem) => {
			if (!includes(excludeIDs, innerItem?.id)) {
				newItems[innerItem?.id] = innerItem
			}
		})

		newItems = {
			...newItems,
			[item?.id]: {
				...newItems[item?.id],
				content: {
					...newItems[item?.id].content,
					questions: newQuestionsList
				}
			}
		}

		itemsSetter(newItems)
	}

	return (
		<div className={'custom-grid-item faq'}>
			<label className={'input-label'}>{t('Názov sekcie')}</label>
			<Input
				placeholder={t('Názov sekcie')}
				className={'input-field'}
				style={{ marginBottom: '15px' }}
				value={captureTitle}
				maxLength={INPUT_MAX_LENGTH}
				onChange={(e) => setField('captureTitle', e.target.value)}
				onBlur={(e) => setField('captureTitle', trim(e.target.value))}
			/>

			<div className={'questions-list'}>
				{map(questions, (question, index) => (
					<div className={'question'} key={index}>
						<div className={'title-wrapper'}>
							<div style={{ width: '100%', marginRight: '15px' }}>
								<label className={'input-label'}>{`${t('Otázka č')}. ${index + 1}`}</label>
								<Input
									placeholder={t('Znenie otázky')}
									className={'input-field'}
									style={{ marginBottom: '15px' }}
									value={get(question, 'title')}
									maxLength={INPUT_MAX_LENGTH}
									onChange={(e) => setQuestion('title', index, e.target.value)}
									onBlur={(e) => setQuestion('title', index, trim(e.target.value))}
								/>
							</div>

							<Button type={'danger'} style={{ marginTop: '22px' }} onClick={() => removeQuestion(index)}>
								<DeleteOutlined />
								{t('Odstrániť')}
							</Button>
						</div>

						<GridItem
							key={item?.id}
							item={item}
							items={items}
							index={itemIndex}
							onDeleteItem={onDeleteItem}
							onAddItem={onAddItem}
							itemContentSetter={itemContentSetter}
							name={t('Obsah')}
							staticGrid
							specificData={{ questionIndex: index }}
							noCenter
						/>
					</div>
				))}

				<Button
					type={'primary'}
					onClick={(e) => {
						e.preventDefault()
						addQuestion()
					}}
				>
					{t('Pridať otázku')}
				</Button>
			</div>
		</div>
	)
}

FAQ.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			captureTitle: PropTypes.string,
			questions: PropTypes.arrayOf(PropTypes.shape({}))
		})
	}),
	itemIndex: PropTypes.number.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onDeleteItem: PropTypes.func.isRequired,
	onAddItem: PropTypes.func.isRequired,
	itemsSetter: PropTypes.func.isRequired,
	itemContentSetter: PropTypes.func.isRequired
}

FAQ.defaultProps = {
	item: {}
}

export default FAQ
