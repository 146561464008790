export const GET_ADMINISTRATORS = {
	START: 'GET_ADMINISTRATORS_START',
	DONE: 'GET_ADMINISTRATORS_DONE',
	FAILED: 'GET_ADMINISTRATORS_FAILED'
}

export const GET_ADMINISTRATOR = {
	START: 'GET_ADMINISTRATOR_START',
	DONE: 'GET_ADMINISTRATOR_DONE',
	FAILED: 'GET_ADMINISTRATOR_FAILED'
}

export const DELETE_ADMINISTRATOR = {
	START: 'DELETE_ADMINISTRATOR_START',
	DONE: 'DELETE_ADMINISTRATOR_DONE',
	FAILED: 'DELETE_ADMINISTRATOR_FAILED'
}

export const UPDATE_ADMINISTRATOR = {
	START: 'UPDATE_ADMINISTRATOR_START',
	DONE: 'UPDATE_ADMINISTRATOR_DONE',
	FAILED: 'UPDATE_ADMINISTRATOR_FAILED'
}

export const CREATE_ADMINISTRATOR = {
	START: 'CREATE_ADMINISTRATOR_START',
	DONE: 'CREATE_ADMINISTRATOR_DONE',
	FAILED: 'CREATE_ADMINISTRATOR_FAILED'
}
