import { Modal } from 'antd'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { changeUserSubscriptionsTier, getUserSubscriptions, getUserSubscriptionsTierValidate } from '../redux/subscriptions/actions'

// utils
import { FORMS } from '../utils/enums'

// forms
import EditSubscriptionForm from './forms/EditSubscriptionForm'
import { EditSubscriptionFormValues } from './validators/validateEditSubscriptionForm'

type EditSubscriptionModalProps = {
	className?: string
	showEditSubscriptionModal: boolean
	setShowEditSubscriptionModal: (arg: boolean) => void
	userID: number
}

const EditSubscriptionModal = ({ className, showEditSubscriptionModal, setShowEditSubscriptionModal, userID }: EditSubscriptionModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const handleSubmit = (body: EditSubscriptionFormValues) => {
		dispatch(
			changeUserSubscriptionsTier(userID, body, () => {
				dispatch(getUserSubscriptions(userID))
				dispatch(getUserSubscriptionsTierValidate(userID))
				setShowEditSubscriptionModal(false)
			})
		)
	}

	return (
		<Modal
			className={className}
			open={showEditSubscriptionModal}
			title={t('Upraviť členstvo')}
			okText={t('Upraviť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowEditSubscriptionModal(false)}
			onOk={() => {
				dispatch(submit(FORMS.EDIT_SUBSCRIPTION_FORM))
			}}
		>
			<EditSubscriptionForm onSubmit={handleSubmit} userID={userID} />
		</Modal>
	)
}

export default EditSubscriptionModal
