import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

const InstructorChips = styled.img`
	background-color: black;
	border-radius: 50% !important;
	width: 40px;
	height: 40px;
	border: 1px solid #f1f1f1;
	margin-left: -8px;
`

const InstructorWrapper = styled.div`
	padding-left: 8px;
	display: flex;
	flex-direction: row;
`

interface IInstructorRepresent {
	trainers?: {
		id: number
		name: string
		urlSlug: string
		briefDescription?: string | null
		image?: string | null
	}[]
}

const InstructorRepresent: React.FC<IInstructorRepresent> = ({ trainers }) => {
	const { t } = useTranslation()

	return (
		<InstructorWrapper>
			{map(trainers, (trainer) => (
				<Tooltip title={trainer?.name || t('Bez mena')} placement={'bottom'}>
					<InstructorChips src={trainer.image || ''} />
				</Tooltip>
			))}
		</InstructorWrapper>
	)
}

export default InstructorRepresent
