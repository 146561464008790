/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { each, forEach, get, isEmpty } from 'lodash'
import {
	BoldOutlined,
	ItalicOutlined,
	UnderlineOutlined,
	OrderedListOutlined,
	UnorderedListOutlined,
	LineHeightOutlined,
	LinkOutlined,
	SortDescendingOutlined
} from '@ant-design/icons'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import { Editor, Transforms, createEditor, Text, Range } from 'slate'
import { jsx } from 'slate-hyperscript'
import { withHistory } from 'slate-history'
import escapeHtml from 'escape-html'
import i18next from 'i18next'

import { Button, Toolbar } from './richTextEditorComponents'

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code'
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const removeUnwantedElements = (el) => {
	if (el?.firstChild) {
		forEach(Array.from(el.childNodes), (node) => {
			removeUnwantedElements(node)
			switch (node.nodeName) {
				case 'DIV':
				case 'SPAN':
					if (node?.firstChild) {
						forEach(Array.from(node.childNodes), (child) => {
							node.parentNode.insertBefore(child, node)
						})
					}
					node.parentNode.removeChild(node)
					break
				default:
			}
			if (node.nodeName !== 'P' && (node?.innerHTML?.replace(/\s/g, '') === '' || node?.textContent?.replace(/\s/g, '') === '')) {
				if (node?.parentNode) {
					node.parentNode.removeChild(node)
				}
			}
		})
	}
}

const handleNestedInnerElements = (el) => {
	if (el?.firstChild) {
		forEach(Array.from(el.childNodes), (node) => {
			handleNestedInnerElements(node)
			switch (node.nodeName) {
				case 'H1':
				case 'P':
				case 'UL':
				case 'OL':
					if (node?.firstChild) {
						forEach(Array.from(node.childNodes), (child) => {
							node.parentNode.insertBefore(child, node)
						})
					}
					node.parentNode.removeChild(node)
					break
				default:
			}
		})
	}
}

const handleNestedListItems = (el) => {
	if (el?.firstChild) {
		forEach(Array.from(el.childNodes), (node) => {
			handleNestedListItems(node)
			switch (node.nodeName) {
				case 'LI':
					forEach(Array.from(node.childNodes), (innerLI) => {
						switch (innerLI.nodeName) {
							case 'LI':
								node.parentNode.insertBefore(innerLI, node.nextSibling)
								break
							default:
						}
					})
					break
				default:
			}
		})
	}
}

const wrapUnwrappedRootElementsToParagraph = (document) => {
	let wrapper = null

	forEach(Array.from(document.body.childNodes), (node, index, array) => {
		switch (node.nodeName) {
			case 'H1':
			case 'P':
			case 'OL':
			case 'UL':
				if (wrapper) {
					node.parentNode.insertBefore(wrapper, node)
					wrapper = null
				}
				break
			default:
				if (!wrapper) {
					wrapper = document.createElement('p')
				}
				if (index === array.length - 1) {
					node.parentNode.appendChild(wrapper)
					wrapper.appendChild(node)
					wrapper = null
				} else {
					wrapper.appendChild(node)
				}
		}
	})
}

const sliceMarksForEditor = (el) => {
	switch (el.nodeName) {
		case 'P':
		case 'A':
		case 'H1':
		case 'OL':
		case 'UL':
		case 'LI':
			forEach(Array.from(el.childNodes), (childEl) => {
				sliceMarksForEditor(childEl)
			})
			break
		case 'STRONG':
		case 'EM':
		case 'SUP':
		case 'U':
		case 'CODE':
		case 'B': {
			forEach(Array.from(el.childNodes), (childEl) => {
				const wrapper = el.cloneNode(false)
				el.parentNode.insertBefore(wrapper, el)
				wrapper.appendChild(childEl)
				sliceMarksForEditor(childEl)
			})
			el.parentNode.removeChild(el)
			break
		}
		default:
			break
	}
}

const jsxMark = (props, children) => {
	const childProps = children[0]?.text ? { ...children[0] } : {}
	switch (children[0]?.type) {
		case 'paragraph':
		case 'link':
		case 'list-item':
		case 'heading-one':
			forEach(children[0].children, (child, index) => {
				// eslint-disable-next-line no-param-reassign
				children[0].children[index] = {
					...props,
					...child
				}
			})
			return children[0]
		case 'numbered-list':
		case 'bulleted-list':
			forEach(children[0].children, (child) => {
				forEach(child.children, (innerChild, innerIndex) => {
					// eslint-disable-next-line no-param-reassign
					child.children[innerIndex] = {
						...props,
						...innerChild
					}
				})
			})
			return children[0]
		default:
			return {
				...props,
				...childProps,
				text: children[0]?.text || children[0]
			}
	}
}

const getChildrenArray = (children) => (isEmpty(children) ? [{ text: '' }] : children)

const deserialize = (el) => {
	switch (el.nodeType) {
		case 3:
			return el.textContent
		case 1:
			break
		default:
			return null
	}

	const children = Array.from(el.childNodes).map(deserialize)
	switch (el.nodeName) {
		case 'BODY':
			return jsx('fragment', {}, getChildrenArray(children))
		case 'P':
			return jsx('element', { type: 'paragraph' }, getChildrenArray(children))
		case 'BR':
			return '\n'

		case 'STRONG':
			return jsxMark({ bold: true }, getChildrenArray(children))
		case 'EM':
			return jsxMark({ italic: true }, getChildrenArray(children))
		case 'U':
			return jsxMark({ underline: true }, getChildrenArray(children))
		case 'CODE':
			return jsxMark({ code: true }, getChildrenArray(children))
		// elements converted to standard marks in WYSIWYG
		case 'B':
			return jsxMark({ bold: true }, getChildrenArray(children))
		case 'SUP':
			return jsxMark({ sup: true }, getChildrenArray(children))
		case 'I':
			return jsxMark({ italic: true }, getChildrenArray(children))

		case 'A':
			return jsx('element', { type: 'link', url: el.getAttribute('href') }, getChildrenArray(children))
		case 'H1':
			return jsx('element', { type: 'heading-one' }, getChildrenArray(children))
		case 'OL':
			return jsx('element', { type: 'numbered-list' }, getChildrenArray(children))
		case 'UL':
			return jsx('element', { type: 'bulleted-list' }, getChildrenArray(children))
		case 'LI':
			return jsx('element', { type: 'list-item' }, getChildrenArray(children))
		default:
			return el.textContent
	}
}

export const initialContent = {
	editor: [
		{
			type: 'paragraph',
			children: [{ text: '' }]
		}
	],
	html: '<p></p>',
	center: false
}

export const deserializeHTML = (html) => {
	let parseHTML = html ? html.trim() : initialContent.html
	parseHTML = parseHTML !== '' ? parseHTML : initialContent.html

	// delete comments
	parseHTML = parseHTML.replaceAll(/<!--(.*?)-->/g, '')

	const parser = new DOMParser()
	const document = parser.parseFromString(parseHTML, 'text/html')

	removeUnwantedElements(document.body)

	forEach(Array.from(document.body.childNodes), (node) => {
		handleNestedInnerElements(node)
	})

	forEach(Array.from(document.body.childNodes), (node) => {
		handleNestedListItems(node)
	})

	wrapUnwrappedRootElementsToParagraph(document)

	forEach(Array.from(document.body.childNodes), (node) => {
		sliceMarksForEditor(node)
	})

	const finalHTML = document.documentElement.innerHTML.replaceAll('</a><a', '</a><span> </span><a')

	return deserialize(parser.parseFromString(finalHTML, 'text/html').body)
}

const WYSIWYG = ({ itemId, itemContent, itemEditor, itemContentSetter, placeholder, autofocus, onFocus, onBlur, useWithDeserialisation }) => {
	// eslint-disable-next-line no-use-before-define
	const renderElement = useCallback((props) => <Element {...props} />, [])
	const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
	// eslint-disable-next-line no-use-before-define
	const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), [])

	const isEmptyText = (text) => !text || text.replace(/\s/g, '') === ''

	const areChildrenEmpty = (node) => {
		if (!node?.children || !node?.children?.length === 0) {
			return true
		}

		let emptyChildren = true
		// eslint-disable-next-line consistent-return
		forEach(node?.children, (child) => {
			switch (child?.type) {
				case 'link':
					if (!areChildrenEmpty(child)) {
						emptyChildren = false
						return false
					}
					break
				default:
					if (!isEmptyText(child?.text)) {
						emptyChildren = false
						return false
					}
			}
		})

		return emptyChildren
	}

	const areListItemsEmpty = (node) => {
		if (!node?.children || !node?.children?.length === 0) {
			return true
		}

		let emptyChildren = true
		// eslint-disable-next-line consistent-return
		forEach(node?.children, (child) => {
			if (!areChildrenEmpty(child)) {
				emptyChildren = false
				return false
			}
		})

		return emptyChildren
	}

	const serializeLeaf = (leaf) => {
		if (leaf.type === 'link') {
			return areChildrenEmpty(leaf)
				? ''
				: `<a href='${leaf.url}' target='_blank'>${leaf?.children ? leaf.children.map((n) => serializeLeaf(n)).join('') : ''}</a>`
		}

		const mixedElement = {
			startTags: '',
			endTags: ''
		}

		if (leaf.bold) {
			mixedElement.startTags += '<strong>'
			mixedElement.endTags = `</strong>${mixedElement.endTags}`
		}

		if (leaf.sup) {
			mixedElement.startTags += '<sup>'
			mixedElement.endTags = `</sup>${mixedElement.endTags}`
		}

		if (leaf.code) {
			mixedElement.startTags += '<code>'
			mixedElement.endTags = `</code>${mixedElement.endTags}`
		}

		if (leaf.italic) {
			mixedElement.startTags += '<em>'
			mixedElement.endTags = `</em>${mixedElement.endTags}`
		}

		if (leaf.underline) {
			mixedElement.startTags += '<u>'
			mixedElement.endTags = `</u>${mixedElement.endTags}`
		}

		if (mixedElement.startTags !== '') {
			return isEmptyText(leaf.text) ? '' : `${mixedElement.startTags}${leaf.text}${mixedElement.endTags}`
		}

		return isEmptyText(leaf.text) ? '' : `<span>${leaf.text}</span>`
	}

	const serializeListItems = (items) => {
		let listItems = ''

		each(items, (element) => {
			listItems += areChildrenEmpty(element) ? '' : `<li>${element?.children ? element.children.map((n) => serializeLeaf(n)).join('') : ''}</li>`
		})

		return listItems
	}

	const serialize = (node) => {
		if (Text.isText(node)) {
			return escapeHtml(node.text)
		}

		switch (node.type) {
			case 'paragraph':
				// paragraphs have function of enters
				return `<p>${node?.children ? node.children.map((n) => serializeLeaf(n)).join('') : ''}</p>`
			case 'bulleted-list':
				return areListItemsEmpty(node) ? '' : `<ul>${node?.children ? serializeListItems(node.children) : ''}</ul>`
			case 'heading-one':
				return areChildrenEmpty(node) ? '' : `<h1>${node?.children ? node.children.map((n) => serializeLeaf(n)).join('') : ''}</h1>`
			case 'numbered-list':
				return areListItemsEmpty(node) ? '' : `<ol>${node?.children ? serializeListItems(node.children) : ''}</ol>`
			default:
				return node?.children ? node.children.map((n) => serialize(n)).join('') : ''
		}
	}

	const serializeLeafExact = (leaf) => {
		if (leaf.type === 'link') {
			return `<a href='${leaf.url}' target='_blank'>${leaf.children.map((n) => serializeLeafExact(n)).join('')}</a>`
		}

		const mixedElement = {
			startTags: '',
			endTags: ''
		}

		if (leaf.bold) {
			mixedElement.startTags += '<strong>'
			mixedElement.endTags = `</strong>${mixedElement.endTags}`
		}

		if (leaf.sup) {
			mixedElement.startTags += '<sup>'
			mixedElement.endTags = `</sup>${mixedElement.endTags}`
		}

		if (leaf.code) {
			mixedElement.startTags += '<code>'
			mixedElement.endTags = `</code>${mixedElement.endTags}`
		}

		if (leaf.italic) {
			mixedElement.startTags += '<em>'
			mixedElement.endTags = `</em>${mixedElement.endTags}`
		}

		if (leaf.underline) {
			mixedElement.startTags += '<u>'
			mixedElement.endTags = `</u>${mixedElement.endTags}`
		}

		if (mixedElement.startTags !== '') {
			return `${mixedElement.startTags}${leaf.text}${mixedElement.endTags}`
		}

		return `<span>${leaf.text === '' ? ' ' : leaf.text}</span>`
	}

	const serializeListItemsExact = (items) => {
		let listItems = ''

		each(items, (element) => {
			if (element?.children?.length > 0) {
				listItems += `<li>${element.children.map((n) => serializeLeafExact(n)).join('')}</li>`
			}
		})

		return listItems
	}

	const serializeExact = (node) => {
		if (Text.isText(node)) {
			return escapeHtml(node.text)
		}

		switch (node.type) {
			case 'paragraph':
				return `<p>${node.children.map((n) => serializeLeafExact(n)).join('')}</p>`
			case 'bulleted-list':
				return `<ul>${serializeListItemsExact(node.children)}</ul>`
			case 'heading-one':
				return `<h1>${node.children.map((n) => serializeLeafExact(n)).join('')}</h1>`
			case 'numbered-list':
				return `<ol>${serializeListItemsExact(node.children)}</ol>`
			default:
				return node.children.map((n) => serializeExact(n)).join('')
		}
	}

	const setContent = (newValue) => {
		let html = ''
		each(newValue, (node) => {
			html += useWithDeserialisation ? serialize(node) : serializeExact(node)
		})

		itemContentSetter(itemId, {
			...itemContent,
			editor: newValue,
			html
		})
	}

	return (
		<Slate editor={editor} value={itemEditor} onChange={(newValue) => setContent(newValue)}>
			<Toolbar>
				<MarkButton format={'bold'} icon={<BoldOutlined />} />
				<MarkButton format={'italic'} icon={<ItalicOutlined />} />
				<MarkButton format={'underline'} icon={<UnderlineOutlined />} />
				<MarkButton format={'sup'} icon={<SortDescendingOutlined />} />
				<LinkButton />
				<BlockButton format={'heading-one'} icon={<LineHeightOutlined />} />
				<BlockButton format={'numbered-list'} icon={<OrderedListOutlined />} />
				<BlockButton format={'bulleted-list'} icon={<UnorderedListOutlined />} />
			</Toolbar>
			<Editable
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				placeholder={placeholder}
				spellCheck
				autoFocus={autofocus}
				onFocus={onFocus}
				onBlur={onBlur}
				className={'wysiwyg'}
				onKeyDown={(event) => {
					// eslint-disable-next-line no-restricted-syntax
					for (const hotkey in HOTKEYS) {
						// eslint-disable-next-line no-undef
						if (isHotkey(hotkey, event)) {
							event.preventDefault()
							const mark = HOTKEYS[hotkey]
							// eslint-disable-next-line no-use-before-define
							toggleMark(editor, mark)
						}
					}
				}}
			/>
		</Slate>
	)
}

const toggleBlock = (editor, format) => {
	// eslint-disable-next-line no-use-before-define
	const isActive = isBlockActive(editor, format)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type),
		split: true
	})

	Transforms.setNodes(editor, {
		// eslint-disable-next-line no-nested-ternary
		type: isActive ? 'paragraph' : isList ? 'list-item' : format
	})

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

const toggleMark = (editor, format) => {
	// eslint-disable-next-line no-use-before-define
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

const isBlockActive = (editor, format) => {
	const [match] = Editor.nodes(editor, {
		match: (n) => n.type === format
	})

	return !!match
}

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

// eslint-disable-next-line react/prop-types
const Element = ({ attributes, children, element }) => {
	// eslint-disable-next-line react/prop-types
	switch (element.type) {
		case 'bulleted-list':
			return <ul {...attributes}>{children}</ul>
		case 'heading-one':
			return <h1 {...attributes}>{children}</h1>
		case 'list-item':
			return <li {...attributes}>{children}</li>
		case 'numbered-list':
			return <ol {...attributes}>{children}</ol>
		case 'link':
			return (
				<a {...attributes} href={get(element, 'url')}>
					{children}
				</a>
			)
		default:
			return <p {...attributes}>{children}</p>
	}
}

// eslint-disable-next-line react/prop-types
const Leaf = ({ attributes, children, leaf }) => {
	// eslint-disable-next-line react/prop-types
	if (leaf.bold) {
		// eslint-disable-next-line no-param-reassign
		children = <strong>{children}</strong>
	}
	// eslint-disable-next-line react/prop-types
	if (leaf.sup) {
		// eslint-disable-next-line no-param-reassign
		children = <sup>{children}</sup>
	}

	// eslint-disable-next-line react/prop-types
	if (leaf.code) {
		// eslint-disable-next-line no-param-reassign
		children = <code>{children}</code>
	}

	// eslint-disable-next-line react/prop-types
	if (leaf.italic) {
		// eslint-disable-next-line no-param-reassign
		children = <em>{children}</em>
	}

	// eslint-disable-next-line react/prop-types
	if (leaf.underline) {
		// eslint-disable-next-line no-param-reassign
		children = <u>{children}</u>
	}

	return <span {...attributes}>{children}</span>
}

// eslint-disable-next-line react/prop-types
const BlockButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isBlockActive(editor, format)}
			style={{ height: '22px' }}
			onMouseDown={(event) => {
				event.preventDefault()
				toggleBlock(editor, format)
			}}
		>
			{icon}
		</Button>
	)
}

const MarkButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isMarkActive(editor, format)}
			style={{ height: '22px' }}
			onMouseDown={(event) => {
				event.preventDefault()
				toggleMark(editor, format)
			}}
		>
			{icon}
		</Button>
	)
}

const isLinkActive = (editor) => {
	const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link' })
	return !!link
}

const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' })
}

const wrapLink = (editor, url) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor)
	}

	if (url) {
		const { selection } = editor
		const isCollapsed = selection && Range.isCollapsed(selection)
		const link = {
			type: 'link',
			url,
			children: isCollapsed ? [{ text: url }] : []
		}

		if (isCollapsed) {
			Transforms.insertNodes(editor, link)
		} else {
			Transforms.wrapNodes(editor, link, { split: true })
			Transforms.collapse(editor, { edge: 'end' })
		}
	}
}

const insertLink = (editor, url) => {
	if (editor.selection) {
		wrapLink(editor, url)
	}
}

const withLinks = (editor) => {
	const { insertData, insertText, isInline } = editor

	// eslint-disable-next-line no-param-reassign
	editor.isInline = (element) => (element.type === 'link' ? true : isInline(element))

	// eslint-disable-next-line no-param-reassign
	editor.insertText = (text) => {
		if (text && isUrl(text)) {
			wrapLink(editor, text)
		} else {
			insertText(text)
		}
	}

	// eslint-disable-next-line no-param-reassign
	editor.insertData = (data) => {
		const text = data.getData('text/plain')

		if (text && isUrl(text)) {
			wrapLink(editor, text)
		} else {
			insertData(data)
		}
	}

	return editor
}

const LinkButton = () => {
	const { t } = useTranslation()

	const editor = useSlate()
	return (
		<Button
			active={isLinkActive(editor)}
			onMouseDown={(event) => {
				event.preventDefault()
				// eslint-disable-next-line no-alert
				const url = window.prompt(`${t('Zadajte adresu')}:`, 'https://')
				if (url === null) {
					return
				}
				if ('https://'.indexOf(url) > -1 || 'http://'.indexOf(url) > -1) {
					insertLink(editor, null)
					return
				}

				if (url.slice(0, 5) !== 'mailto') {
					insertLink(editor, url)
				} else {
					let urlCorrection = url

					if (!isUrl(url)) {
						urlCorrection = `//${url}`

						if (!isUrl(urlCorrection)) {
							// eslint-disable-next-line no-alert
							window.alert(`${t('Neplatná adresa')}: ${url}`)
							return
						}
					}
					insertLink(editor, urlCorrection)
				}
			}}
		>
			<LinkOutlined />
		</Button>
	)
}

WYSIWYG.propTypes = {
	itemId: PropTypes.string.isRequired,
	itemContent: PropTypes.shape({}),
	itemEditor: PropTypes.arrayOf(PropTypes.shape({})),
	itemContentSetter: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	autofocus: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	useWithDeserialisation: PropTypes.bool
}

WYSIWYG.defaultProps = {
	itemContent: initialContent,
	itemEditor: initialContent.editor,
	placeholder: i18next.t('Sem zadajte text…'),
	autofocus: false,
	onFocus: () => {},
	onBlur: () => {},
	useWithDeserialisation: false
}

export default WYSIWYG
