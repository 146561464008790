import { GENERATE_STATE } from '../../utils/enums'
import { LOAD_VIDEOS, LOAD_VIDEO, GENERATE_ANIMATION, ANIMATION_SAVE, CHECK_GENERATION } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	},
	ganerateAnimation: {
		pictureID: null,
		videoID: null,
		status: GENERATE_STATE.IDLE
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOAD_VIDEOS.START:
			return {
				...initState,
				...state,
				isLoading: true,
				isFailure: false,
				list: { ...state.list }
			}
		case LOAD_VIDEOS.DONE:
			return {
				...initState,
				...state,
				isLoading: false,
				isFailure: false,
				list: action.payload
			}
		case LOAD_VIDEOS.FAILED:
			return {
				...state,
				isLoading: false,
				isFailure: true,
				list: { ...state.list }
			}
		case LOAD_VIDEO.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_VIDEO.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_VIDEO.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case GENERATE_ANIMATION.START:
			return {
				...state,
				ganerateAnimation: {
					thumbsetID: null,
					status: GENERATE_STATE.SENDING,
					videoID: null,
					pictureID: null
				}
			}
		case GENERATE_ANIMATION.DONE:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation,
					status: GENERATE_STATE.CHECKING,
					videoID: action.payload.vimeoVideoID,
					pictureID: action.payload.thumbsetID
				}
			}
		case GENERATE_ANIMATION.STOP:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation,
					status: GENERATE_STATE.IDLE
				}
			}
		case ANIMATION_SAVE.START:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation
				}
			}
		case ANIMATION_SAVE.FAILED:
		case ANIMATION_SAVE.DONE:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation,
					status: GENERATE_STATE.IDLE,
					videoID: null,
					pictureID: null
				}
			}
		case CHECK_GENERATION.START:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation
				}
			}
		case CHECK_GENERATION.CHECKING:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation
				}
			}
		case CHECK_GENERATION.FAILED:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation,
					status: GENERATE_STATE.IDLE
				}
			}
		case CHECK_GENERATION.DONE:
			return {
				...state,
				ganerateAnimation: {
					...state.ganerateAnimation,
					status: GENERATE_STATE.SAVING
				}
			}
		default:
			return state
	}
}
