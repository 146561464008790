import { Modal } from 'antd'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import validateForm from './validateDonationForm'

// atoms
import TextField from '../../../atoms/form/TextField'

type DonationResendEmailFormProps = {
	modalState: { visible: boolean; id: number | null }
	setModal: (args: any) => void
}

const DonationResendEmailForm = ({
	modalState,
	setModal,
	handleSubmit
}: DonationResendEmailFormProps & InjectedFormProps<{}, DonationResendEmailFormProps>) => {
	const { t } = useTranslation()

	return (
		<Modal
			visible={modalState?.visible}
			title={t('Poslať email')}
			okText={t('Poslať')}
			cancelText={t('Zavrieť')}
			className={'thumbnail-generator-modal'}
			onCancel={() => setModal({ visible: false, id: null })}
			onOk={handleSubmit}
		>
			<p>{t('Zadajte email, na ktorý chcete poslať aktivačný email')}</p>
			<form onSubmit={handleSubmit}>
				<Field name={'activationEmail'} type={'text'} component={TextField} label={t('Email')} required />
			</form>
		</Modal>
	)
}

export default reduxForm<{}, DonationResendEmailFormProps>({
	form: FORMS.DONATION_EMAIL_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	validate: validateForm
})(DonationResendEmailForm)
