import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SUBSCRIPTION_TYPE } from '../utils/enums'

const SubscriptionChips = styled.div<{ color: string }>`
	display: inline-block;
	background-color: #fdfdfd;
	color: ${({ color }) => color};
	border-radius: 4px;
	padding: 2px 8px;
	text-align: center;
	width: 270px;
	border: 1px solid #f1f1f1;
`

interface ISubscriptionRepresent {
	value?: string
	text?: string
}

const SubscriptionRepresent: React.FC<ISubscriptionRepresent> = ({ text, value }) => {
	const { t } = useTranslation()
	const pickColor = () => {
		if (value === SUBSCRIPTION_TYPE.CUSTOM || value === SUBSCRIPTION_TYPE.PROMOCODE) {
			return '#00961f'
		}
		if (value === null || value === undefined) {
			return '#ffb4b4'
		}
		return 'black'
	}

	return <SubscriptionChips color={pickColor()}>{text || t('Bez členstva')}</SubscriptionChips>
}

export default SubscriptionRepresent
