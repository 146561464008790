import { CSSProperties } from 'react'
import { Tooltip, Statistic } from 'antd'

type StatisticWithDescriptionProps = {
	title: string
	value: string
	description: string
	prefix?: string
	suffix?: string
	valueStyle?: CSSProperties
}

const StatisticWithDescription = ({ title, value, description, suffix, prefix, valueStyle }: StatisticWithDescriptionProps) => (
	<Tooltip placement={'bottom'} title={description}>
		<span>
			<Statistic title={title} value={value} suffix={suffix} prefix={prefix} valueStyle={valueStyle} />
		</span>
	</Tooltip>
)

export default StatisticWithDescription
