import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Empty, Popconfirm, Progress, Select, Table, Tooltip } from 'antd'
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { get, includes, intersection, join, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { deletePushNotification, getPushNotifications } from '../../redux/pushNotifications/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { setFiltersForPage } from '../../utils/helpers'
import { AUDIENCE, AUDIENCE_OPTIONS, EMPTY_VALUE, LIMITS, PUSH_NOTIFICATION_STATE, PUSH_NOTIFICATION_STATE_OPTIONS } from '../../utils/enums'

// types
import { Paths } from '../../types/api'

// atoms
import DateRepresent from '../../atoms/DateRepresent'
import PushNotificationRepresent from '../../atoms/PushNotificationRepresent'
import { RootState } from '../../redux'

const { Option } = Select

const PAGE_SIZE = 20

interface IProgress {
	totalRecipientsCount: number
	failedRecipientsCount: number
	successfulRecipientsCount: number
}

const PushNotifications = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const { list, context, isLoading } = useSelector((state: RootState) => state.pushNotifications?.list) || {}
	const isLoadingDetail = useSelector((state) => get(state, 'pushNotifications.detail.isLoading'))

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:pushNotifications|key'), persistFilter))

	const arrayOfAudiences = [t('Premium'), t('Bez premium'), t('iOS'), t('Android'), t('Aktívni'), t('Neaktívni')]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getPushNotifications(body))
		dispatch(setFilters(t('paths:pushNotifications|key'), body))
	}, [dispatch, filter])

	const columns = [
		{
			title: t('Nadpis'),
			key: 'title',
			dataIndex: 'title',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Stav'),
			key: 'state',
			dataIndex: 'state',
			ellipsis: true,
			render: (value: string) => <PushNotificationRepresent value={value} /> || EMPTY_VALUE
		},
		{
			title: t('Progres'),
			key: 'progress',
			dataIndex: 'progress',
			ellipsis: true,
			width: 180,
			render: (value: IProgress | null) => {
				if (value) {
					const totalPercentage = ((value.successfulRecipientsCount + value.failedRecipientsCount) / value.totalRecipientsCount) * 100
					const successPercentage = (value.successfulRecipientsCount * 100) / value.totalRecipientsCount
					return (
						<Tooltip
							title={t(
								`úspešných ${value.successfulRecipientsCount}\nneúspešných ${value.failedRecipientsCount}\ncelkovo ${value.totalRecipientsCount}`
							)}
							overlayStyle={{ whiteSpace: 'pre-line' }}
						>
							<Progress percent={totalPercentage} success={{ percent: successPercentage, strokeColor: 'green' }} strokeColor={'red'} />
						</Tooltip>
					)
				}

				return null
			}
		},
		{
			title: t('Dátum a čas odoslania'),
			key: 'scheduledAt',
			dataIndex: 'scheduledAt',
			ellipsis: true,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: t('Cieľová skupina'),
			key: 'audiences',
			dataIndex: 'audiences',
			ellipsis: true,
			render: (values: string[]) =>
				join(
					intersection(
						arrayOfAudiences,
						map(AUDIENCE_OPTIONS, (option) => includes(values, option.value) && option.label)
					),
					', '
				)
		},
		{
			title: t('Počet adresátov'),
			key: 'recipientCount',
			dataIndex: 'recipientCount',
			ellipsis: true,
			render: (value: number) => value
		},
		{
			title: '',
			key: 'operation',
			width: 50,
			render: (record: Paths.GetAdminPushNotifications.Responses.$200['notifications'][0]) => (
				<Popconfirm
					title={t('Skutočne chcete vymazať push notifikáciu?')}
					placement={'topRight'}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					cancelText={t('Zrušiť')}
					okText={t('Vymazať')}
					onConfirm={(e: any) => {
						e.stopPropagation()
						dispatch(
							deletePushNotification(record.id, () => {
								const body = {
									limit: PAGE_SIZE,
									page: 1,
									...filter
								}

								dispatch(getPushNotifications(body))
							})
						)
					}}
					okButtonProps={{
						size: 'small',
						type: 'primary',
						danger: true
					}}
					cancelButtonProps={{
						size: 'small',
						type: 'ghost'
					}}
				>
					<Button
						icon={<DeleteOutlined />}
						type={'primary'}
						onClick={(e: any) => e.stopPropagation()}
						disabled={
							(record.state !== PUSH_NOTIFICATION_STATE.DRAFT && dayjs(record.scheduledAt).diff(dayjs(), 'minute') < LIMITS.NOTIFICATION_EDIT) ||
							record.state === PUSH_NOTIFICATION_STATE.PROCESSING ||
							record.state === PUSH_NOTIFICATION_STATE.SENT ||
							record.state === PUSH_NOTIFICATION_STATE.FAILED
						}
						danger
					/>
				</Popconfirm>
			)
		}
	]

	const handleTableChange = (pagination: any) => {
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex'} style={{ justifyContent: 'space-between' }}>
				<div className={'flex'} style={{ gap: '8px' }}>
					<Select
						style={{ width: 180 }}
						onChange={(state: PUSH_NOTIFICATION_STATE) => setFilter({ ...filter, state, page: 1 })}
						placeholder={t('Stav')}
						allowClear
					>
						{map(PUSH_NOTIFICATION_STATE_OPTIONS, (option, index) => {
							return (
								<Option key={index} value={option.value}>
									<PushNotificationRepresent value={option.value} />
								</Option>
							)
						})}
					</Select>
					<Select
						style={{ width: 200 }}
						onChange={(audience: AUDIENCE) => setFilter({ ...filter, audience, page: 1 })}
						placeholder={t('Cieľová skupina')}
						allowClear
					>
						{map(AUDIENCE_OPTIONS, (option, index) => {
							return (
								<Option key={index} value={option.value}>
									{option.label}
								</Option>
							)
						})}
					</Select>
				</div>
				<Button icon={<PlusCircleOutlined />} href={t('paths:pushNotificationCreate|path')} type={'primary'}>
					{t('Pridať notifikáciu')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={list}
				onChange={handleTableChange}
				style={{ marginTop: 20 }}
				scroll={{ x: 'max-content' }}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page')
				}}
				loading={isLoading || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:pushNotificationDetail|path')}/${record.id}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
			/>
		</div>
	)
}

export default PushNotifications
