import { useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Modal, notification } from 'antd'
import { getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'

// forms
import UserProgramForm from '../forms/UserProgramForm'

const CreateUserProgramModal = ({ setShowModal, handleSubmitUserProgram }) => {
	const { t } = useTranslation()
	const programFormValues = useSelector((state) => getFormValues(FORMS.USER_PROGRAM_FORM)(state))

	useEffect(() => {
		document.body.style.overflowY = 'hidden'
		return () => {
			document.body.style.overflowY = 'auto'
		}
	}, [])

	return (
		<Modal
			visible
			className={'program-modal'}
			title={t('Vytvoriť program uživatelovi')}
			okText={t('Vytvoriť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(null)}
			onOk={() => {
				if (get(programFormValues, 'program')) {
					handleSubmitUserProgram(programFormValues)
					setShowModal(null)
				} else {
					notification.error({
						message: t('Pre vytvorenie zvolte program')
					})
				}
			}}
		>
			<UserProgramForm onSubmit={handleSubmitUserProgram} isCreate />
		</Modal>
	)
}

CreateUserProgramModal.propTypes = {
	setShowModal: PropTypes.func.isRequired,
	handleSubmitUserProgram: PropTypes.func.isRequired
}

export default CreateUserProgramModal
