import { Button, Empty, Form, Input, Menu, Popconfirm, Select, Table } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { debounce, get, map } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { deleteVideo, getVideos } from '../../redux/videos/actions'
import { getInstructors } from '../../redux/select/actions'
import { loadSettings } from '../../redux/settings/settingsActions'
import { setFilters } from '../../redux/filters/actions'

// helpers
import { getLanguageName, setFiltersForPage } from '../../utils/helpers'

// enums
import { EMPTY_VALUE, INPUT_MAX_LENGTH, LANGUAGES, VIDEO_CATEGORY_FILTER } from '../../utils/enums'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'
import InstructorRepresent from '../../atoms/InstructorsRepresent'
import OrderRepresent from '../../atoms/OrderRepresent'

const { Option } = Select

const PAGE_SIZE = 20

const Videos = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const dataSource = useSelector((state) => get(state, 'videos.list.videos'))
	const context = useSelector((state) => get(state, 'videos.list.context'))
	const isLoadingList = useSelector((state) => get(state, 'videos.isLoading'))
	const isLoadingDetail = useSelector((state) => get(state, 'videos.detail.isLoading'))
	const persistFilter = useSelector((state) => get(state, 'filters'))
	const trainers = useSelector((state) => get(state, 'select.instructors.list', []))

	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:videos|key'), persistFilter))
	const [filterType, setFilterType] = useState<string>(get(filter, 'category') || VIDEO_CATEGORY_FILTER.EXERCISE)

	useEffect(() => {
		dispatch(
			getInstructors({
				limit: 1000000
			})
		)
		dispatch(loadSettings())
	}, [dispatch])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter,
			category: filterType
		}

		dispatch(getVideos(body))
		dispatch(setFilters(t('paths:videos|key'), body))
	}, [dispatch, filter, filterType, t])

	const columns = [
		{
			title: t('Náhľad'),
			dataIndex: 'image',
			key: 'image',
			width: 200,
			render: (image: string, record: any) => <img src={image} height={40} alt={record.name} />
		},
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center' as const,
			width: 90,
			render: (value: number) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: t('Inštruktori'),
			dataIndex: 'trainers',
			key: 'trainers',
			width: '200px',
			ellipsis: true,
			render: (value: any[]) => <InstructorRepresent trainers={value} />
		},
		{
			title: t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			width: 110,
			align: 'center' as const,
			render: (state: boolean) => <BooleanRepresent isTrue={!!state} />
		},
		{
			title: t('Vytvorený'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center' as const,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: t('Aktualizovaný'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			align: 'center' as const,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right' as const,
			width: 100,
			render: (text: string, record: any) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:videoDefault|path')}/detail/${get(record, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať video?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteVideo(get(record, 'id'), () => {
									const body = {
										limit: PAGE_SIZE,
										page: 1,
										...filter
									}
									dispatch(getVideos(body))
									dispatch(setFilters(t('paths:videos|key'), body))
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	const resetFilter = () => {
		setFilter({})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} style={{ width: 300 }} maxLength={INPUT_MAX_LENGTH} onChange={handleOnChange} allowClear />
				</Form.Item>
				<Button icon={<PlusCircleOutlined />} href={t('paths:videosCreate|path')} type={'primary'}>
					{t('Pridať Video')}
				</Button>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Select
						style={{ width: 250, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, trainers: value, page: 1 })}
						placeholder={t('Tréner')}
						value={filter.trainers}
						mode={'multiple'}
						filterOption={(input: string, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{map(trainers, (trainer) => (
							<Option key={`trainer-${get(trainer, 'id')}`} value={get(trainer, 'id')}>
								{get(trainer, 'name')}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Select
						style={{ width: 200, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, language: value, page: 1 })}
						placeholder={t('Jazyk')}
						value={filter.language}
					>
						{map(LANGUAGES, (language, index) => (
							<Option key={`language-filter-${index}`} value={language}>
								{getLanguageName(language)}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Menu
				style={{ marginTop: -20 }}
				onClick={(menu) => setFilterType(menu.key)}
				selectedKeys={[filterType]}
				mode={'horizontal'}
				items={[
					{ key: VIDEO_CATEGORY_FILTER.EXERCISE, label: t('Cvičenia') },
					{ key: VIDEO_CATEGORY_FILTER.LIVESTREAM, label: t('Živé prenosy') },
					{ key: VIDEO_CATEGORY_FILTER.PREVIEW_PROGRAM, label: t('Program') },
					{ key: VIDEO_CATEGORY_FILTER.PREVIEW_TRAINER, label: t('Preview inštruktora') },
					{ key: VIDEO_CATEGORY_FILTER.OTHER, label: t('Nezaradené') }
				]}
			/>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={dataSource}
				onChange={handleTableChange}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:videoDefault|path')}/detail/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

Videos.propTypes = {}

export default Videos
