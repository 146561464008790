// types
import { Paths } from '../../types/api'
import { ArrElement } from '../../types/types'

export type PaymentsPayload = Paths.GetAdminPayments.Responses.$200['payments']
export type PaymentsListItemPayload = ArrElement<PaymentsPayload>
export type PaymentPayload = Paths.GetAdminPaymentsId.Responses.$200

export enum GET_USER_PAYMENTS {
	START = 'GET_USER_PAYMENTS_START',
	DONE = 'GET_USER_PAYMENTS_DONE',
	FAILED = 'GET_USER_PAYMENTS_FAILED'
}

export enum GET_SUBSCRIPTION_PAYMENTS {
	START = 'GET_SUBSCRIPTION_PAYMENTS_START',
	DONE = 'GET_SUBSCRIPTION_PAYMENTS_DONE',
	FAILED = 'GET_SUBSCRIPTION_PAYMENTS_FAILED'
}

export enum GET_PAYMENTS {
	START = 'GET_PAYMENTS_START',
	DONE = 'GET_PAYMENTS_DONE',
	FAILED = 'GET_PAYMENTS_FAILED'
}

export enum EXPORT_PAYMENTS {
	START = 'EXPORT_PAYMENTS_START',
	DONE = 'EXPORT_PAYMENTS_DONE',
	FAILED = 'EXPORT_PAYMENTS_FAILED'
}

export enum GET_PAYMENT {
	START = 'GET_PAYMENT_START',
	DONE = 'GET_PAYMENT_DONE',
	FAILED = 'GET_PAYMENT_FAILED'
}

export enum GET_PAYMENT_INVOICE {
	START = 'GET_PAYMENT_INVOICE_START',
	DONE = 'GET_PAYMENT_INVOICE_DONE',
	FAILED = 'GET_PAYMENT_INVOICE_FAILED'
}

export enum REFUND_PAYMENT {
	START = 'REFUND_PAYMENT_START',
	DONE = 'REFUND_PAYMENT_DONE',
	FAILED = 'REFUND_PAYMENT_FAILED'
}

export enum REFUND_PAYMENTS {
	START = 'REFUND_PAYMENTS_START',
	DONE = 'REFUND_PAYMENTS_DONE',
	FAILED = 'REFUND_PAYMENTS_FAILED'
}

export enum EXECUTE_PAYMENT {
	START = 'EXECUTE_PAYMENT_START',
	DONE = 'EXECUTE_PAYMENT_DONE',
	FAILED = 'EXECUTE_PAYMENT_FAILED'
}

export enum DEACTIVATE_SMS_CODE {
	START = 'DEACTIVATE_SMS_CODE_START',
	DONE = 'DEACTIVATE_SMS_CODE_DONE',
	FAILED = 'DEACTIVATE_SMS_CODE_FAILED'
}
