import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, Modal, Row, Col, Tooltip, Badge, Alert as AntAlert } from 'antd'
import { DollarCircleOutlined, CloudDownloadOutlined, RollbackOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { executePayment, getPaymentDetail, getPaymentInvoice } from '../../../redux/payments/actions'

// utils
import {
	formatSMSStatus,
	formatSMSType,
	formatResultCode,
	formatAcquirerResponseId,
	formatPaymentState,
	formatPaymentType,
	getPaymentStateColor
} from '../../../utils/helpers'
import { FORMS, PAYMENT_TYPE, PAYMENT_STATE, PAYMENT_METHOD, EMPTY_VALUE, SMS_CODE_STATUS } from '../../../utils/enums'

// validators
import validateForm from './validateForm'

// components
import TextField from '../../../atoms/form/TextField'

const Alert = styled(AntAlert)`
	margin-top: 14px;
`

interface IPaymentsForm {
	handleRefund: () => void
	detail?: any
	handleSMSCodeDeactivate: (value: any) => void
}

const PaymentsForm: FC<IPaymentsForm> = ({ handleRefund, detail, handleSMSCodeDeactivate }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PAYMENT_FORM)(state)) as any
	const smsCodeDeactivated = detail?.data?.smsNotifications?.find((notification: any) => notification.state === SMS_CODE_STATUS.DEACTIVATED)

	const onRefundClick = () => {
		Modal.confirm({
			title: t('Skutočne si prajete refundovať platbu?'),
			icon: <RollbackOutlined style={{ color: 'red' }} />,
			okText: t('Refundovať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => handleRefund()
		})
	}

	const onSMSCodeDeactivateClick = () => {
		Modal.confirm({
			title: t('Skutočne chcete deaktivovať SMS kód?'),
			icon: <CloseOutlined style={{ color: 'red' }} />,
			okText: t('Deaktivovať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => handleSMSCodeDeactivate(get(detail, 'data.smsCode'))
		})
	}

	const onExecuteClick = () => {
		Modal.confirm({
			title: t('Naozaj chcete znova vyvolať platbu?'),
			icon: <DollarCircleOutlined />,
			okText: t('Vyvolať'),
			cancelText: t('Zrušiť'),
			okType: 'default',
			onOk: () =>
				dispatch(
					executePayment(get(detail, 'data.id'), () => {
						dispatch(getPaymentDetail(get(detail, 'data.id')))
					})
				)
		})
	}

	const warningBanner = () => {
		if (get(detail, 'data.paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE) {
			return (
				<Alert
					message={`${t(
						'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Apple konzolu podľa identifikátoru platby '
					)}
					 ${get(detail, 'data.paymentID') || EMPTY_VALUE}`}
					type={'warning'}
					closable
				/>
			)
		}
		if (get(detail, 'data.paymentMethod') === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE) {
			return (
				<Alert
					message={`${t(
						'Túto platbu nie je možné refundovať cez administráciu - je potrebné refundovať ju priamo cez Google konzolu podľa identifikátoru platby '
					)}${get(detail, 'data.paymentID') || EMPTY_VALUE}`}
					type={'warning'}
					closable
				/>
			)
		}
		if (!get(detail, 'data.isRefundable', true) && get(fieldValues, 'paymentType') !== formatPaymentType(PAYMENT_TYPE.REFUND)) {
			return (
				<Alert
					message={t('V prípade refundácie bude vytvorený opravný doklad, platbu však bude nutné refundovať manuálne')}
					type={'warning'}
					closable
				/>
			)
		}
		return null
	}

	const getRefundButton = () => {
		if (
			get(detail, 'data.paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE ||
			get(detail, 'data.paymentMethod') === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE
		) {
			return null
		}

		if (
			get(detail, 'data.paymentMethod') === PAYMENT_METHOD.BANK_TRANSACTION ||
			get(detail, 'data.paymentMethod') === PAYMENT_METHOD.TRUSTPAY_BANK ||
			get(detail, 'data.paymentMethod') === PAYMENT_METHOD.SMS
		) {
			return (
				<Tooltip title={t('Iba vytvorte faktúry, refundácia nebude automaticky uplatnená')} placement={'bottom'}>
					<Button
						icon={<RollbackOutlined />}
						type={'primary'}
						danger={true}
						onClick={onRefundClick}
						style={{ margin: '20px' }}
						disabled={
							get(detail, 'data.paymentState') === PAYMENT_STATE.FAILED ||
							get(detail, 'data.paymentType') === PAYMENT_TYPE.REFUND ||
							get(detail, 'data.isRefunded')
						}
					>
						{t('Refundovať platbu')}
					</Button>
				</Tooltip>
			)
		}

		return (
			<Button
				icon={<RollbackOutlined />}
				onClick={onRefundClick}
				type={'primary'}
				danger={true}
				style={{ margin: '20px' }}
				disabled={
					get(detail, 'data.paymentState') === PAYMENT_STATE.FAILED ||
					get(detail, 'data.paymentType') === PAYMENT_TYPE.REFUND ||
					get(detail, 'data.isRefunded')
				}
			>
				{t('Refundovať platbu')}
			</Button>
		)
	}

	return (
		<form>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<Row gutter={[16, 16]} align={'middle'} justify={'space-between'}>
						<Col>
							<h2 style={{ margin: 0 }}>
								{t('Informácie o platbe')}
								<Badge
									count={`${formatPaymentState(
										get(detail, 'data.paymentState'),
										get(detail, 'data.isRefunded'),
										get(detail, 'data.paymentType')
									)}`}
									style={{
										backgroundColor: getPaymentStateColor(
											get(detail, 'data.paymentState'),
											get(detail, 'data.isRefunded'),
											get(detail, 'data.paymentType')
										),
										marginLeft: '10px'
									}}
								/>
							</h2>
						</Col>
						<Col>
							<Button
								type={'primary'}
								icon={<UserOutlined />}
								onClick={() => history.push(`${t('paths:user|path')}/${get(detail, 'data.userID')}`)}
							>
								{t('Používateľ')}
							</Button>
						</Col>
					</Row>

					{warningBanner()}
					<br />
					<Row>
						<Col span={12} className={'grid flex direction-col justify-start main-content'}>
							<Field name={'id'} component={TextField} label={t('ID platby')} disabled />
							<Field name={'invoiceNumber'} component={TextField} label={t('Číslo faktúry')} disabled />
							<Row>
								<Field name={'price'} component={TextField} label={t('Cena')} disabled />
								<Field name={'currency'} component={TextField} label={t('Mena')} disabled />
							</Row>
							{get(fieldValues, 'note') && <Field name={'note'} component={TextField} label={t('Poznámka')} disabled />}
						</Col>
						<Col span={12} className={'grid flex direction-col justify-start main-content'}>
							<Field name={'paymentMethod'} component={TextField} label={t('Spôsob platby')} disabled />
							<Field name={'paymentType'} component={TextField} label={t('Typ platby')} disabled />
							<Field name={'paymentState'} component={TextField} label={t('Stav platby')} disabled />
						</Col>
					</Row>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'createdAt'} component={TextField} disabled label={t('Dátum vytvorenia platby')} />
						<Field name={'processedAt'} component={TextField} disabled label={t('Dátum spracovania platby')} />
						<div className={'flex direction-col justify-center'}>
							{getRefundButton()}
							{get(detail, 'data.paymentMethod') === PAYMENT_METHOD.SMS && (
								<Button
									icon={<CloseOutlined />}
									type={'primary'}
									danger={true}
									onClick={onSMSCodeDeactivateClick}
									style={{ margin: '20px' }}
									disabled={
										get(detail, 'data.paymentState') !== PAYMENT_STATE.FINISHED ||
										!get(detail, 'data.smsCode') ||
										get(detail, 'data.subscriptionID') ||
										smsCodeDeactivated
									}
								>
									{t('Deaktivovať SMS kód')}
								</Button>
							)}
							{get(detail, 'data.isExecutable', false) && (
								<Button
									icon={<DollarCircleOutlined />}
									onClick={() => onExecuteClick()}
									type={'primary'}
									style={{ width: '100%' }}
									disabled={!get(detail, 'data.isExecutable', false)}
								>
									{t('Vyvolať platbu')}
								</Button>
							)}
							{get(detail, 'data.paymentMethod') !== PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE &&
								get(detail, 'data.paymentMethod') !== PAYMENT_METHOD.IOS_IN_APP_PURCHASE && (
									<Button
										icon={<CloudDownloadOutlined />}
										type={'primary'}
										onClick={(e) => {
											e.stopPropagation()
											dispatch(
												getPaymentInvoice(get(fieldValues, 'id'), (url: string) => {
													window.open(url, '_blank')
												})
											)
										}}
									>
										{t('Stiahnuť faktúru')}
									</Button>
								)}
						</div>
					</div>
				</Col>
			</Row>
			<h2 style={{ marginTop: '16px' }}>{t('Detaily platby')}</h2>
			<Row gutter={16}>
				{(get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.TRUSTPAY_BANK ||
					get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.TRUSTPAY_CARD) && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								<Row>
									{!!get(fieldValues, 'dataAccountId') && (
										<Field name={'dataAccountId'} component={TextField} label={t('ID účtu')} disabled />
									)}
									{!!get(fieldValues, 'dataReference') && (
										<Field name={'dataReference'} component={TextField} label={t('Referencia (Číslo faktúry)')} disabled />
									)}
								</Row>
								{!!get(fieldValues, 'dataAmount') && (
									<Row>
										<Field name={'dataAmount'} component={TextField} label={t('Cena')} disabled />
										<Field name={'dataCurrency'} component={TextField} label={t('Mena')} disabled />
									</Row>
								)}
								<Row>
									{!!get(fieldValues, 'dataType') && <Field name={'dataType'} component={TextField} label={t('Typ')} disabled />}
									{(!!get(fieldValues, 'dataResultCode') || get(fieldValues, 'dataResultCode') === 0) && (
										<Tooltip placement={'bottom'} title={formatResultCode(`${get(fieldValues, 'dataResultCode')}`)}>
											<div>
												<Field name={'dataResultCode'} component={TextField} label={t('Typ výsledku')} disabled />
											</div>
										</Tooltip>
									)}
									{!!get(fieldValues, 'dataAcquirerResponseId') && (
										<Tooltip placement={'bottom'} title={formatAcquirerResponseId(`${get(fieldValues, 'dataAcquirerResponseId')}`)}>
											<div>
												<Field name={'dataAcquirerResponseId'} component={TextField} label={t('Kód výsledku')} disabled />
											</div>
										</Tooltip>
									)}
								</Row>
								{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.TRUSTPAY_CARD && (
									<>
										<Row>
											{!!get(fieldValues, 'cardId') && <Field name={'cardId'} component={TextField} label={t('Id karty')} disabled />}
											{!!get(fieldValues, 'cardMask') && (
												<Field name={'cardMask'} component={TextField} label={t('Posledné 4 čísla karty')} disabled />
											)}
										</Row>
										{!!get(fieldValues, 'cardRecTxSec') && (
											<Field name={'cardRecTxSec'} component={TextField} label={t('Rec Tx Sec')} disabled />
										)}
									</>
								)}
							</Col>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'dataCounterAccount') && (
									<Tooltip placement={'bottom'} title={get(fieldValues, 'dataCounterAccount')}>
										<div>
											<Field name={'dataCounterAccount'} component={TextField} label={t('IBAN')} disabled />
										</div>
									</Tooltip>
								)}
								{!!get(fieldValues, 'dataCounterAccountName') && (
									<Field name={'dataCounterAccountName'} component={TextField} label={t('Názov účtu')} disabled />
								)}
								{!!get(fieldValues, 'dataSignature') && (
									<Tooltip placement={'bottom'} title={get(fieldValues, 'dataSignature')}>
										<div>
											<Field name={'dataSignature'} component={TextField} label={t('Podpis')} disabled />
										</div>
									</Tooltip>
								)}
								{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.TRUSTPAY_CARD && (
									<>
										{!!get(fieldValues, 'cardExpiration') && (
											<Field name={'cardExpiration'} component={TextField} label={t('Expirácia karty')} disabled />
										)}
										{!!get(fieldValues, 'cardAuthNumber') && (
											<Field name={'cardAuthNumber'} component={TextField} label={t('Autorizačne číslo')} disabled />
										)}
									</>
								)}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.SMS && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								<Row style={{ marginBottom: '16px' }}>
									<Col span={24}>
										<span style={{ display: 'inline-flex', alignItems: 'center' }}>
											<b>{`${t('SMS')} ${t('Promo kód')}`}</b>
											{smsCodeDeactivated && (
												<Badge count={t('Deaktivovaný')} style={{ backgroundColor: '#464646', marginLeft: '10px' }} />
											)}
										</span>
									</Col>
									<Col span={24}>
										<span style={{ fontSize: '32px' }}>{get(fieldValues, 'smsCode', '------')}</span>
									</Col>
								</Row>
								<Row gutter={[0, 8]} style={{ marginBottom: '16px' }}>
									<Col span={24}>
										<b>{`${t('SMS')} ${t('Notifikácie')}`}</b>
									</Col>
									<Col span={3}>
										<b>{t('ID')}</b>
									</Col>
									<Col span={3}>
										<b>{t('SMS ID')}</b>
									</Col>
									<Col span={8}>
										<b>{t('Typ')}</b>
									</Col>
									<Col span={8}>
										<b>{t('Status')}</b>
									</Col>
								</Row>
								{map(get(fieldValues, 'smsNotifications'), (item) => (
									<Row style={{ padding: '8px', borderTop: '1px solid gray' }}>
										<Col span={3}>{get(item, 'id')}</Col>
										<Col span={3}>{get(item, 'smsId', EMPTY_VALUE)}</Col>
										<Col span={8}>{formatSMSType(get(item, 'type'))}</Col>
										<Col span={8}>{formatSMSStatus(get(item, 'state'))}</Col>
									</Row>
								))}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.BANK_TRANSACTION && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								{t(' Žiadne informácie o prevode')}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.PAYPAL && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'paypalPayerID') && (
									<Field name={'paypalPayerID'} component={TextField} label={t('Id platitela')} disabled />
								)}
								{!!get(fieldValues, 'paypalToken') && <Field name={'paypalToken'} component={TextField} label={t('Paypal token')} disabled />}
								{!!get(fieldValues, 'paypalCreateTime') && (
									<Field name={'paypalCreateTime'} component={TextField} label={t('Čas vytvorenia')} disabled />
								)}
								{!!get(fieldValues, 'paypalSummary') && <Field name={'paypalSummary'} component={TextField} label={t('Sumar')} disabled />}
							</Col>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'paypalPaymentId') && (
									<Field name={'paypalPaymentId'} component={TextField} label={t('Id platby')} disabled />
								)}
								{!!get(fieldValues, 'paypalEventType') && (
									<Field name={'paypalEventType'} component={TextField} label={t('Typ eventu')} disabled />
								)}
								{!!get(fieldValues, 'paypalId') && <Field name={'paypalId'} component={TextField} label={t('Paypal ID')} disabled />}
							</Col>
						</Row>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.PAYPAL && typeof get(fieldValues, 'paypalData') === 'string' && (
									// eslint-disable-next-line react/no-danger
									<div dangerouslySetInnerHTML={{ __html: fieldValues?.paypalData }} />
								)}
							</Col>
						</Row>
					</Col>
				)}
			</Row>
			<h2 style={{ marginTop: '16px' }}>{t('Zľava')}</h2>
			<Row gutter={16}>
				{get(fieldValues, 'promoCode') || get(fieldValues, 'promoCodeType') || get(fieldValues, 'discount') ? (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={8} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'promoCode') && <Field name={'promoCode'} component={TextField} label={t('Promo kód')} disabled />}
							</Col>
							<Col span={8} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'discount') && <Field name={'discount'} component={TextField} label={t('Výška zľavy')} disabled />}
							</Col>
							<Col span={8} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'promoCodeType') && (
									<Field name={'promoCodeType'} component={TextField} label={t('Typ promo kódu')} disabled />
								)}
							</Col>
						</Row>
					</Col>
				) : (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								{t(' Žiadna zľava')}
							</Col>
						</Row>
					</Col>
				)}
			</Row>
			<h2 style={{ marginTop: '16px' }}>{t('Informácie o platiteľovi')}</h2>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<Row>
						<Col span={12} className={'grid flex direction-col justify-start main-content'}>
							<Field name={'registerEmail'} component={TextField} label={t('Registračný e-mail')} disabled />
							<Field name={'contactEmail'} component={TextField} label={t('Kontaktný e-mail')} disabled />
							{!!get(fieldValues, 'note') && <Field name={'note'} component={TextField} label={t('Poznámka')} disabled />}
						</Col>
						<Col span={12} className={'grid flex direction-col justify-start main-content'}>
							{get(fieldValues, 'paymentMethodRaw') === PAYMENT_METHOD.SMS && get(fieldValues, 'phoneNumber') && (
								<Field name={'phoneNumber'} component={TextField} label={t('Telefónne číslo')} disabled />
							)}
							<Field name={'subscriptionType'} component={TextField} label={t('Typ členstva')} disabled />
							<Field name={'subscriptionDonation'} component={TextField} label={t('Darované členstvo')} disabled />
						</Col>
					</Row>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<{}, IPaymentsForm>({
	form: FORMS.PAYMENT_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateForm
})(PaymentsForm)
