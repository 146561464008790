import { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import WYSIWYG, { deserializeHTML, initialContent } from '../../components/articleBuilder/customComponents/WYSIWYG'

const WYSIWYGEditor = ({ input, label, placeholder, showLabel, customClass, customLabelClass, meta: { touched, visited, error, active }, required }) => {
	const [content, setContent] = useState(initialContent)
	const [key, setKey] = useState(Math.random())

	const generateWYSIWYGKey = () => {
		let newKey = 0
		do {
			newKey = Math.random()
		} while (newKey === key)
		setKey(newKey)
	}

	useEffect(() => {
		if (!visited || input?.value !== content.html) {
			generateWYSIWYGKey()
			setContent(
				input?.value
					? {
							editor: deserializeHTML(input.value),
							html: input.value
						}
					: {
							editor: initialContent.editor,
							html: ''
						}
			)
		}
	}, [input?.value, visited])

	const onChange = (value) => {
		if (value.html === initialContent.html) {
			if (touched) {
				input.onChange('')
			}
			setContent({
				editor: value.editor,
				html: ''
			})
		} else {
			input.onChange(value.html)
			setContent({ ...value })
		}
	}

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={input.name}>
					{label}
				</label>
			)}
			<div className={cx('input-field wysiwyg-editor-input-field wysiwyg-editor', { active })}>
				<WYSIWYG
					key={key}
					itemId={input.name}
					itemEditor={content.editor}
					itemContentSetter={(itemId, value) => onChange(value)}
					placeholder={placeholder}
					onFocus={input.onFocus}
					onBlur={input.onBlur}
					useWithDeserialisation
				/>
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

WYSIWYGEditor.propTypes = {
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		visited: PropTypes.bool.isRequired,
		error: PropTypes.string,
		active: PropTypes.bool.isRequired
	}).isRequired,
	input: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
		onFocus: PropTypes.func.isRequired,
		onBlur: PropTypes.func.isRequired
	}).isRequired,
	required: PropTypes.bool,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string
}

WYSIWYGEditor.defaultProps = {
	showLabel: true,
	placeholder: '',
	label: '',
	required: false,
	customClass: null,
	customLabelClass: null
}

export default WYSIWYGEditor
