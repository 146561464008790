import { LOAD_SETTINGS } from './settingsTypes'

export const initState = {
	isLoading: false,
	isFailure: false,
	pricelist: []
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case LOAD_SETTINGS.START:
			return {
				...state,
				isLoading: true,
				isFailure: false
			}
		case LOAD_SETTINGS.DONE:
			return {
				...state,
				...action.payload,
				isLoading: false,
				isFailure: false
			}
		case LOAD_SETTINGS.FAILED:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		default:
			return state
	}
}
