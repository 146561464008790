import { FC, useCallback, useEffect, useState } from 'react'
import Tooltip from 'antd/lib/tooltip'
import dayjs from 'dayjs'
import { Button, Empty, Input, Popconfirm, Table } from 'antd'
import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { debounce, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { deleteChallenge, deleteProgram, getPrograms } from '../../redux/programs/actions'
import { setFilters } from '../../redux/filters/actions'

// helpers
import { setFiltersForPage } from '../../utils/helpers'

// enums
import { CHALLENGE_TYPE, EMPTY_VALUE, INPUT_MAX_LENGTH } from '../../utils/enums'

// atoms
import OrderRepresent from '../../atoms/OrderRepresent'
import DateRepresent from '../../atoms/DateRepresent'
import BooleanRepresent from '../../atoms/BooleanRepresent'

const PAGE_SIZE = 20

const Programs: FC = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const persistFilter = useSelector((state) => get(state, 'filters'))
	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:programs|key'), persistFilter))

	const columns = [
		{
			title: t('Logo'),
			dataIndex: 'logo',
			key: 'logo',
			width: 200,
			render: (logo: string, record: any) => <img src={logo} height={30} alt={record.name} />
		},
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Typ'),
			dataIndex: 'isChallenge',
			key: 'isChallenge',
			sorter: true,
			align: 'center' as const,
			render: (isChallenge: boolean, row: any) => {
				if (isChallenge) {
					return row?.challengeType === CHALLENGE_TYPE.COMPETITIVE ? (
						<span className={'program-type'} style={{ background: '#f15859' }}>
							{t('Súťažná výzva')}
						</span>
					) : (
						<span className={'program-type'} style={{ background: '#58aff1' }}>
							{t('Harmonická výzva')}
						</span>
					)
				}
				return (
					<span className={'program-type'} style={{ background: '#fea385' }}>
						{t('Program')}
					</span>
				)
			}
		},
		{
			title: t('Viditeľné / Skryté na úvodnej stránke'),
			dataIndex: 'isVisibleOnLandingPage',
			key: 'isVisibleOnLandingPage',
			align: 'center' as const,
			width: 200,
			sorter: true,
			render: (isVisibleOnLandingPage: boolean) =>
				isVisibleOnLandingPage ? (
					<Tooltip title={t('Viditeľné')}>
						<BooleanRepresent isTrue={true} />
					</Tooltip>
				) : (
					<Tooltip title={t('Skryté')}>
						<BooleanRepresent isTrue={false} />
					</Tooltip>
				)
		},
		{
			title: t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center' as const,
			width: 60,
			render: (value: number) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			align: 'center' as const,
			width: 110,
			render: (state: boolean, record: any) => <BooleanRepresent isTrue={state && dayjs(new Date()) >= dayjs(record.publishDate)} />
		},
		{
			title: t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: true,
			width: 210,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right' as const,
			width: 100,
			align: 'right' as const,
			render: (text: string, record: any) => (
				<>
					<span style={{ marginRight: '8px' }}>
						<Link to={`${t('paths:program|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()

							if (get(record, 'isChallenge')) {
								dispatch(
									deleteChallenge(record.key, () => {
										dispatch(
											getPrograms({
												limit: PAGE_SIZE,
												page: 1,
												...filter
											})
										)
									})
								)
							} else {
								dispatch(
									deleteProgram(record.key, () => {
										dispatch(
											getPrograms({
												limit: PAGE_SIZE,
												page: 1,
												...filter
											})
										)
									})
								)
							}
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	const programs = useSelector((state) => get(state, 'programs.list.tableList'))
	const isLoading = useSelector((state) => get(state, 'programs.list.isLoading'))
	const context = useSelector((state) => get(state, 'programs.list.context'))

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getPrograms(body))

		dispatch(setFilters(t('paths:programs|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Input.Search defaultValue={filter?.search} style={{ width: 300 }} maxLength={INPUT_MAX_LENGTH} onChange={handleOnChange} allowClear />
				<div className={'flex'}>
					<Button icon={<PlusCircleOutlined />} href={t('paths:programCreate|path')} type={'primary'} style={{ marginRight: 15 }}>
						{t('Pridať program')}
					</Button>
					<Button icon={<PlusCircleOutlined />} href={t('paths:challengeCreate|path')} type={'primary'}>
						{t('Pridať výzvu')}
					</Button>
				</div>
			</div>
			<Table
				className={'general-table'}
				columns={columns}
				style={{ marginTop: 20 }}
				dataSource={programs}
				onChange={handleTableChange}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoading}
				onRow={(record) => ({
					onClick: () => {
						if (get(record, 'isChallenge')) {
							history.push(`${t('paths:challenge|path')}/${get(record, 'key')}`)
						} else {
							history.push(`${t('paths:program|path')}/${get(record, 'key')}`)
						}
					}
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Programs
