import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES } from '../../../utils/enums'

export type ExerciseFormValues = {
	id: number
	name_sk: string
	name_cz?: string
	name_en?: string
	detailedDescription_sk: string
	detailedDescription_cz?: string
	detailedDescription_en?: string
	image: string
	video: any
	programs: any[]
	bonusExercises?: { labelSK: string; labelCZ: string }[]
	order: number
	isLiveStream: boolean
	liveStreamPublishDate?: string
	liveVideoID?: string
	isPublished: boolean
	publishDate: string
}

export default (values: ExerciseFormValues) => {
	const errors: FormErrors<ExerciseFormValues> = {}

	if (!values.detailedDescription_sk) {
		errors.detailedDescription_sk = i18next.t('Toto pole je povinné')
	}

	if (values?.isLiveStream === true) {
		if (!values.liveStreamPublishDate) {
			errors.liveStreamPublishDate = i18next.t('Toto pole je povinné')
		}
		if (!values.liveVideoID || values.liveVideoID?.length === 0) {
			errors.liveVideoID = i18next.t('Toto pole je povinné')
		}
	}

	each(LANGUAGES, (item) => {
		const detailedDescription = get(values, `name_${item}`)

		if (detailedDescription) {
			if (!values[`detailedDescription_${item}`]) {
				errors[`detailedDescription_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if ((!values.video || values.video?.length === 0) && values?.isLiveStream !== true) {
		errors.video = i18next.t('Toto pole je povinné')
	}

	if (!values.programs || values.programs?.length === 0) {
		errors.programs = i18next.t('Toto pole je povinné')
	}

	if (values.bonusExercises && get(values, 'bonusExercises.length', 0) > 0) {
		each(values.bonusExercises, (item) => {
			if (!item.labelSK) {
				errors.bonusExercises = i18next.t('Každé bonusové cvičenie musí mať nadpis')
			}
			if (!item.labelCZ) {
				errors.bonusExercises = i18next.t('Každé bonusové cvičenie musí mať nadpis')
			}
		})
	}

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	return errors
}
