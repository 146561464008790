import i18next from 'i18next'
import { get } from 'lodash'

export default (values) => {
	const errors = {}

	if (!values.startedAt) {
		errors.startedAt = i18next.t('Toto pole je povinné')
	}

	if (!values?.difficulty) {
		errors.difficulty = i18next.t('Toto pole je povinné')
	}
	// weight
	if (get(values, 'weightBefore') && !(parseInt(get(values, 'weightBefore'), 10) >= 30 && parseInt(get(values, 'weightBefore'), 10) <= 300)) {
		errors.weightBefore = i18next.t('Váha mimo rozsah')
	}
	if (get(values, 'weightAfter') && !(parseInt(get(values, 'weightAfter'), 10) >= 30 && parseInt(get(values, 'weightAfter'), 10) <= 300)) {
		errors.weightAfter = i18next.t('Váha mimo rozsah')
	}
	// hips
	if (get(values, 'hipsBefore') && !(parseInt(get(values, 'hipsBefore'), 10) >= 10 && parseInt(get(values, 'hipsBefore'), 10) <= 200)) {
		errors.hipsBefore = i18next.t('Boky mimo rozsah')
	}
	if (get(values, 'hipsAfter') && !(parseInt(get(values, 'hipsAfter'), 10) >= 10 && parseInt(get(values, 'hipsAfter'), 10) <= 200)) {
		errors.hipsAfter = i18next.t('Boky mimo rozsah')
	}
	// thighs
	if (get(values, 'thighBefore') && !(parseInt(get(values, 'thighBefore'), 10) >= 10 && parseInt(get(values, 'thighBefore'), 10) <= 200)) {
		errors.thighBefore = i18next.t('Stehná mimo rozsah')
	}
	if (get(values, 'thighAfter') && !(parseInt(get(values, 'thighAfter'), 10) >= 10 && parseInt(get(values, 'thighAfter'), 10) <= 200)) {
		errors.thighAfter = i18next.t('Stehná mimo rozsah')
	}
	// waist
	if (get(values, 'waistBefore') && !(parseInt(get(values, 'waistBefore'), 10) >= 10 && parseInt(get(values, 'waistBefore'), 10) <= 200)) {
		errors.waistBefore = i18next.t('Pás mimo rozsah')
	}
	if (get(values, 'waistAfter') && !(parseInt(get(values, 'waistAfter'), 10) >= 10 && parseInt(get(values, 'waistAfter'), 10) <= 200)) {
		errors.waistAfter = i18next.t('Pás mimo rozsah')
	}

	return errors
}
