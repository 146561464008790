import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGE, slugErrorText } from '../../../utils/enums'
import { slugRegex } from '../../../utils/regex'

export type PageFormValues = {
	id: number
	title: string
	urlSlug: string
	image: string
	imageTitle: string
	contentUpdate: string
	language: LANGUAGE
	isPublished: boolean
}

export default (values: PageFormValues) => {
	const errors: FormErrors<PageFormValues> = {}

	if (!values.title) {
		errors.title = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug) {
		errors.urlSlug = i18next.t('Toto pole je povinné')
	}

	if (values.urlSlug && !slugRegex.test(values.urlSlug)) {
		errors.urlSlug = i18next.t(slugErrorText)
	}

	if (values.image && !values.imageTitle) {
		errors.imageTitle = i18next.t('Toto pole je povinné')
	}

	return errors
}
