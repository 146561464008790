// types
import { GET_PAGE, GET_PAGES, UPDATE_PAGE, DELETE_PAGE, CREATE_PAGE, DUPLICATE_PAGE } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_PAGES.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PAGES.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PAGES.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PAGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PAGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PAGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_PAGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_PAGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_PAGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_PAGE.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_PAGE.DONE:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_PAGE.FAILED:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_PAGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_PAGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_PAGE.FAILED:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DUPLICATE_PAGE.DONE:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				}
			}
		case DUPLICATE_PAGE.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case DUPLICATE_PAGE.FAILED:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
