import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { ThunkAction } from 'redux-thunk'

import storageSession from 'redux-persist/es/storage/session'
import storageLocal from 'redux-persist/lib/storage'

import accessoriesReducers from './accessories/reducers'
import administratorsReducers from './administrators/reducers'
import bannersReducers from './banners/reducers'
import blogCategoriesReducers from './blogCategories/reducers'
import blogReducers from './blog/reducers'
import dashboardReducers from './dashboard/reducers'
import donationsReducer from './donations/reducers'
import downloadCategoriesReducers from './downloadCategories/reducers'
import downloadsReducers from './downloads/reducers'
import exerciseTargetsReducer from './exerciseTargets/reducers'
import exercisesReducer from './exercises/reducers'
import filtersReducer from './filters/reducers'
import giftProductsReducer from './giftProducts/reducers'
import instructorsReducer from './instructors/reducers'
import landingReducers from './landing/reducers'
import listsReducer from './lists/reducers'
import liveStreamCategoriesReducers from './liveStreamCategories/reducers'
import motivationAdvicesReducer from './motivationAdvices/reducers'
import pagesReducers from './pages/reducers'
import paymentsReducers from './payments/reducers'
import preferencesReducers from './preferences/reducers'
import programsReducer from './programs/reducers'
import promoCodesReducer from './promoCodes/reducers'
import pushNotificationsReducers from './pushNotifications/reducers'
import recipeBooksReducers from './recipeBooks/reducers'
import selectReducer from './select/reducers'
import settingsReducers from './settings/settingsReducers'
import subscriptionsReducers from './subscriptions/reducers'
import tagsReducer from './tags/reducers'
import translationsReducer from './translations/reducers'
import userReducers from './user/userReducers'
import usersReducers from './users/reducers'
import videosReducer from './videos/reducers'

export const REDUCER_KEYS = {
	FORM: 'form'
}

const rootReducer = combineReducers({
	form: persistReducer(
		{
			key: REDUCER_KEYS.FORM,
			storage: storageSession
		},
		formReducer
	),
	user: persistReducer(
		{
			key: 'USER',
			storage: storageLocal
		},
		userReducers
	),
	lists: listsReducer,
	select: persistReducer(
		{
			key: 'SELECT',
			storage: storageLocal
		},
		selectReducer
	),
	settings: persistReducer(
		{
			key: 'SETTINGS',
			storage: storageSession
		},
		settingsReducers
	),
	accessories: accessoriesReducers,
	administrators: administratorsReducers,
	banners: bannersReducers,
	blog: blogReducers,
	blogCategories: blogCategoriesReducers,
	dashboard: dashboardReducers,
	donations: donationsReducer,
	downloadCategories: downloadCategoriesReducers,
	downloads: downloadsReducers,
	exerciseTargets: exerciseTargetsReducer,
	exercises: exercisesReducer,
	filters: filtersReducer,
	giftProducts: giftProductsReducer,
	instructors: instructorsReducer,
	landing: landingReducers,
	liveStreamCategories: liveStreamCategoriesReducers,
	motivationAdvices: motivationAdvicesReducer,
	pages: pagesReducers,
	payments: paymentsReducers,
	preferences: preferencesReducers,
	programs: programsReducer,
	promoCodes: promoCodesReducer,
	pushNotifications: pushNotificationsReducers,
	recipeBooks: recipeBooksReducers,
	subscriptions: subscriptionsReducers,
	tags: tagsReducer,
	translations: translationsReducer,
	users: usersReducers,
	videos: videosReducer
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>
