import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import i18next from 'i18next'
import { Button, Empty, Input, Popconfirm, Table } from 'antd'
import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteAdministrator, getAdministrators } from '../../redux/administrators/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { setFiltersForPage } from '../../utils/helpers'
import { ADMIN_ROLE, EMPTY_VALUE, INPUT_MAX_LENGTH } from '../../utils/enums'

// atoms
import DateRepresent from '../../atoms/DateRepresent'
import BooleanRepresent from '../../atoms/BooleanRepresent'

const PAGE_SIZE = 20

const Administrators = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const accessories = useSelector((state: RootState) => state.administrators?.list?.tableList)
	const isLoadingAccessories = useSelector((state: RootState) => state.administrators?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.administrators?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.administrators?.list?.context)

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:administrators|key'), persistFilter)) as any

	// constants
	const columns = [
		{
			title: i18next.t('Email'),
			dataIndex: 'email',
			key: 'email',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Meno'),
			dataIndex: 'firstName',
			key: 'firstName',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Priezvisko'),
			dataIndex: 'lastName',
			key: 'lastName',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Rola'),
			dataIndex: 'role',
			key: 'role',
			sorter: true,
			ellipsis: true,
			render: (value: ADMIN_ROLE) => {
				switch (value) {
					case ADMIN_ROLE.BLOGGER:
						return t('Editor')
					case ADMIN_ROLE.ADMIN:
						return t('Administrátor')
					case ADMIN_ROLE.SUPERADMIN:
						return t('Superadmin')
					default:
						return EMPTY_VALUE
				}
			}
		},
		{
			title: i18next.t('Potvrdený'),
			dataIndex: 'isConfirmed',
			key: 'isConfirmed',
			ellipsis: true,
			align: 'center',
			render: (value: boolean) => <BooleanRepresent isTrue={value} />
		},
		{
			title: i18next.t('Posledné prihlásenie'),
			dataIndex: 'lastLoginAt',
			key: 'lastLoginAt',
			width: 155,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: any) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:administrators|path')}/${record?.id}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteAdministrator(record.id, () => {
									dispatch(
										getAdministrators({
											limit: PAGE_SIZE,
											page: 1,
											...filter
										})
									)
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getAdministrators(body))
		dispatch(setFilters(t('paths:administrators|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Input.Search defaultValue={filter?.search} maxLength={INPUT_MAX_LENGTH} style={{ width: 300 }} onChange={handleOnChange} allowClear />
				<Button icon={<PlusCircleOutlined />} href={t('paths:administratorCreate|path')} type={'primary'}>
					{t('Pridať administrátora')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={accessories}
				onChange={handleTableChange}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				style={{ marginTop: 20 }}
				rowKey={'id'}
				pagination={{
					pageSize: get(context, 'count'),
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingAccessories || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:administrators|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Administrators
