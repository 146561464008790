import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { GET_BANNERS, LOAD_BANNER, DELETE_BANNER, CREATE_BANNER, UPDATE_BANNER, BannersPayload, BannerPayload } from './types'
import { Paths } from '../../types/api'

export const getBanners = (params?: Paths.GetAdminHeadlines.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_BANNERS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_BANNERS, normalizeQueryParams)

		const tableList = map(data.banners, (item: BannersPayload[0]) => ({
			key: item?.id,
			shortTitle: item?.translations[0]?.shortTitle,
			title: item?.translations[0]?.title,
			image: item?.translations[0]?.image,
			isVisible: item?.isVisible,
			order: item?.order,
			updatedAt: dayjs(get(item, 'updatedAt')).format('D. MMM YYYY HH:mm'),
			bannerType: item?.types
		}))

		dispatch({
			type: GET_BANNERS.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_BANNERS.FAILED })
		Promise.reject(error)
	}
}

export const getBanner = (id: number, onSuccess?: (args?: BannerPayload) => void, onFailure?: (args?: any) => void) => async (dispatch: any) => {
	dispatch({ type: LOAD_BANNER.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_BANNER(id))
		dispatch({ type: LOAD_BANNER.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_BANNER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteBanner = (id: number, onSuccess?: () => void, onFailure?: (args?: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_BANNER.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_BANNER(id))
		dispatch({ type: DELETE_BANNER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_BANNER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateBanner =
	(id: number, body: Paths.PutAdminHeadlinesId.RequestBody, onSuccess?: () => void, onFailure?: (args?: any) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_BANNER.START })
		try {
			await putReq(ENDPOINTS.UPDATE_BANNER(id), undefined, body)
			dispatch({ type: UPDATE_BANNER.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_BANNER.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createBanner = (body: Paths.PostAdminHeadlines.RequestBody, onSuccess?: () => void, onFailure?: (args?: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_BANNER.START })
	try {
		await postReq(ENDPOINTS.CREATE_BANNER, undefined, body)
		dispatch({ type: CREATE_BANNER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CREATE_BANNER.FAILED })
		return onFailure && onFailure(error)
	}
}
