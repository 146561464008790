import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const OrderChips = styled.div<{ views: boolean }>`
	display: inline-block;
	background-color: #fdfdfd;
	color: ${({ color }) => color};
	border-radius: 4px;
	padding: 2px 8px;
	text-align: center;
	min-width: 50px;
	color: ${({ views }) => (views ? '#a4a4a4' : 'black')};
	font-style: ${({ views }) => (views ? 'italic' : 'normal')};
	border: 1px solid #f1f1f1;
`

interface IOrderRepresent {
	value?: string | number | null
	views?: boolean
	tooltip: string
}

const OrderRepresent: React.FC<IOrderRepresent> = ({ value, views = false, tooltip }) => {
	return (
		<Tooltip title={`${tooltip}: ${value || (views ? 0 : 1)}`} placement={'top'}>
			<OrderChips views={views}>{value || (views ? 0 : 1)}</OrderChips>
		</Tooltip>
	)
}

export default OrderRepresent
