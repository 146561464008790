import { useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Modal } from 'antd'
import { initialize, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { createTestimonial, updateTestimonial } from '../../../redux/landing/actions'

// utils
import { FORMS, LANGUAGES } from '../../../utils/enums'

// types
import { ILandingModalState } from '../../../types/interfaces'

// forms
import { TestimonialFormValues } from '../forms/validateTestimonialForm'
import TestimonialForm from '../forms/TestimonialForm'

type TestimonialModalProps = {
	modalState: ILandingModalState
	setModalState: (args: ILandingModalState) => void
}

const TestimonialModal = ({ modalState, setModalState }: TestimonialModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const record = useSelector((state: RootState) => state.landing?.page?.data?.testimonials.filter((item: any) => item.id === modalState?.id))
	useEffect(() => {
		if (record.length > 0) {
			const langData = get(record[0], 'translations')
			const langValues: any = {}
			const initValues = {
				...record[0]
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = get(lang, 'title')
				langValues[`description_${item}`] = get(lang, 'description')
				langValues[`customerDescription_${item}`] = get(lang, 'customerDescription')
				langValues[`buttonText_${item}`] = get(lang, 'buttonText')
				langValues[`buttonUrl_${item}`] = get(lang, 'buttonUrl')
			})

			dispatch(initialize(FORMS.LANDING_TESTIMONIAL_FORM, { ...initValues, ...langValues }))
		} else {
			dispatch(initialize(FORMS.LANDING_TESTIMONIAL_FORM, {}))
		}
	}, [dispatch])

	const handleForm = (values: TestimonialFormValues) => {
		const body: any = {
			id: values.id,
			image: values.image,
			customerName: values.customerName,
			customerWeightLoss: values.customerWeightLoss,
			order: values.order,
			translations: []
		}
		each(LANGUAGES, (item) => {
			const title = get(values, `title_${item}`)
			const description = get(values, `description_${item}`)
			const customerDescription = get(values, `customerDescription_${item}`)
			const buttonText = get(values, `buttonText_${item}`)
			const buttonUrl = get(values, `buttonUrl_${item}`)
			if (title && description && customerDescription && buttonText && buttonUrl) {
				const translation = {
					title: get(values, `title_${item}`),
					description: get(values, `description_${item}`),
					customerDescription: get(values, `customerDescription_${item}`),
					buttonText: get(values, `buttonText_${item}`),
					buttonUrl: get(values, `buttonUrl_${item}`),
					language: item
				}

				body.translations.push(translation)
			}
		})

		if (modalState?.id) {
			dispatch(updateTestimonial(modalState.id, body))
		} else {
			body.id = dayjs().valueOf()
			dispatch(createTestimonial(body))
		}
		setModalState({ open: false })
	}

	return (
		<Modal
			visible
			title={modalState?.id ? t('Upraviť premenu') : t('Pridať premenu')}
			okText={modalState?.id ? t('Uložiť') : t('Pridať')}
			cancelText={t('Zrušiť')}
			onCancel={() => setModalState({ open: false })}
			onOk={() => {
				dispatch(submit(FORMS.LANDING_TESTIMONIAL_FORM))
			}}
		>
			<TestimonialForm onSubmit={handleForm} />
		</Modal>
	)
}

export default TestimonialModal
