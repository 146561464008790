import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { FormErrors } from 'redux-form'

// utils
import { AUDIENCE, LANGUAGE } from '../../../utils/enums'

export type PushNotificationFormValues = {
	id: number
	title_sk: string
	message_sk: string
	scheduledAt: string
	audiences: AUDIENCE[]
}

export default (values: PushNotificationFormValues) => {
	const errors: FormErrors<PushNotificationFormValues> = {}
	const { scheduledAt, audiences } = values

	if (!values[`title_${LANGUAGE.SK}`]) {
		errors[`title_${LANGUAGE.SK}`] = i18next.t('Toto pole je povinné')
	}

	if (!values[`message_${LANGUAGE.SK}`]) {
		errors[`message_${LANGUAGE.SK}`] = i18next.t('Toto pole je povinné')
	}

	if (!scheduledAt) {
		errors.scheduledAt = i18next.t('Toto pole je povinné')
	}
	if (isEmpty(audiences)) {
		errors.audiences = i18next.t('Toto pole je povinné')
	}

	return errors
}
