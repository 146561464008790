import React from 'react'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

interface IBooleanRepresent {
	isTrue: boolean
}
const BooleanRepresent: React.FC<IBooleanRepresent> = ({ isTrue = false }) => {
	if (isTrue) {
		return <CheckCircleFilled style={{ color: '#72bb72' }} />
	}
	return <CloseCircleFilled style={{ color: '#ff4d4f' }} />
}

export default BooleanRepresent
