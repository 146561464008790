import debounce from 'lodash/debounce'
import get from 'lodash/get'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteExerciseTarget } from '../../../redux/exerciseTargets/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateExerciseTargetForm, { ExerciseTargetFormValues } from './validateExerciseTargetForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'

const { TabPane } = Tabs

type ExerciseTargetFormProps = {
	isCreate?: boolean
}

const ExerciseTargetForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: ExerciseTargetFormProps & InjectedFormProps<ExerciseTargetFormValues, ExerciseTargetFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.EXERCISE_TARGET_FORM)(state)) as ExerciseTargetFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.EXERCISE_TARGET_FORM))

	// constants
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL')
	}

	const debounceChangeField = debounce((value, language) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.EXERCISE_TARGET_FORM, `urlSlug_${language}`, slug))
	}, 300)

	const changeUrlSlug = (event: any, language: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, language)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať zameranie?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteExerciseTarget(get(fieldValues, 'id'), () => {
						history.push(t('paths:exerciseTargets|path'))
					})
				)
			}
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať zameranie') : t('Detail zamerania')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nové zameranie'),
										path: t('paths:exerciseTargetCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`name_${item}`}
										component={TextField}
										onInput={(e: any) => changeUrlSlug(e, item)}
										label={t('Názov')}
										size={'large'}
										required
									/>
									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required />
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať zameranie')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<ExerciseTargetFormValues, ExerciseTargetFormProps>({
	form: FORMS.EXERCISE_TARGET_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateExerciseTargetForm
})(ExerciseTargetForm)
