import { useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteAccessory } from '../../../redux/accessories/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateForm, { AccessoryFormValues } from './validateAccessoryForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type AccessoryFormProps = {
	isCreate?: boolean
}

const AccessoryForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: AccessoryFormProps & InjectedFormProps<AccessoryFormValues, AccessoryFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.ACCESSORY_FORM)(state)) as AccessoryFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.ACCESSORY_FORM))

	// states
	const [image, setImage] = useState({
		isLoading: false,
		imageUrl: ''
	})

	// constants
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		image: t('Titulný obrázok (upload)')
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať pomôcku?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteAccessory(fieldValues?.id, () => history.push(t('paths:accessories|path'))))
			}
		})
	}

	const uploadImage = async ({ file }: any) => {
		setImage({
			isLoading: true,
			imageUrl: ''
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.ACCESSORIES)

		if (fileUrl) {
			dispatch(change(FORMS.ACCESSORY_FORM, 'image', fileUrl))
			setImage({
				isLoading: false,
				imageUrl: fileUrl
			})
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať pomôcku') : t('Detail pomôcky')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať novú pomôcku'),
										path: t('paths:accessoryCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field name={`name_${item}`} component={TextField} label={t('Názov')} size={'large'} required />
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field
							name={'image'}
							customHelper={t('recommendedSize|accessoriesIcon')}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Titulný obrázok (upload)')}
							customRequest={uploadImage}
							isLoading={image.isLoading}
							required
						/>

						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať pomôcku')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<AccessoryFormValues, AccessoryFormProps>({
	form: FORMS.ACCESSORY_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateForm
})(AccessoryForm)
