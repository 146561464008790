import { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import i18next from 'i18next'
import map from 'lodash/map'
import { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, QuestionCircleOutlined, PlusCircleOutlined, LinkOutlined, CopyOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { Table, Empty, Tabs, Button, Popconfirm, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { getPages, deletePage, duplicatePage } from '../../redux/pages/actions'

// utils
import { PAGE_TYPE, LANGUAGES, LANGUAGE, EMPTY_VALUE } from '../../utils/enums'
import { getLanguageName } from '../../utils/helpers'

// types
import { PagesListItemPayload } from '../../redux/pages/types'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'

// assets
import flagCzechia from '../../assets/images/icons/flag-czechia.svg'
import flagSlovakia from '../../assets/images/icons/flag-slovakia.svg'

const { TabPane } = Tabs
const { Option } = Select

const PAGE_SIZE = 25

interface IFilter {
	pageType: PAGE_TYPE
	language: LANGUAGE | undefined
}

const Pages = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const [filter, setFilter] = useState<IFilter>({
		pageType: PAGE_TYPE.STATIC,
		language: undefined
	})

	const columnsStaticPages = [
		{
			title: '',
			dataIndex: 'language',
			key: 'language',
			width: 30,
			render: (value: LANGUAGE) => {
				switch (value) {
					case LANGUAGE.SK:
						return <img src={flagSlovakia} alt={value} className={'lng-flag'} />
					case LANGUAGE.CZ:
						return <img src={flagCzechia} alt={value} className={'lng-flag'} />
					default:
						return value
				}
			}
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'title',
			key: 'title',
			ellipsis: true,
			width: 250,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('URL'),
			dataIndex: 'urlSlug',
			key: 'urlSlug',
			ellipsis: true,
			width: 250,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Publikované'),
			dataIndex: 'isPublished',
			key: 'isPublished',
			ellipsis: true,
			align: 'center',
			width: 210,
			render: (state: boolean) => <BooleanRepresent isTrue={!!state} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			ellipsis: true,
			width: 155,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			dataIndex: 'tools',
			key: 'tools',
			ellipsis: true,
			width: filter.pageType === PAGE_TYPE.STATIC ? 50 : 125,
			align: 'right',
			fixed: 'right',
			render: (_: any, record: PagesListItemPayload & { key: number }) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:pageDetail|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					{get(record, 'pageType') === PAGE_TYPE.DYNAMIC && (
						<span style={{ marginRight: '5px' }}>
							<Popconfirm
								title={t('Skutočne chcete duplikovať stránku?')}
								icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
								cancelText={t('Zrušiť')}
								okText={t('Duplikovať')}
								onConfirm={(e: any) => {
									e.stopPropagation()
									dispatch(
										duplicatePage(record.key, (data) => {
											history.push(`${t('paths:pageDetail|path')}/${get(data, 'data.id')}`)
										})
									)
								}}
								onCancel={(e: any) => e.stopPropagation()}
								okButtonProps={{
									size: 'small',
									type: 'primary'
								}}
								cancelButtonProps={{
									size: 'small',
									type: 'ghost'
								}}
							>
								<Button icon={<CopyOutlined />} onClick={(e) => e.stopPropagation()} />
							</Popconfirm>
						</span>
					)}
					{get(record, 'pageType') === PAGE_TYPE.DYNAMIC && (
						<Popconfirm
							title={t('Skutočne chcete vymazať stránku?')}
							icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
							cancelText={t('Zrušiť')}
							okText={t('Vymazať')}
							onConfirm={(e: any) => {
								e.stopPropagation()
								dispatch(
									deletePage(record.key, () => {
										dispatch(
											getPages({
												pageType: get(record, 'pageType')
											})
										)
									})
								)
							}}
							onCancel={(e: any) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								danger: true
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Button icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} danger />
						</Popconfirm>
					)}
				</>
			)
		}
	]

	const dataSource = useSelector((state) => get(state, 'pages.list.tableList'))
	const isLoadingList = useSelector((state) => get(state, 'pages.list.isLoading'))

	useEffect(() => {
		dispatch(getPages(filter))
	}, [dispatch, filter])

	const button = (
		<div className={'pages-language-select'}>
			<Form.Item>
				<Select style={{ width: 200, marginRight: 20 }} onChange={(value) => setFilter({ ...filter, language: value })} value={filter?.language}>
					<Option value={null}>{t('Všetky jazyky')}</Option>
					{map(LANGUAGES, (language, index) => (
						<Option key={index} value={language}>
							{capitalize(getLanguageName(language))}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Button icon={<PlusCircleOutlined />} href={t('paths:createPage|path')} type={'primary'}>
				{t('Pridať stránku')}
			</Button>
		</div>
	)

	return (
		<div className={'page-wrapper'}>
			<Tabs defaultActiveKey={PAGE_TYPE.STATIC} tabBarExtraContent={button} onTabClick={(key: any) => setFilter({ ...filter, pageType: key })}>
				<TabPane tab={t('Preddefinované stránky')} key={PAGE_TYPE.STATIC}>
					<Table
						className={'general-table'}
						columns={columnsStaticPages as unknown as ColumnsType<any>}
						pagination={{ pageSize: PAGE_SIZE }}
						scroll={{ x: 'width' }}
						dataSource={dataSource}
						style={{ marginTop: 0 }}
						showSorterTooltip={false}
						loading={isLoadingList}
						onRow={(record) => ({
							onClick: () => history.push(`${t('paths:pageDetail|path')}/${get(record, 'key')}`)
						})}
						locale={{
							emptyText: <Empty description={t('Žiadne dáta')} />
						}}
						size={'small'}
					/>
				</TabPane>
				<TabPane tab={t('Ostatné stránky')} key={PAGE_TYPE.DYNAMIC}>
					<Table
						className={'general-table'}
						columns={columnsStaticPages as unknown as ColumnsType<any>}
						dataSource={dataSource}
						style={{ marginTop: 0 }}
						showSorterTooltip={false}
						loading={isLoadingList}
						onRow={(record) => ({
							onClick: () => history.push(`${t('paths:pageDetail|path')}/${get(record, 'key')}`)
						})}
						locale={{
							emptyText: <Empty description={t('Žiadne dáta')} />
						}}
						size={'small'}
					/>
				</TabPane>
			</Tabs>
		</div>
	)
}

export default Pages
