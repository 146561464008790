import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { emailRegEx } from '../../../utils/regex'

export type LoginFormValues = {
	email: string
	password: string
}

export default (values: LoginFormValues) => {
	const errors: FormErrors<LoginFormValues> = {}

	if (!values.email) {
		errors.email = i18next.t('Toto pole je povinné')
	}

	if (values.email && !emailRegEx.test(values.email)) {
		errors.email = i18next.t('Email nie je platný')
	}

	if (!values.password) {
		errors.password = i18next.t('Toto pole je povinné')
	}

	return errors
}
