// types
import {
	CHANGE_CHALLENGES_ORDER,
	CHANGE_PARTNERS_ORDER,
	CHANGE_TESTIMONIALS_ORDER,
	CREATE_CHALLENGE_RECORD,
	CREATE_PARTNER_RECORD,
	CREATE_TESTIMONIAL_RECORD,
	GET_LANDING_PAGE,
	REMOVE_CHALLENGE_RECORD,
	REMOVE_PARTNER_RECORD,
	REMOVE_TESTIMONIAL_RECORD,
	UPDATE_CHALLENGE_RECORD,
	UPDATE_LANDING_PAGE,
	UPDATE_PARTNER_RECORD,
	UPDATE_TESTIMONIAL_RECORD,
	UPDATE_PAGE_METADATA
} from './types'

export const initState = {
	page: {
		data: {
			challenges: [],
			partners: [],
			testimonials: []
		},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_LANDING_PAGE.START:
			return {
				...initState,
				...state,
				page: {
					...state.page,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_LANDING_PAGE.DONE:
			return {
				...initState,
				...state,
				page: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_LANDING_PAGE.FAILED:
			return {
				...state,
				page: {
					...state.page,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_LANDING_PAGE.START:
			return {
				...initState,
				...state,
				page: {
					...state.page,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_LANDING_PAGE.DONE:
			return {
				...initState,
				...state,
				page: {
					...state.page,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_LANDING_PAGE.FAILED:
			return {
				...state,
				page: {
					...state.page,
					isLoading: false,
					isFailure: true
				}
			}
		case REMOVE_CHALLENGE_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						challenges: state.page.data.challenges.filter((item: any) => item.id !== action.payload)
					}
				}
			}
		case UPDATE_CHALLENGE_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						challenges: state.page.data.challenges.map((item: any) => (item.id === action.payload.id ? action.payload.values : item))
					}
				}
			}
		case CREATE_CHALLENGE_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						challenges: [...state.page.data.challenges, action.payload]
					}
				}
			}
		case REMOVE_PARTNER_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						partners: state.page.data.partners.filter((item: any) => item.id !== action.payload)
					}
				}
			}
		case UPDATE_PARTNER_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						partners: state.page.data.partners.map((item: any) => (item.id === action.payload.id ? action.payload.values : item))
					}
				}
			}
		case CREATE_PARTNER_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						partners: [...state.page.data.partners, action.payload]
					}
				}
			}
		case REMOVE_TESTIMONIAL_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						testimonials: state.page.data.testimonials.filter((item: any) => item.id !== action.payload)
					}
				}
			}
		case UPDATE_TESTIMONIAL_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						testimonials: state.page.data.testimonials.map((item: any) => (item.id === action.payload.id ? action.payload.values : item))
					}
				}
			}
		case CREATE_TESTIMONIAL_RECORD:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						testimonials: [...state.page.data.testimonials, action.payload]
					}
				}
			}
		case CHANGE_CHALLENGES_ORDER:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						challenges: action.payload
					}
				}
			}
		case CHANGE_PARTNERS_ORDER:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						partners: action.payload
					}
				}
			}
		case CHANGE_TESTIMONIALS_ORDER:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						testimonials: action.payload
					}
				}
			}
		case UPDATE_PAGE_METADATA:
			return {
				...state,
				page: {
					...state.page,
					data: {
						...state.page.data,
						pageMetadata: action.payload
					}
				}
			}
		default:
			return state
	}
}
