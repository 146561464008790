// types
import { ArrElement } from '../../types/types'
import { Paths } from '../../types/api'

export type PagesPayload = Paths.GetAdminPages.Responses.$200['pages']
export type PagesListItemPayload = ArrElement<PagesPayload>
export type PagePayload = Paths.GetAdminPagesPageId.Responses.$200
export type DuplicatePagePayload = Paths.PostAdminPagesPageIdDuplicate.Responses.$200

export enum GET_PAGES {
	START = 'GET_PAGES_START',
	DONE = 'GET_PAGES_DONE',
	FAILED = 'GET_PAGES_FAILED'
}

export enum GET_PAGE {
	START = 'GET_PAGE_START',
	DONE = 'GET_PAGE_DONE',
	FAILED = 'GET_PAGE_FAILED'
}

export enum UPDATE_PAGE {
	START = 'UPDATE_PAGE_START',
	DONE = 'UPDATE_PAGE_DONE',
	FAILED = 'UPDATE_PAGE_FAILED'
}

export enum DELETE_PAGE {
	START = 'DELETE_PAGE_START',
	DONE = 'DELETE_PAGE_DONE',
	FAILED = 'DELETE_PAGE_FAILED'
}

export enum CREATE_PAGE {
	START = 'CREATE_PAGE_START',
	DONE = 'CREATE_PAGE_DONE',
	FAILED = 'CREATE_PAGE_FAILED'
}

export enum DUPLICATE_PAGE {
	START = 'DUPLICATE_PAGE_START',
	DONE = 'DUPLICATE_PAGE_DONE',
	FAILED = 'DUPLICATE_PAGE_FAILED'
}
