import { Paths } from '../../types/api'

export type BlogPostsPayload = Paths.GetAdminBlogPosts.Responses.$200['blogPosts']
export type BlogPostPayload = Paths.GetAdminBlogPostsId.Responses.$200
export type DuplicateBlogPostPayload = Paths.PostAdminBlogPostsIdDuplicate.Responses.$200
export type RestoreBlogPostPayload = Paths.PostAdminBlogPostsIdRestore.Responses.$200

export enum GET_BLOG_POSTS {
	START = 'GET_BLOG_POSTS_START',
	DONE = 'GET_BLOG_POSTS_DONE',
	FAILED = 'GET_BLOG_POSTS_FAILED'
}

export enum GET_BLOG_RECIPES {
	START = 'GET_BLOG_RECIPES_START',
	DONE = 'GET_BLOG_RECIPES_DONE',
	FAILED = 'GET_BLOG_RECIPES_FAILED'
}

export enum GET_BLOG_POST {
	START = 'GET_BLOG_POST_START',
	DONE = 'GET_BLOG_POST_DONE',
	FAILED = 'GET_BLOG_POST_FAILED'
}

export enum DELETE_BLOG_POST {
	START = 'DELETE_BLOG_POST_START',
	DONE = 'DELETE_BLOG_POST_DONE',
	FAILED = 'DELETE_BLOG_POST_FAILED'
}

export enum DUPLICATE_BLOG_POST {
	START = 'DUPLICATE_BLOG_POST_START',
	DONE = 'DUPLICATE_BLOG_POST_DONE',
	FAILED = 'DUPLICATE_BLOG_POST_FAILED'
}

export enum RESTORE_BLOG_POST {
	START = 'RESTORE_BLOG_POST_START',
	DONE = 'RESTORE_BLOG_POST_DONE',
	FAILED = 'RESTORE_BLOG_POST_FAILED'
}

export enum CREATE_BLOG_POST {
	START = 'CREATE_BLOG_POST_START',
	DONE = 'CREATE_BLOG_POST_DONE',
	FAILED = 'CREATE_BLOG_POST_FAILED'
}

export enum UPDATE_BLOG_POST {
	START = 'UPDATE_BLOG_POST_START',
	DONE = 'UPDATE_BLOG_POST_DONE',
	FAILED = 'UPDATE_BLOG_POST_FAILED'
}
