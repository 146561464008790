import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Checkbox, Input } from 'antd'
import { trim } from 'lodash'
import { INPUT_MAX_LENGTH } from '../../../utils/enums'

const Header = ({ item, itemContentSetter }) => {
	const { t } = useTranslation()

	const isOldContent = typeof item?.content === 'string'

	const setContent = (value) => {
		if (isOldContent) {
			itemContentSetter(item?.id, { center: false, text: value })
		} else {
			itemContentSetter(item?.id, { ...item?.content, text: value })
		}
	}

	const setCenter = (value) => {
		if (isOldContent) {
			itemContentSetter(item?.id, { text: item?.content, center: value })
		} else {
			itemContentSetter(item?.id, { ...item?.content, center: value })
		}
	}

	return (
		<div className={'custom-item'}>
			<div className={'input-field'}>
				<Input
					placeholder={t('Sem zadajte nadpis')}
					className={'input-field'}
					size={'large'}
					maxLength={INPUT_MAX_LENGTH}
					value={isOldContent ? item.content : item?.content?.text}
					onChange={(e) => setContent(e.target.value)}
					onBlur={(e) => setContent(trim(e.target.value))}
				/>
				<div className={'flex justify-end mt-3'}>
					<Checkbox checked={item?.content?.center || false} onChange={(e) => setCenter(e.target.checked)}>
						{t('Centrovať')}
					</Checkbox>
				</div>
			</div>
		</div>
	)
}

Header.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				text: PropTypes.string,
				center: PropTypes.bool
			})
		])
	}),
	itemContentSetter: PropTypes.func.isRequired
}

Header.defaultProps = {
	item: {}
}

export default Header
