import i18next from 'i18next'

export default (values) => {
	const errors = {}

	if (!values.newUserID) {
		errors.newUserID = i18next.t('Toto pole je povinné')
	}

	return errors
}
