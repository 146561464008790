import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import includes from 'lodash/includes'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES, BANNER_TYPE } from '../../../utils/enums'

export default (values: any) => {
	const errors: FormErrors<any> = {}
	const isSlider = includes(values?.bannerType, BANNER_TYPE.ESHOP_SLIDER)

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	if (isSlider) {
		if (!values.buttonText_sk) {
			errors.buttonText_sk = i18next.t('Toto pole je povinné')
		}

		if (!values.title_sk) {
			errors.title_sk = i18next.t('Toto pole je povinné')
		}

		if (!values.shortTitle_sk) {
			errors.shortTitle_sk = i18next.t('Toto pole je povinné')
		}

		each(LANGUAGES, (item) => {
			const title = get(values, `title_${item}`)
			const shortTitle = get(values, `shortTitle_${item}`)
			const buttonText = get(values, `buttonText_${item}`)
			const image = get(values, `image_${item}`)
			if (title || shortTitle || buttonText || image) {
				if (!get(values, `title_${item}`)) {
					errors[`title_${item}`] = i18next.t('Toto pole je povinné')
				}

				if (!get(values, `shortTitle_${item}`)) {
					errors[`shortTitle_${item}`] = i18next.t('Toto pole je povinné')
				}

				if (!get(values, `buttonText_${item}`)) {
					errors[`buttonText_${item}`] = i18next.t('Toto pole je povinné')
				}

				if (!get(values, `image_${item}`)) {
					errors[`image_${item}`] = i18next.t('Toto pole je povinné')
				}
			}
		})
	} else {
		if (!values.subTitle_sk) {
			errors.subTitle_sk = i18next.t('Toto pole je povinné')
		}

		each(LANGUAGES, (item) => {
			const subTitle = get(values, `subTitle_${item}`)

			if (subTitle) {
				if (!get(values, `subTitle_${item}`)) {
					errors[`subTitle_${item}`] = i18next.t('Toto pole je povinné')
				}

				if (!get(values, `image_${item}`)) {
					errors[`image_${item}`] = i18next.t('Toto pole je povinné')
				}
			}
		})
	}

	return errors
}
