/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-promise-executor-return */
import axios from 'axios'
import i18next from 'i18next'
import { mapValues, get } from 'lodash'
import { notification } from 'antd'
import { getReq } from './request'

import { ENDPOINTS, UPLOAD_CATEGORY } from './enums'

const awsS3Axios = axios.create()

export const IMAGE_SIZE = {
	LARGE: 'LARGE',
	MEDIUM: 'MEDIUM',
	SMALL: 'SMALL',
	THUMBNAIL: 'THUMBNAIL'
}

export interface IFileToUpload {
	lastModified: number
	lastModifiedDate: Date
	type: string
	webkitRelativePath: String
	name: string
	size: number
	uid: string
}

// eslint-disable-next-line
export function uploadFile(fileToUpload: IFileToUpload, uploadCategory?: string, customSelectSize?: string) {
	// eslint-disable-next-line
	return new Promise(async (resolve, reject) => {
		try {
			const { name, type } = fileToUpload

			const queries = {
				filename: name,
				category: uploadCategory || UPLOAD_CATEGORY.PROFILE
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.UPLOAD_SIGNED_REQUEST, normalizeQueryParams, undefined, { 'content-type': type })

			try {
				await awsS3Axios.put(get(data, 'signedUrl'), fileToUpload, { headers: { 'Content-Type': type, ...get(data, 'meta') } })
			} catch (err) {
				console.error('awsS3Axios request error')
				return reject(err)
			}

			// Use gif in default size, Gif conversion is not working correctly
			if (type === 'image/gif') return resolve(encodeURI(get(data, 'fileUrl')))

			// asignation of Image to URL is async event and delay depends on file size. 10 second should cover most cases
			setTimeout(() => {
				switch (customSelectSize) {
					case IMAGE_SIZE.LARGE:
						return resolve(encodeURI(get(data, 'imagePaths.largeSizePath')))
					case IMAGE_SIZE.MEDIUM:
						return resolve(encodeURI(get(data, 'imagePaths.mediumSizePath')))
					case IMAGE_SIZE.SMALL:
						return resolve(encodeURI(get(data, 'imagePaths.smallSizePath')))
					case IMAGE_SIZE.THUMBNAIL:
						return resolve(encodeURI(get(data, 'imagePaths.thumbnailSizePath')))
					default:
						return resolve(encodeURI(get(data, 'fileUrl')))
				}
				// @ts-ignore
			}, [9000])
		} catch (err) {
			notification.error({
				message: i18next.t('Pri nahrávaní obrázka sa vyskytla chyba') as string
			})
			return reject(err)
		}
	})
}
