import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { emailRegEx, phoneNumber } from '../../../utils/regex'

export type UserFormValues = {
	id: number
	firstName: string
	lastName: string
	contactEmail: string
	phoneNumber: string
	height: number
	weight: number
	preferences?: number[] | null
	socials?: {
		name?: string | null
	}[]
}

export default (values: UserFormValues) => {
	const errors: FormErrors<UserFormValues> = {}

	if (!values.firstName || (values.firstName && !(values.firstName.length >= 2 && values.firstName.length <= 20))) {
		errors.firstName = i18next.t('Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (!values.lastName || (values.lastName && !(values.lastName.length >= 2 && values.lastName.length <= 20))) {
		errors.lastName = i18next.t('Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (!values.contactEmail || (values.contactEmail && !emailRegEx.test(values.contactEmail))) {
		errors.contactEmail = i18next.t('Email nie je platný')
	}

	if (values.phoneNumber) {
		if (!phoneNumber.test(values.phoneNumber)) {
			errors.phoneNumber = i18next.t('Číslo obsahuje neplatný znak')
		}

		if (values.phoneNumber.length < 13) {
			errors.phoneNumber = i18next.t('Číslo musí mať min 13 znakov')
		}

		if (values.phoneNumber.charAt(0) === '0') {
			errors.phoneNumber = i18next.t('Číslo nesmie začínať nulou')
		}
	}

	if (values.height && values.height < 100) {
		errors.height = i18next.t('Výška musí byť väčšia ako 100cm')
	}

	if (values.height && values.height > 500) {
		errors.height = i18next.t('Výška musí byť menšia ako 500cm')
	}

	return errors
}
