import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { getAccessToken, isLoggedIn } from './auth'

function buildHeaders(items: any) {
	const headers: any = {
		'content-type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache',
		Language: 'SK'
	}
	if (isLoggedIn()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}

	return {
		...headers,
		...(items || {})
	}
}

const cancelGetTokens = {} as { [key: string]: CancelTokenSource } as any
export function getReq(url: string, params?: any, accept?: any, headers?: any) {
	let token = {}

	if (typeof cancelGetTokens[url] !== typeof undefined) {
		cancelGetTokens[url].cancel('Operation canceled due to new request.')
	}
	// Save the cancel token for the current request
	cancelGetTokens[url] = axios.CancelToken.source()
	token = {
		cancelToken: cancelGetTokens[url].token
	}
	let config: AxiosRequestConfig | any = {
		...token,
		headers: buildHeaders(headers)
	}

	if (accept) {
		const editedConfig = {
			...config,
			headers: {
				Accept: accept
			}
		}
		config = editedConfig
	}

	if (params) {
		config.params = params
	}

	config.data = {}

	return axios.get(url, config)
}

export function postReq(url: string, params = undefined, data = {}, headers = undefined, allowCancelToken = false) {
	let token = {}

	if (allowCancelToken) {
		if (typeof cancelGetTokens[url] !== typeof undefined) {
			cancelGetTokens[url].cancel('Operation canceled due to new request.')
		}
		// Save the cancel token for the current request
		cancelGetTokens[url] = axios.CancelToken.source()
		token = {
			cancelToken: cancelGetTokens[url].token
		}
	}
	const config: AxiosRequestConfig = {
		...token,
		headers: buildHeaders(headers)
	}
	if (params) {
		config.params = params
	}

	return axios.post(url, data || {}, config)
}

export function putReq(url: string, params = undefined, data = {}, headers = undefined, allowCancelToken = false) {
	let token = {}

	if (allowCancelToken) {
		if (typeof cancelGetTokens[url] !== typeof undefined) {
			cancelGetTokens[url].cancel('Operation canceled due to new request.')
		}
		// Save the cancel token for the current request
		cancelGetTokens[url] = axios.CancelToken.source()
		token = {
			cancelToken: cancelGetTokens[url].token
		}
	}
	const config: AxiosRequestConfig = {
		...token,
		headers: buildHeaders(headers)
	}

	if (params) {
		config.params = params
	}

	return axios.put(url, data || {}, config)
}

export function patchReq(url: string, params = undefined, data = {}, headers = undefined, allowCancelToken = false) {
	let token = {}

	if (allowCancelToken) {
		if (typeof cancelGetTokens[url] !== typeof undefined) {
			cancelGetTokens[url].cancel('Operation canceled due to new request.')
		}
		// Save the cancel token for the current request
		cancelGetTokens[url] = axios.CancelToken.source()
		token = {
			cancelToken: cancelGetTokens[url].token
		}
	}

	const config: AxiosRequestConfig = {
		...token,
		headers: buildHeaders(headers)
	}

	if (params) {
		config.params = params
	}

	return axios.patch(url, data || {}, config)
}

export function deleteReq(url: string, params = undefined, data = {}, headers = undefined, allowCancelToken = false) {
	let token = {}

	if (allowCancelToken) {
		if (typeof cancelGetTokens[url] !== typeof undefined) {
			cancelGetTokens[url].cancel('Operation canceled due to new request.')
		}
		// Save the cancel token for the current request
		cancelGetTokens[url] = axios.CancelToken.source()
		token = {
			cancelToken: cancelGetTokens[url].token
		}
	}

	const config: AxiosRequestConfig = {
		...token,
		headers: buildHeaders(headers),
		data
	}

	if (params) {
		config.params = params
	}

	return axios.delete(url, config)
}

export function downloadReq(url: string, params = undefined, headers = undefined) {
	const config: AxiosRequestConfig = {
		headers: buildHeaders(headers),
		responseType: 'blob'
	}

	if (params) {
		config.params = params
	}

	return axios.get(url, config)
}
