import each from 'lodash/each'
import get from 'lodash/get'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGES } from '../../../utils/enums'

export type PreferenceFormValues = {
	id: number
	name_sk?: string
	name_cz?: string
	name_en?: string
	description_sk: string
	description_cz?: string
	description_en?: string
	title_sk: string
	title_cz?: string
	title_en?: string
	urlSlug_sk: string
	urlSlug_cz?: string
	urlSlug_en?: string
	type: string
	order: number
}

export default (values: PreferenceFormValues) => {
	const errors: FormErrors<PreferenceFormValues> = {}

	if (!values.title_sk) {
		errors.title_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug_sk) {
		errors.urlSlug_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.description_sk) {
		errors.description_sk = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		const title = get(values, `name_${item}`)
		const description = get(values, `name_${item}`)

		if (title || description) {
			if (!values[`name_${item}`]) {
				errors[`name_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`description_${item}`]) {
				errors[`description_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	if (!values.type) {
		errors.type = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	return errors
}
