import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FORMS } from '../utils/enums'
import ChangePasswordForm from './ChangePasswordForm'
import { changePassword } from '../redux/user/userActions'

const PasswordModal = ({ setShowModal }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const handlePasswordChange = (values) => {
		dispatch(changePassword(values))
		setShowModal(null)
	}

	return (
		<Modal
			visible
			title={t('Zmena hesla')}
			okText={t('Uložiť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(null)}
			onOk={() => {
				dispatch(submit(FORMS.CHANGE_PASSWORD_FORM))
			}}
		>
			<ChangePasswordForm onSubmit={handlePasswordChange} />
		</Modal>
	)
}

PasswordModal.propTypes = {
	setShowModal: PropTypes.func.isRequired
}

export default PasswordModal
