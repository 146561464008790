import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Switch } from 'antd'

const SwitchField = ({ input, name, label, showLabel, customClass, customLabelClass, customInputClass, meta: { touched, error }, disabled, onBlur, size }) => (
	<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
		{showLabel && (
			<label className={cx(customLabelClass)} htmlFor={name}>
				{label}
			</label>
		)}
		<div className={'input-field'}>
			<Switch
				{...input}
				checked={input.value}
				disabled={disabled}
				onChange={input.onChange}
				className={cx('input-field', customInputClass, { disabled })}
				onBlur={onBlur}
				size={size}
			/>
		</div>
		<div className={'tooltip-error'}>{touched ? error : ''}</div>
	</div>
)

SwitchField.propTypes = {
	name: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		onChange: PropTypes.func
	}).isRequired,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	onBlur: PropTypes.func,
	size: PropTypes.string
}

SwitchField.defaultProps = {
	name: null,
	showLabel: true,
	label: '',
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onBlur: null,
	size: 'middle'
}

export default SwitchField
