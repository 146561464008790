import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LeftOutlined } from '@ant-design/icons'
import ProfileDropdown from './ProfileDropdown'

const HeaderContent = ({ routeKey, computedMatch }) => {
	const { t } = useTranslation()

	const path = `paths:${routeKey}|path`
	const justify = computedMatch.path === t(path) || computedMatch.path === '/' ? 'end' : 'space-between'
	return (
		<Row justify={justify} align={'middle'}>
			<Link to={t(path)}>
				<Button
					icon={<LeftOutlined />}
					type={'default'}
					style={{ margin: '20px', display: (computedMatch.path === t(path) || computedMatch.path === '/') && 'none' }}
				>
					{t('Späť')}
				</Button>
			</Link>

			<ProfileDropdown />
		</Row>
	)
}

HeaderContent.propTypes = {
	routeKey: PropTypes.string,
	computedMatch: PropTypes.shape({
		path: PropTypes.string
	})
}

HeaderContent.defaultProps = {
	routeKey: null,
	computedMatch: {
		path: null
	}
}

export default HeaderContent
