import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// redux
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../redux'
import { deleteBanner } from '../../../redux/banners/actions'

// utils
import { BANNER_TYPE, FORMS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validateBannerForm from './validateBannerForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import { IFieldLabels } from '../../../types/interfaces'

const { Option } = Select
const { TabPane } = Tabs

type BannerFormProps = {
	isCreate: boolean
}

const BannerForm = ({ handleSubmit, invalid, pristine, isCreate = false }: BannerFormProps & InjectedFormProps<{}, BannerFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.BANNER_FORM)(state)) as any
	const formErrors = useSelector(getFormSyncErrors(FORMS.BANNER_FORM))

	// states
	const [image, setImage] = useState({
		imageUrl_sk: '',
		imageUrl_cz: '',
		imageUrl_en: '',
		isLoading: false
	})

	// constants
	const isSlide = includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER)
	const bannerTypeOptions = [
		{ value: BANNER_TYPE.ESHOP_SLIDER, label: t('Eshop slider') },
		{ value: BANNER_TYPE.BLOG_DETAIL, label: t('Detail blogového článku'), disabled: includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER) },
		{ value: BANNER_TYPE.EXERCISE_DETAIL, label: t('Detail cvičenia'), disabled: includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER) },
		{ value: BANNER_TYPE.PROGRAM_DETAIL, label: t('Detail programu'), disabled: includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER) },
		{ value: BANNER_TYPE.TRAINER_DETAIL, label: t('Detail inštruktora'), disabled: includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER) },
		{ value: BANNER_TYPE.BLOG_LIST, label: t('Zoznam blogových článkov'), disabled: includes(fieldValues?.bannerType, BANNER_TYPE.ESHOP_SLIDER) }
	]
	const fieldLabels: IFieldLabels = {
		title_sk: t('Dlhý názov'),
		shortTitle_sk: t('Krátky názov'),
		subTitle_sk: t('Popis slidu'),
		buttonText_sk: t('Text v tlačidle'),
		image_sk: t('Obrazok na pozadí (upload)'),
		image_cz: t('Obrazok na pozadí (upload)'),
		image_en: t('Obrazok na pozadí (upload)'),
		order: t('Poradie')
	}

	useEffect(() => {
		if (isSlide && fieldValues?.bannerType?.length > 1) {
			dispatch(change(FORMS.BANNER_FORM, 'bannerType', [BANNER_TYPE.ESHOP_SLIDER]))
		}
	}, [fieldValues?.bannerType])

	const debounceChangeField = debounce((value, language) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.BANNER_FORM, `urlSlug_${language}`, slug))
	}, 300)

	const changeUrlSlug = (event: any, language: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, language)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať banner?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteBanner(get(fieldValues, 'id'), () => {
						history.push(t('paths:banners|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any, language: LANGUAGE) => {
		setImage({
			...image,
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.SLIDER)

		if (fileUrl) {
			dispatch(change(FORMS.BANNER_FORM, `image_${language}`, fileUrl))
			setImage({
				...image,
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať banner') : t('Detail banneru')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový banner'),
										path: t('paths:bannerCreate|path')
									}
								]
							}
						/>
						<Field
							name={'bannerType'}
							component={CustomOptionsSelectField}
							label={t('Typ banneru')}
							options={map(bannerTypeOptions, (part, index) => (
								<Option key={index} value={get(part, 'value')}>
									{get(part, 'label') || ''}
								</Option>
							))}
							multiple
							required
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (language) => (
								<TabPane key={language} tab={getLanguageName(language)}>
									{isSlide && (
										<>
											<Field
												name={`title_${language}`}
												component={TextField}
												onInput={changeUrlSlug as any}
												label={t('Dlhý názov')}
												size={'large'}
												required
											/>
											<Field name={`shortTitle_${language}`} component={TextField} label={t('Krátky názov')} size={'large'} required />
											<Field name={`buttonText_${language}`} component={TextField} label={t('Text v tlačidle')} size={'large'} required />
										</>
									)}
									<Field
										name={`subTitle_${language}`}
										component={TextField}
										label={isSlide ? t('Popis slidu') : t('Popis banneru')}
										size={'large'}
										required
									/>
									<Field
										name={`image_${language}`}
										imageUrl={get(fieldValues, `image_${language}`)}
										customHelper={isSlide ? t('recommendedSize|eshopSlider') : t('recommendedSize|accessoriesIcon')}
										component={UploadInputField}
										label={t('Obrazok na pozadí (upload)')}
										customRequest={(args: any) => uploadImage(args, language)}
										isLoading={image.isLoading}
										required
									/>

									<Field name={`buttonLink_${language}`} component={TextField} label={t('Link')} size={'large'} />
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field name={'isVisible'} component={SwitchField} label={t('Viditelný')} />

						<Field name={'premiumAudience'} component={SwitchField} label={t('Zobraziť premium používateľom')} />
						<Field name={'nonPremiumAudience'} component={SwitchField} label={t('Zobraziť non-premium používateľom')} />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať banner')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<{}, BannerFormProps>({
	form: FORMS.BANNER_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateBannerForm
})(BannerForm)
