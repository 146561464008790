import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Table, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, get, map, some, split } from 'lodash'
import { getTranslations } from '../../redux/translations/actions'
import { INPUT_MAX_LENGTH, LANGUAGE } from '../../utils/enums'

import EditableTable from './EditableTable'

const PAGE_SIZE = 20

const TranslationTable = ({ searchTerm }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const translations = useSelector((state) => get(state, 'translations.translations.rawList'))
	const context = useSelector((state) => get(state, 'translations.translations.context'))
	const isLoading = useSelector((state) => get(state, 'translations.translations.isLoading'))

	const [currentPage, setCurrentPage] = useState(1)

	useEffect(() => {
		dispatch(
			getTranslations({
				search: searchTerm,
				limit: PAGE_SIZE,
				page: 1
			})
		)
	}, [dispatch, searchTerm])

	const handleTableChange = (pagination) => {
		setCurrentPage(pagination.current)
		dispatch(
			getTranslations({
				search: searchTerm,
				limit: PAGE_SIZE,
				page: pagination.current
			})
		)
	}

	const columns = [
		{
			title: t('Kľúč'),
			dataIndex: 'key',
			render: (value) => split(value, ':')[1]
		}
	]

	map(LANGUAGE, (item, index) => {
		columns.unshift({
			title: item.toUpperCase(),
			width: 30,
			align: 'center',
			dataIndex: `language_${item}`,
			render: (value) => (value ? <div key={index} className={'true-cell'} /> : <div key={index} className={'false-cell'} />)
		})
	})

	const customData = map(translations, (item) => {
		const translationItem = {
			...item
		}
		map(LANGUAGE, (lng) => {
			translationItem[`language_${lng}`] = some(item.languages, { language: lng })
		})
		return translationItem
	})

	const expandedRowRender = (row) => <EditableTable data={row} parentCurrentPage={currentPage} parentSearchTerm={searchTerm} />

	return (
		<Table
			onChange={handleTableChange}
			loading={isLoading}
			columns={columns}
			dataSource={customData}
			className={'general-table'}
			size={'small'}
			style={{ marginTop: 20 }}
			expandable={{ expandedRowRender }}
			pagination={{
				pageSize: PAGE_SIZE,
				total: get(context, 'totalCount'),
				current: get(context, 'page'),
				showSizeChanger: false
			}}
		/>
	)
}

TranslationTable.propTypes = {
	searchTerm: PropTypes.string
}

TranslationTable.defaultProps = {
	searchTerm: ''
}

const Translations = () => {
	const dispatch = useDispatch()

	const [search, setSearch] = useState('')

	const handleSearch = (searchTerm) => {
		setSearch(searchTerm)
		dispatch(
			getTranslations({
				search: searchTerm,
				limit: PAGE_SIZE,
				page: 1
			})
		)
	}
	const debounced = useCallback(
		debounce((searchTerm) => handleSearch(searchTerm), 300),
		[]
	)
	const handleOnChange = (e) => {
		debounced(e.target.value)
	}
	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Input.Search style={{ width: 300 }} maxLength={INPUT_MAX_LENGTH} onChange={handleOnChange} allowClear />
			</div>
			<TranslationTable searchTerm={search} />
		</div>
	)
}

export default Translations
