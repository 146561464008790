import React, { FC, useState } from 'react'
import cx from 'classnames'
import { ChromePicker } from 'react-color'

interface IColorField {
	name?: string
	label?: string
	input: any
	meta: {
		error: string
		touched: boolean
	}
	customClass?: string
	customLabelClass?: string
	showLabel?: boolean
	required?: boolean | false
}

const ColorField: FC<IColorField> = ({ input, customClass, showLabel, label, customLabelClass, name, meta: { error, touched }, required }) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleChange = (values: { hex: any }) => {
		input.onChange(values.hex)
	}

	const value = input.value || '#ffffff'

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={name}>
					{label}
				</label>
			)}
			<div className={'color-picker-wrapper'}>
				<button type={'button'} className={'preview'} onClick={() => setIsOpen(!isOpen)}>
					<div className={'circle'} style={{ background: value }} />
					<span>{value}</span>
				</button>
				<div className={cx('color-picker', { 'is-open': isOpen })}>
					<ChromePicker color={value} onChange={handleChange} disableAlpha />
				</div>
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

export default ColorField
