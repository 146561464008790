// types
import { Paths } from '../../types/api'

export type ExerciseTargetsPayload = Paths.GetAdminTarget.Responses.$200['targets']
export type ExerciseTargetPayload = Paths.GetAdminTargetId.Responses.$200

export enum GET_EXERCISE_TARGETS {
	START = 'GET_EXERCISE_TARGETS_START',
	DONE = 'GET_EXERCISE_TARGETS_DONE',
	FAILED = 'GET_EXERCISE_TARGETS_FAILED'
}

export enum GET_EXERCISE_TARGET {
	START = 'GET_EXERCISE_TARGET_START',
	DONE = 'GET_EXERCISE_TARGET_DONE',
	FAILED = 'GET_EXERCISE_TARGET_FAILED'
}

export enum DELETE_EXERCISE_TARGET {
	START = 'DELETE_EXERCISE_TARGET_START',
	DONE = 'DELETE_EXERCISE_TARGET_DONE',
	FAILED = 'DELETE_EXERCISE_TARGET_FAILED'
}

export enum CREATE_EXERCISE_TARGET {
	START = 'CREATE_EXERCISE_TARGET_START',
	DONE = 'CREATE_EXERCISE_TARGET_DONE',
	FAILED = 'CREATE_EXERCISE_TARGET_FAILED'
}

export enum UPDATE_EXERCISE_TARGET {
	START = 'UPDATE_EXERCISE_TARGET_START',
	DONE = 'UPDATE_EXERCISE_TARGET_DONE',
	FAILED = 'UPDATE_EXERCISE_TARGET_FAILED'
}
