import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createExerciseTarget, getExerciseTarget, updateExerciseTarget } from '../../redux/exerciseTargets/actions'

// utils
import { FORMS, LANGUAGES } from '../../utils/enums'

// types
import { ExerciseTargetPayload } from '../../redux/exerciseTargets/types'
import { IComputedMatch } from '../../types/interfaces'
import { Paths } from '../../types/api'

// forms
import { ExerciseTargetFormValues } from './forms/validateExerciseTargetForm'
import ExerciseTargetForm from './forms/ExerciseTargetForm'

type DetailExerciseTargetProps = {
	computedMatch: IComputedMatch
}

const DetailExerciseTarget = ({ computedMatch }: DetailExerciseTargetProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const isLoading = useSelector((state: RootState) => state.exerciseTargets?.detail?.isLoading)

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {}
		dispatch(initialize(FORMS.EXERCISE_TARGET_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: ExerciseTargetPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				...data,
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY ')
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'nameSlug')
			})

			dispatch(
				initialize(FORMS.EXERCISE_TARGET_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getExerciseTarget(id, (data: ExerciseTargetPayload) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: Paths.PostAdminTarget.RequestBody) => {
		dispatch(createExerciseTarget(body, () => history.push(t('paths:exerciseTargets|path'))))
	}

	const handleUpdate = (body: Paths.PutAdminTargetId.RequestBody) => {
		dispatch(
			updateExerciseTarget(id, body, () => {
				dispatch(getExerciseTarget(id, (data: ExerciseTargetPayload) => initDetailForm(data)))
			})
		)
	}

	const handleSubmit = (values: ExerciseTargetFormValues) => {
		const body: any = {
			translations: [],
			name: get(values, 'name_sk')
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)

			if (name && urlSlug) {
				body.translations.push({
					name: get(values, `name_${item}`),
					nameSlug: get(values, `urlSlug_${item}`),
					language: item
				})
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<ExerciseTargetForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailExerciseTarget
