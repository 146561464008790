import slugify from 'slugify'
import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { debounce, each, get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteBlogCategory } from '../../../redux/blogCategories/actions'

// utils
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { FORMS, LANGUAGE, LANGUAGES } from '../../../utils/enums'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateBlogCategoryForm, { BlogCategoryFormValues } from './validateBlogCategoryForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import CircleColorField from '../../../atoms/form/CircleColorField'

const { Option } = Select
const { TabPane } = Tabs

type BlogCategoryFormProps = {
	isCreate?: boolean
}

const BlogCategoryForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: BlogCategoryFormProps & InjectedFormProps<BlogCategoryFormValues, BlogCategoryFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.BLOG_CATEGORY_FORM)(state)) as BlogCategoryFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.BLOG_CATEGORY_FORM))
	const blogCategories = useSelector((state: RootState) => state.blogCategories?.list?.tableList)
	const blogCategoriesSource: { value: number; label: string }[] = []

	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		order: t('Poradie')
	}

	each(blogCategories, (item) => {
		if (item.key !== fieldValues?.id) {
			blogCategoriesSource.push({
				value: item?.key,
				label: item?.name
			})
		}
	})

	const debounceChangeField = debounce((value, language) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.BLOG_CATEGORY_FORM, `urlSlug_${language}`, slug))
	}, 300)

	const changeUrlSlug = (event: any, language: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, language)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať kategóriu?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteBlogCategory(fieldValues?.id, () => history.push(t('paths:blogCategories|path'))))
			}
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať kategóriu') : t('Detail kategórie')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať novú kategóriu'),
										path: t('paths:blogCategoryCreate|path')
									}
								]
							}
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`name_${item}`}
										component={TextField}
										onInput={(e: any) => changeUrlSlug(e, item)}
										label={t('Názov')}
										size={'large'}
										required
									/>

									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required />

									<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} size={'large'} />
								</TabPane>
							))}
						</Tabs>

						<Field name={'color'} component={CircleColorField} label={t('Farba štítku')} />

						<Field
							name={'parentCategoryID'}
							component={CustomOptionsSelectField}
							label={t('Nadradená kategória')}
							options={map(blogCategoriesSource, (category, index) => (
								<Option key={index} value={get(category, 'value')}>
									{get(category, 'label') || ''}
								</Option>
							))}
							showSearch
							allowClear
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať kategóriu')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<BlogCategoryFormValues, BlogCategoryFormProps>({
	form: FORMS.BLOG_CATEGORY_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateBlogCategoryForm
})(BlogCategoryForm)
