import { mapValues, get, ceil } from 'lodash'
import { getReq, patchReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { LOAD_TRANSLATIONS, UPDATE_TRANSLATION } from './types'

export const getTranslations = (params) => async (dispatch) => {
	try {
		dispatch({ type: LOAD_TRANSLATIONS.START })
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_TRANSLATIONS, normalizeQueryParams)

		const totalPages = ceil(get(data, 'context.totalCount', 0) / get(data, 'context.count', 0))
		const isLastPage = totalPages === get(data, 'context.page', 0)

		dispatch({
			type: LOAD_TRANSLATIONS.DONE,
			payload: {
				rawList: data.translations,
				context: data.context,
				isLastPage
			}
		})
	} catch (error) {
		dispatch({ type: LOAD_TRANSLATIONS.FAILED })
		Promise.reject(error)
	}
}

export const updateTranslation = (values, onSuccess, onFailure) => async (dispatch) => {
	dispatch({
		type: UPDATE_TRANSLATION.START
	})
	try {
		await patchReq(ENDPOINTS.UPDATE_TRANSLATION, null, values)

		dispatch({ type: UPDATE_TRANSLATION.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_TRANSLATION.FAILED })
		return onFailure && onFailure(error)
	}
}
