import { FormErrors } from 'redux-form'
import i18next from 'i18next'

// utils
import { SUBSCRIPTION_TIER } from '../../utils/enums'

export type EditSubscriptionFormValues = {
	tier: SUBSCRIPTION_TIER
	activeTo: string
}

export default (values: EditSubscriptionFormValues) => {
	const errors: FormErrors<EditSubscriptionFormValues> = {}

	if (!values.tier) {
		errors.tier = i18next.t('Toto pole je povinné')
	}
	if (!values.activeTo) {
		errors.activeTo = i18next.t('Toto pole je povinné')
	}

	return errors
}
