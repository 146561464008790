import dayjs from 'dayjs'
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form'
import find from 'lodash/find'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { getUserSubscriptionsTier } from '../../redux/subscriptions/actions'

// utils
import { FORMS, SUBSCRIPTION_STATE } from '../../utils/enums'
import { getSubscriptionTierTypes } from '../../utils/helpers'

// types
import { Paths } from '../../types/api'

// forms
import validateEditSubscriptionForm, { EditSubscriptionFormValues } from '../validators/validateEditSubscriptionForm'

// atoms
import DateField from '../../atoms/form/DateField'
import SelectField from '../../atoms/form/SelectField'

type EditSubscriptionFormProps = {
	userID: number
}

const EditSubscriptionForm = ({
	userID,
	handleSubmit
}: EditSubscriptionFormProps & InjectedFormProps<EditSubscriptionFormValues, EditSubscriptionFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const subscriptions = useSelector((state: RootState) => state.subscriptions?.userSubscriptions?.subscriptions)

	// constants
	const disabledOption = find(subscriptions, (subscription) => subscription?.state === SUBSCRIPTION_STATE.ACTIVE)
	const disabledDate = (current: any) => {
		return current && current <= dayjs()
	}

	const handleTierChange = (tier: any) => {
		const params = {
			tier
		}
		dispatch(
			getUserSubscriptionsTier(userID, params, (res: Paths.GetAdminUsersUserIdSubscriptionsTier.Responses.$200) => {
				dispatch(change(FORMS.EDIT_SUBSCRIPTION_FORM, 'activeTo', res?.activeTo))
			})
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Field
				name={'tier'}
				component={SelectField}
				options={getSubscriptionTierTypes(t, disabledOption?.tier)}
				label={t('Nový typ členstva')}
				placeholder={t('Vyber si typ členstva')}
				onChange={handleTierChange}
				required
			/>
			<Field name={'activeTo'} component={DateField} label={t('Koniec členstva')} dttmFormat={'D.M.YYYY'} disabledDate={disabledDate} required />
		</form>
	)
}

export default reduxForm<EditSubscriptionFormValues, EditSubscriptionFormProps>({
	form: FORMS.EDIT_SUBSCRIPTION_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateEditSubscriptionForm
})(EditSubscriptionForm)
