import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from 'react-router-dom'
import Layout from '../layouts/Layout'
import AdminRoute from './AdminRoute'
import Route from './PublicRoute'

import Accessories from '../pages/accessories/Accessories'
import DetailAccessory from '../pages/accessories/DetailAccessory'
import AdministratorDetail from '../pages/administrators/AdministratorDetail'
import Administrators from '../pages/administrators/Administrators'
import Banners from '../pages/banners/Banners'
import DetailBanner from '../pages/banners/DetailBanner'
import BlogPosts from '../pages/blog/BlogPosts'
import DetailBlog from '../pages/blog/DetailBlog'
import BlogCategories from '../pages/blogCategories/BlogCategories'
import DetailBlogCategory from '../pages/blogCategories/DetailBlogCategory'
import Dashboard from '../pages/dashboard/Dashboard'
import DonationDetail from '../pages/donations/DetailDonation'
import Donations from '../pages/donations/Donations'
import DetailDownload from '../pages/downloads/DetailDownload'
import Downloads from '../pages/downloads/Downloads'
import DetailExercise from '../pages/exercises/DetailExercise'
import Exercises from '../pages/exercises/Exercises'
import DetailExerciseTarget from '../pages/exerciseTargets/DetailExerciseTarget'
import ExerciseTargets from '../pages/exerciseTargets/ExerciseTargets'
import ForgottenPasswordPage from '../pages/forgottenPassword/ForgottenPasswordPage'
import Homepage from '../pages/home/Homepage'
import DetailInstructor from '../pages/instructors/DetailInstructor'
import Instructors from '../pages/instructors/Instructors'
import LandingPage from '../pages/landing/LandingPage'
import LiveStreamCategories from '../pages/liveStreamCategories/LiveStreamCategories'
import ForgottenPassword from '../pages/login/ForgottenPassword'
import LoginPage from '../pages/login/LoginPage'
import DetailMotivationAdvice from '../pages/motivationAdvices/DetailMotivationAdvice'
import MotivationAdvices from '../pages/motivationAdvices/MotivationAdvices'
import NotFoundPage from '../pages/NotFoundPage'
import DetailPage from '../pages/pages/DetailPage'
import Pages from '../pages/pages/Pages'
import DetailPayments from '../pages/payments/DetailPayments'
import Payments from '../pages/payments/Payments'
import DetailPreference from '../pages/preferences/DetailPreference'
import Preferences from '../pages/preferences/Preferences'
import DetailProgram from '../pages/programs/DetailProgram'
import Programs from '../pages/programs/Programs'
import DetailPromoCode from '../pages/promoCodes/DetailPromoCode'
import PromoCodes from '../pages/promoCodes/PromoCodes'
import PromoCodesUsagePage from '../pages/promoCodesUsage/PromoCodesUsagePage'
import PushNotifications from '../pages/pushNotifications/PushNotifications'
import Settings from '../pages/settings/Settings'
import TagDetail from '../pages/tags/TagDetail'
import Tags from '../pages/tags/Tags'
import Translations from '../pages/translations/Translations'
import DetailUser from '../pages/users/DetailUser'
import Users from '../pages/users/Users'
import VideoDetail from '../pages/videos/VideoDetail'
import Videos from '../pages/videos/Videos'
import DownloadCategories from '../pages/downloadCategories/DownloadCategories'
import DetailDownloadCategory from '../pages/downloadCategories/DetailDownloadCategory'
import DetailPushNotification from '../pages/pushNotifications/DetailPushNotification'
import PushNotificationsStats from '../pages/pushNotifications/PushNotificationsStats'
// import GiftProducts from '../pages/giftProducts/GiftProducts'
// import LiveStreams from '../pages/liveStreams/LiveStreams'
// import DetailLiveStream from '../pages/liveStreams/DetailLiveStream'

const Routes: FC = () => {
	const { t } = useTranslation()

	return (
		<Switch>
			<Route exact routeName={t('paths:login|title')} routeKey={t('paths:login|key')} path={t('paths:login|path')} component={LoginPage} />
			<Route
				exact
				routeName={t('paths:forgotPassword|title')}
				routeKey={t('paths:forgotPassword|key')}
				path={t('paths:forgotPassword|path')}
				component={ForgottenPassword}
			/>
			<Route
				exact
				routeName={t('paths:forgottenPassword|title')}
				routeKey={t('paths:forgottenPassword|key')}
				path={t('paths:forgottenPassword|path')}
				component={ForgottenPasswordPage}
			/>{' '}
			<Route
				exact
				routeName={t('paths:setNewPassword|title')}
				routeKey={t('paths:setNewPassword|key')}
				path={t('paths:setNewPassword|path')}
				component={ForgottenPassword}
				isRegistrationConfirm
			/>
			<AdminRoute
				exact
				routeName={t('paths:base|title')}
				routeKey={t('paths:base|key')}
				path={t('paths:base|path')}
				component={Homepage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:dashboard|title')}
				routeKey={t('paths:dashboard|key')}
				path={t('paths:dashboard|path')}
				component={Dashboard}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:instructors|title')}
				routeKey={t('paths:instructors|key')}
				path={t('paths:instructors|path')}
				component={Instructors}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:instructorDetail|title')}
				routeKey={t('paths:instructorDetail|key')}
				path={t('paths:instructorDetail|path')}
				component={DetailInstructor}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:instructorCreate|title')}
				routeKey={t('paths:instructorCreate|key')}
				path={t('paths:instructorCreate|path')}
				component={DetailInstructor}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:programs|title')}
				routeKey={t('paths:programs|key')}
				path={t('paths:programs|path')}
				component={Programs}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:programs|title')}
				routeKey={t('paths:programs|key')}
				path={t('paths:programs|path')}
				component={Programs}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:programDetail|title')}
				routeKey={t('paths:programDetail|key')}
				path={t('paths:programDetail|path')}
				component={DetailProgram}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:programCreate|title')}
				routeKey={t('paths:programCreate|key')}
				path={t('paths:programCreate|path')}
				component={DetailProgram}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:challengeDetail|title')}
				routeKey={t('paths:challengeDetail|key')}
				path={t('paths:challengeDetail|path')}
				component={DetailProgram}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:challengeCreate|title')}
				routeKey={t('paths:challengeCreate|key')}
				path={t('paths:challengeCreate|path')}
				component={DetailProgram}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exercises|title')}
				routeKey={t('paths:exercises|key')}
				path={t('paths:exercises|path')}
				component={Exercises}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exerciseDetail|title')}
				routeKey={t('paths:exerciseDetail|key')}
				path={t('paths:exerciseDetail|path')}
				component={DetailExercise}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exerciseCreate|title')}
				routeKey={t('paths:exerciseCreate|key')}
				path={t('paths:exerciseCreate|path')}
				component={DetailExercise}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:videos|title')}
				routeKey={t('paths:videos|key')}
				path={t('paths:videos|path')}
				component={Videos}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:videoDetail|title')}
				routeKey={t('paths:videoDetail|key')}
				path={t('paths:videoDetail|path')}
				component={VideoDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:videosCreate|title')}
				routeKey={t('paths:videosCreate|key')}
				path={t('paths:videosCreate|path')}
				component={VideoDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exerciseTargets|title')}
				routeKey={t('paths:exerciseTargets|key')}
				path={t('paths:exerciseTargets|path')}
				component={ExerciseTargets}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exerciseTargetDetail|title')}
				routeKey={t('paths:exerciseTargetDetail|key')}
				path={t('paths:exerciseTargetDetail|path')}
				component={DetailExerciseTarget}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:exerciseTargetCreate|title')}
				routeKey={t('paths:exerciseTargetCreate|key')}
				path={t('paths:exerciseTargetCreate|path')}
				component={DetailExerciseTarget}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:motivationAdvices|title')}
				routeKey={t('paths:motivationAdvices|key')}
				path={t('paths:motivationAdvices|path')}
				component={MotivationAdvices}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:motivationAdviceDetail|title')}
				routeKey={t('paths:motivationAdviceDetail|key')}
				path={t('paths:motivationAdviceDetail|path')}
				component={DetailMotivationAdvice}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:motivationAdviceCreate|title')}
				routeKey={t('paths:motivationAdviceCreate|key')}
				path={t('paths:motivationAdviceCreate|path')}
				component={DetailMotivationAdvice}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:accessories|title')}
				routeKey={t('paths:accessories|key')}
				path={t('paths:accessories|path')}
				component={Accessories}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:accessoryDetail|title')}
				routeKey={t('paths:accessoryDetail|key')}
				path={t('paths:accessoryDetail|path')}
				component={DetailAccessory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:accessoryCreate|title')}
				routeKey={t('paths:accessoryCreate|key')}
				path={t('paths:accessoryCreate|path')}
				component={DetailAccessory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:users|title')}
				routeKey={t('paths:users|key')}
				path={t('paths:users|path')}
				component={Users}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:userDetail|title')}
				routeKey={t('paths:userDetail|key')}
				path={t('paths:userDetail|path')}
				component={DetailUser}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:userCreate|title')}
				routeKey={t('paths:userCreate|key')}
				path={t('paths:userCreate|path')}
				component={DetailUser}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pushNotifications|title')}
				routeKey={t('paths:pushNotifications|key')}
				path={t('paths:pushNotifications|path')}
				component={PushNotifications}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pushNotificationDetail|title')}
				routeKey={t('paths:pushNotificationDetail|key')}
				path={t('paths:pushNotificationDetail|path')}
				component={DetailPushNotification}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pushNotificationDetailId|title')}
				routeKey={t('paths:pushNotificationDetailId|key')}
				path={t('paths:pushNotificationDetailId|path')}
				component={DetailPushNotification}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pushNotificationCreate|title')}
				routeKey={t('paths:pushNotificationCreate|key')}
				path={t('paths:pushNotificationCreate|path')}
				component={DetailPushNotification}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pushNotificationsStats|title')}
				routeKey={t('paths:pushNotificationsStats|key')}
				path={t('paths:pushNotificationsStats|path')}
				component={PushNotificationsStats}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:payments|title')}
				routeKey={t('paths:payments|key')}
				path={t('paths:payments|path')}
				component={Payments}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:paymentDetail|title')}
				routeKey={t('paths:paymentDetail|key')}
				path={t('paths:paymentDetail|path')}
				component={DetailPayments}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:paymentCreate|title')}
				routeKey={t('paths:paymentCreate|key')}
				path={t('paths:paymentCreate|path')}
				component={DetailPayments}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:donations|title')}
				routeKey={t('paths:donations|key')}
				path={t('paths:donations|path')}
				component={Donations}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:donationsDetail|title')}
				routeKey={t('paths:donationsDetail|key')}
				path={t('paths:donationsDetail|path')}
				component={DonationDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:liveStreamCategories|title')}
				routeKey={t('paths:liveStreamCategories|key')}
				path={t('paths:liveStreamCategories|path')}
				component={LiveStreamCategories}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blog|title')}
				routeKey={t('paths:blog|key')}
				path={t('paths:blog|path')}
				component={BlogPosts}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogDetail|title')}
				routeKey={t('paths:blogDetail|key')}
				path={t('paths:blogDetail|path')}
				component={DetailBlog}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogDetailId|title')}
				routeKey={t('paths:blogDetailId|key')}
				path={t('paths:blogDetailId|path')}
				component={DetailBlog}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogCreate|title')}
				routeKey={t('paths:blogCreate|key')}
				path={t('paths:blogCreate|path')}
				component={DetailBlog}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogCategories|title')}
				routeKey={t('paths:blogCategories|key')}
				path={t('paths:blogCategories|path')}
				component={BlogCategories}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogCategoryDetail|title')}
				routeKey={t('paths:blogCategoryDetail|key')}
				path={t('paths:blogCategoryDetail|path')}
				component={DetailBlogCategory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogCategoryDetailId|title')}
				routeKey={t('paths:blogCategoryDetailId|key')}
				path={t('paths:blogCategoryDetailId|path')}
				component={DetailBlogCategory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:blogCategoryCreate|title')}
				routeKey={t('paths:blogCategoryCreate|key')}
				path={t('paths:blogCategoryCreate|path')}
				component={DetailBlogCategory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloads|title')}
				routeKey={t('paths:downloads|key')}
				path={t('paths:downloads|path')}
				component={Downloads}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:banners|title')}
				routeKey={t('paths:banners|key')}
				path={t('paths:banners|path')}
				component={Banners}
				layout={Layout}
			/>
			{/* <AdminRoute */}
			{/*	exact */}
			{/*	routeName={t('paths:giftProducts|title')} */}
			{/*	routeKey={t('paths:giftProducts|key')} */}
			{/*	path={t('paths:giftProducts|path')} */}
			{/*	component={GiftProducts} */}
			{/*	layout={Layout} */}
			{/* /> */}
			<AdminRoute
				exact
				routeName={t('paths:downloadDetail|title')}
				routeKey={t('paths:downloadDetail|key')}
				path={t('paths:downloadDetail|path')}
				component={DetailDownload}
				layout={Layout}
			/>{' '}
			<AdminRoute
				exact
				routeName={t('paths:bannerCreate|title')}
				routeKey={t('paths:bannerCreate|key')}
				path={t('paths:bannerCreate|path')}
				component={DetailBanner}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadDetailId|title')}
				routeKey={t('paths:downloadDetailId|key')}
				path={t('paths:downloadDetailId|path')}
				component={DetailDownload}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:bannerDetail|title')}
				routeKey={t('paths:bannerDetail|key')}
				path={t('paths:bannerDetail|path')}
				component={DetailBanner}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadCreate|title')}
				routeKey={t('paths:downloadCreate|key')}
				path={t('paths:downloadCreate|path')}
				component={DetailDownload}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadCategories|title')}
				routeKey={t('paths:downloadCategories|key')}
				path={t('paths:downloadCategories|path')}
				component={DownloadCategories}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadCategoryDetail|title')}
				routeKey={t('paths:downloadCategoryDetail|key')}
				path={t('paths:downloadCategoryDetail|path')}
				component={DetailDownloadCategory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadCategoryDetailId|title')}
				routeKey={t('paths:downloadCategoryDetailId|key')}
				path={t('paths:downloadCategoryDetailId|path')}
				component={DetailDownloadCategory}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:downloadCategoryCreate|title')}
				routeKey={t('paths:downloadCategoryCreate|key')}
				path={t('paths:downloadCategoryCreate|path')}
				component={DetailDownloadCategory}
				layout={Layout}
			/>
			<AdminRoute exact routeName={t('paths:tags|title')} routeKey={t('paths:tags|key')} path={t('paths:tags|path')} component={Tags} layout={Layout} />
			<AdminRoute
				exact
				routeName={t('paths:tagsCreate|title')}
				routeKey={t('paths:tagsCreate|key')}
				path={t('paths:tagsCreate|path')}
				component={TagDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:tagDetail|title')}
				routeKey={t('paths:tagDetail|key')}
				path={t('paths:tagDetail|path')}
				component={TagDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:settings|title')}
				routeKey={t('paths:settings|key')}
				path={t('paths:settings|path')}
				component={Settings}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pages|title')}
				routeKey={t('paths:pages|key')}
				path={t('paths:pages|path')}
				component={Pages}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pageDetail|title')}
				routeKey={t('paths:pageDetail|key')}
				path={t('paths:pageDetail|path')}
				component={DetailPage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:createPage|title')}
				routeKey={t('paths:createPage|key')}
				path={t('paths:createPage|path')}
				component={DetailPage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:pageDetailId|title')}
				routeKey={t('paths:pageDetailId|key')}
				path={t('paths:pageDetailId|path')}
				component={DetailPage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:landing|title')}
				routeKey={t('paths:landing|key')}
				path={t('paths:landing|path')}
				component={LandingPage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:promoCodes|title')}
				routeKey={t('paths:promoCodes|key')}
				path={t('paths:promoCodes|path')}
				component={PromoCodes}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:promoCodeCreate|title')}
				routeKey={t('paths:promoCodeCreate|key')}
				path={t('paths:promoCodeCreate|path')}
				component={DetailPromoCode}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:promoCodeDetailId|title')}
				routeKey={t('paths:promoCodeDetailId|key')}
				path={t('paths:promoCodeDetailId|path')}
				component={DetailPromoCode}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:promoCodesUsage|title')}
				routeKey={t('paths:promoCodesUsage|key')}
				path={t('paths:promoCodesUsage|path')}
				component={PromoCodesUsagePage}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:administrators|title')}
				routeKey={t('paths:administrators|key')}
				path={t('paths:administrators|path')}
				component={Administrators}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:administratorDetail|title')}
				routeKey={t('paths:administratorDetail|key')}
				path={t('paths:administratorDetail|path')}
				component={AdministratorDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:administratorCreate|title')}
				routeKey={t('paths:administratorCreate|key')}
				path={t('paths:administratorCreate|path')}
				component={AdministratorDetail}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:translations|title')}
				routeKey={t('paths:translations|key')}
				path={t('paths:translations|path')}
				component={Translations}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:preferences|title')}
				routeKey={t('paths:preferences|key')}
				path={t('paths:preferences|path')}
				component={Preferences}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:preferenceDetail|title')}
				routeKey={t('paths:preferenceDetail|key')}
				path={t('paths:preferenceDetail|path')}
				component={DetailPreference}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:preferenceCreate|title')}
				routeKey={t('paths:preferenceCreate|key')}
				path={t('paths:preferenceCreate|path')}
				component={DetailPreference}
				layout={Layout}
			/>
			<Route component={NotFoundPage} layout={Layout} />
			{/* TODO: Remove after livestream, exercise and video refactor */}
			{/* <AdminRoute
				exact
				routeName={t('paths:liveStreams|title')}
				routeKey={t('paths:liveStreams|key')}
				path={t('paths:liveStreams|path')}
				component={LiveStreams}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:liveStreamDetail|title')}
				routeKey={t('paths:liveStreamDetail|key')}
				path={t('paths:liveStreamDetail|path')}
				component={DetailLiveStream}
				layout={Layout}
			/>
			<AdminRoute
				exact
				routeName={t('paths:liveStreamCreate|title')}
				routeKey={t('paths:liveStreamCreate|key')}
				path={t('paths:liveStreamCreate|path')}
				component={DetailLiveStream}
				layout={Layout}
			/> */}
		</Switch>
	)
}

export default Routes
