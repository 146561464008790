// types
import { ArrElement } from '../../types/types'
import { Paths } from '../../types/api'

export type PreferencesPayload = Paths.GetAdminPreferences.Responses.$200['preferences']
export type PreferencesListItemPayload = ArrElement<PreferencesPayload>
export type PreferencePayload = Paths.GetAdminPreferencesId.Responses.$200

export enum GET_PREFERENCES {
	START = 'GET_PREFERENCES_START',
	DONE = 'GET_PREFERENCES_DONE',
	FAILED = 'LOAD_PREFERENCES_FAILED'
}

export enum GET_PREFERENCE {
	START = 'GET_PREFERENCE_START',
	DONE = 'GET_PREFERENCE_DONE',
	FAILED = 'GET_PREFERENCE_FAILED'
}

export enum DELETE_PREFERENCE {
	START = 'DELETE_PREFERENCE_START',
	DONE = 'DELETE_PREFERENCE_DONE',
	FAILED = 'DELETE_PREFERENCE_FAILED'
}

export enum CREATE_PREFERENCE {
	START = 'CREATE_PREFERENCE_START',
	DONE = 'CREATE_PREFERENCE_DONE',
	FAILED = 'CREATE_PREFERENCE_FAILED'
}

export enum UPDATE_PREFERENCE {
	START = 'UPDATE_PREFERENCE_START',
	DONE = 'UPDATE_PREFERENCE_DONE',
	FAILED = 'UPDATE_PREFERENCE_FAILED'
}
