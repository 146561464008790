// types
import {
	GET_PROGRAM,
	GET_PROGRAMS,
	CREATE_PROGRAM,
	UPDATE_PROGRAM,
	DELETE_PROGRAM,
	LOAD_CHALLENGE,
	CREATE_CHALLENGE,
	UPDATE_CHALLENGE,
	DELETE_CHALLENGE,
	GET_PROGRAM_USERS
} from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLastPage: false,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		users: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_PROGRAMS.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAMS.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAMS.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLastPage: false,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROGRAM.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAM.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAM.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_PROGRAM.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_PROGRAM.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_PROGRAM.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_PROGRAM.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_PROGRAM.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_PROGRAM.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_PROGRAM.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_PROGRAM.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_PROGRAM.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case LOAD_CHALLENGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case LOAD_CHALLENGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case LOAD_CHALLENGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case CREATE_CHALLENGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case CREATE_CHALLENGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case CREATE_CHALLENGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case UPDATE_CHALLENGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_CHALLENGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_CHALLENGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case DELETE_CHALLENGE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case DELETE_CHALLENGE.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case DELETE_CHALLENGE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PROGRAM_USERS.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PROGRAM_USERS.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					users: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PROGRAM_USERS.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
