import { useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Modal } from 'antd'
import { initialize, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { createPartner, updatePartner } from '../../../redux/landing/actions'

// utils
import { FORMS, LANGUAGES } from '../../../utils/enums'

// types
import { ILandingModalState } from '../../../types/interfaces'

// forms
import { PartnerFormValues } from '../forms/validatePartnerForm'
import PartnerForm from '../forms/PartnerForm'

type PartnerModalProps = {
	modalState: ILandingModalState
	setShowModal: (args: ILandingModalState) => void
}

const PartnerModal = ({ modalState, setShowModal }: PartnerModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const record = useSelector((state: RootState) => state.landing?.page?.data?.partners.filter((item: any) => item.id === modalState?.id))

	useEffect(() => {
		if (record.length > 0) {
			const langData = get(record[0], 'translations')
			const langValues: any = {}

			const initValues = {
				...record[0]
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
			})

			dispatch(initialize(FORMS.LANDING_PARTNER_FORM, { ...initValues, ...langValues }))
		} else {
			dispatch(initialize(FORMS.LANDING_PARTNER_FORM, {}))
		}
	}, [dispatch])

	const handleForm = (values: PartnerFormValues) => {
		const body: any = {
			id: values.id,
			order: values.order,
			logo: values.logo,
			translations: []
		}
		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			if (name) {
				const translation = {
					name: get(values, `name_${item}`),
					language: item
				}

				body.translations.push(translation)
			}
		})

		if (modalState?.id) {
			dispatch(updatePartner(modalState.id, body))
		} else {
			body.id = dayjs().valueOf()
			dispatch(createPartner(body))
		}
		setShowModal({ open: false })
	}

	return (
		<Modal
			visible
			title={modalState?.id ? t('Upraviť partnera') : t('Pridať partnera')}
			okText={modalState?.id ? t('Uložiť') : t('Pridať')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal({ open: false })}
			onOk={() => {
				dispatch(submit(FORMS.LANDING_PARTNER_FORM))
			}}
		>
			<PartnerForm onSubmit={handleForm} />
		</Modal>
	)
}

export default PartnerModal
