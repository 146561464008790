import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import join from 'lodash/join'
import map from 'lodash/map'
import { Button, Empty, Form, Input, Popconfirm, Select, Table } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, LinkOutlined, PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteInstructor, getInstructors } from '../../redux/instructors/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { EMPTY_VALUE, INPUT_MAX_LENGTH, INSTRUCTOR_ROLES_OPTIONS, LANGUAGES } from '../../utils/enums'
import { getLanguageName, setFiltersForPage } from '../../utils/helpers'

// types
import { InstructorsPayload } from '../../redux/instructors/types'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'
import OrderRepresent from '../../atoms/OrderRepresent'

const { Option } = Select
const PAGE_SIZE = 20

const Instructors = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const instructors = useSelector((state: RootState) => state.instructors?.instructors?.tableList)
	const isLoadingInstructor = useSelector((state: RootState) => state.instructors?.instructors?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.instructors?.instructorDetail?.isLoading)
	const context = useSelector((state: RootState) => state.instructors?.instructors?.context)
	const persistFilter = useSelector((state: RootState) => state.filters)

	// states
	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:instructors|key'), persistFilter))

	// constants
	const arrayOfRoles = [t('Tréner'), t('Výživový poradca'), t('Kouč')]

	const columns = [
		{
			title: t('Meno'),
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: t('Typ'),
			dataIndex: 'roles',
			key: 'roles',
			ellipsis: true,
			render: (values: string[]) =>
				join(
					intersection(
						arrayOfRoles,
						map(INSTRUCTOR_ROLES_OPTIONS, (option) => includes(values, option.value) && option.label)
					),
					', '
				)
		},
		// {
		// 	title: t('Krátky popis'),
		// 	dataIndex: 'briefDescription',
		// 	key: 'briefDescription',
		// 	ellipsis: true,
		// 	render: (value) => value || EMPTY_VALUE
		// },
		{
			title: t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center',
			width: 60,
			render: (value: number) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			align: 'center',
			width: 110,
			render: (state: boolean) => <BooleanRepresent isTrue={state} />
		},
		{
			title: t('Viditeľné / Skryté na úvodnej stránke'),
			dataIndex: 'isVisibleOnLandingPage',
			key: 'isVisibleOnLandingPage',
			align: 'center',
			sorter: true,
			width: 200,
			render: (isVisibleOnLandingPage: boolean) => <BooleanRepresent isTrue={isVisibleOnLandingPage} />
		},
		{
			title: t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			sorter: true,
			align: 'center',
			key: 'updatedAt',
			width: 210,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: InstructorsPayload[0] & { key: number }) => (
				<>
					<span style={{ marginRight: '8px' }}>
						<Link to={`${t('paths:instructor|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<PlusCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteInstructor(record.key, () => {
									dispatch(
										getInstructors({
											limit: PAGE_SIZE,
											page: 1,
											...filter
										})
									)
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getInstructors(body))

		dispatch(setFilters(t('paths:instructors|key'), body))
	}, [filter, dispatch, t])

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ marginBottom: '16px' }}>
				<Input.Search onChange={handleOnChange} maxLength={INPUT_MAX_LENGTH} defaultValue={filter?.search} style={{ width: 300 }} allowClear />
				<Button icon={<UserAddOutlined />} href={t('paths:instructorCreate|path')} type={'primary'}>
					{t('Pridať inštruktora')}
				</Button>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap', height: '32px' }}>
				<Form.Item>
					<Select
						style={{ width: 200, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, language: value, page: 1 })}
						placeholder={t('Jazyk')}
						value={filter.language}
					>
						{map(LANGUAGES, (language, index) => (
							<Option key={`language-filter-${index}`} value={language}>
								{getLanguageName(language)}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button icon={<CloseCircleOutlined />} onClick={() => setFilter({})}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				style={{ marginTop: 20 }}
				dataSource={instructors}
				onChange={handleTableChange}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingInstructor || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:instructor|path')}/${get(record, 'key')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Instructors
