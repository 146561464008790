import { useHistory } from 'react-router-dom'
import map from 'lodash/map'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Field, reduxForm, getFormValues, getFormSyncErrors, InjectedFormProps } from 'redux-form'
import { Tabs, Row, Col, Tooltip, Button, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteDownloadCategory } from '../../../redux/downloadCategories/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateForm, { DownloadCategoryFormValues } from './validateDownloadCategoryForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'

type DownloadCategoryFormProps = {
	isCreate?: boolean
}

const DownloadCategoryForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false
}: DownloadCategoryFormProps & InjectedFormProps<DownloadCategoryFormValues, DownloadCategoryFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.DOWNLOAD_CATEGORY_FORM)(state)) as DownloadCategoryFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.DOWNLOAD_CATEGORY_FORM))

	// constants
	const tabItems = map(LANGUAGES, (item: string) => ({
		label: getLanguageName(item),
		key: item,
		children: <Field name={`name_${item}`} component={TextField} label={t('Názov')} size={'large'} required={item === 'sk'} />
	}))

	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov')
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať kategóriu?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteDownloadCategory(fieldValues?.id, () => history.push(t('paths:downloadCategories|path'))))
			}
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať kategóriu') : t('Detail kategórie')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať novú kategóriu'),
										path: t('paths:downloadCategoryCreate|path')
									}
								]
							}
						/>
						<Tabs items={tabItems} defaultActiveKey={LANGUAGE.SK} className={'translations'} />
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať kategóriu')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<DownloadCategoryFormValues, DownloadCategoryFormProps>({
	form: FORMS.DOWNLOAD_CATEGORY_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateForm
})(DownloadCategoryForm)
