import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type SetNewPasswordFormValues = {
	email: string
	password: string
	confirmPassword: string
}

export default (values: SetNewPasswordFormValues) => {
	const errors: FormErrors<SetNewPasswordFormValues> = {}

	if (!values.password) {
		errors.password = i18next.t('Toto pole je povinné')
	}

	if (values.password && values.password.length < 5) {
		errors.password = i18next.t('Minimálna dĺžka hesla je 5 znakov')
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = i18next.t('Toto pole je povinné')
	}

	if (values.confirmPassword && values.password && values.confirmPassword !== values.password) {
		errors.confirmPassword = i18next.t('Heslá sa nezhodujú')
	}

	return errors
}
