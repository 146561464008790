import { useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deletePage } from '../../../redux/pages/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES, PAGE_TYPE, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateBlogForm, { PageFormValues } from './validatePageForm'

// components
import Area from '../../../components/articleBuilder/Area'

// atoms
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { TabPane } = Tabs

type PageFormProps = {
	onUpdateForm: (args: any) => void
	isCreate?: boolean
	pageBuilderData: any
	editableSlug: boolean
}

const PageForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false,
	onUpdateForm,
	pageBuilderData = {},
	editableSlug = false
}: PageFormProps & InjectedFormProps<PageFormValues, PageFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PAGE_FORM)(state)) as PageFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PAGE_FORM))

	// states
	const [profilePhoto, setProfilePhoto] = useState({
		isLoading: false,
		imageUrl: fieldValues?.image || null
	})

	// constants
	const languagesSource = map(LANGUAGES, (item) => ({
		value: item,
		label: getLanguageName(item)
	}))
	const fieldLabels: IFieldLabels = {
		title: t('Názov'),
		urlSlug: t('URL'),
		imageTitle: t('Titulok pre obrázok')
	}

	const refreshAfterUpload = (fileUrl: string) => {
		setProfilePhoto({
			isLoading: false,
			imageUrl: fileUrl
		})
	}

	const uploadImage = async ({ file }: any) => {
		setProfilePhoto({
			...profilePhoto,
			isLoading: true
		})

		const fileUrl: any = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.PAGE_FORM, 'image', fileUrl))
			refreshAfterUpload(fileUrl)
		}
	}

	const debounceChangeField = debounce((value) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.PAGE_FORM, 'urlSlug', slug))
	}, 300)

	const changeUrlSlug = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value)
		}
	}

	const handlePageBuilderChange = (data: any) => {
		onUpdateForm(data)
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať stránku?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deletePage(get(fieldValues, 'id'), () => {
						history.push(t('paths:pages|path'))
					})
				)
			}
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						{isCreate ? <h1>{t('Vytvoriť stránku')}</h1> : <h1>{t('Detail stránky')}</h1>}

						<Field name={'title'} component={TextField} label={t('Názov')} onInput={changeUrlSlug} size={'large'} required />

						<Field name={'urlSlug'} component={TextField} label={t('URL')} required disabled={!editableSlug} />

						<Field name={'language'} component={SelectField} label={t('Jazyk stránky')} options={languagesSource} required />

						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`title_${item}`}
										component={TextField}
										label={t('Metadáta titulok')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `title_${item}.length`, 0),
											totalCount: 60
										}}
									/>

									<Field
										name={`description_${item}`}
										component={TextField}
										label={t('Metadáta popis')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `description_${item}.length`, 0),
											totalCount: 150
										}}
									/>

									<Field
										name={`ogTitle_${item}`}
										component={TextField}
										label={t('OG titulok')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `ogTitle_${item}.length`, 0),
											totalCount: 60
										}}
									/>

									<Field
										name={`ogDescription_${item}`}
										component={TextField}
										label={t('OG popis')}
										counter={{
											label: t('optimálne'),
											actualCount: get(fieldValues, `ogDescription_${item}.length`, 0),
											totalCount: 150
										}}
									/>
								</TabPane>
							))}
						</Tabs>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							customHelper={t('recommendedSize|pageImage')}
							label={t('Fotka (upload)')}
							customRequest={uploadImage}
							isLoading={profilePhoto.isLoading}
						/>

						<Field name={'imageTitle'} component={TextField} label={t('Titulok pre obrázok')} required={!!get(fieldValues, 'image')} />

						<div className={'flex direction-col justify-center'}>
							{fieldValues?.id && (
								<Button
									icon={<EyeOutlined />}
									onClick={() =>
										window.open(
											`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:pagePreview|path')}/${fieldValues?.id}`,
											'_blank'
										)
									}
									type={'default'}
									style={{ margin: '20px' }}
								>
									{t('Zobraziť náhľad')}
								</Button>
							)}

							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && get(fieldValues, 'pageType') === PAGE_TYPE.DYNAMIC && (
								<Button icon={<DeleteOutlined />} onClick={handleRemove} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<div className={'page-builder'}>
				<label className={'general-label'}>{t('Detail článku')}</label>
				<Area data={pageBuilderData} onChange={handlePageBuilderChange} />
				<div className={'flex direction-col justify-center'}>
					<Button icon={<SaveOutlined />} onClick={handleSubmit} disabled={pristine || invalid} type={'primary'} style={{ margin: '30px 0' }}>
						{t('Uložiť')}
					</Button>
				</div>
			</div>
		</form>
	)
}

export default reduxForm<PageFormValues, PageFormProps>({
	form: FORMS.PAGE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateBlogForm
})(PageForm)
