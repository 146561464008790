// types
import {
	GET_USER_PAYMENTS,
	GET_PAYMENTS,
	GET_PAYMENT,
	REFUND_PAYMENT,
	REFUND_PAYMENTS,
	GET_PAYMENT_INVOICE,
	EXECUTE_PAYMENT,
	GET_SUBSCRIPTION_PAYMENTS
} from './types'

export const initState = {
	userPayments: {
		payments: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	subscriptionPayments: {
		payments: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	payments: {
		payments: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_USER_PAYMENTS.START:
			return {
				...initState,
				...state,
				userPayments: {
					...state.userPayments,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_USER_PAYMENTS.DONE:
			return {
				...initState,
				...state,
				userPayments: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_USER_PAYMENTS.FAILED:
			return {
				...state,
				userPayments: {
					...state.userPayments,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_SUBSCRIPTION_PAYMENTS.START:
			return {
				...initState,
				...state,
				subscriptionPayments: {
					...state.subscriptionPayments,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_SUBSCRIPTION_PAYMENTS.DONE:
			return {
				...initState,
				...state,
				subscriptionPayments: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_SUBSCRIPTION_PAYMENTS.FAILED:
			return {
				...state,
				subscriptionPayments: {
					...state.subscriptionPayments,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PAYMENTS.START:
			return {
				...initState,
				...state,
				payments: {
					...state.payments,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PAYMENTS.DONE:
			return {
				...initState,
				...state,
				payments: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PAYMENTS.FAILED:
			return {
				...state,
				payments: {
					...state.payments,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_PAYMENT.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_PAYMENT.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_PAYMENT.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case EXECUTE_PAYMENT.START:
		case REFUND_PAYMENT.START:
		case REFUND_PAYMENTS.START:
		case GET_PAYMENT_INVOICE.START:
			return {
				...initState,
				...state,
				payments: {
					...state.payments,
					isLoading: true,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case EXECUTE_PAYMENT.DONE:
		case REFUND_PAYMENT.DONE:
		case REFUND_PAYMENTS.DONE:
		case GET_PAYMENT_INVOICE.DONE:
			return {
				...initState,
				...state,
				payments: {
					...state.payments,
					isLoading: false,
					isFailure: false
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case EXECUTE_PAYMENT.FAILED:
		case REFUND_PAYMENT.FAILED:
		case REFUND_PAYMENTS.FAILED:
		case GET_PAYMENT_INVOICE.FAILED:
			return {
				...initState,
				...state,
				payments: {
					...state.payments,
					isLoading: false,
					isFailure: true
				},
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
