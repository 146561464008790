import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Upload } from 'antd'
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const UploadField = ({
	name,
	label,
	showLabel,
	customClass,
	customLabelClass,
	required,
	disabled,
	customHelper,
	showCustomHelper,
	meta: { touched, error },
	imageUrl,
	customRequest,
	isLoading,
	onRemove
}) => {
	const { t } = useTranslation()
	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div>{t('Vyber súbor')}</div>
		</div>
	)

	const handleRemove = (e) => {
		e.stopPropagation()
		onRemove()
	}

	const renderImage = imageUrl ? (
		<>
			<img className={'upload-image'} src={imageUrl} alt={'avatar'} />
			{onRemove ? (
				<div className={'upload-remove-button'} onClick={(e) => handleRemove(e)}>
					<DeleteOutlined />
				</div>
			) : null}
		</>
	) : (
		uploadButton
	)

	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			{showLabel && (
				<label className={cx(customLabelClass, { required })} htmlFor={name}>
					{label}
				</label>
			)}
			<div className={'input-field'}>
				{showCustomHelper && (
					<span
						style={{
							fontSize: '0.9rem',
							lineHeight: 'normal',
							display: 'flex',
							color: '#aaaaaa'
						}}
					>
						{customHelper}
					</span>
				)}
				<Upload
					listType={'picture-card'}
					className={cx('upload-field', { 'upload-with-remove': onRemove })}
					showUploadList={false}
					customRequest={customRequest}
					disabled={disabled}
				>
					{/* eslint-disable-next-line no-nested-ternary */}
					{isLoading ? <LoadingOutlined /> : renderImage}
				</Upload>
			</div>
			<div className={'tooltip-error'}>{touched ? error : ''}</div>
		</div>
	)
}

UploadField.propTypes = {
	name: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({}).isRequired,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	imageUrl: PropTypes.string,
	customRequest: PropTypes.func,
	onRemove: PropTypes.func,
	isLoading: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	showCustomHelper: PropTypes.bool,
	customHelper: PropTypes.string
}

UploadField.defaultProps = {
	name: null,
	showLabel: true,
	label: '',
	customClass: null,
	customLabelClass: null,
	imageUrl: null,
	customRequest: null,
	onRemove: null,
	isLoading: false,
	required: false,
	disabled: false,
	showCustomHelper: true,
	customHelper: ''
}

export default UploadField
