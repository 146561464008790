import get from 'lodash/get'
import map from 'lodash/map'
import { Button, Row, Col, Tooltip } from 'antd'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { SendOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'

// utils
import { formatResultCode, formatAcquirerResponseId, formatSMSType, formatSMSStatus } from '../../../utils/helpers'
import { EMPTY_VALUE, FORMS, PAYMENT_METHOD } from '../../../utils/enums'

// forms
import validateForm, { DonationFormValues } from './validateDonationForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import TextField from '../../../atoms/form/TextField'

type DonationFormProps = {
	id: number
	setModalState: (args: any) => void
}

const DonationForm = ({ id, setModalState }: DonationFormProps) => {
	const { t } = useTranslation()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.DONATION_FORM)(state)) as DonationFormValues

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader title={t('Detail darovaného členstva')} />
						<Row gutter={12}>
							<Col span={12}>
								<Field name={'donorName'} component={TextField} label={t('Meno darcu')} size={'large'} disabled />
								<Field name={'donorEmail'} component={TextField} size={'large'} label={t('Email darcu')} disabled />
								<Field name={'recipientName'} component={TextField} label={t('Meno obdarovaného')} size={'large'} disabled />
								<Field name={'recipientEmail'} component={TextField} size={'large'} label={t('Email obdarovaného')} disabled />
							</Col>
							<Col span={12}>
								<Field name={'isRegistered'} component={TextField} label={t('Registrovaný uživatel')} size={'large'} disabled />
								<Field name={'subscriptionTier'} component={TextField} label={t('Typ členstva')} size={'large'} disabled />
								<Field name={'subscriptionType'} component={TextField} label={t('Trvanie členstva')} size={'large'} disabled />
								<Field name={'paymentFormated.paymentState'} component={TextField} label={t('Typ platby')} size={'large'} disabled />
								<Field name={'paymentFormated.paymentMethod'} component={TextField} label={t('Platobna metoda')} size={'large'} disabled />
							</Col>
						</Row>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'price'} component={TextField} label={t('Cena')} disabled />
						<div className={'highlight'}>
							<Field name={'code'} component={TextField} label={t('Kod priradeny k clenstvu')} disabled />
						</div>
						<Field name={'purchasedAt'} component={TextField} label={t('Zakúpený')} disabled />
						<Field name={'activatedAt'} component={TextField} label={t('Uplatnený')} disabled />

						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={fieldValues?.isUsed ? t('Po uplatnení promo kódu už nie je možné opätovne odoslať e-mail') : null}
								placement={'bottom'}
							>
								<Button
									icon={<SendOutlined />}
									onClick={() => setModalState({ visible: true, id })}
									type={'primary'}
									disabled={fieldValues?.isUsed}
									style={{ margin: '20px' }}
								>
									{t('Poslať znova')}
								</Button>
							</Tooltip>
						</div>
					</div>
				</Col>
			</Row>
			<Row gutter={16} style={{ marginTop: 16 }}>
				{(get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_BANK || get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_CARD) && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								<Row>
									{!!get(fieldValues, 'dataAccountId') && (
										<Field name={'dataAccountId'} component={TextField} label={t('ID účtu')} disabled />
									)}
									{!!get(fieldValues, 'dataReference') && (
										<Field name={'dataReference'} component={TextField} label={t('Referencia (Číslo faktúry)')} disabled />
									)}
								</Row>
								{!!get(fieldValues, 'dataAmount') && (
									<Row>
										<Field name={'dataAmount'} component={TextField} label={t('Cena')} disabled />
										<Field name={'dataCurrency'} component={TextField} label={t('Mena')} disabled />
									</Row>
								)}
								<Row>
									{!!get(fieldValues, 'dataType') && <Field name={'dataType'} component={TextField} label={t('Typ')} disabled />}
									{(!!get(fieldValues, 'dataResultCode') || get(fieldValues, 'dataResultCode') === 0) && (
										<Tooltip placement={'bottom'} title={formatResultCode(`${get(fieldValues, 'dataResultCode')}`)}>
											<div>
												<Field name={'dataResultCode'} component={TextField} label={t('Typ výsledku')} disabled />
											</div>
										</Tooltip>
									)}
									{!!get(fieldValues, 'dataAcquirerResponseId') && (
										<Tooltip placement={'bottom'} title={formatAcquirerResponseId(`${get(fieldValues, 'dataAcquirerResponseId')}`)}>
											<div>
												<Field name={'dataAcquirerResponseId'} component={TextField} label={t('Kód výsledku')} disabled />
											</div>
										</Tooltip>
									)}
								</Row>
								{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_CARD && (
									<>
										<Row>
											{!!get(fieldValues, 'cardId') && <Field name={'cardId'} component={TextField} label={t('Id karty')} disabled />}
											{!!get(fieldValues, 'cardMask') && (
												<Field name={'cardMask'} component={TextField} label={t('Posledné 4 čísla karty')} disabled />
											)}
										</Row>
										{!!get(fieldValues, 'cardRecTxSec') && (
											<Field name={'cardRecTxSec'} component={TextField} label={t('Rec Tx Sec')} disabled />
										)}
									</>
								)}
							</Col>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'dataCounterAccount') && (
									<Tooltip placement={'bottom'} title={get(fieldValues, 'dataCounterAccount')}>
										<div>
											<Field name={'dataCounterAccount'} component={TextField} label={t('IBAN')} disabled />
										</div>
									</Tooltip>
								)}
								{!!get(fieldValues, 'dataCounterAccountName') && (
									<Field name={'dataCounterAccountName'} component={TextField} label={t('Názov účtu')} disabled />
								)}
								{!!get(fieldValues, 'dataSignature') && (
									<Tooltip placement={'bottom'} title={get(fieldValues, 'dataSignature')}>
										<div>
											<Field name={'dataSignature'} component={TextField} label={t('Podpis')} disabled />
										</div>
									</Tooltip>
								)}
								{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.TRUSTPAY_CARD && (
									<>
										{!!get(fieldValues, 'cardExpiration') && (
											<Field name={'cardExpiration'} component={TextField} label={t('Expirácia karty')} disabled />
										)}
										{!!get(fieldValues, 'cardAuthNumber') && (
											<Field name={'cardAuthNumber'} component={TextField} label={t('Autorizačne číslo')} disabled />
										)}
									</>
								)}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.SMS && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								<Row style={{ marginBottom: '8px' }}>
									<Col span={16}>
										<b>{t('SMS Promokod')}</b>
									</Col>
									<Col span={16}>
										<span style={{ fontSize: '32px' }}>{get(fieldValues, 'smsCode', '------')}</span>
									</Col>
								</Row>
								<h3>{t('SMS notifikacie')}</h3>
								<Row style={{ marginBottom: '8px' }}>
									<Col span={3}>
										<b>{t('ID')}</b>
									</Col>
									<Col span={3}>
										<b>{t('SMS ID')}</b>
									</Col>
									<Col span={8}>
										<b>{t('Typ')}</b>
									</Col>
									<Col span={8}>
										<b>{t('Status')}</b>
									</Col>
								</Row>
								{map(get(fieldValues, 'smsNotifications'), (item: any) => (
									<Row style={{ padding: '8px', borderTop: '1px solid gray' }}>
										<Col span={3}>{item?.id}</Col>
										<Col span={3}>{item?.smsId || EMPTY_VALUE}</Col>
										<Col span={8}>{formatSMSType(item?.type)}</Col>
										<Col span={8}>{formatSMSStatus(item?.state)}</Col>
									</Row>
								))}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.BANK_TRANSACTION && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								{t(' Žiadne informácie o prevode')}
							</Col>
						</Row>
					</Col>
				)}
				{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.PAYPAL && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'paypalPayerID') && (
									<Field name={'paypalPayerID'} component={TextField} label={t('Id platitela')} disabled />
								)}
								{!!get(fieldValues, 'paypalToken') && <Field name={'paypalToken'} component={TextField} label={t('Paypal token')} disabled />}
								{!!get(fieldValues, 'paypalCreateTime') && (
									<Field name={'paypalCreateTime'} component={TextField} label={t('Čas vytvorenia')} disabled />
								)}
								{!!get(fieldValues, 'paypalSummary') && <Field name={'paypalSummary'} component={TextField} label={t('Sumar')} disabled />}
							</Col>
							<Col span={12} className={'grid flex direction-col justify-start main-content'}>
								{!!get(fieldValues, 'paypalPaymentId') && (
									<Field name={'paypalPaymentId'} component={TextField} label={t('Id platby')} disabled />
								)}
								{!!get(fieldValues, 'paypalEventType') && (
									<Field name={'paypalEventType'} component={TextField} label={t('Typ eventu')} disabled />
								)}
								{!!get(fieldValues, 'paypalId') && <Field name={'paypalId'} component={TextField} label={t('Paypal ID')} disabled />}
							</Col>
						</Row>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'}>
								{get(fieldValues, 'paymentMethod') === PAYMENT_METHOD.PAYPAL && typeof get(fieldValues, 'paypalData') === 'string' && (
									// eslint-disable-next-line react/no-danger
									<div dangerouslySetInnerHTML={{ __html: fieldValues?.paypalData }} />
								)}
							</Col>
						</Row>
					</Col>
				)}

				{get(fieldValues, 'paymentData') === null && (
					<Col span={18} className={'grid'}>
						<Row>
							<Col span={24} className={'grid flex direction-col justify-start main-content'} style={{ paddingBottom: 56, textAlign: 'center' }}>
								<strong>{t('Žiadne informácie o platbe sa nenašli')}</strong>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
		</form>
	)
}

export default reduxForm<{}, DonationFormProps>({
	form: FORMS.DONATION_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	validate: validateForm
})(DonationForm)
