// eslint-disable-next-line import/prefer-default-export
export const ITEM_TYPES = {
	TEXT: 'TEXT',
	BUTTON: 'BUTTON',
	IMAGE: 'IMAGE',
	H1: 'H1',
	H2: 'H2',
	H3: 'H3',
	H4: 'H4',
	YOUTUBE: 'YOUTUBE',
	TESTIMONIAL: 'TESTIMONIAL',
	COLORED_BOX: 'COLORED_BOX',
	RECIPE_STEPS: 'RECIPE_STEPS',
	EMBED_ELEMENT: 'EMBED_ELEMENT',
	TABLE: 'TABLE',
	RECIPE_STEP: 'RECIPE_STEP',
	NUTRITION: 'NUTRITION',
	INGREDIENTS: 'INGREDIENTS',
	GRID: 'GRID',
	DURATION: 'DURATION',
	PORTIONS: 'PORTIONS',
	PREPARATION_TIME: 'PREPARATION_TIME',
	REDIRECT_ELEMENT: 'REDIRECT_ELEMENT',
	USER_PROFILE: 'USER_PROFILE',
	PROFILE_CARD: 'PROFILE_CARD',
	HORIZONTAL_GAP: 'HORIZONTAL_GAP',
	REFERENCE: 'REFERENCE',
	FAQ: 'FAQ',
	BATCH: 'BATCH',
	STATISTICS: 'STATISTICS',
	ANCHOR: 'ANCHOR'
}
