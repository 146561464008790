import dayjs from 'dayjs'
import toNumber from 'lodash/toNumber'
import { submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../../redux'

import { sendOrderBonusRecipeBook, sendOrderStandardRecipeBook } from '../../../../redux/recipeBooks/actions'

// utils
import { FORMS, RECIPE_BOOK_TYPE } from '../../../../utils/enums'

// types
import { Paths } from '../../../../types/api'

// forms
import RecipeBooksForm from '../../forms/RecipeBooksForm'
import { RecipeBooksFormValues } from '../../forms/valiateRecipeBooksForm'

// styles
import * as SC from './RecipeBooksModalStyles'

type RecipeBooksModalProps = {
	open: boolean
	setOpen: (arg: boolean) => void
}

const RecipeBooksModal = ({ open = true, setOpen }: RecipeBooksModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const userDetail = useSelector((state: RootState) => state.users?.detail?.data)

	const handleSubmit = (values: RecipeBooksFormValues) => {
		let body: Paths.PostAdminUsersUserIdNutritionPlansStandardOrder.RequestBody | Paths.PostAdminUsersUserIdNutritionPlansBonusOrder.RequestBody

		if (values?.recipeBookType === RECIPE_BOOK_TYPE.STANDARD) {
			body = {
				goal: values.goal,
				height: toNumber(values.height),
				weight: values.weight,
				birthDate: values.birthDate.toString(),
				gender: values.gender,
				workDifficulty: values.workDifficulty,
				exerciseActivity: values.exerciseActivity,
				stress: values.stress,
				...(values?.exerciseActivityDetails && {
					exerciseActivityDetails: `${values?.exerciseActivityDetails}`
				})
			}
			dispatch(sendOrderStandardRecipeBook(userDetail?.id, body, () => setOpen(false)))
		} else if (values?.recipeBookType === RECIPE_BOOK_TYPE.BONUS) {
			body = {
				date: dayjs(values?.date).format('YYYY-MM-DD')
			}
			dispatch(sendOrderBonusRecipeBook(userDetail?.id, body, () => setOpen(false)))
		}
	}

	return (
		<SC.StyledModal
			open={open}
			title={t('Žiadosť o vygenerovanie nového jedálnička')}
			okText={t('Odoslať')}
			onOk={() => {
				dispatch(submit(FORMS.RECIPE_BOOKS_FORM))
			}}
			cancelText={t('Zrušiť')}
			onCancel={() => setOpen(false)}
			destroyOnClose
		>
			<RecipeBooksForm onSubmit={handleSubmit} />
		</SC.StyledModal>
	)
}

export default RecipeBooksModal
