import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { GET_ADMINISTRATORS, GET_ADMINISTRATOR, DELETE_ADMINISTRATOR, UPDATE_ADMINISTRATOR, CREATE_ADMINISTRATOR } from './types'
import { Paths } from '../../types/api'

export const getAdministrators = (params: Paths.GetAdminAdministrators.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_ADMINISTRATORS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.ADMINISTRATORS, normalizeQueryParams)

		dispatch({
			type: GET_ADMINISTRATORS.DONE,
			payload: {
				tableList: data.administrators,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_ADMINISTRATORS.FAILED })
		Promise.reject(error)
	}
}

export const getAdministrator = (id: number, onSuccess?: (args: any) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_ADMINISTRATOR.START })
	try {
		const { data } = await getReq(ENDPOINTS.ADMINISTRATOR(id))
		dispatch({
			type: GET_ADMINISTRATOR.DONE,
			payload: data
		})

		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({
			type: GET_ADMINISTRATOR.FAILED
		})
		return onFailure && onFailure(error)
	}
}

export const deleteAdministrator = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_ADMINISTRATOR.START })
	try {
		await deleteReq(ENDPOINTS.ADMINISTRATOR(id))
		dispatch({ type: DELETE_ADMINISTRATOR.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_ADMINISTRATOR.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createAdministrator =
	(body: Paths.PostAdminAdministrators.RequestBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_ADMINISTRATOR.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await postReq(ENDPOINTS.ADMINISTRATORS, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_ADMINISTRATOR.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CREATE_ADMINISTRATOR.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updateAdministrator =
	(id: number, body: Paths.PutAdminAdministratorsId.RequestBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_ADMINISTRATOR.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await putReq(ENDPOINTS.ADMINISTRATOR(id), undefined, normalizeQueryParams)
			dispatch({ type: UPDATE_ADMINISTRATOR.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_ADMINISTRATOR.FAILED })
			return onFailure && onFailure(error)
		}
	}
