import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import { Paths } from '../../types/api'
import {
	GET_PROGRAM,
	GET_PROGRAMS,
	DELETE_PROGRAM,
	UPDATE_PROGRAM,
	CREATE_PROGRAM,
	LOAD_CHALLENGE,
	DELETE_CHALLENGE,
	UPDATE_CHALLENGE,
	CREATE_CHALLENGE,
	EXPORT_CHALLENGE_USERS,
	GET_PROGRAM_USERS,
	ProgramPayload,
	ChallengePayload
} from './types'

export const getPrograms = (params: Paths.GetAdminPrograms.QueryParameters) => async (dispatch: any) => {
	dispatch({ type: GET_PROGRAMS.START })

	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_PROGRAMS, normalizeQueryParams)

		const tableList = map(data.programs, (item) => ({
			key: get(item, 'id'),
			logo: get(item, 'logo'),
			name: get(item, 'name'),
			isChallenge: get(item, 'isChallenge'),
			challengeType: get(item, 'challengeType'),
			questions: get(item, 'questions', null),
			order: get(item, 'order'),
			isPublished: get(item, 'isPublished'),
			publishDate: get(item, 'publishDate'),
			isVisibleOnLandingPage: get(item, 'isVisibleOnLandingPage'),
			updatedAt: dayjs(get(item, 'updatedAt'))
		}))

		const selectList = map(data.programs, (item) => ({
			value: get(item, 'id'),
			label: get(item, 'name'),
			difficulties: item?.difficulties,
			isChallenge: get(item, 'isChallenge'),
			challengeType: get(item, 'challengeType'),
			questions: get(item, 'questions', null)
		}))

		dispatch({
			type: GET_PROGRAMS.DONE,
			payload: {
				tableList,
				selectList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_PROGRAMS.FAILED })
		Promise.reject(error)
	}
}

export const getProgram = (id: number, onSuccess?: (args: ProgramPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_PROGRAM.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_PROGRAM(id))
		dispatch({ type: GET_PROGRAM.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_PROGRAM.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getProgramUsers =
	(id: number, params?: Paths.GetAdminProgramsIdUsers.QueryParameters, onSuccess?: (args: any) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_PROGRAM_USERS.START })
		try {
			const queries = {
				limit: 20,
				page: 1,
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_PROGRAM_USERS(id), normalizeQueryParams)

			dispatch({ type: GET_PROGRAM_USERS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({
				type: GET_PROGRAM_USERS.FAILED
			})
			return onFailure && onFailure(error)
		}
	}

export const exportChallengeUsers = (id: number, onSuccess?: (args: any) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: EXPORT_CHALLENGE_USERS.START })
	try {
		const { data } = await getReq(ENDPOINTS.EXPORT_CHALLENGE_USERS(id))
		dispatch({ type: EXPORT_CHALLENGE_USERS.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: EXPORT_CHALLENGE_USERS.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getChallenge = (id: number, onSuccess?: (args: ChallengePayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: LOAD_CHALLENGE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_CHALLENGE(id))
		dispatch({ type: LOAD_CHALLENGE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: LOAD_CHALLENGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteProgram = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_PROGRAM.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_PROGRAM(id))
		dispatch({ type: DELETE_PROGRAM.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_PROGRAM.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteChallenge = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_CHALLENGE.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_CHALLENGE(id))
		dispatch({ type: DELETE_CHALLENGE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_CHALLENGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createProgram =
	(body: Paths.PostAdminPrograms.RequestBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_PROGRAM.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => (query || query === false || query === 0 ? query : undefined))
			const { data } = await postReq(ENDPOINTS.CREATE_PROGRAM, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_PROGRAM.DONE })
			return onSuccess && onSuccess(get(data, 'data.id'))
		} catch (error) {
			dispatch({ type: CREATE_PROGRAM.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createChallenge =
	(body: Paths.PostAdminChallenges.RequestBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_CHALLENGE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => (query || query === false || query === 0 ? query : undefined))
			const { data } = await postReq(ENDPOINTS.CREATE_CHALLENGE, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_CHALLENGE.DONE })
			return onSuccess && onSuccess(get(data, 'data.id'))
		} catch (error) {
			dispatch({ type: CREATE_CHALLENGE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updateProgram =
	(id: number, body: Paths.PutAdminProgramsId.RequestBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_PROGRAM.START })
		try {
			await putReq(ENDPOINTS.UPDATE_PROGRAM(id), undefined, body)
			dispatch({ type: UPDATE_PROGRAM.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_PROGRAM.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updateChallenge =
	(id: number, body: Paths.PutAdminChallengesId.RequestBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_CHALLENGE.START })
		try {
			await putReq(ENDPOINTS.UPDATE_CHALLENGE(id), undefined, body)
			dispatch({ type: UPDATE_CHALLENGE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_CHALLENGE.FAILED })
			return onFailure && onFailure(error)
		}
	}
