import * as ACTIONS from './types'
import { ILoadingAndFailure } from '../../types/interfaces'

interface IList {
	list: object
}

export const initState = {
	isLoading: false,
	isFailure: false,
	list: {}
} as ILoadingAndFailure & IList

export default (state = initState, action: { type: string; payload?: any }) => {
	switch (action.type) {
		case ACTIONS.LOAD_LIST.START:
			return {
				...initState,
				...state,
				isLoading: true,
				isFailure: false
			}
		case ACTIONS.LOAD_LIST.FAILED:
			return {
				...initState,
				isLoading: false,
				isFailure: true
			}
		case ACTIONS.LOAD_LIST.DONE:
			return {
				...initState,
				isLoading: false,
				isFailure: false,
				list: action.payload
			}
		default:
			return state
	}
}
